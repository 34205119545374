import { ButtonDefault } from '@components/Buttons/ButtonDefault';
import CardHome from '@components/Home/CardHome';
import { useOrganization } from '@hooks/organization/useOrganization';
import { useEffect, useState } from 'react';
import searchIcon from '../../assets/icons/search.svg';
import { useTranslation } from 'react-i18next';
import useDebounce from '@hooks/useDebounce';
import { GlobeAltIcon } from '@heroicons/react/24/solid';
import Loading from '@components/loading';
import Input from '@components/Inputs';

export function DashboardSuperAdmin() {
  const { t } = useTranslation();
  const { getOrganizations, organizations } = useOrganization();
  const [loading, setLoading] = useState(false);
  const [organizationSearch, setOrganizationSearch] = useState<string>('');
  const debouncedOrganizationSearch = useDebounce(organizationSearch, 400);

  useEffect(() => {
    setLoading(true);
    getOrganizations(`sortBy=created_at&sortDirection=desc&search=${debouncedOrganizationSearch}`).finally(() => {
      setLoading(false);
    });
  }, [debouncedOrganizationSearch]);

  if (loading) {
    return (
      <div className="w-full flex justify-center items-center h-full">
        <Loading className="!w-10 !h-10 !mx-auto my-0" />
      </div>
    );
  }

  return (
    <div className="flex flex-col mt-4 gap-4 px-4">
      {/* <div className="flex flex-col gap-2">
                    <h1 className="text-4xl text-gray-800 font-bold">
                        Organizações
                    </h1>
                    <h2 className="text-gray-600 text-base">
                        Aqui você pode administrar suas organizações.
                    </h2>
                </div> */}
      <div className="mt-4 flex justify-between gap-3">
        <div className="relative rounded-full flex items-center gap-1 px-3 py-1">
          <Input
            type="search"
            name="search"
            value={organizationSearch}
            onChange={(e) => setOrganizationSearch(e.target.value)}
            id="search"
            placeholder={`${t('search')}...`}
          />
          {organizationSearch.length == 0 && (
            <img src={searchIcon} alt="Search icon" className="w-5 absolute right-5" />
          )}
        </div>
        <ButtonDefault href="/dashboard/organization/create" content={t('new_organization')} />
      </div>
      <>
        <div className="grid lg:grid-cols-4 grid-cols-2 w-full justify-center gap-4">
          {organizations?.map((organization) => (
            <CardHome
              key={organization.uuid}
              link={`/dashboard/organization/${organization.uuid}`}
              description={organization.name}
              icon={<GlobeAltIcon className="h-9" />}
              type="primary"
            />
          ))}
        </div>
      </>
    </div>
  );
}
