import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enTranslations from '../locale/en.json';
import ptTranslations from '../locale/pt.json';

const initI18n = (language: string) => {
  i18n
    .use(initReactI18next)
    .init({
      lng: language,
      resources: {
        en: enTranslations,
        pt: ptTranslations,
      },
    });
};

const userJSON = sessionStorage.getItem('user-auth');

if (userJSON) {
  const user = JSON.parse(userJSON);

  if (user && user.state && user.state.user && user.state.user.language && user.state.user.language.language_code) {
    initI18n(user.state.user.language.language_code);
  } else {
    initI18n('en');
  }
}





