import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';

export const NextMonth = () => (
  <button className="p-2 hover:bg-primary/20 rounded-lg absolute top-5 right-6 text-primary text-base">
    <ChevronRightIcon className="text-2xl w-6" />
  </button>
);

export const PrevMonth = () => (
  <button className="p-2 hover:bg-primary/20 rounded-lg absolute top-5 right-16 text-primary text-base">
    <ChevronLeftIcon className="text-2xl  w-6" />
  </button>
);

export const Month = ({
  month,
}: {
  month: moment.Moment;
  onMonthSelect: (currentMonth: moment.Moment, newMonthVal: string) => void;
  onYearSelect: (currentMonth: moment.Moment, newMonthVal: string) => void;
  isVisible: boolean;
}) => (
  <div className="flex flex-row gap-2 items-center justify-start px-2">
    <span className="text-base text-gray-600 font-bold">{month.format('MMMM')}</span>
    <span className="text-base text-gray-600 font-bold">{month.year()}</span>
  </div>
);
