import { Modal, Toggle } from 'rsuite';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import './style.css';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import DateRangerPicker from '@components/DatePicker';
import Button from '@components/Common/Buttons/Button';

interface ModalContentProps {
  open: boolean;
  onClose: () => void;
  title: string;
  onConfirm: (overrideUserRequest?: boolean, startDeteConfirm?: string, endDateConfirm?: string, loading?: boolean) => void;
  startDate: string;
  endDate: string;
  loading?: boolean;
}

interface DateRange {
  startDate: string;
  endDate: string;
}

export default function ModalConfirmRequestVacation({
  open,
  onClose,
  title,
  onConfirm,
  startDate,
  endDate,
  loading
}: ModalContentProps) {
  const { t } = useTranslation();
  const [isCorrectionMode, setIsCorrectionMode] = useState(false);
  const [startDateSelected, setStartDateSelected] = useState<[Date, Date] | null>(null);
  const [endDateSelected, setEndDateSelected] = useState<[Date, Date] | null>(null);
  const [startDateRangeSelected, setStartDateRangeSelected] = useState<DateRange | null>(null);
  const [endDateRangeSelected, setEndDateRangeSelected] = useState<DateRange | null>(null);
  const [isDisabledButton, setIsDisabledButton] = useState(true);

  useEffect(() => {
    if (!isCorrectionMode || startDateRangeSelected && endDateRangeSelected) {
      setIsDisabledButton(false);
    } else {
      setIsDisabledButton(true);
    }
  }, [isCorrectionMode, startDateRangeSelected, endDateRangeSelected]);

  return (
    <Modal open={open} onClose={onClose} size='sm'>
      <Modal.Header>
        <Modal.Title><p className='text-2xl font-bold'>{title}</p></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='flex flex-col gap-3'>
          <div className="bg-gray-200 rounded-xl py-3 px-4 mt-2 flex flex-col gap-2">
            <div className="flex gap-2">
              <h3 className="text-gray-700 text-base font-bold">{t('fix_request')}?</h3>
              <Toggle onChange={value => setIsCorrectionMode(value)} />
            </div>
            <div className='grid md:grid-cols-2 grid-cols-1 gap-2'>
              {startDate === endDate ? (
                <>
                  <div>
                    <p className="text-gray-700 text-base font-medium">{t('start_date')}</p>
                    <span>{startDate}</span>
                  </div>
                </>
              ) : (
                <>
                  <div>
                    <p className="text-gray-700 text-base font-medium">{t('start_date')}</p>
                    <span>{startDate}</span>
                  </div>
                  <div>
                    <p className="text-gray-700 text-base font-medium">{t('end_date')}</p>
                    <span>{endDate}</span>
                  </div>
                </>
              )}

            </div>
            <div className={`transition-opacity duration-300 ease-in-out ${isCorrectionMode ? 'opacity-100' : 'opacity-0'}`}>
              {isCorrectionMode && (
                <div>
                  <p className='text-gray-700 text-base'>{t('fix_request_for')}:</p>
                  <div className='grid md:grid-cols-2 grid-cols-1 gap-2'>
                    <div>
                      <p className="text-gray-700 text-base font-medium">{t('start_date_confirm')}</p>
                      <DateRangerPicker
                        typeDate="date"
                        dateSelected={startDateSelected}
                        setDateSelected={setStartDateSelected}
                        setDateRangeSelected={setStartDateRangeSelected}
                        dateRangeSelected={startDateRangeSelected}
                      />
                    </div>
                    <div>
                      <p className="text-gray-700 text-base font-medium">{t('end_date_confirm')}</p>
                      <DateRangerPicker
                        typeDate="date"
                        dateSelected={endDateSelected}
                        setDateSelected={setEndDateSelected}
                        setDateRangeSelected={setEndDateRangeSelected}
                        dateRangeSelected={endDateRangeSelected}
                      />

                    </div>
                  </div>
                </div>
              )}
            </div>

          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="mt-6 mb-2 flex gap-2">
          <Button
            onClick={onClose}
            buttonType={Button.Type.SECONDARY}
            disabled={loading}
          >
            {t('cancel')}
          </Button>
          <Button
            onClick={() => onConfirm(isCorrectionMode, startDateRangeSelected?.startDate, endDateRangeSelected?.endDate,)}
            disabled={isDisabledButton}
            loading={loading}
          >
            {t('confirm_request')}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
