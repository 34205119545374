import { useTranslation } from 'react-i18next';

interface NavigationTableProps {
  currentPage: number;
  onPrev(): void;
  onNext(): void;
  onSelectPage(page: number): void;
  infoPage: {
    last_page: number;
    next_page_url: string | null;
  };
}

function NavigationTable({ currentPage, onNext, onPrev, infoPage, onSelectPage }: NavigationTableProps) {
  const { t } = useTranslation();
  const HAS_NEXT_PAGE = infoPage.last_page >= currentPage + 1 && infoPage.next_page_url;

  if (currentPage == 1 && infoPage.next_page_url === null && infoPage.last_page == 1) {
    return <div></div>;
  }

  const pageNumbers: any = [];
  const totalPages = infoPage.last_page;
  const MAX_VISIBLE_PAGES = 7;

  if (totalPages <= MAX_VISIBLE_PAGES) {
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }
  } else {
    if (currentPage <= MAX_VISIBLE_PAGES - 2) {
      for (let i = 1; i <= MAX_VISIBLE_PAGES - 1; i++) {
        pageNumbers.push(i);
      }
      pageNumbers.push('...');
      pageNumbers.push(totalPages - 1);
      pageNumbers.push(totalPages);
    } else if (currentPage >= totalPages - (MAX_VISIBLE_PAGES - 3)) {
      pageNumbers.push(1);
      pageNumbers.push(2);
      pageNumbers.push('...');
      for (let i = totalPages - (MAX_VISIBLE_PAGES - 2); i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      pageNumbers.push(1);
      pageNumbers.push(2);
      pageNumbers.push('...');

      for (let i = currentPage - 1; i <= currentPage + 1; i++) {
        pageNumbers.push(i);
      }

      pageNumbers.push('...');
      pageNumbers.push(totalPages - 1);
      pageNumbers.push(totalPages);
    }
  }

  return (
    <nav className="bg-gray-50 border-t border-gray-200 pb-2 flex items-center justify-between sm:px-0">
      <div className="-mt-px w-0 flex-1 flex">
        {currentPage !== 1 && (
          <button
            onClick={onPrev}
            className="p-2 border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm font-medium text-primary/90 hover:text-gray-700 hover:border-primary/90"
          >
            <svg
              className="mr-3 h-5 w-5 text-gray-400"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
            {t('previous')}
          </button>
        )}
      </div>
      <div className="hidden md:-mt-px md:flex">
        {pageNumbers.map((pageNumber: any, index: number) => (
          <span key={index} aria-current={pageNumber === currentPage ? 'page' : undefined}>
            {pageNumber === '...' ? (
              <span className="border-transparent text-primary/90 border-b-2 pt-4 px-4 inline-flex items-center text-sm font-medium">
                ...
              </span>
            ) : (
              <button
                onClick={() => {
                  onSelectPage(Number(pageNumber));
                }}
                className={`border-transparent text-primary/90 hover:text-gray-700 hover:border-primary/90 border-b-2 pt-4 px-4 inline-flex items-center text-sm font-medium ${
                  Number(pageNumber) === currentPage ? '!border-b-2 !border-[#3F3524] !text-[#3F3524]' : ''
                }`}
                aria-label={`Go to page ${pageNumber}`}
              >
                {pageNumber}
              </button>
            )}
          </span>
        ))}
      </div>
      {HAS_NEXT_PAGE ? (
        <div className="-mt-px w-0 flex-1 flex justify-end">
          <button
            onClick={onNext}
            className="p-2 border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm font-medium text-primary/90 hover:text-gray-700 hover:border-primary/90"
          >
            {t('next')}
            <svg
              className="ml-3 h-5 w-5 text-gray-400"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>
        </div>
      ) : (
        <div className="flex-1"></div>
      )}
    </nav>
  );
}

export default NavigationTable;
