import ModalHeader from './ModalHeader';
import ModalBody from './ModalBody';
import ModalFooter from './ModalFooter';
import { HTMLAttributes } from 'react';
import { classNames } from '@components/Badges/StatusBadge';

export interface ModalProps extends HTMLAttributes<HTMLDivElement> {
  open?: boolean;
  setOpen?: (data: boolean) => void | undefined;
}

export default function Modal({ open, className, children, setOpen, ...props }: ModalProps) {
  if (open) {
    return (
      <div className="z-20" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div
          className="fixed inset-0 bg-black bg-opacity-20 transition-opacity backdrop-filter backdrop-blur-[3px] z-[50]"
          onClick={() => {
            setOpen?.(!open);
          }}
        />

        <div className="fixed inset-0 z-[60] w-screen overflow-y-auto">
          <div className="absolute flex items-center justify-center w-full h-full max-h-[inherit]">
            <>
              <div className="fixed inset-0 bg-black bg-opacity-30 transition-opacity backdrop-filter backdrop-blur-md z-[50]" />
              <div
                className={classNames(
                  'flex flex-col z-50 relative bg-gray-50 gap-10 shadow-lg rounded-2xl max-w-lg w-full md:max-w-3xl lg:max-w-5xl h-full',
                  className,
                )}
                {...props}
              >
                {children}
              </div>
            </>
          </div>
        </div>
      </div>
    );
  }
  return <></>;
}

Modal.Header = ModalHeader;
Modal.Body = ModalBody;
Modal.Footer = ModalFooter;
