import { useAuth } from '@hooks/auth/useAuth';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import Card from './Card';
import Row from '@components/Flex/Row';
import { Toggle } from 'rsuite';
import useAxios from '@hooks/axios/useAxios';
import Loading from '@components/loading';

export default function NotificationsSettings() {
  const { t } = useTranslation();
  const { user } = useAuth();
  const [emailNotifications, setEmailNotifications] = useState<boolean>(user?.email_notifications!);

  const { loading: editUserLoading, request: editUserRequest } = useAxios();

  const updateUserNotifications = useCallback(
    async (emailNotifications: boolean) => {
      const form = new FormData();
      form.append('email_notifications', String(Number(emailNotifications)));
      form.append('_method', 'PUT');

      try {
        const response = await editUserRequest(`/api/users/${user?.uuid}`, 'POST', form);

        if (response.data && response.data.content.data) {
          const newUser = response.data.content.data;

          const sessionDataString = sessionStorage.getItem('user-auth');
          if (sessionDataString !== null) {
            const sessionData = JSON.parse(sessionDataString);

            sessionData.state.user.email_notifications = newUser.email_notifications;

            sessionStorage.setItem('user-auth', JSON.stringify(sessionData));
          }
          toast.success(t('user_updated_successfully'));
        } else {
          toast.error(t('error_updating_user'));
        }
      } catch (error: any) {
        toast.error(t('error_updating_user'));
        console.error(error);
      }
    },
    [user],
  );

  return (
    <Card gap={3}>
      <span className="text-blue-900 font-semibold text-base">{t('notifications')}</span>

      {(editUserLoading && <Loading />) || (
        <>
          <Row gap={2} className="items-center">
            <Toggle
              className=" my-2"
              checked={emailNotifications}
              onChange={setEmailNotifications}
              onClick={() => updateUserNotifications?.(!emailNotifications)}
            />
            {t('email_notifications')}
          </Row>
        </>
      )}
    </Card>
  );
}
