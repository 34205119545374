import { useEffect, useMemo, useState } from 'react';
import { ModalDelete } from '../../components/Modal/ModalDelete';
import { useAuth } from '@hooks/auth/useAuth';
import { api } from 'services/api';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Location, useLocation } from '@hooks/location/useLocation';
import { toast } from 'react-toastify';

import { useTranslation } from 'react-i18next';

import axios from 'axios';
import { QrCodeIcon } from '@heroicons/react/24/solid';
import Loading from '@components/loading';

export default function ReadLocation() {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { getLocationById, deleteLocation } = useLocation();
  const { locationUuid } = useParams<{ locationUuid: string }>();
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [location, setLocation] = useState<Location | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    if (!locationUuid) {
      return;
    }
    async function getLocation() {
      const { location } = await getLocationById(user?.organization?.uuid!, locationUuid!);
      setLocation(location);
    }

    getLocation()
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  }, [getLocationById, locationUuid]);

  const handleDeleteButtonClick = () => {
    setIsModalDeleteOpen(true);
  };

  const qrCodeUrl = useMemo(async () => {
    try {
      const response = await axios.get(
        `${api.defaults.baseURL}api/organizations/${user?.organization?.uuid}/locations/${locationUuid}/presence-qrcode.pdf`,
        {
          headers: {
            Authorization: `Bearer ${user?.token}`,
          },
          responseType: 'arraybuffer',
        },
      );

      const blob = new Blob([response.data], { type: 'application/pdf' });
      const blobUrl = URL.createObjectURL(blob);

      return blobUrl;
    } catch (error) {
      console.error(error);
      return null;
    }
  }, [user, locationUuid]);

  const handleDownloadQRCode = async () => {
    const url = await qrCodeUrl;

    if (url) {
      const a = document.createElement('a');
      a.href = url;
      a.download = 'qr-code.pdf'; // Nome do arquivo a ser baixado
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  return (
    <main className="px-4">
      <div className="flex flex-col mt-5 lg:max-w-5xl md:max-w-3xl max-w-lg w-full mx-auto">
        <div className="flex flex-col gap-2">
          <h1 className="text-4xl text-gray-800 font-bold">{location?.name}</h1>
        </div>
      </div>
      {loading ? (
        <Loading className="w-full mx-auto" />
      ) : (
        <div className="mt-5 p-4 bg-gray-100 rounded-lg shadow-lg text-gray-600 mb-11 lg:max-w-5xl md:max-w-3xl max-w-lg w-full mx-auto">
          <div className="w-full grid grid-cols-1 gap-2 mb-3">
            <div>
              <label htmlFor="" className="font-bold">
                {t('name')}
              </label>
              <p className="bg-gray-50 rounded-lg p-2 mt-1">{location?.name}</p>
            </div>
          </div>
          <div className="w-full grid lg:grid-cols-4 grid-cols-2 gap-2 mb-3">
            <div className="col-span-3">
              <label htmlFor="" className="font-bold">
                {t('address')}
              </label>
              <p className="bg-gray-50 rounded-lg p-2 mt-1">{location?.street_address}</p>
            </div>
            {/* <div>
              <label htmlFor="" className="font-bold">
                Número
              </label>
              <p className="bg-gray-50 rounded-lg p-2 mt-1">{location?.number}</p>
            </div>
            <div>
              <label htmlFor="" className="font-bold">
                Complemento
              </label>
              <p className="bg-gray-50 rounded-lg p-2 mt-1">{location?.complement == null ? `...` : location?.complement}</p>
            </div>
            <div>
              <label htmlFor="" className="font-bold">
                Bairro
              </label>
              <p className="bg-gray-50 rounded-lg p-2 mt-1">{location?.neighborhood}</p>
            </div> */}
            <div className="col-span-1">
              <label htmlFor="" className="font-bold">
                {t('zip_code')}
              </label>
              <p className="bg-gray-50 rounded-lg p-2 mt-1">{location?.zip_code}</p>
            </div>
          </div>
          <div className="w-full grid lg:grid-cols-4 grid-cols-2 gap-2 mb-3">
            <div className="col-span-2">
              <label htmlFor="" className="font-bold">
                {t('city')}
              </label>
              <p className="bg-gray-50 rounded-lg p-2 mt-1">{location?.city}</p>
            </div>
            {/* <div>
              <label htmlFor="" className="font-bold">
                Estado
              </label>
              <p className="bg-gray-50 rounded-lg p-2 mt-1">{location?.state}</p>
            </div> */}
            <div>
              <label htmlFor="" className="font-bold">
                Latitude
              </label>
              <p className="bg-gray-50 rounded-lg p-2 mt-1">{location?.lat}</p>
            </div>
            <div>
              <label htmlFor="" className="font-bold">
                Longitude
              </label>
              <p className="bg-gray-50 rounded-lg p-2 mt-1">{location?.lng}</p>
            </div>
          </div>

          <div className="mt-6 mb-4 flex md:flex-row flex-col gap-3">
            <Link
              to={`/organizations/${user?.organization?.uuid}/dashboard/location/${location?.uuid}/edit`}
              className="bg-primary text-sm cursor-pointer hover:brightness-[.95] text-white font-medium w-max py-3 px-5 rounded-md transition-all mr-3"
            >
              {t('edit_location')}
            </Link>
            <Link
              to="#"
              onClick={handleDeleteButtonClick}
              className="bg-gray-50 text-sm cursor-pointer hover:brightness-[.95] text-gray-600 font-medium w-max p-3 rounded-md transition-all border border-solid border-primary/90 mr-3"
            >
              {t('delete_location')}
            </Link>
            <Link
              to={'#'}
              onClick={handleDownloadQRCode}
              className="flex gap-2 items-center bg-gray-50 text-sm cursor-pointer hover:brightness-[.95] text-gray-600 font-medium w-max p-3 rounded-md transition-all border border-solid border-primary/90"
              rel="noreferrer"
            >
              <QrCodeIcon className="w-5 h-5" />
              QrCode
            </Link>
            <Link
              to={`/organizations/${user?.organization?.uuid}/dashboard/location/${location?.id}/holidays`}
              className="flex gap-2 items-center bg-gray-50 text-sm cursor-pointer hover:brightness-[.95] text-gray-600 font-medium w-max p-3 rounded-md transition-all border border-solid border-primary/90"
              rel="noreferrer"
            >
              {t('holidays')}
            </Link>
          </div>
        </div>
      )}

      {isModalDeleteOpen && (
        <ModalDelete
          isOpen={isModalDeleteOpen}
          onClose={() => setIsModalDeleteOpen(false)}
          title={t('delete_location')}
          content={`${t('are_you_sure_you_want_to_delete_the_location')} ${location?.name} ?`}
          onConfirm={() => {
            deleteLocation(user?.organization?.uuid!, locationUuid!)
              .then(() => {
                toast.success(t('location_successfully_deleted'));
                navigate(`/organizations/${user?.organization?.uuid}/dashboard/locations`);
              })
              .catch((error) => {
                toast.error(t('error_deleting_location'), error);
              })
              .finally(() => {
                setIsModalDeleteOpen(false);
              });
          }}
        />
      )}
    </main>
  );
}
