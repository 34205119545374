import Col, { ColProps } from '@components/Flex/Col';

export interface CardProps extends ColProps {}
export default function Card({ children, className, ...props }: CardProps) {
  return (
    <Col className={`w-full bg-white border items-start border-gray-300 rounded-lg p-2 ${className}`} {...props}>
      {children}
    </Col>
  );
}
