import { LIMIT_RECORDS } from 'App';
import { api } from 'services/api';
import { create } from 'zustand';
import { PointProps } from './usePoint';
import { values } from 'lodash';
import { toast } from 'react-toastify';

type PresencesState = {
  currentSection: 'presences' | 'point' | 'absence' | 'vocation';
  setCurrentSection: (section: 'presences' | 'point' | 'absence' | 'vocation') => void;
  isOpenQrModal: boolean;
  setIsOpenQrModal: (value: boolean) => void;
  clearConfigPresences(): void;
  getOrganizationPresences: (organizationUuid: string) => Promise<any>;
  globalPresences: PointProps[];
  setGlobalPresences: (points: PointProps[]) => void;
  refreshGlobalPresences: (
    organizationUuid: string,
    userId: string,
    day: number,
    month: number,
    year: number,
  ) => Promise<any>;
};

export const usePresences = create<PresencesState>((set) => ({
  currentSection: 'point',
  setCurrentSection: (section) => set({ currentSection: section }),
  isOpenQrModal: false,
  setIsOpenQrModal: (value) => set({ isOpenQrModal: value }),
  clearConfigPresences: () => set({ isOpenQrModal: false, currentSection: 'point' }),
  getOrganizationPresences: (organizationUuid: string) => {
    return api
      .get(`/api/organizations/${organizationUuid}/presences?take=${LIMIT_RECORDS}`)
      .then(({ data }) => data?.content?.data?.data);
  },

  globalPresences: [],
  setGlobalPresences: (points) => set({ globalPresences: points }),
  refreshGlobalPresences: (organizationUuid, userId, day, month, year) => {
    return api
      .get(
        `/api/organizations/${organizationUuid}/presences/user/${userId}/${year}/${month}/${day}?sortBy=created_at&sortDirection=desc`,
      )
      .then(({ data }) => {
        set({ globalPresences: data?.content?.data?.data });
      })
      .catch(({ response }) => {
        values(response?.data?.errors)?.map((errors: any) => errors?.map((error: any) => toast.error(error)));
      });
  },
}));
