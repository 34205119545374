import { classNames } from '@components/Badges/StatusBadge';
import { HTMLAttributes } from 'react';

export interface ModalFooterProps extends HTMLAttributes<HTMLDivElement> {}

export default function ModalFooter({ children, className, title, ...props }: ModalFooterProps) {
  return (
    <div className={classNames('flex flex-row items-center w-full px-6 pb-6', className)} {...props}>
      {children}
    </div>
  );
}
