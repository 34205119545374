import { create } from 'zustand';

type QrCodeArgs = {
  qrCodeScanContent?: string | null;
  setQrCodeScanContent: (qrCodeScanContent?: string | null) => void;

  qrCodeExpenseContent?: string | null;
  setQrCodeExpenseContent: (qrCodeExpenseContent?: string | null) => void;
};

export const useQrCode = create<QrCodeArgs>((set) => ({
  qrCodeScanContent: undefined,
  setQrCodeScanContent: (qrCodeScanContent) => set({ qrCodeScanContent }),

  qrCodeExpenseContent: undefined,
  setQrCodeExpenseContent: (qrCodeExpenseContent) => set({ qrCodeExpenseContent }),
}));
