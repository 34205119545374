import { useCallback, useEffect, useState } from 'react';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import AttachmentFilePresenceModal from '../../Presence/Modals/AttachmentFilePresenceModal';
import { ModalDenyAbsence } from '@components/Modal/ModalDenyAbsence';
import { useAuth } from '@hooks/auth/useAuth';
import StatusBadge, { BadgeStatus } from '@components/Badges/StatusBadge';
import { toast } from 'react-toastify';
import Button from '@components/Common/Buttons/Button';
import ButtonFloat from '@components/Common/Buttons/ButtonFloat';
import { values } from 'lodash';
import useAxios from '@hooks/axios/useAxios';
import { useTranslation } from 'react-i18next';
import { ButtonToolbar, Timeline } from 'rsuite';
import ModalConfirmRequestVacation from '@components/Modal/ModalConfirmRequestVacation';
import { Vacation, useVacations } from '@hooks/presence/useVacations';
import { DayPickerSingleDateController } from 'react-dates';
import { getCurrentDimension } from '@components/Presence/Sections/AbsenceSection';
import moment from 'moment';
import parseISO from 'date-fns/esm/parseISO/index.js';
import { format, parse } from 'date-fns';
import { useAbsences } from '@hooks/presence/useAbsences';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useOrganization } from '@hooks/organization/useOrganization';
import { PresenceSummary } from '@components/Calendar/Summary/PresenceSummary';
import { AbsenceMark, HolidayMark, VacationMark } from '@components/Calendar/Summary';
import { Month, NextMonth, PrevMonth } from '@components/Calendar';
import { ArrowPathIcon, CheckIcon, XMarkIcon } from '@heroicons/react/24/solid';
import Loading from '@components/loading';
import { ApprovedDot, PendingDot, RejectedDot } from '@components/Timeline/Dots';

type ModalDenyState = Record<string, boolean>;

function VacationSection() {
  const { t } = useTranslation();
  const { user } = useAuth();

  const [modalDenyState, setModalDenyState] = useState<ModalDenyState>({});
  const [selectedEmployee, setSelectedEmployee] = useState<string | null>(null);
  const [selectedRequest, setSelectedRequest] = useState<Vacation | null>(null);
  const [modalConfirmRequestVacationState, setModalConfirmRequestVacationState] = useState(false);
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const [vacationDateState, setVacationDateState] = useState<moment.Moment>(moment());

  const [focusedInput, setFocusedInput] = useState<boolean>(false);

  const { getOrganizationVacations } = useVacations();
  const { getOrganizationAbsences } = useAbsences();
  const { getOrganizationHolidays } = useOrganization();

  const queryClient = useQueryClient();

  const { isLoading: queryVacationsLoading, data: localVacations } = useQuery<any[]>(['vacations', user], () =>
    getOrganizationVacations(user?.organization?.uuid!),
  );

  const { isLoading: queryAbsencesLoading, data: localAbsences } = useQuery<any[]>(['absences', user], () =>
    getOrganizationAbsences(user?.organization?.uuid!),
  );

  const { isLoading: queryHolidaysLoading, data: localHolidays } = useQuery<any[]>(['holidays', user], () =>
    getOrganizationHolidays(user?.organization?.uuid!),
  );

  const manageVacationMutation = useMutation({
    mutationFn: async ({ vacationUuid, status }: { vacationUuid: string; status: BadgeStatus }) =>
      manageVacancyRequest(`/api/organizations/${user?.organization?.uuid}/vacations/${vacationUuid}/manage`, 'POST', {
        user_id: user?.id,
        vacation_status: status,
      })
        .then(() => {
          getVacations();
          toast.success(t('vacations_successfully_updated'));
        })
        .catch(({ response }) => {
          values(response?.data?.errors)?.map((errors: any) => errors?.map((error: any) => toast.error(error)));
        }),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: ['vacations'] });
    },
  });

  const confirmVacancyRequestMutation = useMutation({
    mutationFn: async ({ newData, vacationUuid }: { vacationUuid: string; newData: any }) =>
      confirmVacancyRequest(`/api/organizations/${user?.organization?.uuid}/vacations/${vacationUuid}`, 'POST', newData)
        .then(async () => {
          manageVacation('approved' as BadgeStatus, vacationUuid);
          handleCloseConfirmRequestVacationModal();
          getVacations();
        })
        .catch(({ response }) => {
          values(response?.data?.errors)?.map((errors: any) => errors?.map((error: any) => toast.error(error)));
        }),
  });

  const handleOpenConfirmRequestVacationModal = () => {
    setModalConfirmRequestVacationState(true);
  };

  const handleCloseConfirmRequestVacationModal = () => {
    setModalConfirmRequestVacationState(false);
  };

  const [vacationRequests, setVacationRequests] = useState<any[]>([]);

  const { loading: queryVacanciesLoading, request: queryVacanciesRequest } = useAxios();
  const { loading: manageVacancyLoading, request: manageVacancyRequest } = useAxios();
  const { loading: confirmVacancyLoading, request: confirmVacancyRequest } = useAxios();

  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  const getVacations = useCallback(() => {
    const day = vacationDateState?.toDate()?.getDate();
    const month = vacationDateState?.toDate().getMonth() + 1;
    const year = vacationDateState?.toDate()?.getFullYear();

    queryVacanciesRequest(
      `/api/organizations/${user?.organization?.uuid}/vacations/date/${year}/${month}/${day}?sortBy=created_at&sortDirection=desc`,
    )
      .then(({ data }) => {
        setVacationRequests(data?.content?.data?.data ?? []);
      })
      .catch(({ response }) => {
        values(response?.data?.errors)?.map((errors: any) => errors?.map((error: any) => toast.error(error)));
      });
  }, [user, vacationDateState]);

  useEffect(() => {
    getVacations();
  }, [vacationDateState, user]);

  const manageVacation = useCallback(
    (status: BadgeStatus, vacationUuid: string) => {
      manageVacationMutation.mutate({
        status,
        vacationUuid,
      });
    },
    [user, getVacations],
  );

  const handleConfirmVacancyRequest = (
    vacationUuid: string,
    overrideUserRequest?: boolean,
    startDeteConfirm?: string,
    endDateConfirm?: string,
  ) => {
    const form = new FormData();
    form.append('time_in_confirm', overrideUserRequest ? startDeteConfirm! : selectedRequest?.time_in!);
    form.append('time_out_confirm', overrideUserRequest ? endDateConfirm! : selectedRequest?.time_out!);
    form.append('_method', 'PUT');

    confirmVacancyRequestMutation.mutate({ vacationUuid, newData: form });
  };

  const countPendingVacationRequests = () => {
    return vacationRequests.filter((vacation) => vacation.status === 'pending').length;
  };

  const countAprovedVacationRequests = () => {
    return vacationRequests.filter((vacation) => vacation.status === 'approved').length;
  };

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener('resize', updateDimension);

    return () => {
      window.removeEventListener('resize', updateDimension);
    };
  }, [screenSize]);

  return (
    <>
      <section className="flex flex-col items-center justify-center w-full pb-10">
        <AttachmentFilePresenceModal />
        <div className="mt-4 w-full">
          <div className="mt-4 w-full flex flex-row justify-center">
            <DayPickerSingleDateController
              daySize={48}
              navNext={<NextMonth />}
              navPrev={<PrevMonth />}
              renderMonthElement={Month}
              initialVisibleMonth={() => moment()}
              noBorder
              verticalBorderSpacing={5}
              date={vacationDateState}
              onFocusChange={({ focused }) => {
                setFocusedInput(focused);
              }}
              focused={focusedInput}
              renderCalendarInfo={() => <PresenceSummary />}
              onDateChange={(date: any) => {
                setVacationDateState(date);
              }}
              renderDayContents={(day) => {
                const dateAbsenceSelected = localAbsences?.some((absence: any) => {
                  console.log();

                  const absenceDate = parse(
                    format(parseISO(absence?.created_at), 'yyyy-MM-dd HH:mm:ss'),
                    'yyyy-MM-dd HH:mm:ss',
                    new Date(),
                  );

                  return day.isSame(moment(absenceDate, 'MM/D/YYYY'), 'date');
                });

                const dateVacationSelected =
                  localVacations?.filter((vacation: any) => {
                    const vacationDate = parse(
                      format(parseISO(vacation?.created_at), 'yyyy-MM-dd HH:mm:ss'),
                      'yyyy-MM-dd HH:mm:ss',
                      new Date(),
                    );
                    return day.isSame(moment(vacationDate, 'MM/D/YYYY'), 'date');
                  }) ?? [];

                const dateHolidaySelected = localHolidays?.some((holiday: any) => {
                  const selectedHoliday = parse(holiday.date, 'yyyy-MM-dd HH:mm:ss', new Date());

                  return day.isSame(moment(selectedHoliday, 'MM/D/YYYY'), 'date');
                });

                if (dateAbsenceSelected || dateVacationSelected || dateHolidaySelected) {
                  return (
                    <div className="flex flex-col items-center justify-center gap-2">
                      {day.format('D')}
                      <div className="flex flex-row gap-1 items-center">
                        {dateAbsenceSelected && <AbsenceMark />}
                        {dateVacationSelected?.length > 0 && (
                          <VacationMark>{dateVacationSelected?.length}</VacationMark>
                        )}
                        {dateHolidaySelected && <HolidayMark />}
                      </div>
                    </div>
                  );
                }
                return day.format('D');
              }}
            />
          </div>
        </div>
        <div className="flex justify-center gap-2 w-full px-8 mb-4">
          <div className="flex w-full flex-col text-center shadow-sm">
            <label className="bg-secondary px-4 text-white font-semibold py-1 rounded-t-md">
              {t('approved_vacations')}
            </label>
            <span className="border py-1">{countAprovedVacationRequests()}</span>
          </div>
          <article className="flex w-full flex-col text-center shadow-sm">
            <label className="bg-secondary px-4 text-white font-semibold py-1 rounded-t-md">
              {t('vacations_to_be_approved')}
            </label>
            <span className="border py-1">{countPendingVacationRequests()}</span>
          </article>
        </div>
        {vacationDateState && (
          <>
            <h2 className="text-base font-semibold w-full">
              {t('vacation_requests_for')} {vacationDateState?.toDate().toLocaleDateString('pt-BR')}
            </h2>
            <div className="mt-4 w-full overflow-x-auto rounded-lg">
              <div className="flex flex-col gap-3 justify-center w-full mb-2">
                {!queryVacanciesLoading && vacationRequests.length == 0 && (
                  <p className="text-center text-gray-950 text-base w-full">{t('no_vacations_to_show')}</p>
                )}

                {(queryVacationsLoading || queryAbsencesLoading || queryHolidaysLoading || queryVacanciesLoading) && (
                  <Loading className="w-full mx-auto" />
                )}

                {!queryVacationsLoading &&
                  !queryAbsencesLoading &&
                  !queryHolidaysLoading &&
                  !queryVacanciesLoading &&
                  vacationRequests?.map((vacation, index) => {
                    const startTimeVacation = String(new Date(vacation?.time_in).toISOString().split('T')[0])
                      .replace('-', '/')
                      .replace('-', '/');

                    const startTimeVacationConfirm = String(
                      new Date(vacation?.time_in_confirm).toISOString().split('T')[0],
                    )
                      .replace('-', '/')
                      .replace('-', '/');

                    const endTimeVacation = String(new Date(vacation?.time_out).toISOString().split('T')[0])
                      .replace('-', '/')
                      .replace('-', '/');

                    const endTimeVacationConfirm = String(
                      new Date(vacation?.time_out_confirm).toISOString().split('T')[0],
                    )
                      .replace('-', '/')
                      .replace('-', '/');

                    return (
                      <section
                        key={vacation?.id}
                        className="flex flex-col gap-4 w-full border-b-2 border-solid border-gray-200 py-2"
                      >
                        <div className="flex justify-between items-center">
                          <span className="text-gray-700 text-sm font-medium">
                            {String(new Date(vacation?.created_at).toISOString().split('T')[0])
                              .replace('-', '/')
                              .replace('-', '/')}
                            {' - '}
                            {vacation.user.name} {t('requested_an_vacation')}
                          </span>

                          <div className="flex flex-row gap-3 items-center">
                            <button
                              className="p-2 flex items-center justify-center hover:bg-primary/20 rounded-lg"
                              onClick={() => {
                                if (selectedIndex == index && typeof selectedIndex == 'number') {
                                  setSelectedIndex(null);
                                } else setSelectedIndex(index);
                              }}
                            >
                              <ArrowPathIcon className="text-primary text-lg" />
                            </button>
                            <StatusBadge status={vacation?.status} />
                          </div>
                        </div>

                        {(selectedIndex == index && vacation.statuses.length >= 0 && (
                          <Timeline className="custom-timeline ml-4">
                            {vacation.statuses.map((status: any) => {
                              if (status.name == 'pending') {
                                return (
                                  <Timeline.Item dot={<PendingDot />} key={status?.id}>
                                    <p className="ml-2">
                                      {format(parseISO(status?.created_at), 'yyyy/MM/dd HH:mm:ss')}
                                    </p>
                                    <p className="ml-2">{`${status?.user?.name} ${t('requested_an_vacation')}`}</p>
                                  </Timeline.Item>
                                );
                              } else if (status.name == 'rejected') {
                                return (
                                  <Timeline.Item dot={<RejectedDot />} key={status?.id}>
                                    <p className="ml-2">
                                      {format(parseISO(status?.created_at), 'yyyy/MM/dd HH:mm:ss')}
                                    </p>
                                    <p className="ml-2">{`${status?.user?.name} ${t('rejected_vacation_request')}`}</p>
                                  </Timeline.Item>
                                );
                              } else {
                                return (
                                  <Timeline.Item dot={<ApprovedDot />} key={status?.id}>
                                    <p className="ml-2">
                                      {format(parseISO(status?.created_at), 'yyyy/MM/dd HH:mm:ss')}
                                    </p>
                                    <p className="ml-2">{`${status?.user?.name} ${t('approved_vacation_request')}`}</p>
                                  </Timeline.Item>
                                );
                              }
                            })}
                          </Timeline>
                        )) ||
                          (selectedIndex == index && vacation.statuses.length == 0 && (
                            <span className="text-base text-black text-center w-full">{t('nothing_to_show')}</span>
                          ))}
                        <div className="flex flex-row justify-between items-center">
                          <div className="flex flex-col gap-2">
                            {vacation.status === 'approved' ? (
                              <>
                                {startTimeVacation === startTimeVacationConfirm &&
                                endTimeVacation === endTimeVacationConfirm ? (
                                  <>
                                    <span key={vacation?.id} className="text-black text-base">
                                      {startTimeVacation}{' '}
                                      {startTimeVacation !== endTimeVacation && (
                                        <>
                                          -{'>'} {endTimeVacation}
                                        </>
                                      )}
                                    </span>
                                  </>
                                ) : (
                                  <>
                                    <span key={vacation?.id} className="text-black text-base line-through">
                                      {startTimeVacation}{' '}
                                      {startTimeVacation !== endTimeVacation && (
                                        <>
                                          -{'>'} {endTimeVacation}
                                        </>
                                      )}
                                    </span>
                                    <span key={vacation?.id} className="text-black text-base">
                                      {startTimeVacationConfirm}{' '}
                                      {startTimeVacationConfirm !== endTimeVacationConfirm && (
                                        <>
                                          -{'>'} {endTimeVacationConfirm}
                                        </>
                                      )}
                                    </span>
                                  </>
                                )}
                              </>
                            ) : (
                              <>
                                <span key={vacation?.id} className="text-black text-base">
                                  {startTimeVacation}{' '}
                                  {startTimeVacation !== endTimeVacation && (
                                    <>
                                      -{'>'} {endTimeVacation}
                                    </>
                                  )}
                                </span>
                              </>
                            )}
                          </div>
                          <div className="flex flex-row gap-3 items-center">
                            <ButtonFloat
                              buttonType={Button.Type.ERROR}
                              onClick={() => manageVacation('rejected' as BadgeStatus, vacation?.uuid)}
                              disabled={manageVacancyLoading}
                            >
                              <XMarkIcon className="w-6" />
                            </ButtonFloat>
                            <ButtonToolbar>
                              <ButtonFloat
                                buttonType={Button.Type.SUCCESS}
                                onClick={() => {
                                  handleOpenConfirmRequestVacationModal();
                                  setSelectedRequest(vacation);
                                }}
                                disabled={manageVacancyLoading}
                              >
                                <CheckIcon className="w-6" />
                              </ButtonFloat>
                            </ButtonToolbar>
                          </div>
                        </div>
                      </section>
                    );
                  })}
              </div>
              {selectedRequest && modalConfirmRequestVacationState && (
                <ModalConfirmRequestVacation
                  open={modalConfirmRequestVacationState}
                  onClose={() => {
                    setSelectedRequest(null);
                    handleCloseConfirmRequestVacationModal();
                  }}
                  onConfirm={(overrideUserRequest?: boolean, startDateConfirm?: string, endDateConfirm?: string) => {
                    handleConfirmVacancyRequest(
                      selectedRequest?.uuid!,
                      overrideUserRequest,
                      startDateConfirm,
                      endDateConfirm,
                    );
                  }}
                  title={t('confirm_request')}
                  startDate={String(new Date(selectedRequest.time_in).toISOString().split('T')[0])}
                  endDate={String(new Date(selectedRequest.time_out).toISOString().split('T')[0])}
                  loading={confirmVacancyLoading}
                />
              )}
            </div>
          </>
        )}
      </section>
      {selectedEmployee && modalDenyState[selectedEmployee] && (
        <ModalDenyAbsence
          onConfirm={async () => {
            setModalDenyState((prevState) => ({
              ...prevState,
              [selectedEmployee]: false,
            }));
            setSelectedEmployee(null);
          }}
          isOpen={modalDenyState[selectedEmployee]}
          onClose={() => {
            setModalDenyState((prevState) => ({
              ...prevState,
              [selectedEmployee]: false,
            }));
            setSelectedEmployee(null);
          }}
          title={t('deny_vacation_request')}
          content={`${t('are_you_sure_you_want_to_deny_the_vacation_request_for')} ${selectedEmployee}?`}
        />
      )}
    </>
  );
}

export default VacationSection;
