import { classNames } from '@components/Badges/StatusBadge';
import { HTMLAttributes } from 'react';

export interface ColProps extends HTMLAttributes<HTMLDivElement> {
  gap?: number | string;
}

export default function Col({ gap = 0, children, className, ...props }: ColProps) {
  return (
    <div className={classNames(`flex flex-col gap-${gap}`, className)} {...props}>
      {children}
    </div>
  );
}
