import { create } from 'zustand';
import { api } from '../../services/api';
import { toast } from 'react-toastify';
import { Organization } from '@hooks/organization/useOrganization';
import { createJSONStorage, persist } from 'zustand/middleware';
import { Location } from '@hooks/location/useLocation';
import { Vacation } from '@hooks/presence/useVacations';
import { WorkType } from '@pages/User/EditUser';

export interface Auth {
  email: string;
  password: string;
}

export interface Role {
  name: string;
  uuid: string;
}

export interface User {
  id: number;
  uuid?: string;
  name: string;
  email: string;
  nif?: string;
  organization?: Organization;
  organization_id?: number;
  vacations?: Vacation;
  location?: Location;
  avatar?: string;
  start_work_date?: string;
  end_work_date?: string;
  token?: string;
  roles?: Role[];
  language?: languageProps;
  work_type?: WorkType;
  email_notifications?: boolean;
}

export interface languageProps {
  uuid?: string;
  id?: number;
  language_code?: string;
}

interface AuthProps {
  user: User | null;
  setUser?: (user: User) => void;
  isAuthenticated: boolean;
  signIn: (email: string, password: string) => Promise<void>;
  logOut: () => void;
  forgetPassword: (email: string) => Promise<void>;
  resetPassword: (email: string, token: string, password: string, password_confirmation: string) => Promise<void>;
}

export const useAuth = create<AuthProps>()(
  persist(
    (set, get) => ({
      isAuthenticated: get()?.user ? true : false,
      user: null,
      setUser: (user: User | null) => {
        set({ user, isAuthenticated: !!user });
      },

      signIn: async (email: string, password: string) => {
        try {
          const { data } = await api.post('/api/auth/login', {
            email,
            password,
          });
          set({ user: { ...data.user, token: data.token }, isAuthenticated: true });
          api.defaults.headers.common['Authorization'] = `Bearer ${data.token}`;
          // api.defaults.headers.common = { Authorization: `Bearer ${data.token}` };
          //Lembrar de encriptografar o token de requisição com JWT*
        } catch (error: any) {
          // const errors = error.response.data.errors;
          // const message = Object.keys(errors)[0];
          // toast.error(errors[message][0]);
          throw error;
        }
      },

      logOut: async () => {
        try {
          set({ user: null, isAuthenticated: false });
          sessionStorage.removeItem('user-auth');
          await api.post('/api/auth/logout');
        } catch (error) {
          sessionStorage.removeItem('user-auth');
          console.log(error);
        }
      },

      forgetPassword: async (email: string) => {
        try {
          await api.post('/api/auth/forget-password', {
            email,
          });
          toast.success('Um email de redefinição de senha foi enviado.');
        } catch (error: any) {
          // console.log(error);
          // const errors = error.response.data.errors;
          // const message = Object.keys(errors)[0];
          throw error;
          // set({ forgetError: errors[message][0] ?? error.response.data.message });
        }
      },

      resetPassword: async (email: string, token: string, password: string, password_confirmation: string) => {
        try {
          await api.post('/api/auth/reset-password', {
            email,
            token,
            password,
            password_confirmation,
          });
          toast.success('Password alterada com sucesso!');
        } catch (error: any) {
          // console.log(error);
          // const errors = error.response.data.errors;
          // const message = Object.keys(errors)[0];
          throw error;
          // set({ forgetError: errors[message][0] ?? error.response.data.message });
        }
      },
    }),
    {
      name: 'user-auth',
      storage: createJSONStorage(() => sessionStorage),
    },
  ),
);
