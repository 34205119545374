import { useTranslation } from 'react-i18next';
import { useAuth } from '@hooks/auth/useAuth';
import TabLink from '@components/Navbar/TabLink';
import { useLayout } from '@hooks/layout/useLayout';
import { useEffect } from 'react';

function NavbarPresence() {
  const { t } = useTranslation();
  const { user } = useAuth();

  const { setPageTitle } = useLayout();

  useEffect(() => {
    setPageTitle(t('presences'));

    return () => {
      setPageTitle(null);
    };
  }, []);

  return (
    <div className="w-full flex rounded-lg shadow-lg bg-gray-50">
      <TabLink to={`/organizations/${user?.organization?.uuid}/dashboard/presences/user/${user?.uuid}`}>
        {t('presence')}
      </TabLink>
      <TabLink to={`/organizations/${user?.organization?.uuid}/dashboard/presences/user/${user?.uuid}/absences`}>
        {t('absences')}
      </TabLink>
      <TabLink to={`/organizations/${user?.organization?.uuid}/dashboard/presences/user/${user?.uuid}/vacations`}>
        {t('vacations')}
      </TabLink>
    </div>
  );
}

export default NavbarPresence;
