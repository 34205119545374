import React, { useEffect, useState } from 'react';

import { useUser } from '../../hooks/user/useUser';

import viewIcon from '../../assets/icons/view.svg';
// import editIcon from "../../assets/icons/edit.svg";
// import deleteIcon from "../../assets/icons/delete.svg";
import searchIcon from '../../assets/icons/search.svg';
import { ButtonDefault } from '../../components/Buttons/ButtonDefault';
import { Link, useParams } from 'react-router-dom';
import NavigationTable from '@components/NavigationTable';
import { useTranslation } from 'react-i18next';

import imageDefaultUser from '../../assets/images/user-img-default.jpg';
import Loading from '@components/loading';
import Input from '@components/Inputs';

export default function ListUsers() {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState<string | null>(null);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const { organizationUuid } = useParams<{ organizationUuid: string }>();

  const { users, getUsers, infoPage } = useUser();

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < infoPage.last_page) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleSelectPage = (page: number) => {
    if (page >= 1 && page <= infoPage.last_page) {
      setCurrentPage(page);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoadingSearch(true);
      await getUsers(
        organizationUuid!,
        `take=10&page=${currentPage}&&sortBy=created_at&sortDirection=desc&search=${
          searchQuery == null ? '' : searchQuery
        }`,
      );
      setLoadingSearch(false);
    };

    fetchData();
  }, [currentPage, searchQuery]);

  async function onSearch() {
    setLoadingSearch(true);
    await getUsers(
      organizationUuid!,
      `take=10&page=1&&sortBy=created_at&sortDirection=desc&search=${searchQuery == null ? '' : searchQuery}`,
    );
    setLoadingSearch(false);
  }

  useEffect(() => {
    getUsers(organizationUuid!, '&take=10&page=1&sortBy=created_at&sortDirection=desc');
  }, []);

  useEffect(() => {
    onSearch();
  }, [searchQuery]);

  function formatDate(dateString: any) {
    const date = new Date(dateString);
    return date.toLocaleDateString('pt-BR');
  }

  return (
    <main className="z-0 max-h-screen h- px-4 mt-3">
      <div className="flex flex-col w-full gap-2 bg-gray-50 z-10 pb-4">
        <div className="flex flex-col gap-2">
          <h1 className="lg:text-4xl text-3xl text-gray-800 font-bold">{t('users')}</h1>
          <h2 className="text-gray-600 text-base">{t('here_you_can_manage_your_users')}.</h2>
        </div>
        <div className="mt-9 flex lg:justify-end justify-between gap-3">
          <div className="relative">
            <Input
              type="search"
              name="search"
              id="search"
              placeholder={`${t('search')}...`}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <img src={searchIcon} alt="Search icon" className="w-5 absolute top-3 right-3" />
          </div>
          <ButtonDefault href={`/organizations/${organizationUuid}/dashboard/user/create`} content={t('new_user')} />
        </div>
      </div>
      <table className="table-auto w-full rounded-lg">
        <thead className="bg-gray-200 text-gray-800 sticky -top-2">
          <tr className="">
            <th className="py-2 px-4 text-start">{t('name')}</th>
            <th className="hidden lg:table-cell py-2 px-4 text-start">{t('email')}</th>
            <th className="hidden lg:table-cell py-2 px-4 text-start">{t('last_check_in')}</th>
            <th className="hidden lg:table-cell py-2 px-4 text-start">{t('last_check_out')}</th>
            <th className="hidden lg:table-cell py-2 px-4 text-start">{t('image')}</th>
            <th className="py-2 px-4 text-start"></th>
          </tr>
        </thead>
        <tbody className="text-gray-800 h-full overflow-y-scroll">
          {loadingSearch ? (
            <tr>
              <td colSpan={6} className="text-center py-10">
                <Loading className="w-full mx-auto" />{' '}
              </td>
            </tr>
          ) : searchQuery !== null && searchQuery.length > 0 ? (
            users.length === 0 ? (
              <tr>
                <td colSpan={6} className="text-center py-10">
                  <span className="text-gray-600 text-sm mx-auto inline-block">Nenhum utilizador encontrado!</span>
                </td>
              </tr>
            ) : (
              users.map((userOrganization, index) => (
                <React.Fragment key={userOrganization.id}>
                  <tr key={userOrganization.id} className={index % 2 === 0 ? 'bg-gray-50' : ' bg-gray-100'}>
                    <td className="py-2 px-4 whitespace-nowrap">
                      {userOrganization.name}
                      <span className="text-xs -mt-4 block md:hidden">
                        <br />
                        {userOrganization.email}
                      </span>
                    </td>
                    <td className="hidden lg:table-cell py-2 px-4">{userOrganization.email}</td>
                    <td className="hidden lg:table-cell py-2 px-4">{formatDate(userOrganization.start_work_date)}</td>
                    <td className="hidden lg:table-cell py-2 px-4">{formatDate(userOrganization.end_work_date)}</td>
                    <td className="hidden lg:table-cell py-2 px-4">
                      <img
                        className="h-8 w-8 rounded-full object-cover"
                        src={`${import.meta.env.VITE_API_URL}storage/images/user/${userOrganization.avatar}`}
                        alt="Image"
                      />
                    </td>
                    <td className="py-2 px-4 flex">
                      <Link to={`/organizations/${organizationUuid}/dashboard/user/${userOrganization.uuid}`}>
                        <img src={viewIcon} alt="View" className="mt-2" />
                      </Link>
                    </td>
                  </tr>
                </React.Fragment>
              ))
            )
          ) : (
            users.map((userOrganization, index) => (
              <React.Fragment key={userOrganization.id}>
                <tr key={userOrganization.id} className={index % 2 === 0 ? 'bg-gray-50' : ' bg-gray-100'}>
                  <td className="py-2 px-4 whitespace-nowrap">
                    {userOrganization.name}
                    <span className="text-xs -mt-4 block md:hidden">
                      <br />
                      {userOrganization.email}
                    </span>
                  </td>
                  <td className="hidden lg:table-cell py-2 px-4">{userOrganization.email}</td>
                  <td className="hidden lg:table-cell py-2 px-4">{formatDate(userOrganization.start_work_date)}</td>
                  <td className="hidden lg:table-cell py-2 px-4">{formatDate(userOrganization.end_work_date)}</td>
                  <td className="hidden lg:table-cell py-2 px-4">
                    <img
                      className="h-8 w-8 rounded-full object-cover"
                      src={`${
                        userOrganization.avatar === null
                          ? imageDefaultUser
                          : `${import.meta.env.VITE_API_URL}storage/images/user/${userOrganization.avatar}`
                      } `}
                      alt="Image"
                    />
                  </td>
                  <td className="py-2 px-4 flex">
                    <Link to={`/organizations/${organizationUuid}/dashboard/user/${userOrganization.uuid}`}>
                      <img src={viewIcon} alt="View" className="mt-2" />
                    </Link>
                  </td>
                </tr>
              </React.Fragment>
            ))
          )}
        </tbody>
      </table>
      <NavigationTable
        currentPage={currentPage}
        onPrev={handlePrevPage}
        onNext={handleNextPage}
        onSelectPage={handleSelectPage}
        infoPage={{
          last_page: infoPage.last_page,
          next_page_url: infoPage.next_page_url,
        }}
      />
    </main>
  );
}
