import { Link } from 'react-router-dom';
import React from 'react';

interface CardHome {
  link: string;
  description: string;
  type: 'primary' | 'secondary';
  icon: React.ReactNode;
  onClick?: () => void;
}

function CardHome({ description, icon, link, type, ...props }: CardHome) {
  return (
    <Link
      {...props}
      to={link}
      className={`${
        type === 'primary' ? 'bg-blue-50  text-primary' : 'text-primary bg-white '
      } shadow-lg rounded-lg w-full h-40 py-6 px-4 flex flex-col items-start`}
    >
      <div
        className={`bg-blue-50 p-3 rounded-lg mb-2 ${type === 'primary' ? 'border-2 border-solid border-primary' : ''}`}
      >
        {icon}
      </div>
      <span className="font-medium text-lg">{description}</span>
    </Link>
  );
}

export default CardHome;
