import { useCallback, useEffect, useMemo, useState } from 'react';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { PointProps, usePoint } from '../../../hooks/presence/usePoint';
import { ptBR } from 'date-fns/locale';
import { format, parse } from 'date-fns';
import { useAuth } from '@hooks/auth/useAuth';
import ButtonFloat from '@components/Common/Buttons/ButtonFloat';
import useAxios from '@hooks/axios/useAxios';
import { keys, values } from 'lodash';
import { toast } from 'react-toastify';
import { DatePicker, Divider, Modal, SelectPicker } from 'rsuite';
import Button from '@components/Common/Buttons/Button';
import { useTranslation } from 'react-i18next';
import StatusBadge, { BadgeStatus } from '@components/Badges/StatusBadge';
import { DayPickerSingleDateController } from 'react-dates';
import { getCurrentDimension } from './AbsenceSection';
import moment from 'moment';
import { useVacations } from '@hooks/presence/useVacations';
import { useAbsences } from '@hooks/presence/useAbsences';
import { useQuery } from 'react-query';
import { useOrganization } from '@hooks/organization/useOrganization';
import { PresenceSummary } from '@components/Calendar/Summary/PresenceSummary';
import { AbsenceMark, HolidayMark, VacationMark } from '@components/Calendar/Summary';
import { Month, NextMonth, PrevMonth } from '@components/Calendar';
import Loading from '@components/loading';
import { CheckCircleIcon, QrCodeIcon } from '@heroicons/react/24/solid';
import { useNavigate } from 'react-router-dom';
import { useQrCode } from '@hooks/layout/useQrCode';
import { usePresences } from '@hooks/presence/usePresences';
import Col from '@components/Flex/Col';
import { CalendarIcon } from '@heroicons/react/24/outline';

const persenceTypeValues = ['in', 'out'].map((type) => ({ value: type, label: type == 'in' ? 'Entrada' : 'Saída' }));
export const START_DATE = 'startDate';
export const END_DATE = 'endDate';

function PointSection() {
  const today = new Date();

  const { t } = useTranslation();

  const [selectedDayRecords, setSelectedDayRecords] = useState<PointProps[]>([]);
  const { user } = useAuth();
  const navigate = useNavigate();
  const [entryTime, setEntryTime] = useState<Date | null>(null);
  const [locations, setLocations] = useState<any[]>([]);
  const [selectedLocationId, setSelectedLocationId] = useState<unknown>();
  const [selectedPresenceType, setSelectedPresenceType] = useState<unknown>();

  const { loading: queryUserPointLoading, request: queryUserPointRequest } = useAxios();
  const { loading: queryAddPointManuallyLoading, request: queryAddPointManuallyRequest } = useAxios();
  const { request: queryOrganizationLocationsRequest } = useAxios();
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const [focusedInput, setFocusedInput] = useState<boolean>(false);
  const { getAllAbsences } = useAbsences();
  const { getAllVacations } = useVacations();
  const { getOrganizationHolidays } = useOrganization();
  const { qrCodeScanContent, setQrCodeScanContent } = useQrCode();
  const { createPointQrCode } = usePoint();
  const { refreshGlobalPresences, globalPresences } = usePresences();

  const [latitude, setLatitude] = useState<number | null>(null);
  const [longitude, setLongitude] = useState<number | null>(null);

  const [presenceDateState, setPresenceDateState] = useState<moment.Moment>(moment());

  function refreshPoints(date: Date = new Date()) {
    if (user && user.organization?.uuid && presenceDateState) {
      const day = date?.getDate();
      const month = date?.getMonth()! + 1;
      const year = date?.getFullYear();

      queryUserPointRequest(
        `/api/organizations/${user.organization.uuid}/presences/user/${user.uuid}/${year}/${month}/${day}?sortBy=created_at&sortDirection=desc`,
        'GET',
      )
        .then(({ data }) => {
          setSelectedDayRecords(data?.content?.data?.data ?? []);
        })
        .catch(({ response }) => {
          values(response?.data?.errors)?.map((errors: any) => errors?.map((error: any) => toast.error(error)));
        });
    }
  }

  const { isLoading: queryVacationsLoading, data: localVacations } = useQuery<any[]>(['vacations', user], () =>
    getAllVacations(user!),
  );

  const { isLoading: queryAbsencesLoading, data: localAbsences } = useQuery<any[]>(['absences', user], () =>
    getAllAbsences(user!),
  );

  const { isLoading: queryHolidaysLoading, data: localHolidays } = useQuery<any[]>(['holidays', user], () =>
    getOrganizationHolidays(user?.organization?.uuid!),
  );

  function addManualPoint() {
    queryAddPointManuallyRequest(`api/organizations/${user?.organization?.uuid}/presences`, 'POST', {
      user_id: user?.id,
      location_id: selectedLocationId,
      manual: true,
      type: selectedPresenceType,
      time: `${presenceDateState?.format('yyyy-MM-DD')} ${format(entryTime!, 'HH:mm:ss')}`,
    })
      .then(() => {
        setOpen(false);
        refreshPoints(presenceDateState?.toDate());
      })
      .catch(({ response }) => {
        values(response?.data?.errors)?.map((errors: any) => errors?.map((error: any) => toast.error(error)));
      });
  }

  const refreshOrganizationLocations = () => {
    queryOrganizationLocationsRequest(`api/organizations/${user?.organization?.uuid}/locations`, 'GET')
      .then(({ data }) =>
        setLocations(
          (data?.content?.data?.data ?? ([] as any[])).map((location: any) => ({
            value: location?.id,
            label: location?.name!,
          })) as any[],
        ),
      )
      .catch(({ response }) => {
        values(response?.data?.errors)?.map((errors: any) => errors?.map((error: any) => toast.error(error)));
      });
  };

  useEffect(() => {
    refreshOrganizationLocations();
  }, []);

  useEffect(() => {
    refreshPoints(presenceDateState?.toDate());
  }, [presenceDateState]);

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener('resize', updateDimension);

    return () => {
      window.removeEventListener('resize', updateDimension);
    };
  }, [screenSize]);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setLatitude(position.coords.latitude);
        setLongitude(position.coords.longitude);
      });
    }
  }, []);

  const handleCompareIfDateSelected = useCallback(
    (item: moment.Moment) => {
      const tomorrow = new Date();
      tomorrow.setDate(today.getDate() + 1);
      return (
        localVacations?.some((vacation: any) => {
          const vacationStart = parse(vacation.time_in, 'yyyy-MM-dd HH:mm:ss', new Date());
          const vacationEnd = parse(vacation.time_out, 'yyyy-MM-dd HH:mm:ss', new Date());

          return (
            (item.isAfter(vacationStart) && item.isBefore(vacationEnd)) ||
            item.isSame(vacationStart, 'day') ||
            item.isSame(vacationEnd, 'day')
          );
        }) ||
        localAbsences?.some((absence: any) => {
          const absenceStart = parse(absence.time_in, 'yyyy-MM-dd HH:mm:ss', new Date());
          const absenceEnd = parse(absence.time_out, 'yyyy-MM-dd HH:mm:ss', new Date());

          return (
            (item.isAfter(absenceStart) && item.isBefore(absenceEnd)) ||
            item.isSame(absenceStart, 'day') ||
            item.isSame(absenceEnd, 'day')
          );
        }) ||
        localHolidays?.some?.((holiday: any) => {
          const selectedHoliday = parse(holiday.date, 'yyyy-MM-dd HH:mm:ss', new Date());

          return item.isSame(selectedHoliday, 'day');
        }) ||
        item.isAfter(tomorrow)
      );
    },
    [localAbsences, localHolidays, localVacations],
  );

  const formatDate = (date: Date | undefined) => {
    if (!date) return '';
    return format(date, "d 'de' MMMM 'de' yyyy", { locale: ptBR });
  };

  const [open, setOpen] = useState<boolean>(false);

  const dayType = useMemo<'out' | 'in'>(() => {
    //@ts-ignore
    const dayNow = globalPresences[0] ?? undefined;
    //@ts-ignore
    return dayNow?.type === 'in' ? 'out' : 'in';
  }, [globalPresences]);

  const presencesByLocation = useMemo<{ [key: string]: PointProps[] }>(
    () =>
      selectedDayRecords.reduce((groups: any, presence: PointProps) => {
        const locationType = presence?.location?.name!;
        if (!groups[locationType]) {
          groups[locationType] = [];
        }
        groups[locationType].push(presence);
        return groups;
      }, {}) as any,
    [selectedDayRecords],
  );

  function savePresenceQrCode() {
    if (qrCodeScanContent) {
      const point = {
        user_id: user!.id,
        organization_id: user!.organization!.uuid!,
        type: dayType,
        time: moment(new Date())?.format('yyyy-MM-DD HH:mm:ss'),
        // lat: user?.location?.lat,
        // lng: user?.location?.lng,
        lat: latitude?.toString() || '',
        lng: longitude?.toString() || '',
      } as PointProps;
      createPointQrCode(qrCodeScanContent, point)
        .then(() => {
          toast.success(dayType === 'in' ? t('entry_successfully_registered') : t('exit_successfully_registered'));
          if (user) {
            const date = new Date();
            const day = date?.getDate();
            const month = date?.getMonth()! + 1;
            const year = date?.getFullYear();
            refreshPoints(presenceDateState?.toDate());

            refreshGlobalPresences(user?.organization?.uuid!, user?.uuid!, day, month, year);
          }

          setQrCodeScanContent(null);
        })
        .catch((error) => {
          console.error('Erro ao cadastrar ponto', error);
          toast.error(error.response.data.message);
        });
    }

    setQrCodeScanContent(null);
  }

  return (
    <>
      <section className="flex flex-col items-center justify-center w-full mt-4">
        <Modal
          open={open}
          onClose={() => {
            setOpen(false);
          }}
        >
          <Modal.Header>
            <Modal.Title>
              <p className="text-2xl font-bold">{t('do_you_really_wanto_add_a_manual_presence')}</p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <span className="text-libre-grey-100 text-base w-full flex justify-start">
              {t('manual_presences_will_be_sent_to_administrator')}
            </span>
          </Modal.Body>
          <Modal.Footer>
            <div className="flex gap-3 justify-end mt-2">
              <Button
                disabled={queryAddPointManuallyLoading}
                onClick={() => {
                  setOpen(false);
                }}
                buttonType={Button.Type.SECONDARY}
              >
                {t('cancel')}
              </Button>
              <Button loading={queryAddPointManuallyLoading} onClick={addManualPoint}>
                {t('confirm')}
              </Button>
            </div>
          </Modal.Footer>
        </Modal>

        {(queryVacationsLoading || queryAbsencesLoading || queryHolidaysLoading) && (
          <Loading className="w-full mx-auto" />
        )}

        {!queryVacationsLoading && !queryAbsencesLoading && !queryHolidaysLoading && (
          <div className="flex flex-col justify-center items-center w-full">
            <div className="flex items-center justify-center w-full">
              <DayPickerSingleDateController
                daySize={48}
                navNext={<NextMonth />}
                navPrev={<PrevMonth />}
                renderMonthElement={Month}
                initialVisibleMonth={() => moment()}
                noBorder
                verticalBorderSpacing={5}
                isDayHighlighted={handleCompareIfDateSelected}
                isDayBlocked={handleCompareIfDateSelected}
                date={presenceDateState}
                onFocusChange={({ focused }) => {
                  setFocusedInput(focused);
                }}
                focused={focusedInput}
                renderCalendarInfo={() => <PresenceSummary />}
                onDateChange={(date: any) => {
                  const isDateSelectedOnDisabledRange = localAbsences?.some((absence: any) => {
                    const absenceStart = parse(absence.time_in, 'yyyy-MM-dd HH:mm:ss', new Date());
                    const absenceEnd = parse(absence.time_out, 'yyyy-MM-dd HH:mm:ss', new Date());

                    return date.isSame(absenceStart) || date.isSame(absenceEnd);
                  });

                  const isDateSelectedOnVacationRange = localVacations?.some((vacation: any) => {
                    const vacationStart = parse(vacation.time_in, 'yyyy-MM-dd HH:mm:ss', new Date());
                    const vacationEnd = parse(vacation.time_out, 'yyyy-MM-dd HH:mm:ss', new Date());

                    return date.isSame(vacationStart) || date.isSame(vacationEnd);
                  });

                  const isDateSelectedOnHolidayRange = localHolidays?.some?.((holiday: any) => {
                    const selectedHoliday = parse(holiday.date, 'yyyy-MM-dd HH:mm:ss', new Date());

                    return date.isSame(selectedHoliday);
                  });

                  if (
                    !isDateSelectedOnDisabledRange &&
                    !isDateSelectedOnHolidayRange &&
                    !isDateSelectedOnVacationRange
                  ) {
                    setPresenceDateState(date);
                    return;
                  }
                }}
                renderDayContents={(day) => {
                  const dateAbsenceSelected = localAbsences?.some((absence: any) => {
                    const absenceStart = parse(absence.time_in, 'yyyy-MM-dd HH:mm:ss', new Date());
                    const absenceEnd = parse(absence.time_out, 'yyyy-MM-dd HH:mm:ss', new Date());

                    return (
                      day.isBetween(absenceStart, absenceEnd) ||
                      day.isSame(absenceStart, 'day') ||
                      day.isSame(absenceEnd, 'day')
                    );
                  });

                  const dateVacationSelected = localVacations?.some((vacation: any) => {
                    const vacationStart = parse(vacation.time_in, 'yyyy-MM-dd HH:mm:ss', new Date());
                    const vacationEnd = parse(vacation.time_out, 'yyyy-MM-dd HH:mm:ss', new Date());
                    return (
                      day.isBetween(vacationStart, vacationEnd) ||
                      day.isSame(vacationStart, 'day') ||
                      day.isSame(vacationEnd, 'day')
                    );
                  });

                  const dateHolidaySelected = localHolidays?.some?.((holiday: any) => {
                    const selectedHoliday = parse(holiday.date, 'yyyy-MM-dd HH:mm:ss', new Date());

                    return day.isSame(moment(selectedHoliday, 'MM/D/YYYY'), 'date');
                  });

                  if (dateAbsenceSelected || dateVacationSelected || dateHolidaySelected) {
                    return (
                      <div className="flex flex-col items-center justify-center gap-2">
                        {day.format('D')}
                        <div className="flex flex-row gap-1 items-center">
                          {dateAbsenceSelected && <AbsenceMark />}
                          {dateVacationSelected && <VacationMark />}
                          {dateHolidaySelected && <HolidayMark />}
                        </div>
                      </div>
                    );
                  }
                  return day.format('D');
                }}
              />
            </div>
          </div>
        )}

        <div className="flex flex-col items-center justify-center gap-3 w-full mt-8 p-2">
          {(!presenceDateState?.isSame(today, 'date') &&
            presenceDateState?.format('dd/MM/yyy') != format(today, 'dd/MM/yyy') && (
              <section className="flex flex-col fexl-wrap w-full gap-3 items-center justify-center">
                <span className="text-primary text-base text-start justify-start self-start">
                  {t('manual_point_create_manage')}
                </span>
                <div className="flex flex-col gap-1 items-start max-w-md w-full">
                  <p>{t('location')}</p>

                  <SelectPicker
                    value={selectedLocationId}
                    onChange={setSelectedLocationId}
                    data={locations}
                    style={{ width: '100%' }}
                  />
                </div>
                <div className="flex flex-col gap-1 items-start max-w-md w-full">
                  <p>{t('type')}</p>

                  <SelectPicker
                    value={selectedPresenceType}
                    onChange={setSelectedPresenceType}
                    data={persenceTypeValues}
                    style={{ width: '100%' }}
                  />
                </div>

                <div className="flex flex-col gap-1 items-start max-w-md w-full">
                  <p>{t('time')}</p>
                  <DatePicker
                    placeholder="Hora de entrada"
                    placement="topEnd"
                    format="HH:mm"
                    ranges={[]}
                    value={entryTime}
                    style={{ width: '100%' }}
                    onChange={(value) => setEntryTime(value)}
                  />
                </div>

                <Button
                  loading={queryAddPointManuallyLoading}
                  style={{ maxWidth: 448 }}
                  onClick={() => {
                    if (!selectedLocationId || (!entryTime && !selectedPresenceType) || !presenceDateState) {
                      toast.error('Preencha todos os campos.');
                    } else setOpen(true);
                  }}
                >
                  {t('mark_point')}
                </Button>
              </section>
            )) ||
            (qrCodeScanContent && <Button onClick={savePresenceQrCode}>Confirm presence</Button>) || (
              <ButtonFloat
                onClick={() => {
                  navigate(`/organizations/${user!.organization?.uuid}/dashboard/read-point`);
                }}
              >
                <QrCodeIcon className="w-9 h-9" />
              </ButtonFloat>
            )}
        </div>
        <Divider />

        <div className="w-full mt-4 px-4">
          <span className="text-gray-700 text-sm font-medium">{formatDate(presenceDateState?.toDate())}</span>

          {queryUserPointLoading && <Loading className="w-full mx-auto" />}

          {keys(presencesByLocation).length === 0 && (
            <Col className="w-full justify-center items-center gap-2 mt-2">
              <div className="bg-gray-300 rounded-lg p-3">
                <CalendarIcon className="w-6 text-white" />
              </div>
              <p className="text-gray-400 text-sm text-center">{t('no_records_for_the_selected_date')}.</p>
            </Col>
          )}

          {!queryUserPointLoading &&
            keys(presencesByLocation).map((location: string, index) => (
              <div key={index} className="w-full mt-2 flex justify-between items-center">
                <div className="flex flex-col gap-2 items-start w-full justify-start">
                  <span className="text-2xl font-bold text-black">{location}</span>
                  {values(presencesByLocation)[index]?.map?.((record: PointProps, index: number) => (
                    <div key={index} className="w-full mt-2 flex justify-between items-center">
                      <div className="flex gap-2 items-center">
                        {record?.status === 'approved' ? (
                          <CheckCircleIcon className="text-primary w-6" />
                        ) : (
                          <StatusBadge status={record?.status! as BadgeStatus} />
                        )}
                        <p className="text-lg text-gray-900 font-normal">
                          {record.type === 'in'
                            ? t('entry_successfully_registered')
                            : t('exit_successfully_registered')}
                        </p>
                      </div>
                      <span className="text-gray-400 text-base font-normal">
                        {format(new Date(record.time), 'HH:mm')}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            ))}
        </div>
      </section>
    </>
  );
}

export default PointSection;
