import { useTranslation } from 'react-i18next';
import useAxios from '@hooks/axios/useAxios';
import { useAuth } from '@hooks/auth/useAuth';
import { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Card from './Card';
import Col from '@components/Flex/Col';
import StackedButton from '@components/StackedList/StackedButton';
import Input from '@components/Inputs';

export default function PasswordProfile() {
  const { t } = useTranslation();
  const { user } = useAuth();
  const navigate = useNavigate();
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [disabledButton, setDisabledButton] = useState(true);
  const [editing, setEditing] = useState(false);

  const { loading: editPasswordLoading, request: editPasswordRequest } = useAxios();

  const passwordRef = useRef<HTMLInputElement>(null);
  const confirmPasswordRef = useRef<HTMLInputElement>(null);

  function handlePasswordChange() {
    setDisabledButton(true);
    const password = passwordRef.current?.value;
    const confirmPassword = confirmPasswordRef.current?.value;

    if (password === confirmPassword) {
      setDisabledButton(false);
    }

    setPasswordMatch(password === confirmPassword);
  }

  async function updatePassword() {
    const form = new FormData();
    form.append('password', passwordRef.current?.value!);
    form.append('password_confirmation', confirmPasswordRef.current?.value!);
    form.append('_method', 'PUT');

    try {
      await editPasswordRequest(`/api/users/${user?.uuid}`, 'POST', form);
      toast.success(t('password_updated_successfully'));
      navigate(`/organizations/${user?.organization?.uuid}/dashboard/profile/preferences`);
    } catch (error: any) {
      toast.error(t('error_updating_password'));
    }
  }

  return (
    <Card gap={3}>
      <span className="text-blue-900 font-semibold text-base">{t('access')}</span>
      {(editing && (
        <form
          action=""
          className="text-gray-600 w-full"
          onSubmit={(e) => {
            e.preventDefault();
            updatePassword();
          }}
        >
          <label htmlFor="">{t('new_password')}</label>
          <Input placeholder="********" type="password" ref={passwordRef} />
          <label htmlFor="">{t('confirm_new_password')}</label>
          <Input placeholder="********" type="password" ref={confirmPasswordRef} onChange={handlePasswordChange} />
          {!passwordMatch && <div className="text-red-500">{t('passwords_do_not_match')}</div>}
          <div className="flex gap-2 mt-2">
            <StackedButton className="w-full" onClick={() => setEditing(false)}>
              {t('cancel')}
            </StackedButton>
            <StackedButton
              className="w-full"
              theme="primary"
              onClick={(event) => {
                event?.preventDefault();
                updatePassword();
              }}
              loading={editPasswordLoading}
              disabled={disabledButton}
            >
              {t('update')}
            </StackedButton>
          </div>
        </form>
      )) || (
        <Col gap={1} className="w-full">
          <label htmlFor="">{t('password')}</label>

          <Input placeholder="********" type="password" ref={passwordRef} readOnly />
          <StackedButton
            className="w-fit"
            onClick={() => {
              setEditing(true);
            }}
          >
            {t('edit')}
          </StackedButton>
        </Col>
      )}
    </Card>
  );
}
