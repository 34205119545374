import { classNames } from '@components/Badges/StatusBadge';
import { HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { AbsenceMark, HolidayMark, VacationMark } from '.';

export const PresenceSummary = ({ children, className, ...props }: HTMLAttributes<HTMLDivElement>) => {
  const { t } = useTranslation();

  return (
    <div
      className={classNames(
        'flex flex-row gap-2 items-start justify-start px-2 mx-auto my-4 border-t border-black/10 w-full pt-3 max-w-xs overflow-x-scroll',
        className,
      )}
      {...props}
    >
      <span className="text-base text-gray-950 flex flex-row items-center gap-2">
        <VacationMark /> {t('vacation')}
      </span>
      <span className="text-base text-gray-950 flex flex-row items-center gap-2">
        <AbsenceMark /> {t('absence')}
      </span>

      <span className="text-base text-gray-950 flex flex-row items-center gap-2">
        <HolidayMark /> {t('holiday')}
      </span>
      {children}
    </div>
  );
};
