import { useState, useRef } from 'react';
import { useOrganization } from '@hooks/organization/useOrganization';

import { useNavigate } from 'react-router-dom';

import imageDefault from '@assets/images/company-logo-default.png';
import Button from '@components/Common/Buttons/Button';
import { useTranslation } from 'react-i18next';
import { keys, values } from 'lodash';
import { SelectPicker } from 'rsuite';
import Input from '@components/Inputs';

export interface LanguageOptionsProps {
  value: string;
  label: string;
}

export enum TypesOrganizations {
  small_company = 'Empresa pequena (uma localização)',
  medium_company = 'Empresa média (com + de 10 funcionários)',
  remote_company = 'Empresa remota',
}

export const enumTypes = (enumObj: any) => {
  return Object.keys(enumObj).map((key) => ({
    label: enumObj[key],
    value: key,
  }));
};

export const getExpenseLabel = (organizationType: string) => {
  const selectedTypeKey = keys(TypesOrganizations).findIndex((currentType) => {
    return organizationType == currentType;
  });
  const selectedTypeValues = values(TypesOrganizations)[selectedTypeKey];
  return selectedTypeValues;
};

export default function CreateOrganization() {
  const { t } = useTranslation();
  const [selectedLanguageOption, setSelectedLanguageOption] = useState<string | null>(null);
  const { createOrganization } = useOrganization();
  const navigate = useNavigate();
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [selectedTypesOrganizations, setSelectedTypesOrganizations] = useState<string | null>(null);
  const optionsTypesOrganizations = enumTypes(TypesOrganizations);

  const nameRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const phoneRef = useRef<HTMLInputElement>(null);
  const nifRef = useRef<HTMLInputElement>(null);
  const imageRef = useRef<HTMLInputElement>(null);

  const languageOptions: LanguageOptionsProps[] = [
    { value: 'PT', label: 'PT' },
    { value: 'EN', label: 'EN' },
  ];

  function handleImageChange(event: any) {
    const imgElement = document.getElementById('preview-img') as HTMLImageElement;
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      if (imgElement instanceof HTMLImageElement && reader.result) {
        imgElement.src = reader.result.toString();
      }
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  }

  function handleSave() {
    setLoadingCreate(true);
    const file = imageRef.current?.files?.[0] ?? null;

    createOrganization(
      {
        name: nameRef.current!.value,
        email: emailRef.current!.value,
        phone: phoneRef.current!.value,
        language: selectedLanguageOption!,
        organization_type: selectedTypesOrganizations!,
        nif: nifRef.current!.value,
      },
      file,
    ).finally(() => {
      setLoadingCreate(false);
    });
  }

  return (
    <main className="px-4">
      <div className="flex flex-col pt-4 lg:max-w-5xl md:max-w-3xl max-w-lg w-full mx-auto">
        <div className="flex flex-col gap-2">
          <h1 className="text-4xl text-gray-800 font-bold">{t('new_organization')}</h1>
          <h2 className="text-gray-600 text-base">{t('here_you_can_add_a_new_organization')}.</h2>
        </div>
      </div>
      <div className="mt-9 text-gray-600 bg-gray-100 rounded-lg shadow-lg p-4 mb-11 lg:max-w-5xl md:max-w-3xl max-w-lg w-full mx-auto">
        <div className="w-full grid grid-cols-1 gap-2 mt-3">
          <div className="flex gap-4 items-center">
            <img
              id="preview-img"
              className="h-14 w-14 rounded-full object-cover bg-gray-50"
              src={imageDefault}
              alt="Profile"
            />
            <label
              htmlFor="avatar"
              className="relative cursor-pointer bg-primary text-sm text-white font-medium w-max py-3 px-5 rounded-md transition-all"
            >
              <span className="cursor-pointer">{t('select_image')}</span>
              <Input
                ref={imageRef}
                type="file"
                id="avatar"
                name="avatar"
                accept="image/*"
                className="absolute inset-0 opacity-0 cursor-pointer"
                onChange={handleImageChange}
              />
            </label>
          </div>
        </div>
        <div className="w-full grid lg:grid-cols-3 grid-cols-1 gap-2 mb-3">
          <div>
            <label htmlFor="" className="font-bold">
              {t('name')}*
            </label>
            <Input placeholder="Fastdoc Ltda." type="text" name="name" ref={nameRef} />
          </div>
          <div>
            <label htmlFor="" className="font-bold">
              {t('email')}*
            </label>
            <Input placeholder="organization@fastdoc.com" type="email" name="email" ref={emailRef} />
          </div>
          <div>
            <label htmlFor="" className="font-bold">
              {t('type_organization')}
            </label>
            <SelectPicker
              data={optionsTypesOrganizations}
              placeholder={t('select_the_type_of_expense')}
              placement="autoVerticalStart"
              style={{ width: '100%' }}
              onChange={(e) => setSelectedTypesOrganizations(e)}
              value={selectedTypesOrganizations}
              searchable={false}
              size="lg"
            />
          </div>
        </div>
        <div className="w-full grid md:grid-cols-3 grid-cols-1 gap-2">
          <div>
            <label htmlFor="" className="font-bold">
              {t('phone')}*
            </label>
            <Input placeholder="357 258 369" type="tel" name="phone" ref={phoneRef} />
          </div>
          <div>
            <label htmlFor="" className="font-bold">
              {t('nif')}
            </label>
            <Input placeholder="12345678" type="number" name="nif" min={0} ref={nifRef} />
          </div>
          <div>
            <label htmlFor="" className="font-bold">
              {t('language')}
            </label>
            <SelectPicker
              // ref={languageRef}
              data={languageOptions}
              value={selectedLanguageOption}
              onChange={(e) => setSelectedLanguageOption(e)}
              placeholder={t('select_language')}
              placement="autoVerticalStart"
              style={{ width: '100%' }}
              searchable={false}
              size="lg"
            />
          </div>
        </div>

        <div className="mt-6 mb-2 flex flex-row items-center gap-3">
          <Button
            onClick={() => {
              navigate(-1);
            }}
            buttonType={Button.Type.SECONDARY}
          >
            {t('cancel')}
          </Button>
          <Button onClick={handleSave} loading={loadingCreate}>
            {t('create_organization')}
          </Button>
        </div>
      </div>
    </main>
  );
}
