import Button from '@components/Common/Buttons/Button';
import { useAuth } from '@hooks/auth/useAuth';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { downloadReport } from './VacancyReportSection';
import { getCurrentDimension } from '@components/Presence/Sections/AbsenceSection';
import moment from 'moment';
import { DayPickerRangeController, FocusedInputShape } from 'react-dates';
import { Month, NextMonth, PrevMonth } from '@components/Calendar';
import { ArrowDownTrayIcon } from '@heroicons/react/24/solid';

export default function DisplacementsReportSection() {
  const { user } = useAuth();

  const [displacementDateState, setDisplacementDateState] = useState<{
    startDate: moment.Moment;
    endDate: moment.Moment;
  }>({
    startDate: moment(),
    endDate: moment(),
  });

  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const [focusedInput, setFocusedInput] = useState('startDate');

  const handleDownloadReportClick = () => {
    const requestStartDate = format(displacementDateState?.startDate?.toDate(), 'yyyy-MM-dd');
    const requestEndDate = format(displacementDateState?.endDate?.toDate(), 'yyyy-MM-dd');

    downloadReport(
      `/api/organizations/${user?.organization?.uuid}/displacements-pdf${
        requestStartDate !== requestEndDate ? `?from=${requestStartDate}&to=${requestEndDate}` : ''
      } `,
      `deslocacoes-${requestStartDate}-a-${requestEndDate}.pdf`,
    );
  };

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener('resize', updateDimension);

    return () => {
      window.removeEventListener('resize', updateDimension);
    };
  }, [screenSize]);

  return (
    <section className="flex flex-col items-center justify-center w-full gap-4">
      <DayPickerRangeController
        daySize={48}
        navNext={<NextMonth />}
        navPrev={<PrevMonth />}
        renderMonthElement={Month}
        initialVisibleMonth={() => moment()}
        noBorder
        verticalBorderSpacing={5}
        startDate={displacementDateState.startDate}
        endDate={displacementDateState.endDate}
        onDatesChange={({ startDate, endDate }) => {
          setDisplacementDateState({
            startDate: startDate as moment.Moment,
            endDate: (endDate ?? startDate) as moment.Moment,
          });
          return;
        }}
        onFocusChange={(focusedInput) => {
          setFocusedInput(focusedInput as string);
        }}
        focusedInput={(focusedInput || 'startDate') as FocusedInputShape}
      />

      <Button className="max-w-xs" onClick={handleDownloadReportClick}>
        <span className="flex flex-row gap-3 items-center text-white w-full justify-center">
          <ArrowDownTrayIcon className="text-white w-6" /> Download
        </span>
      </Button>
    </section>
  );
}
