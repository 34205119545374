import { useAuth } from '@hooks/auth/useAuth';
import { useUser } from '@hooks/user/useUser';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import Card from './Card';
import Row from '@components/Flex/Row';

export default function Language() {
  const { t, i18n } = useTranslation();
  const { user } = useAuth();
  const { updateLanguage } = useUser();
  const [selectedLanguage, setSelectedLanguage] = useState<string>(
    (localStorage.getItem('@fastodc:language') as never) || 'en',
  );

  async function handleChangeLanguage(newLanguage: string) {
    localStorage.setItem('@fastodc:language', newLanguage) as never;

    i18n.changeLanguage(newLanguage);

    const newUserLanguageId = newLanguage === 'en' ? '2' : '1';

    if (user) {
      await updateLanguage(
        {
          user_language_id: newUserLanguageId,
        },
        user.uuid!,
      )
        .then(() => {
          toast.success(t('language_updated_successfully'));
        })
        .catch((error) => {
          toast.error(t('failed_to_update_language', { error }));
        });
    }

    setSelectedLanguage(newLanguage);
  }

  return (
    <Card gap={3}>
      <span className="text-blue-900 font-semibold text-base">{t('language')}</span>

      <Row gap={2} className="items-cente">
        <input
          id="radio-1"
          type="radio"
          value=""
          name="radio"
          onClick={() => handleChangeLanguage('en')}
          checked={selectedLanguage === 'en'}
          className="w-4 h-4 text-blue-900 bg-gray-50 border-blue-900 focus:ring-blue-900 ring-offset-blue-900 focus:ring-2 "
        />
        <label htmlFor="radio-1" className="ms-2 text-sm font-medium text-gray-700 ">
          {t('english')}
        </label>
      </Row>
      <Row gap={2} className="items-center">
        <input
          checked={selectedLanguage === 'pt'}
          id="radio-2"
          type="radio"
          value=""
          name="radio"
          onClick={() => handleChangeLanguage('pt')}
          className="w-4 h-4 text-blue-900 bg-gray-50 border-blue-900 focus:ring-blue-900 ring-offset-blue-900 focus:ring-2 "
        />
        <label htmlFor="radio-2" className="ms-2 text-sm font-medium text-gray-700">
          {t('portuguese')}
        </label>
      </Row>
    </Card>
  );
}
