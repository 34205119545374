import StatusBadge, { BadgeStatus } from '@components/Badges/StatusBadge';
import Button from '@components/Common/Buttons/Button';
import { useAuth } from '@hooks/auth/useAuth';
import useAxios from '@hooks/axios/useAxios';
import { useVacations } from '@hooks/presence/useVacations';
import { parse, parseISO } from 'date-fns';
import format from 'date-fns/format';
import { values } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ModalDelete } from '@components/Modal/ModalDelete';
import { useTranslation } from 'react-i18next';
import { Timeline } from 'rsuite';
import { DayPickerRangeController, FocusedInputShape } from 'react-dates';
import { getCurrentDimension } from './AbsenceSection';
import moment from 'moment';
import { useAbsences } from '@hooks/presence/useAbsences';
import { useOrganization } from '@hooks/organization/useOrganization';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { PresenceSummary } from '@components/Calendar/Summary/PresenceSummary';
import { AbsenceMark, HolidayMark, VacationMark } from '@components/Calendar/Summary';
import { Month, NextMonth, PrevMonth } from '@components/Calendar';
import { ArrowRightCircleIcon, CalendarIcon } from '@heroicons/react/24/outline';
import { ArrowPathIcon, TrashIcon } from '@heroicons/react/24/solid';
import Loading from '@components/loading';
import { ApprovedDot, PendingDot, RejectedDot } from '@components/Timeline/Dots';
import Col from '@components/Flex/Col';

export function getDatesBetween(startDate: Date, endDate: Date) {
  const dates = [];
  const currentDate = new Date(startDate);

  while (currentDate <= endDate) {
    dates.push(new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dates;
}
export default function VacationSection() {
  const { t } = useTranslation();
  const [existingVacationsCount, setExistingVacationsCount] = useState(0);
  const [approvedVacationsCount, setApprovedVacationsCount] = useState<number>(0);
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  const { user } = useAuth();
  const { id } = useParams<{ id: string }>();
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [focusedInput, setFocusedInput] = useState('startDate');
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const [vacationDateState, setVacationDateState] = useState<{
    startDate: moment.Moment | null;
    endDate: moment.Moment | null;
  }>({
    startDate: moment(),
    endDate: moment(),
  });
  const { getOrganizationHolidays } = useOrganization();
  const { getAllVacations } = useVacations();
  const { getAllAbsences } = useAbsences();
  const { request: deleteVacationPending } = useAxios();

  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);

  const handleDeleteButtonClick = () => {
    setIsModalDeleteOpen(true);
  };

  const startDate = vacationDateState?.startDate;
  const endDate = vacationDateState?.endDate;

  const { isLoading: queryVacationsLoading, data: localVacations } = useQuery<any[]>(['vacations', user], () =>
    getAllVacations(user!),
  );

  const { isLoading: queryAbsencesLoading, data: localAbsences } = useQuery<any[]>(['absences', user], () =>
    getAllAbsences(user!),
  );

  const { isLoading: queryHolidaysLoading, data: localHolidays } = useQuery<any[]>(['holidays', user], () =>
    getOrganizationHolidays(user?.organization?.uuid!),
  );

  const queryClient = useQueryClient();

  const createVacationMutation = useMutation({
    mutationFn: async (newData: any) =>
      createhVacation(`/api/organizations/${user?.organization?.uuid}/vacations`, 'POST', newData)
        .then(() => {
          toast.success(t('vacation_successfully_registered'));
        })
        .catch(({ response }) => {
          toast.error(response?.data?.content?.message);
          values(response?.data?.errors)?.map((errors: any) => errors?.map((error: any) => toast.error(error)));
        }),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: ['vacations'] });
    },
  });

  const deleteVacationMutation = useMutation({
    mutationFn: async ({ organizationUuid, vacationUuid }: { organizationUuid: string; vacationUuid: string }) =>
      deleteVacationPending(`/api/organizations/${organizationUuid}/vacations/${vacationUuid}`, 'DELETE')
        .then(() => {
          toast.success(t('vacation_request_successfully_deleted'));
        })
        .catch(({ response }) => {
          values(response?.data?.errors)?.map((errors: any) => errors?.map((error: any) => toast.error(error)));
        }),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: ['vacations'] });
    },
  });

  const duration = moment.duration(endDate?.diff(startDate));

  const daysDifference = duration.asDays() + 1;

  const organizationUuid = user?.organization?.uuid || '';

  const vacationData = useMemo(() => {
    if (vacationDateState.startDate) {
      return {
        organizationUuid: organizationUuid,
        user_id: user?.id,
        time_in: vacationDateState?.startDate?.format('yyyy-MM-DD HH:mm:ss'),
        time_out: vacationDateState?.endDate?.format('yyyy-MM-DD HH:mm:ss'),
      };
    }
  }, [organizationUuid, vacationDateState, user]);

  const { loading: createVacationLoading, request: createhVacation } = useAxios();

  function save() {
    if (!id || !vacationDateState) {
      return;
    }

    createVacationMutation.mutate(vacationData);
  }
  useEffect(() => {
    const existingVacationCount = localVacations?.length ?? 0;

    const nullConfirmationVacations = localVacations?.filter((vacationItem: any) => {
      return vacationItem.time_in_confirm != null && vacationItem.time_out_confirm != null;
    });

    const nullConfirmationVacationCount = nullConfirmationVacations?.length;

    setExistingVacationsCount(existingVacationCount);
    setApprovedVacationsCount(nullConfirmationVacationCount ?? 0);
  }, [localVacations]);

  const handleDeleteVacationPending = (organizationUuid: string, vacationUuid: string) => {
    deleteVacationMutation.mutate({ organizationUuid, vacationUuid });
  };

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener('resize', updateDimension);

    return () => {
      window.removeEventListener('resize', updateDimension);
    };
  }, [screenSize]);

  const handleCompareIfDateSelected = useCallback(
    (item: moment.Moment) => {
      return (
        (localVacations?.some((vacation: any) => {
          const vacationStart = parse(vacation.time_in, 'yyyy-MM-dd HH:mm:ss', new Date());
          const vacationEnd = parse(vacation.time_out, 'yyyy-MM-dd HH:mm:ss', new Date());

          return (
            (item.isAfter(vacationStart) && item.isBefore(vacationEnd)) ||
            item.isSame(vacationStart, 'day') ||
            item.isSame(vacationEnd, 'day')
          );
        }) ||
          localAbsences?.some((absence: any) => {
            const absenceStart = parse(absence.time_in, 'yyyy-MM-dd HH:mm:ss', new Date());
            const absenceEnd = parse(absence.time_out, 'yyyy-MM-dd HH:mm:ss', new Date());

            return (
              (item.isAfter(absenceStart) && item.isBefore(absenceEnd)) ||
              item.isSame(absenceStart, 'day') ||
              item.isSame(absenceEnd, 'day')
            );
          }) ||
          localHolidays?.some((holiday: any) => {
            const selectedHoliday = parse(holiday.date, 'yyyy-MM-dd HH:mm:ss', new Date());

            return item.isSame(selectedHoliday, 'day');
          })) ??
        false
      );
    },
    [localAbsences, localHolidays, localVacations],
  );

  return (
    <section className="flex flex-col items-center justify-center w-full">
      <div className="w-full mt-4 flex justify-center items-center">
        {(queryVacationsLoading || queryAbsencesLoading || queryHolidaysLoading) && (
          <Loading className="w-full mx-auto" />
        )}

        {!queryVacationsLoading && !queryAbsencesLoading && !queryHolidaysLoading && (
          <DayPickerRangeController
            daySize={48}
            navNext={<NextMonth />}
            navPrev={<PrevMonth />}
            renderMonthElement={Month}
            initialVisibleMonth={() => moment()}
            noBorder
            verticalBorderSpacing={5}
            isDayHighlighted={handleCompareIfDateSelected}
            isDayBlocked={handleCompareIfDateSelected}
            startDate={vacationDateState.startDate}
            endDate={vacationDateState.endDate}
            renderCalendarInfo={() => <PresenceSummary />}
            onDatesChange={({ startDate, endDate }) => {
              const isDateSelectedOnDisabledRange = localAbsences?.some((absence: any) => {
                const absenceStart = parse(absence.time_in, 'yyyy-MM-dd HH:mm:ss', new Date());
                const absenceEnd = parse(absence.time_out, 'yyyy-MM-dd HH:mm:ss', new Date());

                return (
                  moment(absenceStart).isBetween(startDate, endDate) || moment(absenceEnd).isBetween(startDate, endDate)
                );
              });

              const isDateSelectedOnVacationRange = localVacations?.some((vacation: any) => {
                const vacationStart = parse(vacation.time_in, 'yyyy-MM-dd HH:mm:ss', new Date());
                const vacationEnd = parse(vacation.time_out, 'yyyy-MM-dd HH:mm:ss', new Date());

                return (
                  moment(vacationStart).isBetween(startDate, endDate) ||
                  moment(vacationEnd).isBetween(startDate, endDate)
                );
              });

              const isDateSelectedOnHolidayRange = localHolidays?.some((holiday: any) => {
                const selectedHoliday = parse(holiday.date, 'yyyy-MM-dd HH:mm:ss', new Date());

                return moment(selectedHoliday).isBetween(startDate, endDate);
              });

              if (!isDateSelectedOnDisabledRange && !isDateSelectedOnHolidayRange && !isDateSelectedOnVacationRange) {
                setVacationDateState({ startDate, endDate: endDate ?? startDate });
                return;
              }
            }}
            onFocusChange={(focusedInput) => {
              setFocusedInput(focusedInput as string);
            }}
            focusedInput={(focusedInput || 'startDate') as FocusedInputShape}
            renderDayContents={(day) => {
              const dateAbsenceSelected = localAbsences?.some((absence: any) => {
                const absenceStart = parse(absence.time_in, 'yyyy-MM-dd HH:mm:ss', new Date());
                const absenceEnd = parse(absence.time_out, 'yyyy-MM-dd HH:mm:ss', new Date());

                return (
                  day.isBetween(absenceStart, absenceEnd) ||
                  day.isSame(absenceStart, 'day') ||
                  day.isSame(absenceEnd, 'day')
                );
              });

              const dateVacationSelected = localVacations?.some((vacation: any) => {
                const vacationStart = parse(vacation.time_in, 'yyyy-MM-dd HH:mm:ss', new Date());
                const vacationEnd = parse(vacation.time_out, 'yyyy-MM-dd HH:mm:ss', new Date());
                return (
                  day.isBetween(vacationStart, vacationEnd) ||
                  day.isSame(vacationStart, 'day') ||
                  day.isSame(vacationEnd, 'day')
                );
              });
              const dateHolidaySelected = localHolidays?.some((holiday: any) => {
                const selectedHoliday = parse(holiday.date, 'yyyy-MM-dd HH:mm:ss', new Date());

                return day.isSame(moment(selectedHoliday, 'MM/D/YYYY'), 'date');
              });

              if (dateAbsenceSelected || dateVacationSelected || dateHolidaySelected) {
                return (
                  <div className="flex flex-col items-center justify-center gap-2">
                    {day.format('D')}
                    <div className="flex flex-row gap-1 items-center">
                      {dateAbsenceSelected && <AbsenceMark />}
                      {dateVacationSelected && <VacationMark />}
                      {dateHolidaySelected && <HolidayMark />}
                    </div>
                  </div>
                );
              }
              return day.format('D');
            }}
          />
        )}
      </div>
      <div className="flex gap-3 items-center bg-primary/20 rounded-full w-full max-w-xs">
        <ArrowRightCircleIcon className="text-primary w-7" />
        <p className="text-sm text-primary font-normal select-none">{t('select_the_start_and_end_period')}</p>
      </div>
      <div className="grid grid-cols-2 gap-4 w-full max-w-md mt-5 mb-8">
        <div className="bg-gray-50 rounded-lg shadow-md px-4 py-5">
          <p className="text-primary/90 text-sm font-medium">{t('for_fun')}</p>
          <span className="text-gray-900 text-3xl font-semibold">{daysDifference}</span>
        </div>
        <div className="bg-gray-50 rounded-lg shadow-md px-4 py-5">
          <p className="text-primary/90 text-sm font-medium">{t('approved')}</p>
          <span className="text-gray-900 text-3xl font-semibold">
            {approvedVacationsCount} {t('of')} {existingVacationsCount}
          </span>
        </div>
      </div>

      <div className="flex flex-col gap-3 justify-center w-full mb-2 px-2">
        {(queryVacationsLoading || queryAbsencesLoading || queryHolidaysLoading) && (
          <Loading className="w-full mx-auto" />
        )}
        {!queryVacationsLoading &&
          !queryAbsencesLoading &&
          !queryHolidaysLoading &&
          localVacations?.map((vacation, index) => {
            const startTimeVacation = String(new Date(vacation?.time_in).toISOString().split('T')[0])
              .replace('-', '/')
              .replace('-', '/');

            const startTimeVacationConfirm = String(new Date(vacation?.time_in_confirm!).toISOString().split('T')[0])
              .replace('-', '/')
              .replace('-', '/');

            const endTimeVacation = String(new Date(vacation?.time_out).toISOString().split('T')[0])
              .replace('-', '/')
              .replace('-', '/');

            const endTimeVacationConfirm = String(new Date(vacation?.time_out_confirm!).toISOString().split('T')[0])
              .replace('-', '/')
              .replace('-', '/');

            return (
              <section
                key={vacation?.uuid}
                className="flex flex-col gap-4 w-full border-b-2 border-solid border-gray-200 py-2"
              >
                <div className="flex justify-between items-center">
                  <span className="text-gray-700 text-sm font-medium">
                    {String(new Date(String(vacation.created_at)).toISOString().split('T')[0])
                      .replace('-', '/')
                      .replace('-', '/')}
                    {' - '}
                    {vacation?.time_in !== vacation?.time_out
                      ? t('you_requested_time_off')
                      : t('you_requested_one_time_off')}
                  </span>

                  <div className="flex flex-row gap-3">
                    <button
                      className="p-2 flex items-center justify-center hover:bg-primary/20 rounded-lg"
                      onClick={() => {
                        if (selectedIndex == index && typeof selectedIndex == 'number') {
                          setSelectedIndex(null);
                        } else setSelectedIndex(index);
                      }}
                    >
                      <ArrowPathIcon className="text-primary text-lg w-4" />
                    </button>
                    {vacation.status === ('pending' as BadgeStatus) && (
                      <>
                        <button onClick={handleDeleteButtonClick} className="text-red-600 hover:text-red-700">
                          <TrashIcon className="w-5" />
                        </button>
                        {isModalDeleteOpen && (
                          <ModalDelete
                            isOpen={isModalDeleteOpen}
                            onClose={() => setIsModalDeleteOpen(false)}
                            title={t('delete_vacation_request')}
                            content={`${t('are_you_sure_you_want_to_delete_the_vacation_request')}`}
                            onConfirm={() => {
                              handleDeleteVacationPending(user?.organization?.uuid!, vacation.uuid!);
                              setIsModalDeleteOpen(false);
                            }}
                          />
                        )}
                      </>
                    )}
                  </div>
                </div>
                <div className="flex flex-row justify-between items-center">
                  <div className="flex flex-col gap-2">
                    {(selectedIndex == index && vacation.statuses.length >= 0 && (
                      <Timeline className="custom-timeline">
                        {vacation.statuses.map((status: any) => {
                          if (status.name == 'pending') {
                            return (
                              <Timeline.Item dot={<PendingDot />} key={status?.id}>
                                <p className="ml-2">{format(parseISO(status?.created_at), 'yyyy/MM/dd HH:mm:ss')}</p>
                                <p className="ml-2">{t('you_requested_an_absence')}</p>
                              </Timeline.Item>
                            );
                          } else if (status.name == 'rejected') {
                            return (
                              <Timeline.Item dot={<RejectedDot />} key={status?.id}>
                                <p className="ml-2">{format(parseISO(status?.created_at), 'yyyy/MM/dd HH:mm:ss')}</p>
                                <p className="ml-2">{t('absence_request_rejected')}</p>
                              </Timeline.Item>
                            );
                          } else {
                            return (
                              <Timeline.Item dot={<ApprovedDot />} key={status?.id}>
                                <p className="ml-2">{format(parseISO(status?.created_at), 'yyyy/MM/dd HH:mm:ss')}</p>
                                <p className="ml-2">{t('absence_request_approved')}</p>
                              </Timeline.Item>
                            );
                          }
                        })}
                      </Timeline>
                    )) ||
                      (selectedIndex == index && vacation.statuses.length == 0 && (
                        <Col className="w-full justify-center items-center gap-2">
                          <div className="bg-gray-300 rounded-lg p-3">
                            <CalendarIcon className="w-6 text-white" />
                          </div>
                          <p className="text-gray-400 text-sm text-center">{t('nothing_to_show')}.</p>
                        </Col>
                      ))}

                    {vacation.status === ('approved' as BadgeStatus) ? (
                      <>
                        {startTimeVacation === startTimeVacationConfirm &&
                        endTimeVacation === endTimeVacationConfirm ? (
                          <>
                            <span key={vacation?.id} className="text-black text-base">
                              {startTimeVacation}{' '}
                              {startTimeVacation !== endTimeVacation && (
                                <>
                                  -{'>'} {endTimeVacation}
                                </>
                              )}
                            </span>
                          </>
                        ) : (
                          <>
                            <span key={vacation?.id} className="text-black text-base line-through">
                              {startTimeVacation}{' '}
                              {startTimeVacation !== endTimeVacation && (
                                <>
                                  -{'>'} {endTimeVacation}
                                </>
                              )}
                            </span>
                            <span key={vacation?.id} className="text-black text-base">
                              {startTimeVacationConfirm}{' '}
                              {startTimeVacationConfirm !== endTimeVacationConfirm && (
                                <>
                                  -{'>'} {endTimeVacationConfirm}
                                </>
                              )}
                            </span>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <span key={vacation?.id} className="text-black text-base">
                          {startTimeVacation}{' '}
                          {startTimeVacation !== endTimeVacation && (
                            <>
                              -{'>'} {endTimeVacation}
                            </>
                          )}
                        </span>
                      </>
                    )}
                  </div>

                  <StatusBadge status={vacation.status!} />
                </div>
              </section>
            );
          })}
      </div>
      <Button className="font-normal sticky bottom-5 m-2" onClick={save} loading={createVacationLoading}>
        {t('add_vacation')}
      </Button>
    </section>
  );
}
