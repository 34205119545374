import { User } from '@hooks/auth/useAuth';
import { CustomFile } from '@hooks/presence/useAbsences';
import { LIMIT_RECORDS } from 'App';
import { toast } from 'react-toastify';
import { api } from 'services/api';
import { create } from 'zustand';

export interface Expense {
  user_id?: number;
  comment: string;
  total: string;
  date?: string;
  organization_id?: string;
  uuid?: string;
  id?: string;
  qr_code?: string;
  supplier?: string;
  expense_type: string;
  expense_file?: ExpenseFiles;
  user?: User;
}

export interface ExpenseFiles {
  expense_id: number;
  file: string;
  file_path: string;
  id: number;
  uuid: string;
}

export type displacementAllowance = {
  id: number;
  day: number;
  service: string;
  locale: string;
  start_time: string;
  end_time: string;
  area: string;
  value: number;
};

export type displacementOwnCar = {
  id: number;
  day: number;
  origin: string;
  destination_location: string;
  justification: string;
  km: number;
};

type ExpensesState = {
  currentSection: 'expenses' | 'spending' | 'displacements';
  isOpenFileModal: boolean;
  isOpenQrModal: boolean;
  isOpenNewOwnCarModal: boolean;
  isOpenNewAllowanceModal: boolean;
  isOpenSendMapDisplacementModal: boolean;
  allowances: displacementAllowance[];
  owlCars: displacementOwnCar[];

  setCurrentSection: (section: 'expenses' | 'spending' | 'displacements') => void;
  setIsOpenFileModal: (value: boolean) => void;
  setIsOpenQrModal: (value: boolean) => void;
  setIsOpenNewOwnCarModal: (value: boolean) => void;
  setIsOpenNewAllowanceModal: (value: boolean) => void;
  setIsOpenSendMapDisplacementModal: (value: boolean) => void;
  clearConfigExpenses(): void;
  addDisplacementOwlCar: (displacement: displacementOwnCar) => void;
  addDisplacementAllowance: (displacementAllowance: displacementAllowance) => void;

  expenses: Expense[];
  files: CustomFile[];
  setFiles: (value: any) => void;
  removeFile(state: any): void;
  getExpenses: (
    organizationUuid: string,
    year: number,
    month: number,
    day: number,
    user_id?: number,
    admin?: boolean,
  ) => Promise<void>;
  getExpensesByDate: (
    organizationUuid: string,
    year: number,
    month: number,
    day: number,
    user_id?: number,
    admin?: boolean,
  ) => Promise<any>;
  getAllExpenses: (user: User) => Promise<any>;

  getExpensesById: (organizationUuid: string, expenseUuid: string) => Promise<Expense>;
  createExpense: (expense: Expense, organizationUuid: string, file: File | '') => Promise<void>;
  editExpense: (expense: Expense, organizationUuid: string, expenseUuid: string, file: File | '') => Promise<void>;
  deleteExpense: (organizationUuid: string, expenseUuid: string) => Promise<void>;
  deleteFile: (OrganizationUuid: string, ExpenseUuid: string, ExpenseFileUuid: string) => Promise<void>;
};

export const useExpenses = create<ExpensesState>((set) => ({
  currentSection: 'expenses',
  isOpenFileModal: false,
  isOpenQrModal: false,
  isOpenNewOwnCarModal: false,
  isOpenNewAllowanceModal: false,
  isOpenSendMapDisplacementModal: false,
  allowances: [],
  owlCars: [],

  setCurrentSection: (section) => set({ currentSection: section }),
  setIsOpenQrModal: (value) => set({ isOpenQrModal: value }),
  setIsOpenFileModal: (value) => set({ isOpenFileModal: value }),
  setIsOpenNewOwnCarModal: (value) => set({ isOpenNewOwnCarModal: value }),
  setIsOpenNewAllowanceModal: (value) => set({ isOpenNewAllowanceModal: value }),
  setIsOpenSendMapDisplacementModal: (value) => set({ isOpenSendMapDisplacementModal: value }),

  clearConfigExpenses: () =>
    set({
      currentSection: 'expenses',
      isOpenFileModal: false,
      isOpenQrModal: false,
    }),
  addDisplacementOwlCar: (displacement) =>
    set((state) => ({
      owlCars: [...state.owlCars, displacement],
    })),

  addDisplacementAllowance: (displacementAllowance) =>
    set((state) => ({
      allowances: [...state.allowances, displacementAllowance],
    })),

  expenses: [],
  files: [],
  setFiles: (value: CustomFile[]) => set({ files: value }),
  removeFile: (identificator: string) => {
    set((state) => ({
      files: state.files.filter((file: any) => file.preview !== identificator),
    }));
  },
  getExpenses: async (
    organizationUuid: string,
    year: number,
    month: number,
    day: number,
    user_id?: number,
    admin = false,
  ) => {
    let endpoint = `/api/organizations/${organizationUuid}/expenses/date/${year}/${month}/${day}?take=${LIMIT_RECORDS}&user_id=${user_id}`;

    try {
      if (admin) {
        endpoint = `/api/organizations/${organizationUuid}/expenses/date/${year}/${month}/${day}?take=${LIMIT_RECORDS}`;
      }
      const { data } = await api.get(endpoint);
      const expenses = data?.content?.data?.data;
      set({ expenses });
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  getExpensesByDate: async (
    organizationUuid: string,
    year: number,
    month: number,
    day: number,
    user_id?: number,
    admin = false,
  ) => {
    let endpoint = `/api/organizations/${organizationUuid}/expenses/date/${year}/${month}/${day}?take=${LIMIT_RECORDS}&user_id=${user_id}`;

    try {
      if (admin) {
        endpoint = `/api/organizations/${organizationUuid}/expenses/date/${year}/${month}/${day}?take=${LIMIT_RECORDS}`;
      }
      const { data } = await api.get(endpoint);
      return data?.content?.data?.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  getAllExpenses: (user: User) => {
    return api
      .get(
        `/api/organizations/${user?.organization?.uuid}/expenses?take=${LIMIT_RECORDS}&sortBy=created_at&sortDirection=desc&user_id=${user?.id}`,
      )
      .then(({ data }) => data?.content?.data?.data);
  },
  getExpensesById: async (organizationUuid: string, expenseUuid: string) => {
    try {
      const { data } = await api.get(`/api/organizations/${organizationUuid}/expenses/${expenseUuid}`);
      const expenses: Expense = data.content.data;
      return expenses;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  createExpense: async (expense: Expense, organizationUuid: string, file: File | '') => {
    try {
      const form = new FormData();
      form.append('user_id', String(expense?.user_id));
      form.append('expense_type', expense.expense_type);
      form.append('comment', expense.comment);
      form.append('total', expense.total);
      form.append('date', String(expense.date)!);
      form.append('supplier', expense.supplier!);
      //form.append('2d_barcode', expense.qr_code!);
      form.append('file', file);

      // files.forEach((file) => {
      //     form.append("files[]", file);
      // })

      try {
        await api.post(`/api/organizations/${organizationUuid}/expenses`, form);
        toast.success('Despesa criada com sucesso!');
      } catch (error: any) {
        if (error.response.data.errors.comment && error.response.data.errors.comment[0]) {
          toast.error(error.response.data.errors.comment[0]);
        }
        if (error.response.data.errors.expense_type_id && error.response.data.errors.expense_type_id[0]) {
          toast.error(error.response.data.errors.expense_type_id[0]);
        }
        if (error.response.data.errors.total && error.response.data.errors.total[0]) {
          toast.error(error.response.data.errors.total[0]);
        }
        if (error.response.data.errors.expense_type && error.response.data.errors.expense_type[0]) {
          toast.error(error.response.data.errors.expense_type[0]);
        }
        if (error.response.data.errors.supplier && error.response.data.errors.supplier[0]) {
          toast.error(error.response.data.errors.supplier[0]);
        }
      }
    } catch (error: any) {
      toast.error(error);
      console.error(error);
    }
  },
  editExpense: async (expense: Expense, organizationUuid: string, expenseUuid: string, file: File | '') => {
    try {
      const form = new FormData();
      form.append('user_id', String(expense.user_id));
      form.append('expense_type', expense.expense_type);
      form.append('comment', expense.comment);
      form.append('total', expense.total);
      form.append('date', String(expense.date));
      form.append('supplier', expense.supplier!);
      //form.append('2d_barcode', expense.qr_code!);
      form.append('_method', 'PUT');
      if (file) {
        form.append('file', file);
      }

      // files.forEach((file) => {
      //     form.append("files[]", file);
      // })
      try {
        await api.post(`/api/organizations/${organizationUuid}/expenses/${expenseUuid}`, form);
        toast.success('Despesa atualizada com sucesso!');
      } catch (error: any) {
        if (error.response.data.errors.comment && error.response.data.errors.comment[0]) {
          toast.error(error.response.data.errors.comment[0]);
        }
        if (error.response.data.errors.expense_type_id && error.response.data.errors.expense_type_id[0]) {
          toast.error(error.response.data.errors.expense_type_id[0]);
        }
        if (error.response.data.errors.total && error.response.data.errors.total[0]) {
          toast.error(error.response.data.errors.total[0]);
        }
        if (error.response.data.errors.expense_type && error.response.data.errors.expense_type[0]) {
          toast.error(error.response.data.errors.expense_type[0]);
        }
      }
    } catch (error: any) {
      console.error(error);
    }
  },
  deleteExpense: async (organizationUuid: string, expenseUuid: string) => {
    try {
      await api.delete(`/api/organizations/${organizationUuid}/expenses/${expenseUuid}`);
      toast.success('Despesa deletada com sucesso!');
    } catch (error) {
      toast.error('Erro ao deletar despesa');
      console.error(error);
    }
  },
  deleteFile: async (OrganizationUuid: string, ExpenseUuid: string, ExpenseFileUuid: string) => {
    try {
      await api.delete(`/api/organizations/${OrganizationUuid}/expenses/${ExpenseUuid}/file/${ExpenseFileUuid}`);
      toast.success('Arquivo deletado com sucesso!');
    } catch (error) {
      toast.error('Erro ao deletar arquivo');
      console.error(error);
    }
  },
}));
