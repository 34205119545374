import { CustomFile, useAbsences } from '../../../../../hooks/presence/useAbsences';
import Button from '../../../../Common/Buttons/Button';
import { useDropzone } from 'react-dropzone';
import { ArrowDownTrayIcon } from '@heroicons/react/24/solid';
import { XMarkIcon } from '@heroicons/react/24/outline';
import Input from '@components/Inputs';

function DragAndDrop() {
  const { setIsOpenFileModal, files, setFiles, removeFile } = useAbsences();

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': [],
    },
    onDrop: (acceptedFiles: any) => {
      setFiles(
        acceptedFiles.map((file: CustomFile) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      );
    },
  });

  return (
    <section className="px-8 w-full md:max-w-[800px] mx-auto">
      <div {...getRootProps({ className: 'dropzone' })}>
        <Input {...getInputProps()} />
        <div className="cursor-pointer shadow-md flex flex-col px-16 py-8 rounded-md border-2 border-dashed border-primary w-full  items-center">
          <ArrowDownTrayIcon className="w-16" />
          <span className="text-center">Arraste e solte os arquivos aqui ou clique para fazer upload</span>
        </div>
      </div>
      {files.length > 0 && (
        <div className="flex mt-8 gap-2 w-full border p-4 overflow-x-auto items-center">
          {files.map((file: CustomFile) => (
            <div key={file.name}>
              <div className="relative w-[100px] h-[100px]">
                <img
                  className="w-[100px] rounded h-[100px] object-cover"
                  src={file.preview}
                  // Revoke data uri after image is loaded
                  onLoad={() => {
                    URL.revokeObjectURL(file.preview);
                  }}
                />
                <button
                  onClick={() => removeFile(file.preview)}
                  className="absolute right-1 top-1 text-red-500 shadow-md"
                >
                  <XMarkIcon className="w-5" />
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
      <div className="mt-6 w-full">
        <Button onClick={() => setIsOpenFileModal(false)}>Salvar</Button>
      </div>
    </section>
  );
}

export default DragAndDrop;
