import TabLink from '@components/Navbar/TabLink';
import { useAuth } from '@hooks/auth/useAuth';
import { useLayout } from '@hooks/layout/useLayout';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, matchPath, useNavigate } from 'react-router-dom';
export default function Profile() {
  const { t } = useTranslation();
  const { user } = useAuth();
  const matchPreferences = !!matchPath(
    window.location.pathname,
    `/organizations/${user?.organization?.uuid}/dashboard/profile/preferences`,
  );

  const matchRequests = !!matchPath(
    window.location.pathname,
    `/organizations/${user?.organization?.uuid}/dashboard/profile/requests`,
  );

  const navigate = useNavigate();

  const { setPageTitle } = useLayout();

  useEffect(() => {
    setPageTitle(t('account'));

    return () => {
      setPageTitle(null);
    };
  }, []);

  return (
    <div className="flex flex-col w-full md:flex-row min-h-[-webkit-fill-available]">
      <section className="flex lg:flex md:bg-primary/10 bg-gray-50 z-9 rounded-tr-lg flex-col gap-10 p-3 md:border-r md:max-w-[368px] w-full">
        <div className="flex flex-col gap-2">
          <span className="text-4xl text-gray-800 font-bold">{t('profile')}</span>
          <span className="text-gray-600 text-base">{t('here_you_can_manage_your_account')}.</span>
        </div>
        <div className="w-full flex md:hidden rounded-lg shadow-lg bg-gray-100 border-b border-gray-200 ">
          <TabLink to={`/organizations/${user?.organization?.uuid}/dashboard/profile/preferences`}>
            {t('settings')}
          </TabLink>
          <TabLink to={`/organizations/${user?.organization?.uuid}/dashboard/profile/requests`}>
            {t('requests')}
          </TabLink>
        </div>
        <div className="flex-col gap-3 md:flex hidden">
          <button
            className={`text-base w-full text-left ${matchPreferences ? 'text-primary font-bold' : 'text-gray-600'}`}
            onClick={() => navigate(`/organizations/${user?.organization?.uuid}/dashboard/profile/preferences`)}
          >
            {t('settings')}
          </button>
          <button
            className={`text-base w-full text-left ${matchRequests ? 'text-primary font-bold' : 'text-gray-600'}`}
            onClick={() => navigate(`/organizations/${user?.organization?.uuid}/dashboard/profile/requests`)}
          >
            {t('requests')}
          </button>
        </div>
      </section>
      <div className="flex flex-row justify-center w-full md:flex-row overflow-y-scroll px-2 pb-6">
        <Outlet />
      </div>
    </div>
  );
}
