import { usePresences } from '../../../../hooks/presence/usePresences';
import { PointProps } from '../../../../hooks/presence/usePoint';
import Button from '../../../Common/Buttons/Button';
import { useEffect, useMemo, useState } from 'react';
import { useAuth } from '@hooks/auth/useAuth';

import { format } from 'date-fns';
import { toast } from 'react-toastify';
import QrCodeReader from '@components/QrCode/QrCodeReader';
import useAxios from '@hooks/axios/useAxios';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';

export function addTimes(times: string[] = []) {
  let duration = 0;
  times?.forEach((time) => {
    duration = duration + moment.duration(time).as('milliseconds');
  });
  return moment.utc(duration).format('HH:mm:ss');
}

interface QRCodePresenceModalProps {
  type: 'in' | 'out';
  onCloseModal: () => void;
  dayPresences: PointProps[];
}

export default function QRCodePresenceModal({ type, onCloseModal, dayPresences }: QRCodePresenceModalProps) {
  const { t } = useTranslation();
  const { isOpenQrModal, setIsOpenQrModal } = usePresences();
  const [result, setResult] = useState<any>(null);
  const [latitude, setLatitude] = useState<number | null>(null);
  const [longitude, setLongitude] = useState<number | null>(null);
  const [hasCamera, setHasCamera] = useState<boolean>(true);
  const [hasGeolocation, setHasGeolocation] = useState<boolean>(false);
  const [currentPresenceCounterSum, setCurrentPresenceCounterSum] = useState<string | null>(null);

  const { loading: createPointLoading, request: createPointRequest } = useAxios();

  const { user } = useAuth();

  const now = new Date();
  const formattedNow = format(now, 'yyyy-MM-dd HH:mm:ss');

  const formattedDateTime = useMemo<string>(() => {
    return ` ${now.toLocaleDateString('pt-BR', {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    })} ${t('at')} ${now.toLocaleTimeString('pt-BR', {
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    })}`;
  }, [result, isOpenQrModal]);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setLatitude(position.coords.latitude);
        setLongitude(position.coords.longitude);
      });
    } else {
      console.error(t('geolocation_is_not_supported_by_your_browser'));
    }
  }, []);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
        },
        () => {},
      );
    }
  }, []);

  function savePresenceQrCode() {
    if (result) {
      createPointRequest(result, 'POST', {
        user_id: user!.id,
        organization_id: user!.organization!.uuid!,
        type,
        time: formattedNow,
        // lat: user?.location?.lat,
        // lng: user?.location?.lng,
        manual: false,
        lat: latitude?.toString() || '',
        lng: longitude?.toString() || '',
      } as PointProps)
        .then(() => {
          onCloseModal?.();
          toast.success(type === 'in' ? t('entry_successfully_registered') : t('exit_successfully_registered'));
        })
        .catch((error) => {
          console.error('Erro ao cadastrar ponto', error);
          toast.error(error.response.data.message);
        });
    }

    setResult(null);
  }

  function onClose() {
    setResult(null);
    setIsOpenQrModal(false);
  }

  function confirmPoint() {
    try {
      savePresenceQrCode();
      setIsOpenQrModal(false);
    } catch {
      return;
    }
  }
  const presenceHoursSum = useMemo(() => {
    const times: string[] = dayPresences.map((dayPresence: PointProps, index) => {
      const previousPresence = dayPresences[index - 1];

      if (dayPresence?.type == 'in' && previousPresence?.type == 'out') {
        var dif = moment.duration(moment(previousPresence?.time).diff(moment(dayPresence?.time)));

        return [dif.hours(), dif.minutes(), dif.seconds()].join(':');
      }
      return 'OO:OO:OO';
    });

    return addTimes(times);
  }, [dayPresences]);

  const lastPresence = useMemo(() => dayPresences?.[0], [dayPresences]);

  useEffect(() => {
    if (lastPresence && presenceHoursSum) {
      var dif = moment.duration(moment(new Date()).diff(moment(lastPresence?.time)));

      setCurrentPresenceCounterSum(addTimes([presenceHoursSum, [dif.hours(), dif.minutes(), dif.seconds()].join(':')]));
    }
    const interval = setInterval(() => {
      if (lastPresence) {
        setCurrentPresenceCounterSum((prevState) => addTimes([prevState ?? '00:00:00', '00:00:01']));
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [lastPresence, presenceHoursSum]);

  useEffect(() => {
    async function isCameraAndGeolocationEnabled() {
      try {
        const cameraStream = await navigator.mediaDevices.getUserMedia({ video: true });
        cameraStream.getTracks().forEach((track) => track.stop());
        setHasCamera(true);

        if ('geolocation' in navigator) {
          navigator.geolocation.getCurrentPosition(
            () => {
              setHasGeolocation(true);
            },
            () => {
              setHasGeolocation(false);
            },
          );
        } else {
          setHasGeolocation(false);
        }
      } catch (error) {
        setHasCamera(false);
        setHasGeolocation(false);
      }
    }

    isCameraAndGeolocationEnabled();
  }, []);

  const refreshPage = () => {
    window.location.reload();
  };

  return (
    <div
      className={`${
        !isOpenQrModal ? 'translate-x-[100%]' : 'translate-x-0'
      } fixed left-0 top-0 w-full z-40 h-full bg-gray-50 transition-all flex flex-col  justify-between py-2`}
    >
      <header className="flex p-6 md:px-16 md:py-12 items-center sticky top-0">
        <button onClick={onClose}>
          <XMarkIcon className="w-6" />
        </button>
      </header>

      <section className="max-w-[500px] mx-auto">
        {!hasCamera && !hasGeolocation && (
          <div className="flex flex-col gap-2 w-full auto h-full justify-center">
            <div className="mt-10 px-6 flex flex-col gap-4 w-full mx-auto">
              <div className="bg-red-50 rounded-full md:py-3 text-center flex flex-col gap-2 items-center">
                <ExclamationCircleIcon className="text-red-600 w-7" />
                <p className="text-sm text-red-600 font-normal">
                  {t('please_enable_the_camera_and_location_to_scan_the_QRCode')}.
                </p>
              </div>

              <Button onClick={refreshPage}> {t('refresh')}</Button>
            </div>
          </div>
        )}

        {hasCamera && hasGeolocation && isOpenQrModal && (
          <>
            <QrCodeReader
              showCamera={isOpenQrModal}
              onRead={(data) => setResult(data)}
              //hasCameraCallback={(hasCamera) => setCameraActive(hasCamera)}
            />
          </>
        )}

        <div className="flex flex-col gap-3 items-start w-full">
          <span className="text-2xl font-bold text-black">{moment().format('dddd, DD MMMM YYYY')}</span>
          <span className="text-lg font-normal text-black">
            {t('total_tracked')}: {presenceHoursSum}
          </span>
          {lastPresence?.type === 'in' && (
            <span className="text-lg font-normal text-black">{currentPresenceCounterSum}</span>
          )}
        </div>

        {result && (
          <div className="px-6 flex flex-col gap-4 mt-4">
            <span className="text-gray-700 text-lg font-medium">
              {t('registering')} {type === 'in' ? t('entry') : t('exit')}: <br></br>
              {formattedDateTime}
            </span>
            <Button onClick={confirmPoint}>{t('save')}</Button>
          </div>
        )}
      </section>

      <Button className="max-w-md mx-auto w-full mt-3" onClick={onClose} loading={createPointLoading}>
        {t('back')}
      </Button>
    </div>
  );
}
