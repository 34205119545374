import Input from '@components/Inputs';
import Loading from '@components/loading';
import {
  ArrowUturnLeftIcon,
  ArrowUturnRightIcon,
  CheckIcon,
  PencilIcon,
  PlusIcon,
  TrashIcon,
} from '@heroicons/react/24/solid';
import useAxios from '@hooks/axios/useAxios';
import { UserVacations, useUserVacations } from '@hooks/user/useUserVacations';
import { User } from '@sentry/react';
import { values } from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

export default function UserVacationsComponent() {
  const { t } = useTranslation();
  const [user, setUser] = useState<User | undefined>();
  const { id } = useParams();

  const { request: queryGetUserRequest } = useAxios();

  const { userVacations, getUserVacations, createUserVacations, editUserVacations, deleteUserVacations } =
    useUserVacations();
  const dateNow = new Date();
  const getYearNow = dateNow.getFullYear();
  const [currentYear, setCurrentYear] = useState(getYearNow);
  const [isAdding, setIsAdding] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingYear, setIsEditingYear] = useState(currentYear);
  const [editedDays, setEditedDays] = useState(1);
  const [loading, setLoading] = useState(false);

  const handleNextYear = () => {
    setCurrentYear((prevYear) => prevYear + 1);
    resetEditingMode();
  };

  const handlePreviousYear = () => {
    setCurrentYear((prevYear) => prevYear - 1);
    resetEditingMode();
  };

  useEffect(() => {
    if (user) {
      setLoading(true);
      getUserVacations(user?.organization?.uuid!, user?.id!).finally(() => {
        setLoading(false);
      });
    }

    resetEditingMode();
  }, [currentYear, user]);

  function resetEditingMode() {
    setIsAdding(false);
    setIsEditing(false);
    setIsEditingYear(currentYear);
    setEditedDays(1);
  }

  function getVacationDaysForYear(userVacations: UserVacations[], year: number) {
    const totalVacationDays =
      userVacations.find((vacation) => parseInt(vacation.vacation_year!) === year)?.vacation_days ?? 0;
    return totalVacationDays;
  }

  function getUUIDsForYear(userVacations: UserVacations[], year: number) {
    const uuids = userVacations
      .filter((vacation) => parseInt(vacation.vacation_year!) === year)
      .map((vacation) => vacation.uuid);
    return uuids;
  }

  const yearUuids = getUUIDsForYear(userVacations, currentYear);

  const handleAddButtonClick = () => {
    setIsAdding(true);
    setIsEditing(false);
    setEditedDays(1);
  };

  const handleEditButtonClick = () => {
    setIsAdding(false);
    setIsEditing(true);
    setIsEditingYear(currentYear);
    setEditedDays(getVacationDaysForYear(userVacations, currentYear));
  };

  const handleSaveButtonClick = () => {
    if (isAdding) {
      const newData = {
        user_id: user?.id!,
        vacation_days: editedDays,
        vacation_year: currentYear.toString(),
      };
      setLoading(true);
      createUserVacations(user?.organization?.uuid!, newData as any)
        .then(() => {
          toast.success('Férias adicionadas com sucesso!');
          setIsAdding(false);
          setIsEditing(false);
          getUserVacations(user?.organization?.uuid, user?.id!);
        })
        .catch(({ response }) => {
          values(response?.data?.errors)?.map((errors: any) => errors?.map((error: any) => toast.error(error)));
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (isEditing) {
      const editedVacation = userVacations.find((vacation) => vacation.vacation_year === currentYear.toString());
      if (editedVacation) {
        editedVacation.vacation_days = editedDays;
        editedVacation._method = 'PUT';
        setLoading(true);
        editUserVacations(user?.organization?.uuid!, editedVacation)
          .then(() => {
            toast.success('Férias atualizadas com sucesso!');
            setIsEditing(false);
            getUserVacations(user?.organization?.uuid!, user?.id!);
          })
          .catch(({ response }) => {
            values(response?.data?.errors)?.map((errors: any) => errors?.map((error: any) => toast.error(error)));
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }

    setIsAdding(false);
    setIsEditing(false);
  };

  function handleDeleteUserVacations(uuid: string) {
    deleteUserVacations(user?.organization?.uuid!, uuid)
      .then(() => {
        toast.success('Férias excluidas com sucesso!');
        getUserVacations(user?.organization?.uuid!, user?.id!);
      })
      .catch(({ response }) => {
        values(response?.data?.errors)?.map((errors: any) => errors?.map((error: any) => toast.error(error)));
      });
  }
  useEffect(() => {
    queryGetUserRequest(`/api/users/${id}`).then(({ data }) => {
      setUser(data.content.data);
    });
  }, []);

  return (
    <>
      <div className=" text-gray-600 bg-gray-100 mb-5 rounded-lg shadow-lg p-4 lg:max-w-5xl md:max-w-3xl max-w-lg w-full mx-auto">
        <p className="text-2xl font-bold">{t('user_vacations')}</p>
        <section className="flex flex-col gap-2 px-4 w-full mx-auto mt-8">
          <div className="flex gap-2 w-full justify-center text-primary">
            <div className="font-bold w-72 text-center border border-primary rounded-md">
              <div className="border-b border-primary py-2 flex items-center justify-center gap-3">
                <button onClick={handlePreviousYear}>
                  <ArrowUturnLeftIcon className="w-6" />
                </button>
                <span className="text-xl">{currentYear}</span>
                <button onClick={handleNextYear}>
                  <ArrowUturnRightIcon className="w-6" />
                </button>
              </div>
              <div className="flex justify-between p-2">
                {loading ? (
                  <Loading className="w-full mx-auto" />
                ) : (
                  <>
                    <div className="py-2">
                      {isAdding || (isEditing && currentYear === isEditingYear) ? (
                        <div className="flex gap-2 items-center">
                          <p className="text-xl">Férias:</p>
                          <Input
                            type="number"
                            value={editedDays}
                            min={1}
                            onChange={(e) => setEditedDays(parseInt(e.target.value))}
                          />
                        </div>
                      ) : (
                        <p className="text-xl">
                          {t('vacations')}: {getVacationDaysForYear(userVacations, currentYear)}
                        </p>
                      )}
                    </div>
                    <div className="flex gap-2">
                      {isAdding || (isEditing && currentYear === isEditingYear) ? ( // Renderizar o botão de salvar
                        <button className="hover:opacity-80" onClick={handleSaveButtonClick}>
                          <CheckIcon className="w-6" />
                        </button>
                      ) : getVacationDaysForYear(userVacations, currentYear) === 0 ? (
                        <button className="hover:opacity-80" onClick={handleAddButtonClick}>
                          <PlusIcon className="w-6" />
                        </button>
                      ) : (
                        <>
                          <button className="hover:opacity-80" onClick={handleEditButtonClick}>
                            <PencilIcon className="w-6" />
                          </button>
                          <button
                            className="hover:text-red-600"
                            onClick={() => handleDeleteUserVacations(yearUuids[0]!)}
                          >
                            <TrashIcon className="w-6" />
                          </button>
                        </>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
