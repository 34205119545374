import Loading from '@components/loading';
import { ButtonHTMLAttributes, DetailedHTMLProps } from 'react';

export interface StackedButtonProps
  extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  theme?: 'primary' | 'secondary' | 'error';
  loading?: boolean;
}

export default function StackedButton({ theme, className, loading, children, disabled, ...props }: StackedButtonProps) {
  let stackedButtonClassName = ' px-2.5 py-0.5 text-sm text-left rounded-xl text-gray-700 text-center ';

  switch (theme) {
    case 'primary':
      stackedButtonClassName += 'bg-primary text-white border border-primary';
      break;
    case 'secondary':
      stackedButtonClassName += 'bg-gray-50 border border-gray-300 text-primary';
      break;
    case 'error':
      stackedButtonClassName += 'bg-red-500 text-white border border-red-500';
      break;
    default:
      stackedButtonClassName += 'bg-white border border-gray-300 hover:bg-gray-50';
      break;
  }
  return (
    <button
      className={`${stackedButtonClassName} ${className} ${disabled ? 'opacity-60 cursor-not-allowed' : ''}`}
      disabled={disabled}
      {...props}
    >
      {(loading && <Loading />) || children}
    </button>
  );
}
