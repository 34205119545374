import StatusBadge from '@components/Badges/StatusBadge';
import { useAuth } from '@hooks/auth/useAuth';
import { useCallback, useEffect, useState } from 'react';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { useTranslation } from 'react-i18next';
import { api } from 'services/api';
import NoRequestsMessage from './NoRequestsMessage';
import { CalendarIcon } from '@heroicons/react/24/outline';
import Col from '@components/Flex/Col';

function RequestsVacationUserSection() {
  const [vacationRequests, setVacationRequests] = useState<any[]>([]);
  const { t } = useTranslation();

  const { user } = useAuth();

  const getVacations = useCallback(() => {
    api
      .get(
        `/api/organizations/${user?.organization?.uuid}/vacations/?user_id=${user?.id}&sortBy=created_at&sortDirection=desc`,
      )
      .then(({ data }) => {
        setVacationRequests(data?.content?.data?.data ?? []);
      })
      .catch((err) => console.error(err));
  }, [user]);

  useEffect(() => {
    getVacations();
  }, []);
  return (
    <>
      <section className="flex flex-col items-center justify-center w-full pb-10">
        <div className="mt-4 w-full">
          <div className="w-full overflow-x-auto rounded-lg">
            {/*Mobile */}
            <div className="flex flex-col gap-3 justify-center w-full mb-2 md:hidden">
              {vacationRequests.length == 0 && <NoRequestsMessage message={t('no_vacations_to_show_yet')} />}
              {vacationRequests?.map((vacation) => {
                const startTimeVacation = String(new Date(vacation?.time_in).toISOString().split('T')[0])
                  .replace('-', '/')
                  .replace('-', '/');

                const endTimeVacation = String(new Date(vacation?.time_out).toISOString().split('T')[0])
                  .replace('-', '/')
                  .replace('-', '/');

                return (
                  <section
                    key={vacation?.id}
                    className="flex flex-col gap-4 w-full border-b-2 border-solid border-gray-200 py-2"
                  >
                    <div className="flex justify-between items-center">
                      <span className="text-gray-700 text-sm font-medium">
                        {String(new Date(vacation?.created_at).toISOString().split('T')[0])
                          .replace('-', '/')
                          .replace('-', '/')}
                        {' - '}
                        {t('you_requested_a_vacation')}
                      </span>
                      <div className="flex flex-row gap-3 items-center">
                        <button
                          onClick={() => console.log('tst')}
                          className="text-blue-400 hover:text-blue-600 font-bold text-base"
                        >
                          {t('edit')}
                        </button>
                        <button
                          onClick={() => console.log('tst')}
                          className="text-red-400 hover:text-red-600 font-bold text-base"
                        >
                          {t('cancel')}
                        </button>
                      </div>
                    </div>
                    <div className="flex flex-row justify-between items-center">
                      <span key={vacation?.id} className="text-black text-base">
                        {startTimeVacation}{' '}
                        {startTimeVacation !== endTimeVacation && (
                          <>
                            -{'>'} {endTimeVacation}
                          </>
                        )}
                      </span>

                      <StatusBadge status={vacation?.status} />
                    </div>
                  </section>
                );
              })}
            </div>
            {/*Desktop */}
            <table className="table-auto w-full hidden md:table">
              <thead className="text-left border">
                <tr>
                  <th className="pl-4 py-2">{t('vacation_period')}</th>
                  <th className="pl-4 py-2">{t('request_state')}</th>
                  <th className="pl-4 py-2">{t('creation')}</th>
                  {/* <th className="pl-4 py-2">Ações</th>*/}
                </tr>
              </thead>
              <tbody>
                {vacationRequests.length == 0 && (
                  <tr className={`border`}>
                    <td className="px-4 py-2" colSpan={5}>
                      <Col className="w-full justify-center items-center gap-2 m-3">
                        <div className="bg-gray-300 rounded-lg p-3">
                          <CalendarIcon className="w-6 text-white" />
                        </div>
                        <p className="text-gray-400 text-sm text-center">{t('nothing_to_show')}.</p>
                      </Col>{' '}
                    </td>
                  </tr>
                )}
                {vacationRequests.map(({ uuid, time_in, time_out, created_at, status }, index) => {
                  const startTimeVacation = String(new Date(time_in).toISOString().split('T')[0])
                    .replace('-', '/')
                    .replace('-', '/');

                  const endTimeVacation = String(new Date(time_out).toISOString().split('T')[0])
                    .replace('-', '/')
                    .replace('-', '/');

                  return (
                    <tr key={index} className={`${index % 2 === 0 ? 'bg-gray-50' : ' bg-gray-100'} border`}>
                      <td className="px-4 py-2">
                        <span key={uuid} className="text-black text-base">
                          {startTimeVacation}{' '}
                          {startTimeVacation !== endTimeVacation && (
                            <>
                              -{'>'} {endTimeVacation}
                            </>
                          )}
                        </span>
                      </td>
                      <td className="px-4 py-2">
                        <StatusBadge status={status} />
                      </td>
                      <td className="px-4 py-2">
                        <span className="text-gray-700 text-base font-medium">
                          {t('created_at')}{' '}
                          {String(new Date(created_at).toISOString().split('T')[0]).replace('-', '/').replace('-', '/')}
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </>
  );
}

export default RequestsVacationUserSection;
