import { classNames } from '@components/Badges/StatusBadge';
import { HTMLAttributes } from 'react';

export interface RowProps extends HTMLAttributes<HTMLDivElement> {
  gap?: number | string;
}

export default function Row({ className, gap = 0, children, ...props }: RowProps) {
  return (
    <div {...props} className={classNames(className, `flex flex-row gap-${gap}`)}>
      {children}
    </div>
  );
}
