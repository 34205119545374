import { useCallback, useEffect, useRef, useState } from 'react';
import { useAuth } from '@hooks/auth/useAuth';
import { ptBR } from 'date-fns/locale';
import { format, parse } from 'date-fns';
import { InputNumber } from 'rsuite';
import EntityResume from '@components/DataDisplay/EntityResume';
import { keys, values } from 'lodash';
import Button from '@components/Common/Buttons/Button';
import { toast } from 'react-toastify';
import useAxios from '@hooks/axios/useAxios';
import SendMapDisplacementExpenses from '../Modals/SendMapDisplacementExpenses';
import { DisplacementType } from '@components/Modal/ModalInfoDisplacement';
import { useTranslation } from 'react-i18next';
import './style.css';
import { Expense } from '@hooks/expense/useExpense';
import { DayPickerSingleDateController } from 'react-dates';
import { getCurrentDimension } from '@components/Presence/Sections/AbsenceSection';
import moment from 'moment';
import { useAbsences } from '@hooks/presence/useAbsences';
import { useVacations } from '@hooks/presence/useVacations';
import { Displacement, useDisplacement } from '@hooks/displacement/useDisplacement';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useOrganization } from '@hooks/organization/useOrganization';
import useDebounce from '@hooks/useDebounce';
import { LIMIT_RECORDS } from 'App';
import { PresenceSummary } from '@components/Calendar/Summary/PresenceSummary';
import { AbsenceMark, DisplacementMark, HolidayMark, VacationMark } from '@components/Calendar/Summary';
import { Month, NextMonth, PrevMonth } from '@components/Calendar';
import { ArrowDownCircleIcon, CheckCircleIcon, CheckIcon, TrashIcon } from '@heroicons/react/24/solid';
import Loading from '@components/loading';
import Input from '@components/Inputs';
import Col from '@components/Flex/Col';
import { CurrencyEuroIcon } from '@heroicons/react/24/outline';

const displacementsTypesOptions = [
  { value: 'own_car', label: DisplacementType.own_car },
  { value: 'company_car', label: DisplacementType.company_car },
  { value: 'public_transport', label: DisplacementType.public_transport },
  { value: 'rental_car', label: DisplacementType.rental_car },
];

export const getDisplacementTypeLabel = (displacementType: string) => {
  const selectedTypeKey = keys(DisplacementType).findIndex((currentType) => {
    return displacementType == currentType;
  });
  const selectedTypeValues = values(DisplacementType)[selectedTypeKey];
  return selectedTypeValues;
};

function DisplacementsSection() {
  const { t } = useTranslation();
  const [showListDisplacements, setShowListDisplacements] = useState(true);
  const [isEditingDisplacement, setIsEditingDisplacement] = useState(false);
  const [displacementType, setDisplacementType] = useState('');
  const [isOpenDisplacementType, setIsOpenDisplacementType] = useState(false);
  const { getAllVacations } = useVacations();
  const { getAllAbsences } = useAbsences();
  const { getOrganizationHolidays } = useOrganization();
  const { getAllUserDisplacements } = useDisplacement();

  const [startDateSelected, setStartDateSelected] = useState<[Date, Date] | null>(null);
  const [endDateSelected, setEndDateSelected] = useState<[Date, Date] | null>(null);
  const [selectedDisplacement, setSelectedDisplacement] = useState<any>();

  const [displacementReason, setDisplacementReason] = useState<string>('');
  const [displacementOrigin, setDisplacementOrigin] = useState<string>('');
  const [displacementDestiny, setDisplacementDestiny] = useState<string>('');
  const [screenSize, setScreenSize] = useState(getCurrentDimension());

  const { user } = useAuth();
  const [expenses, setExpenses] = useState<Expense[]>([]);

  const [displacements, setDisplacements] = useState<any[]>([]);
  const [selectedDisplacementUuid, setSelectedDisplacementUuid] = useState<string>('');
  const displacementsArray = displacements;

  const reasonOwnCarRef = useRef<HTMLInputElement>(null);
  const reasonSubsistenceAllowanceRef = useRef<HTMLInputElement>(null);
  const locationRef = useRef<HTMLInputElement>(null);
  const hourStartRef = useRef<HTMLInputElement>(null);
  const hourEndRef = useRef<HTMLInputElement>(null);
  const territoryRef = useRef<HTMLInputElement>(null);
  const valueRef = useRef<HTMLInputElement>(null);
  const originRef = useRef<HTMLInputElement>(null);
  const destinationRef = useRef<HTMLInputElement>(null);
  const kmRef = useRef<HTMLInputElement>(null);
  const [expenseSearch, setExpenseSearch] = useState<string>('');
  const [kmOwnCar, setKmOwnCar] = useState(undefined);
  const debouncedExpenseSearch = useDebounce(expenseSearch, 399);

  const [isExpenseSelected, setIsExpenseSelected] = useState<string[]>([]);
  const typesExpenses =
    'diesel%2Cgasoline%2Cstays%2Cmiscellaneous_materials%2Cdisplacement_meals%2Cspecialized_services';

  const { loading: queryDisplacementsLoading, request: queryDisplacementsRequest } = useAxios();
  const { loading: queryDisplacementLoading, request: queryDisplacementRequest } = useAxios();
  const { loading: createDisplacementLoading, request: createDisplacementRequest } = useAxios();
  const { loading: editDisplacementLoading, request: editDisplacementRequest } = useAxios();
  const { request: deleteDisplacementRequest } = useAxios();
  const { loading: queryExpensesByFilterLoading, request: queryExpensesByFilter } = useAxios();

  const [displacementDateState, setDisplacementDateState] = useState<moment.Moment>(moment());

  const [focusedInput, setFocusedInput] = useState<boolean>(false);

  const { isLoading: queryVacationsLoading, data: localVacations } = useQuery<any[]>(['vacations', user], () =>
    getAllVacations(user!),
  );

  const { isLoading: queryAbsencesLoading, data: localAbsences } = useQuery<any[]>(['absences', user], () =>
    getAllAbsences(user!),
  );

  const { isLoading: queryHolidaysLoading, data: localHolidays } = useQuery<any[]>(['holidays', user], () =>
    getOrganizationHolidays(user?.organization?.uuid!),
  );

  const { isLoading: queryAllDisplacementsLoading, data: localDisplacements } = useQuery<Displacement[]>(
    ['displacements', user],
    () => getAllUserDisplacements(user!),
  );

  const queryClient = useQueryClient();

  const createDisplacementeMutation = useMutation({
    mutationFn: async (formData: any) =>
      createDisplacementRequest(`/api/organizations/${user?.organization?.uuid}/displacements`, 'POST', formData)
        .then(() => {
          toast.success(t('displacement_successfully_registered'));

          setStartDateSelected(null);
          setEndDateSelected(null);
          setShowListDisplacements(true);
          if (reasonOwnCarRef.current) reasonOwnCarRef.current.value = '';
          if (reasonSubsistenceAllowanceRef.current) reasonSubsistenceAllowanceRef.current.value = '';
          if (locationRef.current) locationRef.current.value = '';
          if (hourStartRef.current) hourStartRef.current.value = '';
          if (hourEndRef.current) hourEndRef.current.value = '';
          if (territoryRef.current) territoryRef.current.value = '';
          if (kmRef.current) kmRef.current.value = '';
          if (valueRef.current) valueRef.current.value = '';
          if (originRef.current) originRef.current.value = '';
          if (destinationRef.current) destinationRef.current.value = '';

          if (displacementDateState.toDate()) {
            getCurrentDisplacement(displacementDateState.toDate());
          }
        })
        .catch(({ response }) => {
          values(response?.data?.errors)?.map((errors: any) => errors?.map((error: any) => toast.error(error)));
        }),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: ['displacements'] });
    },
  });

  const editDisplacementMutation = useMutation({
    mutationFn: async (formData: any) =>
      editDisplacementRequest(
        `/api/organizations/${user?.organization?.uuid}/displacements/${selectedDisplacement?.uuid!}`,
        'POST',
        formData,
      )
        .then(() => {
          toast.success(t('displacement_successfully_updated'));

          getCurrentDisplacement(displacementDateState?.toDate()!);

          setIsEditingDisplacement(false);
          setShowListDisplacements(true);
        })
        .catch(({ response }) => {
          values(response?.data?.errors)?.map((errors: any) => errors?.map((error: any) => toast.error(error)));
        }),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: ['displacements'] });
    },
  });

  const deleteDisplacementMutation = useMutation({
    mutationFn: async ({ organizationUuid, displacementUuid }: any) =>
      deleteDisplacementRequest(`/api/organizations/${organizationUuid}/displacements/${displacementUuid}`, 'DELETE')
        .then(() => {
          toast.success(t('displacement_successfully_deleted'));
          setDisplacements((prevDisplacements) =>
            prevDisplacements.filter((displacement) => displacement.uuid !== displacementUuid),
          );
        })
        .catch(({ response }) => {
          values(response?.data?.errors)?.map((errors: any) => errors?.map((error: any) => toast.error(error)));
        }),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: ['displacements'] });
    },
  });

  const getCurrentDisplacement = async (date: Date) => {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    queryDisplacementsRequest(
      `/api/organizations/${user?.organization
        ?.uuid!}/displacements/date/${year}/${month}/${day}?take=${LIMIT_RECORDS}&user_id=${user?.id!}&sortBy=created_at&sortDirection=desc`,
      'GET',
    )
      .then(({ data }) => {
        const displacements = data?.content?.data?.data ?? [];
        setDisplacements(displacements);
      })
      .catch(({ response }) => {
        values(response?.data?.errors)?.map((errors: any) => errors?.map((error: any) => toast.error(error)));
      });
  };

  const getExpensesFilter = () => {
    queryExpensesByFilter(
      `/api/organizations/${user?.organization?.uuid}/expenses?take=${LIMIT_RECORDS}&search=${debouncedExpenseSearch}&expense_type=${typesExpenses}`,
      'GET',
    ).then(({ data }) => {
      const expenses = data?.content?.data?.data ?? [];
      setExpenses(expenses);
    });
  };

  useEffect(() => {
    getExpensesFilter();
  }, [selectedDisplacementUuid, debouncedExpenseSearch]);

  const handleExpenseClick = (id: string) => {
    if (isExpenseSelected.includes(id)) {
      setIsExpenseSelected(isExpenseSelected.filter((selected) => selected !== id));
    } else {
      setIsExpenseSelected([...isExpenseSelected, id]);
    }
  };

  useEffect(() => {
    const params: any = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop: any) => searchParams.get(prop as string),
    });

    if (params?.displacementEditUuid!) {
      handleEditDisplacementClick(params?.DisplacementsEditUuid!);
    } else {
      getCurrentDisplacement(displacementDateState?.toDate());
    }
  }, [displacementDateState]);

  function handleEditDisplacementClick(DisplacementsUuid: string) {
    setIsEditingDisplacement(true);
    setShowListDisplacements(false);
    refreshSelectedDisplacement(DisplacementsUuid);
    setSelectedDisplacementUuid(DisplacementsUuid);
  }

  function handleDisplacementTypeSelect(selectedRole: string) {
    const selectedValue = displacementsTypesOptions.find((option) => option.label === selectedRole)?.value ?? '';
    setDisplacementType(selectedValue);
    setIsOpenDisplacementType(false);
  }

  function mapValueToLabel(value: string) {
    const option = t(value);
    return option.length > 0 ? option : t('select_a_displacement_type');
  }

  async function handleSave() {
    const startDate = startDateSelected ? startDateSelected[0] : null;
    const endDate = endDateSelected ? endDateSelected[0] : null;
    const subsistenceAllowanceStart = startDate
      ? `${startDate?.toISOString?.()?.split('T')[0]} ${hourStartRef.current?.value}`
      : undefined;
    const subsistenceAllowanceEnd = endDate
      ? `${endDate?.toISOString?.()?.split('T')[0]} ${hourEndRef.current?.value}`
      : undefined;

    const expensesSelectedArray = isExpenseSelected.map((id) => id);

    const formData = new FormData();
    formData.append('organization_id', user?.organization?.uuid!);
    formData.append('user_id', String(user?.id!));
    formData.append('date', displacementDateState?.toDate?.().toISOString().split('T')[0]!);
    formData.append('origin', displacementOrigin);
    formData.append('destiny', displacementDestiny);
    formData.append('reason', displacementReason);
    formData.append('displacement_type', displacementType);
    formData.append('kilometers_traveled', kmOwnCar!);
    formData.append('subsistence_allowance_start', subsistenceAllowanceStart!);
    formData.append('subsistence_allowance_end', subsistenceAllowanceEnd!);
    formData.append('territory', territoryRef.current?.value!);
    formData.append('location', locationRef.current?.value!);
    formData.append('description', reasonSubsistenceAllowanceRef.current?.value!);

    for (let i = 0; i < expensesSelectedArray.length; i++) {
      formData.append('expenses[]', expensesSelectedArray[i]);
    }

    createDisplacementeMutation.mutate(formData);
  }

  const handleSaveEditing = () => {
    const expensesSelectedArray = isExpenseSelected.map((id) => id);
    const form = new FormData();

    form.append('user_id', String(user?.id!));
    form.append('displacement_type', displacementType);
    form.append('reason', displacementReason);
    form.append('date', displacementDateState?.toDate()?.toISOString().split('T')[0]!);
    form.append('origin', displacementOrigin);
    form.append('destiny', displacementDestiny);
    form.append('kilometers_traveled', kmOwnCar!);
    for (let i = 0; i < expensesSelectedArray.length; i++) {
      form.append('expenses[]', expensesSelectedArray[i]);
    }
    form.append('_method', 'PUT');

    editDisplacementMutation.mutate(form);
  };

  const handleDeleteDisplacement = (organizationUuid: string, displacementUuid: string) => {
    deleteDisplacementMutation.mutate({ organizationUuid, displacementUuid });
  };

  if ('eur_per_km' in displacements) {
    delete displacements.eur_per_km;
  }

  const handleInputChangeKm = (value: any) => {
    setKmOwnCar(value);
  };

  const refreshSelectedDisplacement = useCallback(
    (displacementUuid: string) => {
      queryDisplacementRequest(`/api/organizations/${user?.organization?.uuid}/displacements/${displacementUuid}`)
        .then(({ data }) => {
          const displacementObject = data?.content?.data;
          console.log('Displacement Object', displacementObject);
          setSelectedDisplacement(displacementObject);
          setDisplacementType(displacementObject?.displacement_type!);
          setDisplacementReason(displacementObject.reason!);
          setDisplacementOrigin(displacementObject.origin!);
          setDisplacementDestiny(displacementObject.destiny!);
          setKmOwnCar(displacementObject.kilometers_traveled!);

          const displacementExpenses = displacementObject.expenses;

          queryExpensesByFilter(
            `/api/organizations/${user?.organization?.uuid}/expenses?expense_type=${typesExpenses}`,
            'GET',
          ).then(({ data }) => {
            const availableExpenses = data?.content?.data?.data ?? [];

            const selectedExpenseIds = displacementExpenses.map((expense: any) => expense.id);

            setExpenses(availableExpenses);

            setIsExpenseSelected(selectedExpenseIds);
          });
        })
        .catch(({ response }) => {
          values(response?.data?.errors)?.map((errors: any) => errors?.map((error: any) => toast.error(error)));
        });
    },
    [user, expenses],
  );

  const totalDisplacementValue = displacementsArray.reduce((total, displacement) => {
    let multiplier = 0;

    if (displacement?.displacement_type == 'own_car') {
      multiplier = Number(import.meta.env.VITE_OWN_CAR_MULTIPLIER);
    }

    if (displacement?.displacement_type == 'public_transport') {
      multiplier = Number(import.meta.env.VITE_PUBLIC_TRANSPORT_MULTIPLIER);
    }

    if (displacement?.displacement_type == 'rental_car') {
      Number(import.meta.env.VITE_RENTAL_CAR_MULTIPLIER);
    }

    return (
      total + parseFloat(String(displacement?.kilometers_traveled ? displacement?.kilometers_traveled : 0)) * multiplier
    );
  }, 0);

  const totalDisplacementKilometersTraveled = displacementsArray.reduce((total, displacement) => {
    return total + parseFloat(String(displacement?.kilometers_traveled ? displacement?.kilometers_traveled : 0));
  }, 0);

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener('resize', updateDimension);

    return () => {
      window.removeEventListener('resize', updateDimension);
    };
  }, [screenSize]);

  return (
    <>
      <SendMapDisplacementExpenses />
      <main className="flex flex-col justify-center items-center pt-4 px-4">
        {(queryVacationsLoading || queryAbsencesLoading || queryHolidaysLoading || queryAllDisplacementsLoading) && (
          <Loading className="w-full mx-auto" />
        )}

        {!queryVacationsLoading && !queryAbsencesLoading && !queryHolidaysLoading && !queryAllDisplacementsLoading && (
          <DayPickerSingleDateController
            daySize={48}
            navNext={<NextMonth />}
            navPrev={<PrevMonth />}
            renderMonthElement={Month}
            initialVisibleMonth={() => moment()}
            noBorder
            verticalBorderSpacing={5}
            date={displacementDateState}
            onFocusChange={({ focused }) => {
              setFocusedInput(focused);
            }}
            focused={focusedInput}
            renderCalendarInfo={() => (
              <PresenceSummary>
                <span className="text-base text-gray-950 flex flex-row items-center gap-2">
                  <DisplacementMark /> {t('displacement')}
                </span>
              </PresenceSummary>
            )}
            onDateChange={(date: any) => {
              const isDateSelectedOnDisabledRange = localAbsences?.some((absence: any) => {
                const absenceStart = parse(absence.time_in, 'yyyy-MM-dd HH:mm:ss', new Date());
                const absenceEnd = parse(absence.time_out, 'yyyy-MM-dd HH:mm:ss', new Date());

                return date.isSame(absenceStart) || date.isSame(absenceEnd);
              });

              const isDateSelectedOnVacationRange = localVacations?.some((vacation: any) => {
                const vacationStart = parse(vacation.time_in, 'yyyy-MM-dd HH:mm:ss', new Date());
                const vacationEnd = parse(vacation.time_out, 'yyyy-MM-dd HH:mm:ss', new Date());

                return date.isSame(vacationStart) || date.isSame(vacationEnd);
              });

              const isDateSelectedOnHolidayRange = localHolidays?.some((holiday: any) => {
                const selectedHoliday = parse(holiday.date, 'yyyy-MM-dd HH:mm:ss', new Date());

                return date.isSame(selectedHoliday);
              });

              if (!isDateSelectedOnDisabledRange && !isDateSelectedOnHolidayRange && !isDateSelectedOnVacationRange) {
                setDisplacementDateState(date);
                return;
              }
            }}
            renderDayContents={(day) => {
              const dateAbsenceSelected = localAbsences?.some((absence: any) => {
                const absenceStart = parse(absence.time_in, 'yyyy-MM-dd HH:mm:ss', new Date());
                const absenceEnd = parse(absence.time_out, 'yyyy-MM-dd HH:mm:ss', new Date());

                return day.isBetween(absenceStart, absenceEnd);
              });

              const dateVacationSelected = localVacations?.some((vacation: any) => {
                const absenceStart = parse(vacation.time_in, 'yyyy-MM-dd HH:mm:ss', new Date());
                const absenceEnd = parse(vacation.time_out, 'yyyy-MM-dd HH:mm:ss', new Date());
                return day.isBetween(absenceStart, absenceEnd);
              });

              const dateHolidaySelected = localHolidays?.some((holiday: any) => {
                const selectedHoliday = parse(holiday.date, 'yyyy-MM-dd HH:mm:ss', new Date());

                return day.isSame(moment(selectedHoliday, 'MM/D/YYYY'), 'date');
              });

              const dateDisplacementSelected =
                localDisplacements?.filter((displacement: any) => {
                  const selectedDisplacement = parse(displacement.date, 'yyyy-MM-dd HH:mm:ss', new Date());

                  return day.isSame(moment(selectedDisplacement, 'MM/D/YYYY'), 'date');
                }) ?? [];

              if (
                dateAbsenceSelected ||
                dateVacationSelected ||
                dateHolidaySelected ||
                dateDisplacementSelected?.length > 0
              ) {
                return (
                  <div className="flex flex-col items-center justify-center gap-2">
                    {day.format('D')}
                    <div className="flex flex-row gap-1 items-center">
                      {dateAbsenceSelected && <AbsenceMark />}
                      {dateVacationSelected && <VacationMark />}
                      {dateHolidaySelected && <HolidayMark />}
                      {dateDisplacementSelected?.length > 0 && (
                        <DisplacementMark>{dateDisplacementSelected?.length}</DisplacementMark>
                      )}
                    </div>
                  </div>
                );
              }
              return day.format('D');
            }}
          />
        )}

        <div className="grid grid-cols-2 gap-4 w-full mb-8 mx-auto px-4">
          <EntityResume title={t('total_value')} value={String(totalDisplacementValue?.toFixed(2)) + ' €'} />
          <EntityResume
            title={t('kilometers_traveled')}
            value={String(totalDisplacementKilometersTraveled?.toFixed(2)) + ' km'}
          />
        </div>

        <section className="flex flex-col gap-4 w-full mt-4 rounded-md px-2">
          {showListDisplacements ? (
            <>
              {queryDisplacementsLoading && <Loading className="w-full mx-auto" />}

              {!queryDisplacementsLoading && !queryDisplacementLoading && displacementsArray.length === 0 && (
                <Col className="w-full justify-center items-center gap-2">
                  <div className="bg-gray-300 rounded-lg p-3">
                    <CurrencyEuroIcon className="w-6 text-white" />
                  </div>
                  <p className="text-gray-400 text-sm text-center">{t('no_records_for_the_selected_date')}.</p>
                </Col>
              )}
              {!queryDisplacementsLoading &&
                displacementsArray.map((displacement, index) => (
                  <div
                    key={`${displacement.id}-${index}`}
                    className="border-b-2 border-solid border-gray-200 py-2 w-full"
                  >
                    <div className="flex justify-between items-center">
                      <span className="text-gray-700 text-sm font-medium">
                        {displacement?.user?.name}
                        {' · '}
                        {t(displacement?.displacement_type)}
                      </span>
                      <div className="flex gap-2 items-center">
                        <button
                          onClick={() => handleEditDisplacementClick(displacement?.uuid!)}
                          className="text-primary font-bold text-base hover:text-green-700"
                        >
                          {t('edit')}
                        </button>
                        <button
                          onClick={() => handleDeleteDisplacement(user?.organization?.uuid!, displacement.uuid!)}
                          className="text-red-600 hover:text-red-700"
                        >
                          <TrashIcon className="w-5" />
                        </button>
                      </div>
                    </div>
                    <div className="w-full mt-2 flex justify-between items-center">
                      <div className="flex gap-2 items-center">
                        <CheckCircleIcon className="text-primary w-8" />
                        <p className="text-lg text-gray-900 font-normal overflow-ellipsis whitespace-normal">
                          {displacement?.origin} -{'>'} {displacement?.destiny}
                        </p>
                      </div>
                      <span className="text-gray-400 text-base font-normal">
                        <>{displacement?.kilometers_traveled} Km</>
                      </span>
                    </div>
                  </div>
                ))}
            </>
          ) : (
            <>
              {queryDisplacementLoading && <Loading className="w-full mx-auto" />}
              {!queryDisplacementLoading && isEditingDisplacement ? (
                <div className="flex flex-col w-full gap-3">
                  <label htmlFor="" className="text-gray-700 text-sm font-medium">
                    {t('type_of_displacement')}
                  </label>
                  <div className="relative">
                    <div
                      className="border w-full p-2 rounded-lg focus:border-primary cursor-pointer bg-white select-none flex justify-between items-center"
                      onClick={() => setIsOpenDisplacementType(!isOpenDisplacementType)}
                    >
                      {mapValueToLabel(displacementType)}
                      <ArrowDownCircleIcon
                        className={`${isOpenDisplacementType ? 'rotate-180' : ''} transition-all h-6`}
                      />
                    </div>
                    {isOpenDisplacementType && (
                      <div className="absolute mt-2 p-2 bg-gray-50 border border-primary/90 rounded-lg shadow-md w-full z-10">
                        {displacementsTypesOptions.map((option) => (
                          <div
                            key={option.value}
                            onClick={() => handleDisplacementTypeSelect(option.label)}
                            className="cursor-pointer hover:bg-gray-200 p-1 rounded"
                          >
                            {t(option.value)}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  <div className="mt-4">
                    <div className="my-1">
                      <label htmlFor="" className="text-gray-700 text-sm font-medium">
                        {t('day_selected')}
                      </label>
                      <p className="text-gray-900 text-base font-normal">
                        {displacementDateState
                          ? format(displacementDateState?.toDate(), "dd 'de' MMMM 'de' yyyy", { locale: ptBR })
                          : ''}
                      </p>
                    </div>
                    <div className="my-1">
                      <label htmlFor="" className="text-gray-700 text-sm font-medium">
                        {t('reason')}
                      </label>
                      <Input
                        placeholder={t('description_of_displacement')}
                        type="text"
                        name="reasonOwnCar"
                        id="reasonOwnCar"
                        value={displacementReason}
                        onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => {
                          setDisplacementReason(target?.value);
                        }}
                        ref={reasonOwnCarRef}
                      />
                    </div>
                    <div className="my-1">
                      <label htmlFor="" className="text-gray-700 text-sm font-medium">
                        {t('local_origin')}
                      </label>
                      <Input
                        placeholder={t('local_origin')}
                        type="text"
                        name="origin"
                        id="origin"
                        ref={originRef}
                        value={displacementOrigin}
                        onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => {
                          setDisplacementOrigin(target?.value);
                        }}
                      />
                    </div>
                    <div className="my-1">
                      <label htmlFor="" className="text-gray-700 text-sm font-medium">
                        {t('local_destination')}
                      </label>
                      <Input
                        placeholder={t('local_destination')}
                        type="text"
                        name="destination"
                        id="destination"
                        ref={destinationRef}
                        value={displacementDestiny}
                        onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => {
                          setDisplacementDestiny(target?.value);
                        }}
                      />
                    </div>
                    <div className="my-1">
                      <label htmlFor="" className="text-gray-700 text-sm font-medium">
                        N° Kms
                      </label>
                      <InputNumber
                        min={0}
                        step={0.1}
                        postfix="Km"
                        size="lg"
                        name="km"
                        id="km"
                        ref={kmRef}
                        onChange={handleInputChangeKm}
                        value={kmOwnCar}
                      />
                    </div>
                    <div className="my-1 w-full">
                      <label htmlFor="" className="text-gray-700 text-sm font-medium">
                        {t('select_the_related_expenses')}
                      </label>
                      <Input
                        placeholder={t('search')}
                        type="text"
                        name="expenseSearch"
                        id="expenseSearch"
                        value={expenseSearch}
                        onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => {
                          setExpenseSearch(target?.value);
                        }}
                      />
                      <div className="max-h-[300px] overflow-y-auto">
                        {queryExpensesByFilterLoading ? (
                          <Loading className="w-full mx-auto" />
                        ) : (
                          <>
                            {expenses.length === 0 ? (
                              <span className="text-gray-400 text-base font-normal">{t('no_related_expenses')}</span>
                            ) : (
                              <>
                                {expenses.map((expense) => (
                                  <>
                                    <div key={expense.uuid} className="flex items-center w-full gap-1 mt-2">
                                      <div
                                        onClick={() => handleExpenseClick(expense.id!)}
                                        className={`border-2 border-solid hover:border-primary p-2 w-full rounded-md cursor-pointer select-none relative ${
                                          isExpenseSelected.includes(expense.id!) ? 'border-primary' : 'border-gray-200'
                                        }`}
                                      >
                                        {isExpenseSelected.includes(expense.id!) && (
                                          <div className="absolute -top-[0.05rem] -right-[0.05rem] h-6 w-6 rounded-check bg-primary flex justify-end">
                                            <CheckIcon className="text-white w-4" />
                                          </div>
                                        )}
                                        <div className="flex justify-between items-center">
                                          <span className="text-gray-700 text-sm font-medium">
                                            {expense.date} | {expense.supplier}
                                          </span>
                                        </div>
                                        <div className="w-full mt-2 flex justify-between items-center">
                                          <div className="flex gap-2 items-center">
                                            <p className="text-lg text-gray-900 font-normal truncate w-[14rem] md:w-[40rem]">
                                              {expense.comment}
                                            </p>
                                          </div>
                                          <span className="text-gray-400 text-base font-normal">
                                            <>{expense.total}€</>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                ))}
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div>
                    <div>
                      <label htmlFor="" className="text-gray-700 text-sm font-medium">
                        {t('type_of_displacement')}*
                      </label>
                      <div className="relative">
                        <div
                          className="border w-full p-2 rounded-lg focus:border-primary cursor-pointer bg-white select-none flex justify-between items-center"
                          onClick={() => setIsOpenDisplacementType(!isOpenDisplacementType)}
                        >
                          {mapValueToLabel(displacementType)}
                          <ArrowDownCircleIcon
                            className={`${isOpenDisplacementType ? 'rotate-180' : ''} transition-all w-5`}
                          />
                        </div>
                        {isOpenDisplacementType && (
                          <div className="absolute mt-2 p-2 bg-gray-50 border border-primary/90 rounded-lg shadow-md w-full z-10">
                            {displacementsTypesOptions.map((option) => (
                              <div
                                key={option.value}
                                onClick={() => handleDisplacementTypeSelect(option.label)}
                                className="cursor-pointer hover:bg-gray-200 p-1 rounded"
                              >
                                {t(option.value)}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="mt-4">
                      {
                        <>
                          {
                            <>
                              <div className="my-1">
                                <label htmlFor="" className="text-gray-700 text-sm font-medium">
                                  {t('day_selected')}
                                </label>
                                <p className="text-gray-900 text-base font-normal">
                                  {displacementDateState
                                    ? format(displacementDateState?.toDate(), "dd 'de' MMMM 'de' yyyy", {
                                        locale: ptBR,
                                      })
                                    : ''}
                                </p>
                              </div>
                              <div className="my-1">
                                <label htmlFor="" className="text-gray-700 text-sm font-medium">
                                  {t('reason')}*
                                </label>
                                <Input
                                  placeholder={t('description_of_displacement')}
                                  type="text"
                                  name="reasonOwnCar"
                                  id="reasonOwnCar"
                                  ref={reasonOwnCarRef}
                                  value={displacementReason}
                                  onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => {
                                    setDisplacementReason(target?.value);
                                  }}
                                />
                              </div>
                              <div className="my-1">
                                <label htmlFor="" className="text-gray-700 text-sm font-medium">
                                  {t('local_origin')}*
                                </label>
                                <Input
                                  placeholder={t('local_origin')}
                                  type="text"
                                  name="origin"
                                  id="origin"
                                  ref={originRef}
                                  value={displacementOrigin}
                                  onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => {
                                    setDisplacementOrigin(target?.value);
                                  }}
                                />
                              </div>
                              <div className="my-1">
                                <label htmlFor="" className="text-gray-700 text-sm font-medium">
                                  {t('local_destination')}*
                                </label>
                                <Input
                                  placeholder={t('local_destination')}
                                  type="text"
                                  name="destination"
                                  id="destination"
                                  ref={destinationRef}
                                  value={displacementDestiny}
                                  onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => {
                                    setDisplacementDestiny(target?.value);
                                  }}
                                />
                              </div>
                              <div className="my-1">
                                <label htmlFor="" className="text-gray-700 text-sm font-medium">
                                  N° Kms*
                                </label>
                                <InputNumber
                                  min={0}
                                  step={0.1}
                                  postfix="Km"
                                  size="lg"
                                  name="km"
                                  id="km"
                                  ref={kmRef}
                                  onChange={handleInputChangeKm}
                                />
                              </div>

                              <div className="my-1 w-full">
                                <label htmlFor="" className="text-gray-700 text-sm font-medium">
                                  {t('select_the_related_expenses')}
                                </label>
                                <Input
                                  placeholder={t('search')}
                                  type="text"
                                  name="expenseSearch"
                                  id="expenseSearch"
                                  value={expenseSearch}
                                  onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => {
                                    setExpenseSearch(target?.value);
                                  }}
                                />
                                <div className="max-h-[300px] overflow-y-auto">
                                  {queryExpensesByFilterLoading ? (
                                    <Loading className="w-full mx-auto" />
                                  ) : (
                                    <>
                                      {expenses.length === 0 ? (
                                        <span className="text-gray-400 text-base font-normal">
                                          {t('no_related_expenses')}
                                        </span>
                                      ) : (
                                        <>
                                          {expenses.map((expense) => (
                                            <>
                                              <div key={expense.uuid} className="flex items-center w-full gap-1 mt-2">
                                                <div
                                                  onClick={() => handleExpenseClick(expense.id!)}
                                                  className={`border-2 border-solid hover:border-primary p-2 w-full rounded-md cursor-pointer select-none relative ${
                                                    isExpenseSelected.includes(expense.id!)
                                                      ? 'border-primary'
                                                      : 'border-gray-200'
                                                  }`}
                                                >
                                                  {isExpenseSelected.includes(expense.id!) && (
                                                    <div className="absolute -top-[0.05rem] -right-[0.05rem] h-6 w-6 rounded-check bg-primary flex justify-end">
                                                      <CheckIcon className="text-white w-4" />
                                                    </div>
                                                  )}
                                                  <div className="flex justify-between items-center">
                                                    <span className="text-gray-700 text-sm font-medium">
                                                      {expense.date} | {expense.supplier}
                                                    </span>
                                                  </div>
                                                  <div className="w-full mt-2 flex justify-between items-center">
                                                    <div className="flex gap-2 items-center">
                                                      <p className="text-lg text-gray-900 font-normal truncate w-[14rem] md:w-[40rem]">
                                                        {expense.comment}
                                                      </p>
                                                    </div>
                                                    <span className="text-gray-400 text-base font-normal">
                                                      <>{expense.total}€</>
                                                    </span>
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          ))}
                                        </>
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>
                            </>
                          }
                        </>
                      }
                    </div>
                  </div>
                </>
              )}
            </>
          )}

          {showListDisplacements ? (
            <Button
              buttonType={Button.Type.PRIMARY}
              className=" w-full py-[13px] text-base font-normal sticky bottom-5"
              onClick={() => {
                setShowListDisplacements(false);
                getExpensesFilter();
                setIsExpenseSelected([]);
              }}
              loading={createDisplacementLoading}
            >
              {t('add_displacement')}
            </Button>
          ) : isEditingDisplacement ? (
            <div className="grid grid-cols-1 w-full gap-4 mt-4 mx-auto">
              <div className="w-full md:mt-4 flex flex-row gap-3 justify-between items-center">
                <Button
                  onClick={() => {
                    setIsEditingDisplacement(false);
                    setShowListDisplacements(true);
                    setExpenseSearch('');
                  }}
                  buttonType={Button.Type.SECONDARY}
                  disabled={editDisplacementLoading}
                >
                  {t('cancel')}
                </Button>
                <Button onClick={handleSaveEditing} loading={editDisplacementLoading}>
                  {t('save_displacement')}
                </Button>
              </div>
            </div>
          ) : (
            <div className="grid grid-cols-1 w-full gap-4 mx-auto">
              <div className="flex flex-row items-center w-full md:mt-4 justify-between gap-3">
                <Button
                  onClick={() => {
                    setShowListDisplacements(true);
                    setIsExpenseSelected([]);
                    setExpenseSearch('');
                  }}
                  buttonType={Button.Type.SECONDARY}
                >
                  {t('cancel')}
                </Button>
                <Button onClick={handleSave} loading={createDisplacementLoading}>
                  {t('create_displacement')}
                </Button>
              </div>
            </div>
          )}
        </section>
      </main>
    </>
  );
}

export default DisplacementsSection;
