import { useAbsences } from '../../../../hooks/presence/useAbsences';
import DragAndDrop from './parts/DragAndDrop';
import { XMarkIcon } from '@heroicons/react/24/solid';

export default function AttachmentFilePresenceModal() {
  const { isOpenFileModal, setIsOpenFileModal } = useAbsences();

  return (
    <>
      <div
        className={`${
          !isOpenFileModal ? 'translate-x-[100%]' : 'translate-x-0'
        } fixed left-0 top-0 w-full bg-gray-50 flex flex-col justify-center items-center h-screen z-40 transition-all`}
      >
        <header className="fixed top-6 w-full flex p-6 md:py-0 items-center justify-between">
          <button onClick={() => setIsOpenFileModal(false)}>
            <XMarkIcon className="w-6" />
          </button>
        </header>
        <DragAndDrop />
      </div>
    </>
  );
}
