import jsQR from 'jsqr';
import { useDropzone } from 'react-dropzone';
import { useExpenses } from '../../../hooks/expense/useExpense';
import { useSpending } from '../../../hooks/expense/useShopping';
import { ArrowDownTrayIcon, XMarkIcon } from '@heroicons/react/24/solid';
import Input from '@components/Inputs';

export default function AttachmentFileExpenseModal() {
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': [],
    },
    onDrop: (acceptedFiles: any) => {
      const newFiles = acceptedFiles.map((file: any) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          realFile: file,
        }),
      );
      console.log(newFiles);

      setFiles(newFiles);
    },
  });
  const { isOpenFileModal, setIsOpenFileModal } = useExpenses();

  const { files, removeFile, setFiles } = useSpending();

  const checkForQRCode = async (file: any) => {
    const img: any = new Image();

    // Cria uma Promise que será resolvida quando a imagem for carregada
    const imgLoadPromise = new Promise((resolve, reject) => {
      img.onload = resolve;
      img.onerror = reject;
    });

    // Lê o arquivo como um URL de dados (data URL)
    const reader = new FileReader();
    reader.readAsDataURL(file);

    // Aguarda a leitura do arquivo
    await new Promise((resolve) => {
      reader.onloadend = resolve;
    });

    // Define a imagem como a URL de dados lida
    img.src = reader.result;

    // Aguarda o carregamento completo da imagem
    await imgLoadPromise;

    // Converte a imagem em um canvas
    const canvas: any = document.createElement('canvas');
    const context = canvas.getContext('2d');
    canvas.width = img.width;
    canvas.height = img.height;
    context.drawImage(img, 0, 0);

    // Obtém os dados de imagem do canvas
    const imageData = context.getImageData(0, 0, canvas.width, canvas.height);

    // Executa a detecção do QR Code usando a biblioteca jsQR
    const code = jsQR(imageData.data, imageData.width, imageData.height);

    // Verifica se um QR Code foi encontrado
    if (code) {
      console.log('QR Code encontrado:', code.data);
    } else {
      // removeFile(file.preview);
    }
    setIsOpenFileModal(false);
  };

  const handleFileUpload = () => {
    files.forEach((file) => {
      checkForQRCode(file);
    });
  };

  return (
    <>
      <div
        className={`fixed inset-0 bg-[#414241] bg-opacity-30 backdrop-blur z-40 ${
          isOpenFileModal ? 'block' : 'hidden'
        }`}
      >
        <div
          className={`mx-auto w-[85%] md:max-w-screen-md bg-gray-50 py-10 mt-20 rounded-lg flex flex-col justify-center items-center transition-all`}
        >
          <header className="fixed top-6 w-full flex p-6 md:py-0 items-center justify-end">
            <button
              onClick={() => {
                setIsOpenFileModal(false);
                setFiles([]);
              }}
            >
              <XMarkIcon className="w-6" />
            </button>
          </header>
          <section className="px-8 w-full max-w-full md:max-w-[600px] mx-auto">
            <div {...getRootProps({ className: 'dropzone' })}>
              <Input {...getInputProps()} />
              <div className="cursor-pointer shadow-md flex flex-col px-8 py-8 rounded-md border-2 border-dashed border-primary w-full  items-center">
                <ArrowDownTrayIcon className="w-16" />
                <span className="text-center hidden lg:block">
                  Arraste e solte os arquivos aqui ou clique para fazer upload
                </span>
                <span className="text-center block lg:hidden">Clique para fazer upload</span>
              </div>
            </div>
            {files.length > 0 && (
              <div className="flex mt-8 w-full overflow-x-auto items-center">
                {files.map((file) => (
                  <div key={file.name} className="flex-shrink-0">
                    <div className="relative w-[100px] h-[100px]">
                      <img
                        className="max-w-full h-auto rounded object-cover"
                        src={file.preview}
                        // Revoke data uri after image is loaded
                        onLoad={() => {
                          URL.revokeObjectURL(file.preview);
                        }}
                      />
                      <button
                        onClick={() => removeFile(file.preview)}
                        className="absolute right-1 top-1 text-red-500 shadow-md"
                      >
                        <XMarkIcon className="w-5" />
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            )}
            <div className="mt-6 w-full">
              <button
                onClick={handleFileUpload}
                className=" w-full py-4 border-2 border-primary text-primary font-bold rounded-md text-center hover:text-white hover:bg-primary transition-all"
              >
                Verificar QrCode's
              </button>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}
