import { classNames } from '@components/Badges/StatusBadge';
import HeaderHome from '@components/Home/HeaderHome';
import { addTimes } from '@components/Presence/Modals/QRCodePresenceModal';
import { PlayCircleIcon, StopCircleIcon } from '@heroicons/react/24/solid';
import { useAuth } from '@hooks/auth/useAuth';
import { PointProps, usePoint } from '@hooks/presence/usePoint';
import { usePresences } from '@hooks/presence/usePresences';
import { format } from 'date-fns';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, matchPath, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

interface TimerProps {
  time: string;
  onTogglePlay: () => void;
  playing?: boolean;
  loading?: boolean;
}
const Timer = ({ time, onTogglePlay, playing, loading }: TimerProps) => {
  return (
    <div className="flex flex-row items-center gap-2 rounded-3xl bg-[#EFF6FF] pl-3">
      <span className="text-base font-bold text-blue-900">{time}</span>
      <button
        className={classNames('p-1 rounded-full bg-blue-900', loading ? 'opacity-60 cursor-not-allowed' : '')}
        onClick={!loading ? onTogglePlay : undefined}
      >
        {playing ? (
          <StopCircleIcon className="w-9 text-gray-50 bg-blue-900 rounded-full" />
        ) : (
          <PlayCircleIcon className="w-9 text-gray-50 bg-blue-900 rounded-full" />
        )}
      </button>
    </div>
  );
};

export default function FastdocLayout() {
  const { globalPresences, refreshGlobalPresences } = usePresences();
  const [currentPresenceCounterSum, setCurrentPresenceCounterSum] = useState<string | null>(null);
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const { createPointQrCode } = usePoint();

  const presenceHoursSum = useMemo(() => {
    const times: string[] = globalPresences?.map?.((dayPresence: PointProps, index) => {
      const previousPresence = globalPresences[index - 1];

      if (dayPresence?.type == 'in' && previousPresence?.type == 'out') {
        var dif = moment.duration(moment(previousPresence?.time).diff(moment(dayPresence?.time)));

        return [dif.hours(), dif.minutes(), dif.seconds()].join(':');
      }
      return 'OO:OO:OO';
    });

    return addTimes(times);
  }, [globalPresences]);
  const lastPresence = useMemo(() => globalPresences?.[0], [globalPresences]);

  const formattedNow = format(moment().toDate(), 'yyyy-MM-dd HH:mm:ss');

  const dayType = useMemo<'out' | 'in'>(() => {
    //@ts-ignore
    const dayNow = globalPresences[0] ?? undefined;
    //@ts-ignore
    return dayNow?.type === 'in' ? 'out' : 'in';
  }, [globalPresences]);

  function savePresenceQrCode() {
    setIsLoading(true);
    const point = {
      user_id: user!.id,
      organization_id: user!.organization!.uuid!,
      type: dayType,
      time: formattedNow,
    } as PointProps;
    createPointQrCode(
      `api/organizations/${user?.organization?.uuid}/location/${user?.location?.uuid}/presence-qrcodes`,
      point,
    )
      .then(() => {
        toast.success(dayType === 'in' ? t('entry_successfully_registered') : t('exit_successfully_registered'));
        const date = new Date();
        const day = date?.getDate();
        const month = date?.getMonth()! + 1;
        const year = date?.getFullYear();

        if (user) refreshGlobalPresences(user?.organization?.uuid!, user?.uuid!, day, month, year);
      })
      .catch((error) => {
        console.error('Erro ao cadastrar ponto', error);
        toast.error(error.response.data.message);
      })
      .finally(() => setIsLoading(false));
  }
  let location = useLocation();

  const matchDashboard = useMemo(
    () =>
      !!matchPath(window.location.pathname, `/dashboard`) ||
      !!matchPath(window.location.pathname, `/organizations/${user?.organization?.uuid}/dashboard`),
    [location, user?.organization?.uuid],
  );

  useEffect(() => {
    if (lastPresence && presenceHoursSum) {
      var dif = moment.duration(moment(new Date()).diff(moment(lastPresence?.time)));

      setCurrentPresenceCounterSum(addTimes([presenceHoursSum, [dif.hours(), dif.minutes(), dif.seconds()].join(':')]));
    }
    const interval = setInterval(() => {
      if (lastPresence) {
        setCurrentPresenceCounterSum((prevState) => addTimes([prevState ?? '00:00:00', '00:00:01']));
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [lastPresence, presenceHoursSum]);

  return (
    <main className="flex flex-col w-full h-screen pt-20 bg-gray-50 mb-16">
      <HeaderHome />
      {matchDashboard && (user?.work_type == 'hybrid' || user?.work_type == 'remote') && (
        <div className="flex md:hidden fixed bottom-0 p-4 w-full justify-end items-center z-50">
          <Timer
            playing={dayType === 'out' && !isLoading}
            onTogglePlay={savePresenceQrCode}
            loading={isLoading}
            time={(lastPresence?.type === 'in' && currentPresenceCounterSum) || presenceHoursSum}
          />
        </div>
      )}
      <Outlet />
    </main>
  );
}
