import { RouterProvider } from 'react-router-dom';
import { routes } from './routes/app.routes';
import { useEffect } from 'react';
import { useAuth } from '@hooks/auth/useAuth';
import { api } from 'services/api';
import { AxiosInstanceProvider } from '@hooks/axios/AxiosInstanceProvider';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { QueryClient, QueryClientProvider } from 'react-query';

export const LIMIT_RECORDS = 15000;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
    },
  },
});

function App() {
  const { isAuthenticated, user } = useAuth();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (isAuthenticated && user) {
      api.defaults.headers.common['Authorization'] = `Bearer ${user.token}`;
    }
  }, [user, isAuthenticated]);

  useEffect(() => {
    i18n.changeLanguage((localStorage.getItem('@fastodc:language') as string) || 'en');
  }, [localStorage]);

  useEffect(() => {
    moment.updateLocale('pt_PT', {
      months: [
        t('january'),
        t('february'),
        t('march'),
        t('april'),
        t('may'),
        t('june'),
        t('july'),
        t('august'),
        t('september'),
        t('october'),
        t('november'),
        t('december'),
      ],
      weekdaysShort: [t('sun'), t('mon'), t('tue'), t('wed'), t('thu'), t('fri'), t('sat')],
      weekdaysMin: [t('sun'), t('mon'), t('tue'), t('wed'), t('thu'), t('fri'), t('sat')],
    });

    moment.locale(localStorage.getItem('@fastodc:language') == 'pt' ? 'pt_PT' : 'en');
  }, []);

  moment.locale(localStorage.getItem('@fastodc:language') == 'pt' ? 'pt_PT' : 'en');

  return (
    <QueryClientProvider client={queryClient}>
      <AxiosInstanceProvider config={{ baseURL: import.meta.env.VITE_API_URL }}>
        <RouterProvider router={routes} />
      </AxiosInstanceProvider>
    </QueryClientProvider>
  );
}

export default App;
