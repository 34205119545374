import { useEffect, useMemo, useRef, useState } from 'react';
import { useExpenses } from '../../../hooks/expense/useExpense';
import Button from '../../Common/Buttons/Button';
import { Toggle } from 'rsuite';
import { useAuth } from '@hooks/auth/useAuth';
import QrCodeReader from '@components/QrCode/QrCodeReader';
import { TypesExpenses, enumTypes } from '../Sections/ExpensesSection';
import useAxios from '@hooks/axios/useAxios';
import { toast } from 'react-toastify';
import { values } from 'lodash';
import { classNames } from '@components/Badges/StatusBadge';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useDropzone } from 'react-dropzone';
import { ArrowDownIcon, DocumentIcon, PhotoIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { QrCodeIcon } from '@heroicons/react/24/outline';
import Input from '@components/Inputs';
import Textarea from '@components/Inputs/Textarea';

export const extractInfoFromQRCode = (qrCodeData: string) => {
  const qrCodeParts = qrCodeData.split('*');
  const info: { [key: string]: string } = {};

  for (const part of qrCodeParts) {
    const [key, value] = part.split(':');
    info[key] = value;
  }

  return info;
};

export default function QrCodeExpenseModal({
  expenseDate,
  onCloseModal,
}: {
  expenseDate?: string;
  onCloseModal: () => void;
}) {
  const { t } = useTranslation();
  const { isOpenQrModal, setIsOpenQrModal, files, setFiles } = useExpenses();

  const { user } = useAuth();
  const [result, setResult] = useState<any>(null);
  const [showResult, setShowResult] = useState(false);

  const [supplierNif, setSuppliertNif] = useState('');
  const [totalValue, setTotalValue] = useState('');
  const commentRef = useRef<HTMLTextAreaElement>(null);
  const supplierRef = useRef<HTMLInputElement>(null);
  const [selectedTypesExpenses, setSelectedTypesExpenses] = useState<string | null>(null);
  const [isOpenDisplacementType, setIsOpenDisplacementType] = useState(false);

  const optionsTypesExpenses = useMemo(() => enumTypes(TypesExpenses), []);

  function handleDisplacementTypeSelect(selectedRole: string) {
    const selectedValue = optionsTypesExpenses.find((option) => option.label === selectedRole)?.value ?? '';
    setSelectedTypesExpenses(selectedValue);
    setIsOpenDisplacementType(false);
  }
  function onClose() {
    setIsOpenQrModal(false);
    setResult(null);
    setShowResult(false);
    setSuppliertNif('');
    setTotalValue('');
    setSelectedTypesExpenses(null);
    commentRef.current ? (commentRef.current.value = '') : null;
    supplierRef.current ? (supplierRef.current.value = '') : null;
  }

  const handleResult = (result: any) => {
    if (result) {
      setResult(result);
      const qrCodeInfo = extractInfoFromQRCode(result);
      const supplierNif = qrCodeInfo['A'];
      const totalValue = qrCodeInfo['O'];
      if (supplierNif) {
        supplierRef.current!.value = supplierNif;
      }
      setSuppliertNif(supplierNif);
      setTotalValue(totalValue);
      setShowResult(true);
    }
  };

  const { loading: createExpenseLoading, request: createExpenseRequest } = useAxios();

  const queryClient = useQueryClient();

  const createExpenseMutation = useMutation({
    mutationFn: async (newData: any) =>
      createExpenseRequest(`/api/organizations/${user?.organization?.uuid}/expenses`, 'POST', newData)
        .then(() => {
          onClose();
          onCloseModal?.();
          toast.success(t('expense_created_successfully'));
        })
        .catch(({ response }) => {
          values(response?.data?.errors)?.map((errors: any) => errors?.map((error: any) => toast.error(error)));
        }),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: ['expenses'] });
    },
  });

  function handleSave() {
    const form = new FormData();
    form.append('user_id', String(user?.id!));
    form.append('expense_type', selectedTypesExpenses!);
    form.append('comment', commentRef.current?.value!);
    form.append('total', totalValue);
    form.append('date', String(expenseDate)!);
    form.append('supplier', supplierRef.current?.value!);
    form.append('2d_barcode', result);
    form.append('company_money', String(Number(companyMoney)));

    const file = files[0];

    if (file) {
      form.append('file', file);
    }

    createExpenseMutation.mutate(form);
  }

  function mapValueToLabel(value: string) {
    const option = optionsTypesExpenses.find((option) => option.value === value);
    return option ? option.label : t('select_an_expense_type');
  }

  const [companyMoney, setCompanyMoney] = useState<boolean>(false);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': [],
      'application/pdf': [],
    },
    onDrop: (acceptedFiles: any) => {
      const newFiles = acceptedFiles.map((file: any) => {
        const newFile = Object.assign(file, {
          preview: URL.createObjectURL(file),
          realFile: file,
        });
        return newFile;
      });
      setFiles(newFiles);
    },
  });

  useEffect(() => {
    setFiles([]);
  }, [isOpenQrModal]);

  return (
    <div
      className={`${
        !isOpenQrModal ? 'translate-x-[100%]' : 'translate-x-0'
      } fixed left-0 top-0 w-full z-40 h-full bg-gray-50 transition-all flex flex-col  justify-between py-2`}
    >
      <header className="flex p-6 md:px-16 md:py-12 items-center sticky top-0">
        <button onClick={onClose}>
          <XMarkIcon className="w-6" />
        </button>
      </header>

      <section className="max-w-[500px] mx-auto pb-5 overflow-y-scroll">
        {isOpenQrModal && <QrCodeReader showCamera={isOpenQrModal} onRead={handleResult} />}
        <>
          <div className={classNames('px-4  flex-col mt-4 w-full overflow-y-scroll', showResult ? 'flex' : 'hidden')}>
            <div className="my-1">
              <label htmlFor="" className="text-gray-700 text-base font-medium">
                NIF {t('supplier')}
              </label>
              <p className="text-gray-700 text-lg">{supplierNif}</p>
            </div>
            <div className="my-1">
              <label htmlFor="" className="text-gray-700 text-base font-medium">
                {t('base_value')}
              </label>
              <p className="text-gray-700 text-lg">{totalValue} €</p>
            </div>
            <span className="flex flex-row gap-3 items-center w-full">
              <Toggle className=" my-2" checked={companyMoney} onChange={setCompanyMoney} />
              {companyMoney ? t('company_money') : t('own_money')}
            </span>

            <div className="my-2">
              <span className="text-gray-700 text-sm font-medium">{t('type_of_spending')}</span>

              <div className="relative">
                <div
                  className="border w-full p-2 rounded-lg focus:border-primary cursor-pointer bg-white select-none flex justify-between items-center"
                  onClick={() => setIsOpenDisplacementType(!isOpenDisplacementType)}
                >
                  {mapValueToLabel(selectedTypesExpenses!)}
                  <ArrowDownIcon className={`${isOpenDisplacementType ? 'rotate-180' : ''} transition-all w-5`} />
                </div>
                {isOpenDisplacementType && (
                  <div className="absolute mt-2 p-2 bg-gray-50 border border-primary/90 rounded-lg shadow-md w-full z-10">
                    {optionsTypesExpenses.map((option) => (
                      <div
                        key={option.value}
                        onClick={() => handleDisplacementTypeSelect(option.label)}
                        className="cursor-pointer hover:bg-gray-200 p-1 rounded"
                      >
                        {option.label}
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className="my-2">
                <span className="text-gray-700 text-sm font-medium">{t('description')}</span>
                <div className="grid grid-cols-1 w-full">
                  <Textarea
                    ref={commentRef}
                    name="comment"
                    id="comment"
                    placeholder={t('write_a_description_of_the_expense')}
                    // className={`resize-none bg-gray-50 border ${validateFormData ? "border-red-500" : "border-border-primary/90"
                    //     } w-full rounded-lg max-h-36 shadow-md p-2`}
                    className={`resize-none bg-gray-50 border border-primary/90 w-full rounded-lg max-h-36 shadow-md p-2`}
                  />
                </div>
              </div>
              <div className="my-2">
                <span className="text-gray-700 text-sm font-medium">{t('supplier')}</span>
                <div className="grid grid-cols-1 w-full">
                  <Input
                    ref={supplierRef}
                    type="text"
                    name="suplier"
                    id="suplier"
                    placeholder={t('write_down_the_name_of_the_supplier')}
                    // className={`resize-none bg-gray-50 border ${validateFormData ? "border-red-500" : "border-primary/90"
                    //     } w-full rounded-lg max-h-36 shadow-md p-2`}
                  />
                </div>
              </div>

              <div className="w-full my-2">
                <span className="text-gray-700 text-sm font-medium">{t('file')}</span>
                <div {...getRootProps({ className: 'dropzone' })}>
                  <Input {...getInputProps()} />
                  <div className="border-2 border-dashed border-primary/90 flex flex-col justify-center items-center p-4 mt-2 cursor-pointer">
                    <PhotoIcon className="text-primary/90 w-12" />
                    <p className="font-medium text-sm text-gray-600 mt-1">
                      <span className="text-primary">{t('upload')} </span>
                      {t('or_drag_and_drop_a_document')}
                    </p>
                    <p className="text-xs font-normal text-primary/90">{t('file_upload_limit')}</p>
                  </div>
                </div>
                {files.length > 0 && (
                  <div className="flex flex-col mt-8 gap-2 w-full border p-4 overflow-x-auto items-center justify-center">
                    {files.map((file: any) => (
                      <div key={file?.name}>
                        <div className="relative w-[100px] h-[100px]">
                          {file?.type === 'application/pdf' ? (
                            <DocumentIcon className="w-21" />
                          ) : (
                            <img
                              className="w-[100px] rounded h-[100px] object-cover"
                              src={file?.preview}
                              // Revogar o data URI após o carregamento da imagem
                              onLoad={() => {
                                URL.revokeObjectURL(file?.preview);
                              }}
                            />
                          )}
                          <button
                            onClick={() => {
                              setFiles([]);
                            }}
                            className="absolute right-1 top-1 text-red-500 shadow-md"
                          >
                            <XMarkIcon className="w-5" />
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <Button onClick={handleSave} loading={createExpenseLoading}>
              {t('save')}
            </Button>
          </div>
        </>
        {!showResult && (
          <>
            <div className="bg-primary/20 rounded-full md:py-3 py-2 px-7 md:px-11 text-center flex flex-col gap-2 items-center mt-5 w-max mx-auto">
              <QrCodeIcon className="text-primary w-7" />
              <p className="text-sm text-primary font-normal">{t('read_the_QRCode_to_get_the_information')}</p>
            </div>
          </>
        )}
      </section>
      <Button className="max-w-md mx-auto w-full mt-3" onClick={onClose} loading={createExpenseLoading}>
        {t('back')}
      </Button>
    </div>
  );
}
