import TabLink from '@components/Navbar/TabLink';
import { useAuth } from '@hooks/auth/useAuth';
import { useLayout } from '@hooks/layout/useLayout';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

function NavbarRequests() {
  const { t } = useTranslation();

  const { user } = useAuth();

  const { setPageTitle } = useLayout();

  useEffect(() => {
    setPageTitle(t('requests'));

    return () => {
      setPageTitle(null);
    };
  }, []);

  return (
    <div className="w-full flex rounded-lg shadow-lg bg-gray-50">
      <TabLink to={`/organizations/${user?.organization?.uuid}/dashboard/requests/absences`}>{t('absences')}</TabLink>
      <TabLink to={`/organizations/${user?.organization?.uuid}/dashboard/requests/vacations`}>{t('vacations')}</TabLink>
      <TabLink to={`/organizations/${user?.organization?.uuid}/dashboard/requests/presences`}>{t('presences')}</TabLink>
    </div>
  );
}

export default NavbarRequests;
