import React, { DetailedHTMLProps, InputHTMLAttributes } from 'react';

export interface InputProps extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {}

const Input = React.forwardRef<HTMLInputElement, InputProps>(({ className, children, ...props }, ref) => {
  return (
    <input
      className={`border px-3 py-2 border-gray-300 !bg-white w-full rounded-md h-[42px] focus:ring-0 focus:border-gray-300 ${className}`}
      {...props}
      ref={ref}
    />
  );
});

export default Input;
