import { useState } from 'react';
import closeIcon from '../../assets/icons/close.svg';
import { useTranslation } from 'react-i18next';
import Textarea from '@components/Inputs/Textarea';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  content: React.ReactNode;
  onConfirm(): void;
}

export function ModalDenyAbsence({ isOpen, onClose, title, content, onConfirm }: ModalProps) {
  const [denialReason, setDenialReason] = useState('');
  const { t } = useTranslation();

  const closeModal = () => {
    onClose();
  };

  const handleModalClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      closeModal();
    }
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className="fixed inset-0 bg-[#414241] bg-opacity-30 backdrop-blur z-30 cursor-pointer">
      <div
        className="modal-container fixed top-[15%]  left-1/2 transform -translate-x-1/2 translate-y-[15%] z-10 w-96 cursor-default overflow-y-auto"
        onClick={handleModalClick}
      >
        <div className="w-full max-w-[1060px] rounded-xl bg-gray-50 text-black p-4">
          <div className="flex justify-between gap">
            <p className="text-xl font-bold">{title}</p>
            <button onClick={closeModal}>
              <img src={closeIcon} alt="Close" className="cursor-pointer w-4 h-4" />
            </button>
          </div>
          <p className="my-2">{content}</p>
          <div className="mt-2">
            <label className="block text-sm font-medium text-gray-700">{t('deny_reason')}:</label>
            <Textarea
              rows={4}
              value={denialReason}
              onChange={(e) => setDenialReason(e.target.value)}
              onClick={(e) => e.stopPropagation()}
            />
          </div>
          <div className="mt-5 flex gap-2">
            <button
              onClick={onConfirm}
              className="bg-primary text-sm cursor-pointer hover:brightness-[.95] text-white font-medium w-max px-3 py-2 rounded-md transition-all"
            >
              {t('deny')}
            </button>
            <button
              onClick={closeModal}
              className="bg-gray-50 text-sm cursor-pointer hover:brightness-[.95] text-gray-600 border border-gray-600 font-medium w-max px-3 py-2 rounded-md transition-all"
            >
              {t('cancel')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
