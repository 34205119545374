import { Month, NextMonth, PrevMonth } from '@components/Calendar';
import Button from '@components/Common/Buttons/Button';
import { getCurrentDimension } from '@components/Presence/Sections/AbsenceSection';
import { ArrowDownTrayIcon } from '@heroicons/react/24/solid';
import { useAuth } from '@hooks/auth/useAuth';
import { format } from 'date-fns';
import moment from 'moment';
import { useEffect, useState } from 'react';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DayPickerRangeController, FocusedInputShape } from 'react-dates';
import { toast } from 'react-toastify';
import { api } from 'services/api';

export const downloadReport = (url: string, filename: string) => {
  api({
    url,
    method: 'GET',
    //responseType: 'blob',
  })
    .then(async () => {
      await api({
        url,
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        const href = window.URL.createObjectURL(response.data);

        const anchorElement = document.createElement('a');

        anchorElement.href = href;
        anchorElement.download = filename;

        document.body.appendChild(anchorElement);
        anchorElement.click();

        document.body.removeChild(anchorElement);
        window.URL.revokeObjectURL(href);
      });
    })
    .catch(({ response }) => {
      toast.error(response?.data?.message);
    });
};

export default function VacancyReportSection() {
  const { user } = useAuth();

  const [expenseDateState, setExpenseDateState] = useState<{
    startDate: moment.Moment;
    endDate: moment.Moment;
  }>({
    startDate: moment(),
    endDate: moment(),
  });

  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const [focusedInput, setFocusedInput] = useState('startDate');

  const handleDownloadReportClick = () => {
    const requestStartDate = format(expenseDateState?.startDate?.toDate(), 'yyyy-MM-dd');
    const requestEndDate = format(expenseDateState?.endDate?.toDate(), 'yyyy-MM-dd');

    downloadReport(
      `/api/organizations/${user?.organization?.uuid}/vacations-pdf${
        requestStartDate !== requestEndDate ? `?from=${requestStartDate}&to=${requestEndDate}` : ''
      } `,
      `ferias-${requestStartDate}-a-${requestEndDate}.pdf`,
    );
  };

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener('resize', updateDimension);

    return () => {
      window.removeEventListener('resize', updateDimension);
    };
  }, [screenSize]);

  return (
    <section className="flex flex-col items-center justify-center w-full gap-4">
      <DayPickerRangeController
        daySize={48}
        navNext={<NextMonth />}
        navPrev={<PrevMonth />}
        renderMonthElement={Month}
        initialVisibleMonth={() => moment()}
        noBorder
        verticalBorderSpacing={5}
        startDate={expenseDateState.startDate}
        endDate={expenseDateState.endDate}
        onDatesChange={({ startDate, endDate }) => {
          setExpenseDateState({
            startDate: startDate as moment.Moment,
            endDate: (endDate ?? startDate) as moment.Moment,
          });
          return;
        }}
        onFocusChange={(focusedInput) => {
          setFocusedInput(focusedInput as string);
        }}
        focusedInput={(focusedInput || 'startDate') as FocusedInputShape}
      />

      <Button className="max-w-xs" onClick={handleDownloadReportClick}>
        <span className="flex flex-row gap-3 items-center text-white w-full justify-center">
          <ArrowDownTrayIcon className="text-white w-6" /> Download
        </span>
      </Button>
    </section>
  );
}
