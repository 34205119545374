import { keys, values } from 'lodash';
import { Modal } from 'rsuite';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import './style.css';
import { useTranslation } from 'react-i18next';

interface ModalContentProps {
  open: boolean;
  onClose: () => void;
  onDelete: (uuid: string) => void;
  title: string;
  origin: React.ReactNode;
  destiny: React.ReactNode;
  subsistence_allowance_start?: string;
  subsistence_allowance_end?: string;
  date?: string;
  type?: string;
  kilometres_traveled: string;
  territory?: string;
  onConfirm(): void;
  total: number;
  uuid: string;
}

export enum DisplacementType {
  own_car = 'Transporte em viatura própria',
  public_transport = 'Transportes públicos',
  company_car = 'Viatura da empresa',
  rental_car = 'Transporte em automóvel de aluguer',
}

export const getDisplacementLabel = (expenseType: string) => {
  const selectedTypeKey = keys(DisplacementType).findIndex((currentType) => {
    return expenseType == currentType;
  });
  const selectedTypeValues = values(DisplacementType)[selectedTypeKey];
  return selectedTypeValues;
};

export default function ModalInfoDisplacement({
  open,
  onClose,
  title,
  origin,
  destiny,
  onConfirm,
  date,
  type,
  kilometres_traveled,
  onDelete,
  uuid,
}: ModalContentProps) {
  const { t } = useTranslation();

  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Header>
        <Modal.Title>
          <p className="text-2xl font-bold">{title}</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="bg-gray-200 rounded-xl py-3 px-4 mt-2 flex flex-col gap-2">
          <div className="grid grid-cols-2 gap-4">
            <div className="flex flex-col">
              <h3 className="text-gray-700 text-base font-bold">{t('date')}</h3>
              <p className="text-gray-700 text-sm font-medium">{date}</p>
            </div>
            <div className="flex flex-col">
              <h3 className="text-gray-700 text-base font-bold">{t('type')}</h3>
              <p className="text-gray-700 text-sm font-medium">{getDisplacementLabel(type!)}</p>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div className="flex flex-col">
              <h3 className="text-gray-700 text-base font-bold">{t('origin')}</h3>
              <p className="text-gray-700 text-sm font-medium">{origin}</p>
            </div>
            <div className="flex flex-col">
              <h3 className="text-gray-700 text-base font-bold">{t('destiny')}</h3>
              <p className="text-gray-700 text-sm font-medium">{destiny}</p>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div className="flex flex-col">
              <h3 className="text-gray-700 text-base font-bold">{t('km_traveled')}</h3>
              <p className="text-gray-700 text-sm font-medium">{kilometres_traveled} Km</p>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="flex gap-2 justify-end mt-2">
          <button
            onClick={onConfirm}
            className="bg-primary text-sm cursor-pointer hover:brightness-[.95] text-white font-medium w-max px-3 py-2 rounded-md transition-all"
          >
            {t('edit')}
          </button>
          <button
            onClick={() => onDelete(uuid)}
            className="bg-gray-50 text-sm cursor-pointer hover:brightness-[.95] text-gray-600 border border-gray-600 font-medium w-max px-3 py-2 rounded-md transition-all modal-button"
          >
            {t('delete')} {t('expense')}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
