import { create } from 'zustand';
import { api } from '../../services/api';
import { toast } from 'react-toastify';
import { LIMIT_RECORDS } from 'App';

interface WorkingTimesProps {
  end_time: string;
  start_time: string;
  organization_id: number;
  uuid: string;
}

export interface Organization {
  id?: number;
  uuid?: string;
  name: string;
  email: string;
  phone: string;
  image?: string;
  language: string;
  nif?: string;
  working_times?: WorkingTimesProps[];
  organization_type?: string;
  enable_distance_verification?: boolean;
}

interface OrganizationProps {
  getOrganizations: (params?: string) => Promise<void>;
  getOrganizationHolidays: (uuid: string) => Promise<any>;
  getOrganizationById: (id: string) => Promise<{ organization: Organization }>;
  createOrganization: (organization: Organization, file: File | null) => Promise<void>;
  editOrganization: (organization: Organization, file: File | null) => Promise<void>;
  deleteOrganization: (id: string) => Promise<void>;
  organizations: Organization[];
}

export const useOrganization = create<OrganizationProps>((set) => ({
  organizations: [],
  getOrganizations: async (params?: string) => {
    try {
      const { data } = await api.get(`/api/organizations${params ? '?' + params : ''}`);
      set({ organizations: data.content.data.data });
    } catch (error) {
      console.error(error);
    }
  },
  getOrganizationHolidays: (uuid: string) => {
    return api
      .get(`/api/organizations/${uuid}/holidays?take=${LIMIT_RECORDS}`)
      .then(({ data }) => data?.content?.data?.data);
  },
  getOrganizationById: async (id: string) => {
    try {
      const { data } = await api.get(`/api/organizations/${id}`);

      const organization: Organization = data.content.data;
      return {
        organization,
      };
    } catch (error: any) {
      // const errors = await error.response.data.errors;

      //const message = Object.keys(errors)[0];

      console.error(error);
      throw new Error('Erro ao obter organização por ID');
    }
  },
  createOrganization: async (organization: Organization, file: File | null) => {
    const form = new FormData();
    form.append('name', organization.name);
    form.append('email', organization.email);
    form.append('phone', organization.phone);
    form.append('nif', organization.nif!);
    form.append('language', organization.language);
    form.append('organization_type', organization.organization_type!);
    if (file !== null) {
      form.append('image', file);
    }

    try {
      await api.post(`/api/organizations`, form);
      toast.success('Organização criada com sucesso!');
      window.location.href = `/dashboard`;
    } catch (error: any) {
      if (error.response.data.errors.image && error.response.data.errors.image[0]) {
        toast.error(error.response.data.errors.image[0]);
      }
      if (error.response.data.errors.name && error.response.data.errors.name[0]) {
        toast.error(error.response.data.errors.name[0]);
      }
      if (error.response.data.errors.language && error.response.data.errors.language[0]) {
        toast.error(error.response.data.errors.language[0]);
      }
      if (error.response.data.errors.email && error.response.data.errors.email[0]) {
        toast.error(error.response.data.errors.email[0]);
      }
      if (error.response.data.errors.phone && error.response.data.errors.phone[0]) {
        toast.error(error.response.data.errors.phone[0]);
      }
      if (error.response.data.errors.nif && error.response.data.errors.nif[0]) {
        toast.error(error.response.data.errors.nif[0]);
      }

      console.error(error);
    }
  },
  editOrganization: async (organization: Organization, file: File | null) => {
    const form = new FormData();
    form.append('name', organization.name);
    form.append('email', organization.email);
    form.append('phone', organization.phone);
    form.append('nif', organization.nif!);
    form.append('language', organization.language);
    form.append('organization_type', organization.organization_type!);
    form.append('enable_distance_verification', String(Number(organization?.enable_distance_verification!)));

    form.append('_method', 'PUT');
    if (file !== null) {
      form.append('image', file);
    }

    try {
      await api.post(`/api/organizations/${organization.uuid}`, form);
      toast.success('Organização editada com sucesso!');
      //window.location.href = `/dashboard/organization/${organization.uuid}`;
    } catch (error: any) {
      if (error.response.data.errors.image && error.response.data.errors.image[0]) {
        toast.error(error.response.data.errors.image[0]);
      }
      if (error.response.data.errors.name && error.response.data.errors.name[0]) {
        toast.error(error.response.data.errors.name[0]);
      }
      if (error.response.data.errors.language && error.response.data.errors.language[0]) {
        toast.error(error.response.data.errors.language[0]);
      }
      if (error.response.data.errors.email && error.response.data.errors.email[0]) {
        toast.error(error.response.data.errors.email[0]);
      }
      if (error.response.data.errors.phone && error.response.data.errors.phone[0]) {
        toast.error(error.response.data.errors.phone[0]);
      }
      if (error.response.data.errors.nif && error.response.data.errors.nif[0]) {
        toast.error(error.response.data.errors.nif[0]);
      }
      console.error(error);
    }
  },
  deleteOrganization: async (uuid: string) => {
    if (!uuid) {
      return;
    }
    try {
      await api.delete(`/api/organizations/${uuid}`);
      toast.success('Organização deletada com sucesso!');
    } catch (error: any) {
      toast.error('Erro ao deletar Organização');
      // const errors = await error.response.data.errors;

      //const message = Object.keys(errors)[0];

      console.error(error);
    }
  },
}));
