import ButtonFloat from '@components/Common/Buttons/ButtonFloat';
import { HTMLAttributes, useCallback, useEffect, useRef, useState } from 'react';
import QrScanner from 'qr-scanner';
import { useTranslation } from 'react-i18next';
import { BoltIcon, BoltSlashIcon, MapPinIcon } from '@heroicons/react/24/solid';
import { CameraIcon } from '@heroicons/react/24/outline';
import Button from '@components/Common/Buttons/Button';
import Modal from '@components/Modals/Modal';
import allowCamera from '../../assets/images/allow-camera.png';
export interface QrCodeReaderProps extends HTMLAttributes<HTMLDivElement> {
  onRead?: (data: string) => void;
  hasCameraCallback?: (hasCamera: boolean) => void;
  showCamera?: boolean;
}

function hasUserMedia() {
  //@ts-ignore
  navigator.getUserMedia! =
    //@ts-ignore
    navigator?.getUserMedia! ||
    //@ts-ignore
    navigator?.webkitGetUserMedia! ||
    //@ts-ignore
    navigator?.mozGetUserMedia! ||
    //@ts-ignore
    navigator?.msGetUserMedia!;
  //@ts-ignore
  return !!navigator.getUserMedia;
}

export default function QrCodeReader({ onRead, hasCameraCallback, showCamera = true }: QrCodeReaderProps) {
  const { t } = useTranslation();
  const videoScannerRef = useRef<any>(null);
  const videoElement = document.getElementById('qrcode-reader');
  //const [facingMode, setFacingMode] = useState<string>('user');
  const [result, setResult] = useState<string>('');
  const [hasCamera, setHasCamera] = useState<boolean>(true);
  const [openPermissionsWarning, setOpenPermissionsWarning] = useState<boolean>(false);

  const renderLightButton = async () => {
    return await videoScannerRef?.current?.hasFlash?.((hasFlash: boolean) => {
      if (hasFlash) {
        return (
          <ButtonFloat className="w-7 h-7" onClick={handleTurnLight}>
            {videoScannerRef?.current?.isFlashOn() ? <BoltSlashIcon /> : <BoltIcon />}
          </ButtonFloat>
        );
      }
      return <></>;
    });
  };

  const handleTurnLight = () => {
    if (videoScannerRef?.current?.isFlashOn()) {
      videoScannerRef?.current?.turnFlashOff();
    } else {
      videoScannerRef?.current?.turnFlashOn();
    }
  };

  /*const handleToggleFacingMode = () => {
    if (facingMode == 'user') {
      setFacingMode('environment');
    } else {
      setFacingMode('user');
    }
    videoScannerRef?.current?.setCamera?.(facingMode);
  };*/

  const cameraListener = useCallback(() => {
    return setInterval(async () => {
      const status = await QrScanner.hasCamera();
      //const cameras = await QrScanner.listCameras();
      hasCameraCallback?.(status);
    }, 1500);
  }, []);

  const [locationError, setLocationError] = useState<string | undefined>(undefined);

  function getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition, showError);
    } else {
      setLocationError('Geolocation is not supported by this browser.');
    }
  }

  function showPosition(position: any) {
    console.log('Latitude: ' + position.coords.latitude + '<br>Longitude: ' + position.coords.longitude);
  }

  function showError(error: any) {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        setLocationError(t('authorize_the_geolocation_access'));
        break;
      case error.POSITION_UNAVAILABLE:
        setLocationError(t('position_not_avaiable'));
        break;
      case error.TIMEOUT:
        setLocationError(t('error_on_get_position'));
        break;
      case error.UNKNOWN_ERROR:
        setLocationError(t('unknown_error_on_get_position'));
        break;
    }
  }

  useEffect(() => {
    const cameraInterval = cameraListener();
    getLocation();
    if (hasUserMedia()) {
      navigator.mediaDevices.getUserMedia({ video: true }).catch(function () {
        setOpenPermissionsWarning(true);
        setHasCamera(false);
      });
    } else {
      setOpenPermissionsWarning(true);
      setHasCamera(false);
    }
    return () => {
      videoScannerRef?.current?.destroy();
      clearInterval(cameraInterval);
    };
  }, []);

  useEffect(() => {
    if (result) {
      onRead?.(result);
    }
  }, [result]);

  useEffect(() => {
    if (videoElement) {
      videoScannerRef.current = new QrScanner(videoElement as HTMLVideoElement, (result) => {
        setResult(result);
      });
      if (showCamera) {
        setHasCamera(true);
        videoScannerRef?.current?.start?.();
      } else {
        setHasCamera(false);
        videoScannerRef?.current?.stop?.();
      }
    }

    return () => {
      videoScannerRef?.current?.stop?.();
    };
  }, [videoElement, showCamera]);

  return (
    <>
      <Modal open={openPermissionsWarning} setOpen={setOpenPermissionsWarning} className="md:max-h-[60vh] h-full">
        <Modal.Header title="Allow camera" handleCloseButtonClick={() => setOpenPermissionsWarning(false)} />
        <Modal.Body className="gap-3">
          <p className="text-gray-900 text-base">{t('allow_camera_access_message')} </p>
          <span className="text-gray-900 text-base font-bold w-full">{t('do_you_with_to_proceed')}</span>
          <img src={allowCamera} className="w-auto max-w-xs mx-auto" />
        </Modal.Body>
        <Modal.Footer className="justify-end w-fit">
          <Button
            buttonType={Button.Type.SECONDARY}
            onClick={() => {
              setOpenPermissionsWarning(false);
            }}
          >
            {t('proceed')}
          </Button>
        </Modal.Footer>
      </Modal>

      {((locationError || !hasCamera) && (
        <div className="flex flex-col gap-2 w-full h-auto justify-center">
          <div className="mt-10 px-6 flex flex-col gap-4 w-full mx-auto">
            {(!hasCamera && (
              <div className="rounded-full md:py-3 text-center flex flex-col gap-2 items-center">
                <div className="p-4 rounded-lg bg-yellow-100">
                  <CameraIcon className="text-yellow-500 w-7" />
                </div>
                <p className="text-base text-yellow-500 font-normal">
                  {t('please_enable_the_camera_and_location_to_scan_the_QRCode')}.
                </p>
              </div>
            )) || (
              <div className="rounded-full md:py-3 text-center flex flex-col gap-2 items-center">
                <div className="p-4 rounded-lg bg-yellow-100">
                  <MapPinIcon className="text-yellow-500 w-7" />
                </div>
                <p className="text-base text-yellow-500 font-normal">{locationError}</p>
              </div>
            )}
          </div>
        </div>
      )) || (
        <div className="flex flex-col gap-2 w-full auto">
          <video id="qrcode-reader" className="rounded-t-lg max-h-[528px]" />

          {hasCamera && !locationError && (
            <div className="flex flex-row gap-3 w-full justify-center">
              <>{renderLightButton}</>
              {/*
              <div className="block lg:hidden">
                <ButtonFloat title="Câmera frontal/traseira" onClick={handleToggleFacingMode}>
                  {<ArrowPathIcon className="w-7 h-7" />}
                </ButtonFloat>
              </div>
              */}
            </div>
          )}
        </div>
      )}
    </>
  );
}
