import { useEffect, useState } from 'react';

import viewIcon from '../../assets/icons/view.svg';
// import editIcon from "../../assets/icons/edit.svg";
// import deleteIcon from "../../assets/icons/delete.svg";
import searchIcon from '../../assets/icons/search.svg';
import { ButtonDefault } from '../../components/Buttons/ButtonDefault';
import { Link, useParams } from 'react-router-dom';
import { useLocation } from '@hooks/location/useLocation';
import NavigationTable from '@components/NavigationTable';
import { useTranslation } from 'react-i18next';
import Loading from '@components/loading';
import Input from '@components/Inputs';

export default function ListLocation() {
  const { t } = useTranslation();
  const { locations, getLocations, infoPage } = useLocation();
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string | null>(null);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const { organizationUuid } = useParams<{ organizationUuid: string }>();

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < infoPage.last_page) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleSelectPage = (page: number) => {
    if (page >= 1 && page <= infoPage.last_page) {
      setCurrentPage(page);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoadingSearch(true);
      await getLocations(
        organizationUuid!,
        `take=10&page=${currentPage}&&sortBy=created_at&sortDirection=desc&search=${
          searchQuery == null ? '' : searchQuery
        }`,
      );
      setLoadingSearch(false);
    };

    fetchData();
  }, [currentPage, searchQuery]);

  async function onSearch() {
    setLoadingSearch(true);
    await getLocations(
      organizationUuid!,
      `&take=10&page=1&sortBy=created_at&sortDirection=desc&search=${searchQuery == null ? '' : searchQuery}`,
    );
    setLoadingSearch(false);
  }

  useEffect(() => {
    setLoading(true);
    getLocations(organizationUuid!, '&take=10&page=1&sortBy=created_at&sortDirection=desc')
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    onSearch();
  }, [searchQuery]);

  return (
    <main className="">
      <div className="flex flex-col mb-11 px-5 pt-4">
        <div className="flex flex-col gap-2">
          <h1 className="text-4xl text-gray-800 font-bold">{t('locations')}</h1>
          <h2 className="text-gray-600 text-base">{t('here_you_can_manage_locations')}.</h2>
        </div>
        <div className="mt-9 flex lg:justify-end justify-between gap-3">
          <div className="relative">
            <Input
              type="search"
              name="search"
              id="search"
              placeholder={`${t('search')}...`}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <img src={searchIcon} alt="Search icon" className="w-5 absolute right-3 top-3" />
          </div>
          <ButtonDefault
            href={`/organizations/${organizationUuid}/dashboard/location/create`}
            content={t('new_location')}
          />
        </div>
        {loading ? (
          <Loading className="w-full mx-auto" />
        ) : (
          <>
            <div className="overflow-x-auto shadow-lg mt-4 rounded-lg ">
              <table className="table-auto w-full">
                <thead className="bg-gray-200 text-gray-800">
                  <tr className="">
                    <th className="py-2 px-4 text-start">{t('name')}</th>
                    <th className="hidden lg:table-cell py-2 px-4 text-start">{t('address')}</th>
                    <th className="hidden lg:table-cell py-2 px-4 text-start">{t('zip_code')}</th>
                    <th className="hidden lg:table-cell py-2 px-4 text-start">{t('city')}</th>
                    <th className="py-2 px-4 text-start"></th>
                  </tr>
                </thead>
                <tbody className="text-gray-800">
                  {loadingSearch ? (
                    <tr>
                      <td colSpan={6} className="text-center py-10">
                        <Loading className="w-full mx-auto" />{' '}
                      </td>
                    </tr>
                  ) : searchQuery !== null && searchQuery.length > 0 ? (
                    locations.length === 0 ? (
                      <tr>
                        <td colSpan={6} className="text-center py-10">
                          Nenhuma localização encontrada!
                        </td>
                      </tr>
                    ) : (
                      locations.map((location, index) => (
                        <tr key={location.uuid} className={index % 2 === 0 ? 'bg-gray-50' : ' bg-gray-100'}>
                          <td className="py-2 px-4">{location.name}</td>
                          <td className="hidden lg:table-cell py-2 px-4">{location.street_address}</td>
                          <td className="hidden lg:table-cell py-2 px-4">{location.zip_code}</td>
                          <td className="hidden lg:table-cell py-2 px-4">{location.city}</td>
                          <td className="py-2 px-4 flex gap-4 justify-end">
                            <Link to={`/organizations/${organizationUuid}/dashboard/location/${location.uuid}`}>
                              <img src={viewIcon} alt="View" />
                            </Link>
                          </td>
                        </tr>
                      ))
                    )
                  ) : (
                    locations.map((location, index) => (
                      <tr key={location.uuid} className={index % 2 === 0 ? 'bg-gray-50' : ' bg-gray-100'}>
                        <td className="py-2 px-4">{location.name}</td>
                        <td className="hidden lg:table-cell py-2 px-4">{location.street_address}</td>
                        <td className="hidden lg:table-cell py-2 px-4">{location.zip_code}</td>
                        <td className="hidden lg:table-cell py-2 px-4">{location.city}</td>
                        <td className="py-2 px-4 flex gap-4 justify-end">
                          <Link to={`/organizations/${organizationUuid}/dashboard/location/${location.uuid}`}>
                            <img src={viewIcon} alt="View" />
                          </Link>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </>
        )}
      </div>
      <NavigationTable
        currentPage={currentPage}
        onPrev={handlePrevPage}
        onNext={handleNextPage}
        onSelectPage={handleSelectPage}
        infoPage={{
          last_page: infoPage.last_page,
          next_page_url: infoPage.next_page_url,
        }}
      />
    </main>
  );
}
