import { useState, useRef, useEffect } from 'react';
import { Organization, useOrganization } from '@hooks/organization/useOrganization';

import { useNavigate, useParams } from 'react-router-dom';

import imageDefault from '@assets/images/company-logo-default.png';
import Button from '@components/Common/Buttons/Button';
import { useTranslation } from 'react-i18next';
import { TypesOrganizations, enumTypes } from './CreateOrganization';
import { SelectPicker, Toggle } from 'rsuite';
import Loading from '@components/loading';
import Input from '@components/Inputs';

export interface LanguageOptionsProps {
  value: string;
  label: string;
}

export default function EditOrganization() {
  const { t } = useTranslation();
  const [selectedLanguageOption, setSelectedLanguageOption] = useState<string | null>(null);
  const [selectedTypesOrganizations, setSelectedTypesOrganizations] = useState<string | null>(null);
  const optionsTypesOrganizations = enumTypes(TypesOrganizations);
  const { editOrganization, getOrganizationById } = useOrganization();
  const [organization, setOrganization] = useState<Organization | null>(null);
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState(false);
  const [loadingEdit, setLoadingEdit] = useState(false);
  const navigate = useNavigate();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [nif, setNif] = useState('');
  const imageRef = useRef<HTMLInputElement>(null);

  const [distanceVerification, setDistanceVerification] = useState<boolean>(false);

  const languageOptions: LanguageOptionsProps[] = [
    { value: 'PT', label: 'PT' },
    { value: 'EN', label: 'EN' },
  ];

  useEffect(() => {
    setLoading(true);
    if (!id) {
      return;
    }
    getOrganization().finally(() => {
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (organization) {
      setSelectedLanguageOption(organization.language);
    }
  }, [organization]);

  function handleImageChange(event: any) {
    const imgElement = document.getElementById('preview-img');
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      if (imgElement instanceof HTMLImageElement && reader.result) {
        imgElement.src = reader.result.toString();
      }
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  }

  async function getOrganization() {
    const { organization } = await getOrganizationById(id!);

    if (organization) {
      setOrganization(organization);
      setName(organization.name);
      setEmail(organization.email);
      setPhone(organization.phone);
      setNif(organization.nif!);
      setSelectedLanguageOption(organization.language);
      setSelectedTypesOrganizations(organization.organization_type!);
      setDistanceVerification(organization.enable_distance_verification ?? false);
    }

    const imgElement = document.getElementById('preview-img');
    if (imgElement instanceof HTMLImageElement) {
      imgElement.src = organization.image
        ? `${import.meta.env.VITE_API_URL}/storage/images/organization/${organization.image}`
        : imageDefault;
    }
  }

  async function handleSave() {
    setLoadingEdit(true);
    const file = imageRef.current?.files?.[0] ?? null;

    await editOrganization(
      {
        uuid: id,
        name: name,
        email: email,
        phone: phone,
        nif: nif,
        language: selectedLanguageOption!,
        organization_type: selectedTypesOrganizations!,
        enable_distance_verification: distanceVerification,
      },
      file,
    ).finally(() => {
      setLoadingEdit(false);
    });
  }

  return (
    <main className="px-4">
      {loading ? (
        <Loading className="w-full mx-auto" />
      ) : (
        <>
          <div className="flex flex-col pt-4 lg:max-w-5xl md:max-w-3xl max-w-lg w-full mx-auto">
            <div className="flex flex-col gap-2">
              <h1 className="text-4xl text-gray-800 font-bold">
                {t('edit')} {organization?.name}
              </h1>
            </div>
          </div>
          <div className="mt-9 text-gray-600 bg-gray-100 rounded-lg shadow-lg p-4 mb-11 pt-4 lg:max-w-5xl md:max-w-3xl max-w-lg w-full mx-auto">
            <div className="w-full grid grid-cols-1 gap-2 mb-3">
              <div className="flex gap-4 items-center">
                <img
                  id="preview-img"
                  className="h-14 w-14 rounded-full object-cover"
                  src={
                    organization?.image !== null
                      ? `${import.meta.env.VITE_API_URL}/storage/images/organization/${organization?.image}`
                      : imageDefault
                  }
                  alt="Profile"
                />
                <label
                  htmlFor="avatar"
                  className="relative cursor-pointer bg-primary text-sm text-white font-medium w-max py-3 px-5 rounded-md transition-all"
                >
                  <span className="cursor-pointer">{t('select_image')}</span>
                  <Input
                    ref={imageRef}
                    type="file"
                    id="avatar"
                    name="avatar"
                    accept="image/*"
                    className="absolute inset-0 opacity-0 cursor-pointer"
                    onChange={handleImageChange}
                  />
                </label>
              </div>
            </div>
            <div className="w-full grid lg:grid-cols-3 grid-cols-1 gap-2 mb-3">
              <div>
                <label htmlFor="" className="font-bold">
                  {t('name')}*
                </label>
                <Input
                  placeholder="John Doe"
                  type="text"
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div>
                <label htmlFor="" className="font-bold">
                  {t('email')}*
                </label>
                <Input
                  placeholder="jhondoe@example.com"
                  type="email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div>
                <label htmlFor="" className="font-bold">
                  {t('type_organization')}
                </label>
                <SelectPicker
                  data={optionsTypesOrganizations}
                  placeholder={t('select_the_type_of_expense')}
                  placement="autoVerticalStart"
                  style={{ width: '100%' }}
                  onChange={(e) => setSelectedTypesOrganizations(e)}
                  value={selectedTypesOrganizations}
                  searchable={false}
                  size="lg"
                />
              </div>
            </div>
            <div className="w-full grid md:grid-cols-3 grid-cols-1 gap-2">
              <div>
                <label htmlFor="" className="font-bold">
                  {t('phone')}*
                </label>
                <Input
                  placeholder="357 258 369"
                  type="text"
                  name="phone"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
              <div>
                <label htmlFor="" className="font-bold">
                  {t('nif')}
                </label>
                <Input
                  placeholder="12345678"
                  type="number"
                  name="nif"
                  min={0}
                  value={nif}
                  onChange={(e) => setNif(e.target.value)}
                />
              </div>
              <div>
                <label htmlFor="" className="font-bold">
                  {t('language')}*
                </label>
                <SelectPicker
                  data={languageOptions}
                  value={selectedLanguageOption}
                  onChange={(e) => setSelectedLanguageOption(e)}
                  placeholder={t('select_language')}
                  placement="autoVerticalStart"
                  style={{ width: '100%' }}
                  searchable={false}
                  size="lg"
                />
              </div>
            </div>
            <div className="w-full grid lg:grid-cols-3 grid-cols-1 gap-2 mb-3 mt-3">
              <div className="flex flex-col gap-2 items-start justify-start">
                <label htmlFor="" className="font-bold">
                  {t('enable_distance_verification')}
                </label>
                <Toggle className="" checked={distanceVerification} onChange={setDistanceVerification} />
              </div>
            </div>

            <div className="mt-6 mb-2 flex flex-row items-center gap-3">
              <Button
                onClick={() => {
                  navigate(-1);
                }}
                buttonType={Button.Type.SECONDARY}
              >
                {t('cancel')}
              </Button>
              <Button onClick={handleSave} loading={loadingEdit}>
                {t('save_organization')}
              </Button>
            </div>
          </div>
        </>
      )}
    </main>
  );
}
