import { HTMLAttributes } from 'react';

export interface FastdocBannerProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  type?: 'info' | 'default';
}
export default function FastdocBanner({ className, children, type, ...props }: FastdocBannerProps) {
  let bgColor = 'bg-blue-50';

  switch (type) {
    case 'info':
      bgColor = 'bg-blue-50';
      break;
    default:
      bgColor = 'bg-primary/20';
      break;
  }

  return (
    <div className={`flex gap-3 items-center ${bgColor} rounded-full w-full p-1 ${className}`} {...props}>
      {children}
    </div>
  );
}
