import { useCallback, useEffect, useMemo, useState } from 'react';

import { ModalDelete } from '../../components/Modal/ModalDelete';

import { User, useUser } from '@hooks/user/useUser';
import { Link, useParams } from 'react-router-dom';
import { useAuth } from '@hooks/auth/useAuth';
import { useTranslation } from 'react-i18next';

import imageDefaultUser from '../../assets/images/user-img-default.jpg';
import Input from '@components/Inputs';
import moment from 'moment';
import Col from '@components/Flex/Col';
import {
  BellIcon,
  BriefcaseIcon,
  DocumentIcon,
  EnvelopeIcon,
  FlagIcon,
  MapPinIcon,
  UserMinusIcon,
  WifiIcon,
} from '@heroicons/react/24/outline';
import Loading from '@components/loading';
import Row from '@components/Flex/Row';

export default function ReadUser() {
  const { t } = useTranslation();
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  const [userSelected, setUserSelected] = useState<User | null>(null);
  const { getUserById, deleteUser } = useUser();
  const { user } = useAuth();
  const [loading, setLoading] = useState<boolean>(false);
  //const url = window.location.href
  //const uuid = url.split("/").pop();

  const getUser = useCallback(
    async function getUser(id: string) {
      const { user } = await getUserById(id!);
      setUserSelected(user);
    },
    [user],
  );

  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    if (!id) {
      return;
    }
    setLoading(true);
    getUser(id).finally(() => setLoading(false));
  }, [getUserById, id]);

  const lastUserCheckIn = useMemo(
    () => userSelected?.presences?.filter((presence) => presence.type === 'in').at(-1)?.time!,
    [userSelected],
  );
  const lastUserCheckout = useMemo(
    () => userSelected?.presences?.filter((presence) => presence.type === 'out')?.at(-1)?.time!,
    [userSelected],
  );

  const userAvailableVacations = useMemo(
    //@ts-ignore
    () => userSelected?.vacations?.find?.((vacation) => vacation?.vacation_year! == new Date().getFullYear()) ?? 0,
    [userSelected],
  );

  if (loading) {
    return (
      <div className="w-full flex justify-center items-center h-full">
        <Loading className="!w-10 !h-10 !mx-auto my-0" />
      </div>
    );
  }

  return (
    <main className="">
      <div className="flex flex-col lg:max-w-5xl md:max-w-3xl max-w-lg w-full mx-auto">
        <div className="flex  items-center gap-2">
          <img
            className="lg:h-20 lg:w-20 w-14 h-14 object-cover rounded-full"
            src={`${
              userSelected?.avatar === null
                ? imageDefaultUser
                : `${import.meta.env.VITE_API_URL}storage/images/user/${userSelected?.avatar}`
            } `}
            alt="Profile"
          />

          <Col className="items-start gap-2 justify-start">
            <h1 className="lg:text-4xl text-2xl text-gray-800 font-bold">{userSelected?.name}</h1>
            <small className="text-gray-600 flex flex-row gap-1 items-center">
              <BriefcaseIcon className="w-5 h-5 text-primary" /> {t('work_at')} {userSelected?.organization?.name}
            </small>
            {userSelected?.location?.name && (
              <small className="text-gray-600 flex flex-row gap-1 items-center">
                <MapPinIcon className="w-5 h-5 text-primary" /> {userSelected?.location?.name}
              </small>
            )}
          </Col>
        </div>
      </div>
      <Col className="gap-5 mt-6 p-4 md:!flex-row bg-gray-100 rounded-lg text-gray-600 mb-11 lg:max-w-5xl md:max-w-3xl max-w-lg w-full mx-auto">
        <span className="text-4xl text-primary font-bold whitespace-nowrap">
          {t('number')} {userSelected?.id}
        </span>
        <div className="w-full flex flex-col gap-4">
          <Row className="max-md:flex-wrap gap-8 max-md:gap-2">
            <Col className="gap-2">
              <Row className="flex-wrap gap-2 items-center">
                <label className="text-base font-bold text-primary flex flex-row gap-2 items-center">
                  <EnvelopeIcon className="w-5 h-5 text-primary" />
                  E-mail
                </label>
                <span>{user?.email}</span>
              </Row>

              <Row className="flex-wrap gap-2 items-center">
                <label className="text-base font-bold text-primary flex flex-row gap-2 items-center">
                  <BellIcon className="w-5 h-5 text-primary" />
                  {t('notifications')}
                </label>
                <span>{user?.email_notifications ? t('yes') : t('no')}</span>
              </Row>

              <Row className="flex-wrap gap-2 items-center">
                <label className="text-base font-bold text-primary flex flex-row gap-2 items-center">
                  <FlagIcon className="w-5 h-5 text-primary" />
                  {t('language')}
                </label>
                <span>{user?.language ? t('portuguese') : t('english')}</span>
              </Row>
            </Col>
            <Col className="gap-2">
              <Row className="flex-wrap gap-2 items-center">
                <label className="text-base font-bold text-primary flex flex-row gap-2 items-center">
                  <DocumentIcon className="w-5 h-5 text-primary" />
                  {t('nif')}
                </label>
                <span>{user?.nif}</span>
              </Row>

              <Row className="flex-wrap gap-2 items-center">
                <label className="text-base font-bold text-primary flex flex-row gap-2 items-center">
                  <WifiIcon className="w-5 h-5 text-primary" />
                  {t('work_type')}
                </label>
                <span>{t(user?.work_type!)}</span>
              </Row>

              <Row className="flex-wrap gap-2 items-center">
                <label className="text-base font-bold text-primary flex flex-row gap-2 items-center">
                  <UserMinusIcon className="w-5 h-5 text-primary" />
                  {t('available_vacations')}
                </label>
                <span>{String(userAvailableVacations)}</span>
              </Row>
            </Col>
          </Row>

          <div className="w-full flex flex-col items-center md:flex-row gap-2">
            <div className="w-full">
              <label htmlFor="" className="font-bold">
                {t('last_check_in')}
              </label>
              <Input value={moment(lastUserCheckIn).format('DD/MM/yyyy HH:mm:ss')} readOnly />
            </div>
            <div className="w-full">
              <label htmlFor="" className="font-bold">
                {t('last_check_out')}
              </label>
              <Input value={moment(lastUserCheckout).format('DD/MM/yyyy HH:mm:ss')} readOnly />
            </div>
          </div>

          <div className="grid grid-cols-2 gap-2">
            <Link
              to={`/organizations/${user?.organization?.uuid}/dashboard/user/${userSelected?.uuid}/edit`}
              className="bg-primary text-sm cursor-pointer hover:brightness-[.95] text-white font-medium w-full py-3 px-5 rounded-md transition-all text-center"
            >
              {t('edit_user')}
            </Link>
            <Link
              to={`/organizations/${user?.organization?.uuid}/dashboard/presences/${userSelected?.uuid}`}
              className="bg-primary text-sm cursor-pointer hover:brightness-[.95] text-white font-medium w-full py-3 px-5 rounded-md transition-all text-center"
            >
              {t('presence')}
            </Link>
            {/* <a
                        href="/users"
                        className="bg-gray-50 text-sm cursor-pointer hover:brightness-[.95] text-gray-600 font-medium w-max p-3 rounded-md transition-all border border-solid border-primary/90"
                    >
                        Voltar
                    </a> */}
          </div>
        </div>
      </Col>
      {isModalDeleteOpen && (
        <ModalDelete
          onConfirm={async () => {
            await deleteUser(userSelected?.uuid!);
            window.location.href = `/organizations/${user?.organization?.uuid}/dashboard/users/`;
          }}
          isOpen={isModalDeleteOpen}
          onClose={() => setIsModalDeleteOpen(false)}
          title="Apagar Utilizador"
          content={`Tem certeza que deseja excluir o utilizador ${userSelected?.name}?`}
        />
      )}
    </main>
  );
}
