import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { Outlet } from 'react-router-dom';

import 'react-toastify/dist/ReactToastify.css';
import NavbarReports from './NavbarReports';

function ReportPage() {
  return (
    <>
      <main className="max-w-[800px] w-full flex flex-col mt-4 mx-auto md:shadow-md px-8 pb-10 rounded">
        <NavbarReports />
        <Outlet />
      </main>
    </>
  );
}

export default ReportPage;
