import { create } from 'zustand';
import { api } from '../../services/api';
import { toast } from 'react-toastify';
import { languageProps } from '@hooks/auth/useAuth';
import { PointProps } from '@hooks/presence/usePoint';
import { Organization } from '@hooks/organization/useOrganization';
import { Location } from '@hooks/location/useLocation';
import { UserVacations } from './useUserVacations';

export interface User {
  id?: number;
  uuid?: string;
  name?: string;
  email?: string;
  nif?: string;
  password?: string;
  password_confirmation?: string;
  organization?: Organization;
  location?: Location;
  avatar?: string;
  start_work_date?: string | null;
  end_work_date?: string | null;
  notifications?: string;
  roles?: string;
  vacations?: UserVacations[];
  presences?: PointProps[];
  absences?: [];
  organization_id?: number;
  location_id?: string;
  user_language_id?: string;
  language?: languageProps;
  work_type?: string;
}

interface FetchUserProps {
  content: {
    data: User;
  };
}

interface UserProps {
  infoPage: {
    last_page: number;
    next_page_url: string | null;
  };
  getUsers: (organizationUuid: string, params?: string) => Promise<void>;
  createUser: (user: User, file: File | null) => Promise<void>;
  editUser: (user: User, file: File | null) => Promise<any>;
  getUserById: (uuid: string) => Promise<{ user: User }>;
  deleteUser: (uuid: string) => Promise<void>;
  getPresencesByUser: (uuid: string) => Promise<{ user: User }>;
  updateLanguage: (user: User, userUuid: string) => Promise<void>;
  users: User[];
}

export const useUser = create<UserProps>((set) => ({
  infoPage: {
    last_page: 0,
    next_page_url: null,
  },
  users: [],
  getUsers: async (organizationUuid: string, params?: string) => {
    try {
      const { data } = await api.get(`/api/organizations/${organizationUuid}/users${params ? '?' + params : ''}`);
      set({ users: data.content.data.data });
      set({
        infoPage: {
          last_page: data.content.data.last_page,
          next_page_url: data.content.data.next_page_url,
        },
      });
    } catch (error) {
      console.error(error);
    }
  },
  createUser: async (user: User, file: File | null) => {
    const form = new FormData();
    form.append('name', user.name!);
    form.append('email', user.email!);
    form.append('password', user.password!);
    form.append('password_confirmation', user.password_confirmation!);
    form.append('organization_id', String(user.organization_id)!);
    form.append('role_id', user.roles!);
    form.append('location_id', String(user.location_id)!);
    form.append('user_language_id', user.user_language_id!);
    form.append('start_work_date', user.start_work_date!);
    form.append('end_work_date', user.end_work_date!);
    if (user.work_type) {
      form.append('work_type', user.work_type!);
    }

    if (file) {
      form.append('avatar', file);
    }

    try {
      await api.post(`/api/users`, form);
      toast.success('Usuário criado com sucesso!');
    } catch (error: any) {
      if (error.response.data.errors.email && error.response.data.errors.email[0]) {
        toast.error(error.response.data.errors.email[0]);
      }
      if (error.response.data.errors.password && error.response.data.errors.password[0]) {
        toast.error(error.response.data.errors.password[0]);
      }
      if (error.response.data.errors.password_confirmation && error.response.data.errors.password_confirmation[0]) {
        toast.error(error.response.data.errors.password_confirmation[0]);
      }
      if (error.response.data.errors.name && error.response.data.errors.name[0]) {
        toast.error(error.response.data.errors.name[0]);
      }
      if (error.response.data.errors.avatar && error.response.data.errors.avatar[0]) {
        toast.error(error.response.data.errors.avatar[0]);
      }
      if (error.response.data.errors.user_language_id && error.response.data.errors.user_language_id[0]) {
        toast.error(error.response.data.errors.user_language_id[0]);
      }
      console.error(error);
    }
  },
  editUser: async (user: User, file: File | null) => {
    const form = new FormData();
    form.append('name', user.name!);

    if (user.email) {
      form.append('email', user.email!);
    }

    if (user.password) {
      form.append('password', user.password!);
    }

    if (user.password_confirmation) {
      form.append('password_confirmation', user.password_confirmation!);
    }

    if (user.roles) {
      form.append('role_id', user.roles!);
    }

    if (user.user_language_id) {
      form.append('user_language_id', user.user_language_id!);
    }

    if (user.start_work_date) {
      form.append('start_work_date', user.start_work_date!);
    }

    if (user.end_work_date) {
      form.append('end_work_date', user.end_work_date!);
    }

    if (user.work_type) {
      form.append('work_type', user.work_type!);
    }

    form.append('organization_id', String(user.organization_id)!);
    form.append('location_id', String(user.location_id)!);
    form.append('_method', 'PUT');

    if (file) {
      form.append('avatar', file);
    }
    return api.post(`/api/users/${user.uuid}`, form);
  },
  updateLanguage: async (user: User, userUuid: string) => {
    const form = new FormData();
    form.append('user_language_id', user?.user_language_id!);
    form.append('_method', 'PUT');
    try {
      await api.post(`/api/users/${userUuid}`, form);
    } catch (error: any) {
      console.error(error);
    }
  },
  getUserById: async (uuid: string) => {
    try {
      const { data } = await api.get<FetchUserProps>(`/api/users/${uuid}`);
      const user: User = data.content.data;
      return {
        user,
      };
    } catch (error: any) {
      console.error(error);
      throw new Error('Erro ao obter usuário por ID');
    }
  },
  deleteUser: async (uuid: string) => {
    if (!uuid) {
      return;
    }
    try {
      await api.delete(`/api/users/${uuid}`);
      toast.success('Usuário deletado com sucesso!');
    } catch (error: any) {
      toast.error('Erro ao deletar usuário');
      console.error(error);
    }
  },
  getPresencesByUser: async (uuid: string) => {
    try {
      const { data } = await api.get<FetchUserProps>(`/api/users/${uuid}`);
      const user: User = data.content.data;
      return {
        user,
      };
    } catch (error: any) {
      console.error(error);
      throw new Error('Erro ao obter férias por ID');
    }
  },
}));
