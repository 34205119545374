import { api } from 'services/api';
import { create } from 'zustand';

export interface Location {
  uuid?: string;
  name?: string;
  id?: string;
  street_address?: string;
  number?: string;
  complement?: string;
  neighborhood?: string;
  city?: string;
  state?: string;
  zip_code?: string;
  lat?: string;
  lng?: string;
}

export interface Holidays {
  uuid?: string;
  id?: number;
  name?: string;
  date?: string;
  year?: number;
  location_id?: string;
}

interface LocationProps {
  infoPage: {
    last_page: number;
    next_page_url: string | null;
  };
  locations: Location[];
  getLocations: (organizationUuid: string, params?: string) => Promise<void>;
  getLocationById: (organizationUuid: string, locationUuid: string) => Promise<{ location: Location }>;
  createLocation: (organizationUuid: string, location: Location) => Promise<void>;
  editLocation: (organizationUuid: string, locationUuid: string, location: Location) => Promise<void>;
  deleteLocation: (organizationUuid: string, locationUuid: string) => Promise<void>;

  currentSection: 'holidays';
  setCurrentSection: (section: 'holidays') => void;
}

export const useLocation = create<LocationProps>((set) => ({
  infoPage: {
    last_page: 0,
    next_page_url: null,
  },
  locations: [],
  getLocations: async (organizationUuid: string, params?: string) => {
    try {
      const { data } = await api.get(`/api/organizations/${organizationUuid}/locations${params ? '?' + params : ''}`);
      set({ locations: data.content.data.data });
      set({
        infoPage: {
          last_page: data.content.data.last_page,
          next_page_url: data.content.data.next_page_url,
        },
      });
    } catch (error) {
      console.error(error);
    }
  },
  getLocationById: async (organizationUuid: string, locationUuid: string) => {
    try {
      const { data } = await api.get(`/api/organizations/${organizationUuid}/locations/${locationUuid}`);
      const location: Location = data.content.data;
      return { location };
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  createLocation: async (organizationUuid: string, location: Location) => {
    try {
      return api.post(`/api/organizations/${organizationUuid}/locations`, location);
    } catch (error: any) {
      console.error(error);
    }
  },
  editLocation: async (organizationUuid: string, locationUuid: string, location: Location) => {
    try {
      return api.put(`/api/organizations/${organizationUuid}/locations/${locationUuid}`, location);
    } catch (error: any) {
      console.error(error);
    }
  },
  deleteLocation: async (organizationUuid: string, locationUuid: string) => {
    try {
      await api.delete(`/api/organizations/${organizationUuid}/locations/${locationUuid}`);
    } catch (error) {
      console.error(error);
    }
  },
  currentSection: 'holidays',

  setCurrentSection: (section) => set({ currentSection: section }),
}));
