import { useEffect, useState } from 'react';

import { useAuth } from '@hooks/auth/useAuth';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '@components/Common/Buttons/Button';
import { useLocation } from '@hooks/location/useLocation';
import { useTranslation } from 'react-i18next';
import Loading from '@components/loading';
import Input from '@components/Inputs';

export default function EditLocation() {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { locationUuid } = useParams<{ locationUuid: string }>();
  const { editLocation, getLocationById } = useLocation();
  const [loading, setLoading] = useState(false);
  const [loadingEdit, setLoadingEdit] = useState(false);
  const navigate = useNavigate();

  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [number, setNumber] = useState('');
  const [complement, setComplement] = useState('');
  const [neighborhood, setNeighborhood] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [lat, setLat] = useState('');
  const [lng, setLng] = useState('');

  useEffect(() => {
    setLoading(true);
    if (!locationUuid) {
      return;
    }
    async function getLocation() {
      try {
        const { location } = await getLocationById(user?.organization?.uuid!, locationUuid!);

        if (location) {
          setName(location.name!);
          setAddress(location.street_address!);
          setNumber(location.number!);
          setComplement(location.complement!);
          setNeighborhood(location.neighborhood!);
          setCity(location.city!);
          setState(location.state!);
          setZipCode(location.zip_code!);
          setLat(location.lat!);
          setLng(location.lng!);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }

    getLocation();
  }, [getLocationById, locationUuid]);

  async function handleEditLocation() {
    setLoadingEdit(true);
    const location = {
      name: name,
      street_address: address,
      number: number,
      complement: complement,
      city: city,
      state: state,
      zip_code: zipCode,
      neighborhood: neighborhood,
      lat: lat,
      lng: lng,
    };

    await editLocation(user?.organization?.uuid!, locationUuid!, location)
      .then(() => {
        toast.success('Localização atualizada com sucesso!');
        navigate(`/organizations/${user?.organization?.uuid}/dashboard/location/${locationUuid}`);
      })
      .catch((error) => {
        console.error('Erro ao criar localização', error);

        if (error.response.data.errors.city && error.response.data.errors.city[0]) {
          toast.error(error.response.data.errors.city[0]);
        }
        if (error.response.data.errors.lat && error.response.data.errors.lat[0]) {
          toast.error(error.response.data.errors.lat[0]);
        }
        if (error.response.data.errors.lng && error.response.data.errors.lng[0]) {
          toast.error(error.response.data.errors.lng[0]);
        }
        if (error.response.data.errors.name && error.response.data.errors.name[0]) {
          toast.error(error.response.data.errors.name[0]);
        }
        if (error.response.data.errors.number && error.response.data.errors.number[0]) {
          toast.error(error.response.data.errors.number[0]);
        }
        if (error.response.data.errors.state && error.response.data.errors.state[0]) {
          toast.error(error.response.data.errors.state[0]);
        }
        if (error.response.data.errors.street_address && error.response.data.errors.street_address[0]) {
          toast.error(error.response.data.errors.street_address[0]);
        }
        if (error.response.data.errors.zip_code && error.response.data.errors.zip_code[0]) {
          toast.error(error.response.data.errors.zip_code[0]);
        }
      })
      .finally(() => {
        setLoadingEdit(false);
      });
  }

  return (
    <main className="px-4">
      <div className="flex flex-col pt-4 lg:max-w-5xl md:max-w-3xl max-w-lg w-full mx-auto">
        <div className="flex flex-col gap-2">
          <h1 className="text-4xl text-gray-800 font-bold">{t('edit_location')}</h1>
          <h2 className="text-gray-600 text-base">{t('here_you_can_edit_the_location_information')}.</h2>
        </div>
      </div>
      {loading ? (
        <Loading className="w-full mx-auto" />
      ) : (
        <div className="mt-9 text-gray-600 bg-gray-100 rounded-lg shadow-lg p-4 mb-11 px-4 lg:max-w-5xl md:max-w-3xl max-w-lg w-full mx-auto">
          <div className="w-full grid-cols-1 gap-2 mb-3">
            <div className="flex flex-col">
              <label htmlFor="" className="font-bold">
                {t('name')}
              </label>
              <Input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Jaskolski-Abernathy"
                name="nome"
              />
            </div>
          </div>
          <div className="w-full grid lg:grid-cols-4 grid-cols-1 gap-2 mb-3">
            <div className="flex flex-col col-span-3">
              <label htmlFor="" className="font-bold">
                {t('address')}
              </label>
              <Input
                type="text"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                placeholder="Rua Tressie Walks"
                name="endereco"
              />
            </div>
            {/* <div className='flex flex-col'>
              <label htmlFor="" className="font-bold">
                Número
              </label>
              <Input
                type="text"
                value={number}
                onChange={(e) => setNumber(e.target.value)}
                placeholder="35"
                name="numero"
                className='border border-solid border-gray-200 rounded-lg px-3 py-2'
              />
            </div>
            <div className='flex flex-col'>
              <label htmlFor="" className="font-bold">
                Complemento
              </label>
              <Input
                type="text"
                value={complement}
                onChange={(e) => setComplement(e.target.value)}
                placeholder="Sala B"
                name="complemento"
                className='border border-solid border-gray-200 rounded-lg px-3 py-2'
              />
            </div>
            <div className='flex flex-col'>
              <label htmlFor="" className="font-bold">
                Bairro
              </label>
              <Input
                type="text"
                value={neighborhood}
                onChange={(e) => setNeighborhood(e.target.value)}
                placeholder="Linda Spurs"
                name="bairro"
                className='border border-solid border-gray-200 rounded-lg px-3 py-2'
              />
            </div> */}
            <div className="flex flex-col col-span-1">
              <label htmlFor="" className="font-bold">
                {t('zip_code')}
              </label>
              <Input
                type="text"
                value={zipCode}
                onChange={(e) => setZipCode(e.target.value)}
                placeholder="65800-000"
                name="zipcode"
              />
            </div>
          </div>
          <div className="w-full grid md:grid-cols-4 grid-cols-2 gap-2">
            <div className="flex flex-col col-span-2">
              <label htmlFor="" className="font-bold">
                {t('city')}
              </label>
              <Input
                type="text"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                placeholder="Lisboa"
                name="cidade"
              />
            </div>
            {/* <div className='flex flex-col'>
              <label htmlFor="" className="font-bold">
                Estado
              </label>
              <Input
                type="text"
                value={state}
                onChange={(e) => setState(e.target.value)}
                placeholder="Lisboa"
                name="cidade"
                className='border border-solid border-gray-200 rounded-lg px-3 py-2'
              />
            </div> */}
            <div className="flex flex-col">
              <label htmlFor="" className="font-bold">
                Latitude
              </label>
              <Input
                type="text"
                value={lat}
                onChange={(e) => setLat(e.target.value)}
                placeholder="25.3696505"
                name="latitude"
              />
            </div>
            <div className="flex flex-col">
              <label htmlFor="" className="font-bold">
                Longitude
              </label>
              <Input
                type="text"
                value={lng}
                onChange={(e) => setLng(e.target.value)}
                placeholder="-49.6636505"
                name="longitude"
              />
            </div>
          </div>
          <div className="mt-6 mb-2 flex flex-col gap-3">
            <Button onClick={handleEditLocation} loading={loadingEdit}>
              {t('save_location')}
            </Button>
            <Button
              onClick={() => {
                navigate(-1);
              }}
              buttonType={Button.Type.SECONDARY}
            >
              {t('cancel')}
            </Button>
          </div>
        </div>
      )}
    </main>
  );
}
