import QrCodeExpenseModal from '@components/Expenses/Modals/QRCodeModalExpense';
import CardHome from '@components/Home/CardHome';
import { CurrencyEuroIcon, QrCodeIcon, ReceiptRefundIcon } from '@heroicons/react/24/outline';
import { CalendarIcon } from '@heroicons/react/24/solid';
import { useAuth } from '@hooks/auth/useAuth';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

export function DashboardEmployee() {
  const { t } = useTranslation();
  const { user } = useAuth();

  const dateNow = new Date();

  return (
    <>
      <QrCodeExpenseModal
        expenseDate={format(dateNow!, 'yyyy-MM-dd')}
        onCloseModal={() => {
          // console.log('close modal');
        }}
      />
      <div className="flex flex-col mt-4">
        <div className="grid lg:grid-cols-4 grid-cols-2 w-full justify-center gap-4">
          <CardHome
            link={
              user?.work_type == 'remote'
                ? `/organizations/${user!.organization?.uuid}/dashboard/read-point/remote`
                : `/organizations/${user!.organization?.uuid}/dashboard/read-point`
            }
            description={t('read_point')}
            icon={<QrCodeIcon className="h-9" />}
            type="secondary"
          />
          <CardHome
            link={`/organizations/${user!.organization?.uuid}/dashboard/read-expense`}
            description={t('read_expense')}
            icon={<ReceiptRefundIcon className="h-9" />}
            type="secondary"
          />
          <CardHome
            link={`/organizations/${user!.organization?.uuid}/dashboard/expenses/all`}
            description={t('expenses')}
            icon={<CurrencyEuroIcon className="h-9" />}
            type="primary"
          />
          <CardHome
            link={`/organizations/${user!.organization?.uuid}/dashboard/presences/user/${user!.uuid}`}
            description={t('presence')}
            icon={<CalendarIcon className="h-9" />}
            type="primary"
          />
        </div>
      </div>
    </>
  );
}
