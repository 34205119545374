import { useCallback, useEffect, useRef, useState } from 'react';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { format, parseISO } from 'date-fns';
import { useAbsences, CustomFile, Absence } from '../../../hooks/presence/useAbsences';
import { useDropzone } from 'react-dropzone';
import { useAuth } from '@hooks/auth/useAuth';
import { values } from 'lodash';
import { toast } from 'react-toastify';
import { parse } from 'date-fns';
import Button from '@components/Common/Buttons/Button';
import useAxios from '@hooks/axios/useAxios';
import { enumTypes } from '@components/Expenses/Sections/ExpensesSection';
import { SelectPicker, Timeline } from 'rsuite';
import StatusBadge, { BadgeStatus } from '@components/Badges/StatusBadge';
import { ModalDelete } from '@components/Modal/ModalDelete';
import { DayPickerRangeController, FocusedInputShape } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';
import { useVacations } from '@hooks/presence/useVacations';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useOrganization } from '@hooks/organization/useOrganization';
import { AbsenceMark, HolidayMark, VacationMark } from '@components/Calendar/Summary';
import { PresenceSummary } from '@components/Calendar/Summary/PresenceSummary';
import { Month, NextMonth, PrevMonth } from '@components/Calendar';
import {
  ArrowPathIcon,
  DocumentIcon,
  PencilSquareIcon,
  PhotoIcon,
  TrashIcon,
  XMarkIcon,
} from '@heroicons/react/24/solid';
import { ArrowRightCircleIcon, CalendarIcon } from '@heroicons/react/24/outline';
import Loading from '@components/loading';
import Input from '@components/Inputs';
import Textarea from '@components/Inputs/Textarea';
import { ApprovedDot, PendingDot, RejectedDot } from '@components/Timeline/Dots';
import Col from '@components/Flex/Col';

export enum TypesAbsences {
  sick_leave = 'Baixa por doença',
  low_professional = 'Baixa profissional',
  low_non_professional = 'Baixa não profissional',
  training = 'Horas de Formação',
  excused_absence = 'Falta justificada',
  unjustified_absence = 'Falta injustificada',
  fatherhood_maternity = 'Paternidade/Maternidade',
}

export function getCurrentDimension() {
  return {
    width: window.innerWidth,
    height: window.innerHeight,
  };
}

function AbsenceSection() {
  const { t } = useTranslation();
  const { request: editAbsenceRequest, loading: editingAbsenceLoading } = useAxios();
  const { loading: createAbsenceLoading, request: createAbsence } = useAxios();
  const { request: deleteAbsencePending } = useAxios();
  const { request: queryAbsenceRequest } = useAxios();

  const [validateFormData, setValidateFormData] = useState(false);
  const [isDisabledButton, setIsDisabledButton] = useState(true);
  const [showListAbsences, setShowListAbsences] = useState(true);
  const [absenceDateState, setAbsenceDateState] = useState<{
    startDate: moment.Moment | null;
    endDate: moment.Moment | null;
  }>({
    startDate: moment(),
    endDate: moment(),
  });

  const [isEditingAbsence, setIsEditingAbsence] = useState(false);
  const [editingAbsenceDetails, setEditingAbsenceDetails] = useState<Absence | null>(null);
  const [selectedTypesAbsences, setSelectedTypesAbsences] = useState<string | null>(null);
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  const [focusedInput, setFocusedInput] = useState('startDate');
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  const optionsTypesAbsences = enumTypes(TypesAbsences);

  const { setFiles, files, removeFile, deleteFile, getAllAbsences } = useAbsences();
  const { getAllVacations } = useVacations();
  const { getOrganizationHolidays } = useOrganization();

  const { user } = useAuth();

  const reasonRef = useRef<HTMLTextAreaElement>(null);
  const [editedDescription, setEditedDescription] = useState('');

  const reasonEditRef = useRef<HTMLTextAreaElement>(null);
  const handleDeleteButtonClick = () => {
    setIsModalDeleteOpen(true);
  };

  function validateForm() {
    const reason = reasonRef.current?.value;

    if (!reason || reason.trim() === '') {
      setValidateFormData(true);
      setIsDisabledButton(true);
      return false;
    } else {
      setIsDisabledButton(false);
    }

    setValidateFormData(false);
    return true;
  }

  const startTime = user?.organization?.working_times?.[0]?.start_time;
  const [startHours, startMinutes] = startTime ? startTime.split(':') : ['00', '00'];

  const endTime = user?.organization?.working_times?.[0]?.end_time;
  const [endHours, endMinutes] = endTime ? endTime.split(':') : ['00', '00'];

  const formattedStartDate = absenceDateState?.startDate;
  const formattedEndDate = absenceDateState?.endDate;

  const [selectedStartYear, selectedStartMonth, selectedStartDay] = formattedStartDate
    ? formattedStartDate.format('yyyy-MM-DD').split('-')
    : ['0000', '00', '00'];
  const [selectedEndYear, selectedEndMonth, selectedEndDay] = formattedEndDate
    ? formattedEndDate.format('yyyy-MM-DD').split('-')
    : ['0000', '00', '00'];

  const formattedTimeIn = `${selectedStartYear}-${selectedStartMonth}-${selectedStartDay} ${startHours}:${startMinutes}:00`;
  const formattedTimeInDate = `${selectedStartYear}-${selectedStartMonth}-${selectedStartDay}`;
  const formattedTimeOut = `${selectedEndYear}-${selectedEndMonth}-${selectedEndDay} ${endHours}:${endMinutes}:00`;
  const formattedTimeOutDate = `${selectedEndYear}-${selectedEndMonth}-${selectedEndDay}`;
  const [screenSize, setScreenSize] = useState(getCurrentDimension());

  const { isLoading: queryVacationsLoading, data: localVacations } = useQuery<any[]>(['vacations', user], () =>
    getAllVacations(user!),
  );

  const { isLoading: queryAbsencesLoading, data: localAbsences } = useQuery<any[]>(['absences', user], () =>
    getAllAbsences(user!),
  );

  const { isLoading: queryHolidaysLoading, data: localHolidays } = useQuery<any[]>(['holidays', user], () =>
    getOrganizationHolidays(user?.organization?.uuid!),
  );

  const queryClient = useQueryClient();

  const createAbsenceMutation = useMutation({
    mutationFn: async (newData: any) =>
      createAbsence(`/api/organizations/${user?.organization?.uuid!}/absences`, 'POST', newData)
        .then(async () => {
          toast.success(t('absence_requested_successfully'));
          setFiles([]);
          setSelectedTypesAbsences(null);
          setShowListAbsences(true);
        })
        .catch(({ response }) => {
          values(response?.data?.errors)?.map((errors: any) => errors?.map((error: any) => toast.error(error)));
        }),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: ['absences'] });
    },
  });

  const editAbsenceMutation = useMutation({
    mutationFn: async (newData: any) =>
      editAbsenceRequest(
        `/api/organizations/${user?.organization?.uuid!}/absences/${editingAbsenceDetails?.uuid!}`,
        'POST',
        newData,
      )
        .then(() => {
          toast.success(t('absence_successfully_updated'));
          setIsEditingAbsence(false);
          setShowListAbsences(true);
        })
        .catch(({ response }) => {
          values(response?.data?.errors)?.map((errors: any) => errors?.map((error: any) => toast.error(error)));
        }),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: ['absences'] });
    },
  });

  const deletebsenceMutation = useMutation({
    mutationFn: async ({ organizationUuid, absenceUuid }: { organizationUuid: string; absenceUuid: string }) =>
      deleteAbsencePending(`/api/organizations/${organizationUuid}/absences/${absenceUuid}`, 'DELETE')
        .then(() => {
          toast.success(t('absence_request_successfully_deleted'));
        })
        .catch(({ response }) => {
          values(response?.data?.errors)?.map((errors: any) => errors?.map((error: any) => toast.error(error)));
        }),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: ['absences'] });
    },
  });

  function handleSave() {
    if (!validateForm()) {
      return;
    }
    const file = files[0];

    const form = new FormData();
    form.append('organizationUuid', user?.organization?.uuid!);
    form.append('user_id', String(user?.id!));
    form.append('absence_reason', reasonRef.current?.value!);
    form.append('absence_type', selectedTypesAbsences!);

    form.append('time_in', formattedTimeIn);
    form.append('time_out', formattedTimeOut);
    if (file) {
      form.append('file', file);
    }

    createAbsenceMutation.mutate(form);
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': [],
      'application/pdf': [],
    },
    onDrop: (acceptedFiles: any) => {
      const newFiles = acceptedFiles.map((file: any) => {
        const newFile = Object.assign(file, {
          preview: URL.createObjectURL(file),
          realFile: file,
        });

        return newFile;
      });
      setFiles(newFiles);
    },
  });

  useEffect(() => {
    setAbsenceDateState({
      startDate: moment(),
      endDate: moment(),
    });
  }, [editingAbsenceDetails]);

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener('resize', updateDimension);

    return () => {
      window.removeEventListener('resize', updateDimension);
    };
  }, [screenSize]);

  const handleDeleteAbsencePending = (organizationUuid: string, absenceUuid: string) => {
    deletebsenceMutation.mutate({ organizationUuid, absenceUuid });
  };

  function handleEditAbsenceClick(absenceUuid: string) {
    setIsEditingAbsence(true);
    setShowListAbsences(false);

    queryAbsenceRequest(`/api/organizations/${user?.organization?.uuid}/absences/${absenceUuid}`)
      .then(({ data }) => {
        const absenceDetails = data.content.data;
        setEditingAbsenceDetails(absenceDetails);
        setSelectedTypesAbsences(absenceDetails.absence_type);
        setEditedDescription(absenceDetails.absence_reason);
        setAbsenceDateState({
          startDate: moment(absenceDetails.time_in),
          endDate: moment(absenceDetails.time_out),
        });
      })
      .catch(({ response }) => {
        values(response?.data?.errors)?.map((errors: any) => errors?.map((error: any) => toast.error(error)));
      });
  }

  function handleSaveEditing() {
    const file = files[0] || '';

    const form = new FormData();
    form.append('organizationUuid', user?.organization?.uuid!);
    form.append('user_id', String(user?.id!));
    form.append('absence_reason', reasonEditRef.current?.value!);
    form.append('absence_type', selectedTypesAbsences!);

    form.append('time_in', formattedTimeIn);
    form.append('time_out', formattedTimeOut);
    form.append('_method', 'PUT');
    if (file) {
      form.append('file', file);
    }

    editAbsenceMutation.mutate(form);

    setFiles([]);
  }

  const newStartDateSelectedInEditing =
    editingAbsenceDetails?.time_in && format(parseISO(editingAbsenceDetails?.time_in), 'yyyy/MM/dd');
  const newEndDateSelectedInEditing =
    editingAbsenceDetails?.time_out && format(parseISO(editingAbsenceDetails?.time_out), 'yyyy/MM/dd');

  const handleCompareIfDateSelected = useCallback(
    (item: moment.Moment) => {
      return (
        (localVacations?.some((vacation: any) => {
          const vacationStart = parse(vacation.time_in, 'yyyy-MM-dd HH:mm:ss', new Date());
          const vacationEnd = parse(vacation.time_out, 'yyyy-MM-dd HH:mm:ss', new Date());

          return (
            (item.isAfter(vacationStart) && item.isBefore(vacationEnd)) ||
            item.isSame(vacationStart, 'day') ||
            item.isSame(vacationEnd, 'day')
          );
        }) ||
          localAbsences?.some((absence: any) => {
            const absenceStart = parse(absence.time_in, 'yyyy-MM-dd HH:mm:ss', new Date());
            const absenceEnd = parse(absence.time_out, 'yyyy-MM-dd HH:mm:ss', new Date());

            return (
              (item.isAfter(absenceStart) && item.isBefore(absenceEnd)) ||
              item.isSame(absenceStart, 'day') ||
              item.isSame(absenceEnd, 'day')
            );
          }) ||
          localHolidays?.some?.((holiday: any) => {
            const selectedHoliday = parse(holiday.date, 'yyyy-MM-dd HH:mm:ss', new Date());

            return item.isSame(selectedHoliday, 'day');
          })) ??
        false
      );
    },
    [localAbsences, localHolidays, localVacations],
  );

  return (
    <>
      <section className="flex flex-col items-center justify-center w-full px-4">
        <div className="w-full mt-4">
          {(queryVacationsLoading || queryAbsencesLoading || queryHolidaysLoading) && (
            <Loading className="w-full mx-auto" />
          )}

          {!queryVacationsLoading && !queryAbsencesLoading && !queryHolidaysLoading && (
            <div className="flex flex-col justify-center items-center w-full">
              <div className={`flex flex-row justify-center items-center flex-wrap md:flex-nowrap w-full my-2`}>
                <DayPickerRangeController
                  daySize={48}
                  verticalBorderSpacing={5}
                  navNext={<NextMonth />}
                  navPrev={<PrevMonth />}
                  renderMonthElement={Month}
                  initialVisibleMonth={() => moment()}
                  noBorder
                  isDayHighlighted={handleCompareIfDateSelected}
                  isDayBlocked={handleCompareIfDateSelected}
                  startDate={absenceDateState.startDate}
                  endDate={absenceDateState.endDate}
                  renderCalendarInfo={() => <PresenceSummary />}
                  onDatesChange={({ startDate, endDate }) => {
                    const isDateSelectedOnVacationRange = localVacations?.some((vacation: any) => {
                      const vacationStart = parse(vacation.time_in, 'yyyy-MM-dd HH:mm:ss', new Date());
                      const vacationEnd = parse(vacation.time_out, 'yyyy-MM-dd HH:mm:ss', new Date());

                      return (
                        moment(vacationStart).isBetween(startDate, endDate) ||
                        moment(vacationEnd).isBetween(startDate, endDate)
                      );
                    });

                    const isDateSelectedOnDisabledRange = localAbsences?.some((absence: any) => {
                      const absenceStart = parse(absence.time_in, 'yyyy-MM-dd HH:mm:ss', new Date());
                      const absenceEnd = parse(absence.time_out, 'yyyy-MM-dd HH:mm:ss', new Date());

                      return (
                        moment(absenceStart).isBetween(startDate, endDate) ||
                        moment(absenceEnd).isBetween(startDate, endDate)
                      );
                    });

                    const isDateSelectedOnHolidayRange = localHolidays?.some?.((holiday: any) => {
                      const selectedHoliday = parse(holiday.date, 'yyyy-MM-dd HH:mm:ss', new Date());

                      return moment(selectedHoliday).isBetween(startDate, endDate);
                    });

                    if (
                      !isDateSelectedOnDisabledRange &&
                      !isDateSelectedOnHolidayRange &&
                      !isDateSelectedOnVacationRange
                    ) {
                      setAbsenceDateState({ startDate, endDate: endDate ?? startDate });
                      return;
                    }
                  }}
                  onFocusChange={(focusedInput) => {
                    setFocusedInput(focusedInput as string);
                  }}
                  focusedInput={(focusedInput || 'startDate') as FocusedInputShape}
                  renderDayContents={(day) => {
                    const dateAbsenceSelected = localAbsences?.some((absence: any) => {
                      const absenceStart = parse(absence.time_in, 'yyyy-MM-dd HH:mm:ss', new Date());
                      const absenceEnd = parse(absence.time_out, 'yyyy-MM-dd HH:mm:ss', new Date());

                      return (
                        day.isBetween(absenceStart, absenceEnd) ||
                        day.isSame(absenceStart, 'day') ||
                        day.isSame(absenceEnd, 'day')
                      );
                    });

                    const dateVacationSelected = localVacations?.some((vacation: any) => {
                      const vacationStart = parse(vacation.time_in, 'yyyy-MM-dd HH:mm:ss', new Date());
                      const vacationEnd = parse(vacation.time_out, 'yyyy-MM-dd HH:mm:ss', new Date());
                      return (
                        day.isBetween(vacationStart, vacationEnd) ||
                        day.isSame(vacationStart, 'day') ||
                        day.isSame(vacationEnd, 'day')
                      );
                    });

                    const dateHolidaySelected = localHolidays?.some?.((holiday: any) => {
                      const selectedHoliday = parse(holiday.date, 'yyyy-MM-dd HH:mm:ss', new Date());

                      return day.isSame(moment(selectedHoliday, 'MM/D/YYYY'), 'date');
                    });

                    if (dateAbsenceSelected || dateVacationSelected || dateHolidaySelected) {
                      return (
                        <div className="flex flex-col items-center justify-center gap-2">
                          {day.format('D')}
                          <div className="flex flex-row gap-1 items-center">
                            {dateAbsenceSelected && <AbsenceMark />}
                            {dateVacationSelected && <VacationMark />}
                            {dateHolidaySelected && <HolidayMark />}
                          </div>
                        </div>
                      );
                    }
                    return day.format('D');
                  }}
                />
              </div>
              <div className="flex gap-3 items-center bg-primary/20rounded-full w-full max-w-xs">
                <ArrowRightCircleIcon className="text-primary w-7" />
                <p className="text-sm text-primary font-normal select-none">{t('select_the_start_and_end_period')}</p>
              </div>
            </div>
          )}

          <section className="flex flex-col gap-4 w-full mt-4 rounded-md px-2">
            {showListAbsences ? (
              <>
                <div className="flex flex-col gap-3 justify-center w-full mb-2 px-2">
                  {(queryVacationsLoading || queryAbsencesLoading || queryHolidaysLoading) && (
                    <Loading className="w-full mx-auto" />
                  )}

                  {!queryVacationsLoading &&
                    !queryAbsencesLoading &&
                    !queryHolidaysLoading &&
                    localAbsences?.length === 0 && (
                      <Col className="w-full justify-center items-center gap-2">
                        <div className="bg-gray-300 rounded-lg p-3">
                          <CalendarIcon className="w-6 text-white" />
                        </div>
                        <p className="text-gray-400 text-sm text-center">{t('nothing_to_show')}.</p>
                      </Col>
                    )}
                  {!queryVacationsLoading &&
                    !queryAbsencesLoading &&
                    !queryHolidaysLoading &&
                    localAbsences?.map((absence, index) => {
                      const startTimeAbsence = String(new Date(absence?.time_in!).toISOString().split('T')[0])
                        .replace('-', '/')
                        .replace('-', '/');

                      const endTimeAbsence = String(new Date(absence?.time_out!).toISOString().split('T')[0])
                        .replace('-', '/')
                        .replace('-', '/');

                      return (
                        <section
                          key={absence?.uuid}
                          className="flex flex-col gap-4 w-full border-b-2 border-solid border-gray-200 py-2"
                        >
                          <div className="flex justify-between items-center">
                            <span className="text-gray-700 text-sm font-medium">
                              {String(new Date(String(absence.created_at)).toISOString().split('T')[0])
                                .replace('-', '/')
                                .replace('-', '/')}
                              {' - '}
                              {absence?.time_in !== absence?.time_out
                                ? t('you_requested_a_period_of_absence')
                                : t('you_requested_an_absence')}
                            </span>
                            <div className="flex gap-2">
                              <button
                                className="p-2 flex items-center justify-center hover:bg-primary/20 rounded-lg"
                                onClick={() => {
                                  if (selectedIndex == index && typeof selectedIndex == 'number') {
                                    setSelectedIndex(null);
                                  } else setSelectedIndex(index);
                                }}
                              >
                                <ArrowPathIcon className="text-primary text-lg w-4" />
                              </button>

                              {absence.status === ('pending' as BadgeStatus) && (
                                <>
                                  <button
                                    onClick={() => {
                                      handleEditAbsenceClick(absence.uuid!);
                                    }}
                                    className="text-primary hover:text-primary/80"
                                  >
                                    <PencilSquareIcon className="w-5" />
                                  </button>
                                  <button onClick={handleDeleteButtonClick} className="text-red-600 hover:text-red-700">
                                    <TrashIcon className="w-5" />
                                  </button>
                                  <ModalDelete
                                    isOpen={isModalDeleteOpen}
                                    onClose={() => setIsModalDeleteOpen(false)}
                                    title={t('delete_absence_request')}
                                    content={`${t('are_you_sure_you_want_to_delete_the_absence_request')}`}
                                    onConfirm={() => {
                                      handleDeleteAbsencePending(user?.organization?.uuid!, absence.uuid!);
                                      setIsModalDeleteOpen(false);
                                    }}
                                  />
                                </>
                              )}
                            </div>
                          </div>
                          {(selectedIndex == index && absence.statuses.length >= 0 && (
                            <Timeline className="custom-timeline">
                              {absence.statuses.map((status: any) => {
                                if (status.name == 'pending') {
                                  return (
                                    <Timeline.Item dot={<PendingDot />} key={status?.id}>
                                      <p className="ml-2">
                                        {format(parseISO(status?.created_at), 'yyyy/MM/dd HH:mm:ss')}
                                      </p>
                                      <p className="ml-2">{t('you_requested_an_absence')}</p>
                                    </Timeline.Item>
                                  );
                                } else if (status.name == 'rejected') {
                                  return (
                                    <Timeline.Item dot={<RejectedDot />} key={status?.id}>
                                      <p className="ml-2">
                                        {format(parseISO(status?.created_at), 'yyyy/MM/dd HH:mm:ss')}
                                      </p>
                                      <p className="ml-2">{t('absence_request_rejected')}</p>
                                    </Timeline.Item>
                                  );
                                } else {
                                  return (
                                    <Timeline.Item dot={<ApprovedDot />} key={status?.id}>
                                      <p className="ml-2">
                                        {format(parseISO(status?.created_at), 'yyyy/MM/dd HH:mm:ss')}
                                      </p>
                                      <p className="ml-2">{t('absence_request_approved')}</p>
                                    </Timeline.Item>
                                  );
                                }
                              })}
                            </Timeline>
                          )) ||
                            (selectedIndex == index && absence.statuses.length == 0 && (
                              <Col className="w-full justify-center items-center gap-2">
                                <div className="bg-gray-300 rounded-lg p-3">
                                  <CalendarIcon className="w-6 text-white" />
                                </div>
                                <p className="text-gray-400 text-sm text-center">{t('nothing_to_show')}.</p>
                              </Col>
                            ))}

                          <div className="flex flex-row justify-between items-center">
                            <span key={absence?.uuid} className="text-black text-base">
                              {startTimeAbsence}{' '}
                              {startTimeAbsence !== endTimeAbsence && (
                                <>
                                  -{'>'} {endTimeAbsence}
                                </>
                              )}
                            </span>

                            <StatusBadge status={absence.status!} />
                          </div>
                        </section>
                      );
                    })}
                  {!!queryVacationsLoading &&
                    !queryAbsencesLoading &&
                    !queryHolidaysLoading &&
                    localAbsences?.length == 0 && (
                      <span className="text-gray-400 text-center w-full">{t('nothing_to_show')}</span>
                    )}
                </div>
                <Button
                  className="font-normal sticky bottom-5 m-2"
                  onClick={() => {
                    setShowListAbsences(false);
                    setIsEditingAbsence(false);
                  }}
                  loading={createAbsenceLoading}
                >
                  {t('add_absence')}
                </Button>
              </>
            ) : (
              <>
                {isEditingAbsence ? (
                  <>
                    {editingAbsenceLoading ? (
                      <Loading className="w-full mx-auto" />
                    ) : (
                      <>
                        {formattedTimeInDate === formattedTimeOutDate ? (
                          <>
                            <div className="grid grid-cols-2">
                              <div className="flex flex-col gap-3">
                                <span className="text-gray-700 text-sm font-medium">{t('date_selected')}</span>
                                <span className="text-gray-700 text-sm font-medium">
                                  {formattedTimeInDate ? formattedTimeInDate : newStartDateSelectedInEditing}
                                </span>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="grid grid-cols-2">
                              <div className="flex flex-col gap-3">
                                <span className="text-gray-700 text-sm font-medium">{t('selected_start_date')}</span>
                                <span className="text-gray-700 text-sm font-medium">
                                  {formattedTimeInDate ? formattedTimeInDate : newStartDateSelectedInEditing}
                                </span>
                              </div>
                              <div className="flex flex-col gap-3">
                                <span className="text-gray-700 text-sm font-medium">{t('selected_end_date')}</span>
                                <span className="text-gray-700 text-sm font-medium">
                                  {formattedTimeOutDate ? formattedTimeOutDate : newEndDateSelectedInEditing}
                                </span>
                              </div>
                            </div>
                          </>
                        )}

                        <span className="text-gray-700 text-sm font-medium">{t('type_of_absence')}</span>
                        <SelectPicker
                          data={optionsTypesAbsences}
                          placeholder={t('select_the_type_of_absence')}
                          placement="autoVerticalStart"
                          style={{ width: '100%' }}
                          onChange={(e) => setSelectedTypesAbsences(e)}
                          value={selectedTypesAbsences}
                        />

                        <div className="my-2">
                          <span className="text-gray-700 text-sm font-medium">{t('reason')}</span>

                          <div className="grid grid-cols-1 w-full">
                            <Textarea
                              name="motivation"
                              id="motivation"
                              value={editedDescription}
                              ref={reasonEditRef}
                              onChange={(e) => setEditedDescription(e.target.value)}
                              placeholder={t('write_the_reason_for_your_absence')}
                              // className={`resize-none bg-gray-50 border ${validateFormData ? 'border-red-500' : 'border-primary/90'
                              //   } mt-2 w-full rounded-lg max-h-36 shadow-md p-2`}
                            />
                            {validateFormData && (
                              <p className="text-red-500 text-sm mt-2">
                                {t('please_write_down_the_reason_for_your_absence')}
                              </p>
                            )}
                          </div>
                        </div>

                        <div className="w-full mt-2">
                          <span className="text-gray-700 text-sm font-medium">{t('justification')}</span>
                          <div {...getRootProps({ className: 'dropzone' })}>
                            <Input {...getInputProps()} />
                            <div className="border-2 border-dashed border-primary/90 flex flex-col justify-center items-center p-4 mt-2 cursor-pointer">
                              <PhotoIcon className="text-primary/90 w-12" />
                              <p className="font-medium text-sm text-gray-600 mt-1">
                                <span className="text-primary">{t('upload')} </span>
                                {t('or_drag_and_drop_a_document')}
                              </p>
                              <p className="text-xs font-normal text-primary/90">{t('file_upload_limit')}</p>
                            </div>
                          </div>
                          {files.length > 0 && (
                            <div className="flex mt-8 gap-2 w-full border p-4 overflow-x-auto items-center justify-center">
                              {files.map((file: CustomFile) => (
                                <div key={file.name}>
                                  <div className="relative w-[100px] h-[100px]">
                                    {file.type === 'application/pdf' ? (
                                      <DocumentIcon className="text-gray-600 w-20" />
                                    ) : (
                                      <img
                                        className="w-[100px] rounded h-[100px] object-cover"
                                        src={file.preview}
                                        // Revogar o data URI após o carregamento da imagem
                                        onLoad={() => {
                                          URL.revokeObjectURL(file.preview);
                                        }}
                                      />
                                    )}
                                    <button
                                      onClick={() => {
                                        removeFile(file.preview);
                                        handleSaveEditing();
                                      }}
                                      className="absolute right-1 top-1 text-red-500 shadow-md"
                                    >
                                      <XMarkIcon className="w-5" />
                                    </button>
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}
                          {editingAbsenceDetails?.absence_file && (
                            <>
                              <div className="flex flex-col mt-8 gap-2 w-full border p-4 overflow-x-auto items-center justify-center">
                                <div>
                                  <div className="relative w-[100px] h-[100px]">
                                    {editingAbsenceDetails.absence_file?.file &&
                                    editingAbsenceDetails.absence_file?.file.endsWith('.pdf') ? (
                                      <DocumentIcon className="text-gray-600 w-20" />
                                    ) : (
                                      <PhotoIcon className="text-gray-600 w-20" />
                                    )}
                                    <button
                                      onClick={() => {
                                        deleteFile(
                                          user?.organization?.uuid!,
                                          editingAbsenceDetails.uuid!,
                                          editingAbsenceDetails.absence_file?.uuid!,
                                        );
                                        setFiles([]);
                                        setEditingAbsenceDetails((prevDetails) => ({
                                          ...prevDetails!,
                                          absence_file: null as any | null,
                                        }));
                                      }}
                                      className="absolute right-1 top-1 text-red-500 shadow-md"
                                    >
                                      <XMarkIcon className="w-5" />
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                        <div className="mt-8 w-full flex justify-center p-2">
                          <Button
                            onClick={() => {
                              setShowListAbsences(true);
                            }}
                            buttonType={Button.Type.SECONDARY}
                          >
                            {t('cancel')}
                          </Button>
                          <Button onClick={handleSaveEditing} loading={editingAbsenceLoading}>
                            {t('save_absence')}
                          </Button>
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {formattedTimeInDate === formattedTimeOutDate ? (
                      <>
                        <div className="grid grid-cols-2">
                          <div className="flex flex-col gap-3">
                            <span className="text-gray-700 text-sm font-medium">{t('date_selected')}</span>
                            <span className="text-gray-700 text-sm font-medium">{formattedTimeInDate}</span>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="grid grid-cols-2">
                          <div className="flex flex-col gap-3">
                            <span className="text-gray-700 text-sm font-medium">{t('selected_start_date')}</span>
                            <span className="text-gray-700 text-sm font-medium">{formattedTimeInDate}</span>
                          </div>
                          <div className="flex flex-col gap-3">
                            <span className="text-gray-700 text-sm font-medium">{t('selected_end_date')}</span>
                            <span className="text-gray-700 text-sm font-medium">{formattedTimeOutDate}</span>
                          </div>
                        </div>
                      </>
                    )}

                    <span className="text-gray-700 text-sm font-medium">{t('type_of_absence')}</span>
                    <SelectPicker
                      data={optionsTypesAbsences}
                      placeholder={t('select_the_type_of_absence')}
                      placement="autoVerticalStart"
                      style={{ width: '100%' }}
                      onChange={(e) => setSelectedTypesAbsences(e)}
                      value={selectedTypesAbsences}
                    />

                    <div className="my-2">
                      <span className="text-gray-700 text-sm font-medium">{t('reason')}</span>

                      <div className="grid grid-cols-1 w-full">
                        <Textarea
                          name="motivation"
                          id="motivation"
                          ref={reasonRef}
                          onChange={validateForm}
                          placeholder={t('write_the_reason_for_your_absence')}
                        />
                        {validateFormData && (
                          <p className="text-red-500 text-sm mt-2">
                            {t('please_write_down_the_reason_for_your_absence')}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="w-full mt-2">
                      <span className="text-gray-700 text-sm font-medium">{t('justification')}</span>
                      <div {...getRootProps({ className: 'dropzone' })}>
                        <Input {...getInputProps()} />
                        <div className="border-2 border-dashed border-primary/90 flex flex-col justify-center items-center p-4 mt-2 cursor-pointer">
                          <PhotoIcon className="text-primary/90 w-12" />{' '}
                          <p className="font-medium text-sm text-gray-600 mt-1">
                            <span className="text-primary">{t('upload')} </span>
                            {t('or_drag_and_drop_a_document')}
                          </p>
                          <p className="text-xs font-normal text-primary/90">{t('file_upload_limit')}</p>
                        </div>
                      </div>
                      {files.length > 0 && (
                        <div className="flex mt-8 gap-2 w-full border p-4 overflow-x-auto items-center justify-center">
                          {files.map((file: CustomFile) => (
                            <div key={file.name}>
                              <div className="relative w-[100px] h-[100px]">
                                {file.type === 'application/pdf' ? (
                                  <DocumentIcon className="text-gray-600 w-20" />
                                ) : (
                                  <img
                                    className="w-[100px] rounded h-[100px] object-cover"
                                    src={file.preview}
                                    // Revogar o data URI após o carregamento da imagem
                                    onLoad={() => {
                                      URL.revokeObjectURL(file.preview);
                                    }}
                                  />
                                )}
                                <button
                                  onClick={() => removeFile(file.preview)}
                                  className="absolute right-1 top-1 text-red-500 shadow-md"
                                >
                                  <XMarkIcon className="w-5" />
                                </button>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                    <div className="mt-8 w-full flex justify-center p-2">
                      <Button
                        onClick={() => {
                          setShowListAbsences(true);
                        }}
                        buttonType={Button.Type.SECONDARY}
                      >
                        {t('cancel')}
                      </Button>
                      <Button onClick={handleSave} disabled={isDisabledButton} loading={createAbsenceLoading}>
                        {t('request_absence')}
                      </Button>
                    </div>
                  </>
                )}
              </>
            )}
          </section>
        </div>
      </section>
    </>
  );
}

export default AbsenceSection;
