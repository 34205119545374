import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useAuth } from '@hooks/auth/useAuth';
import Button from '@components/Common/Buttons/Button';
import Input from '@components/Inputs';
import signInLogo from '../../assets/images/login-screen-logo.svg';
import { classNames } from '@components/Badges/StatusBadge';
import { useTranslation } from 'react-i18next';

export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [mensageError, setMensageError] = useState('');
  const { signIn, isAuthenticated, user } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);

  const { t } = useTranslation();
  useEffect(() => {
    if (isAuthenticated) {
      navigate(`/organizations/${user?.organization?.uuid}/dashboard`);
    }
  }, [isAuthenticated, navigate, user]);

  async function handleSignIn(e: React.FormEvent) {
    e.preventDefault();

    if (email && password) {
      try {
        setLoading(true);
        await signIn(email, password);
        setLoading(true);

        if (isAuthenticated) {
          const organizationUuid = user?.organization?.uuid;
          if (user?.roles && user!?.roles[0].name === 'superAdmin') {
            navigate(`/dashboard`);
          } else {
            navigate(`/organizations/${organizationUuid}/dashboard`);
          }
        } else {
          setMensageError('Erro ao realizar login');
        }
      } catch (error: any) {
        setLoading(false);

        setMensageError(error.response.data.message);
        console.error(error);
      }
    } else {
      setMensageError('Preencha todos os campos!');
    }
  }

  return (
    <main className="flex flex-col h-screen w-full items-center justify-center bg-gray-50 gap-8">
      <a href="/" className="max-md:max-w-[200px] lg:max-w-xs w-full">
        <img src={signInLogo} alt="logo" className={classNames('w-full')} />
      </a>

      <div className="flex flex-col justify-center items-center gap-2">
        <h1 className="text-black font-bold text-3xl">{t('login')}</h1>
        {/* <Link to="#" className="text-sm font-medium text-primary hover:brightness-[.95]">
                    Criar nova conta
                </Link> */}
      </div>
      <form className="w-full flex flex-col px-6 max-w-[450px]">
        <Input
          className="border w-full p-3 rounded-t-lg focus:border-primary rounded-b-none"
          placeholder="Email address"
          type="text"
          onChange={(e) => setEmail(e.target.value)}
        />
        <Input
          className="border w-full p-3 rounded-b-lg focus:border-primary rounded-t-none border-t-0"
          placeholder="Password"
          type="password"
          onChange={(e) => setPassword(e.target.value)}
        />
        {mensageError && <p className="text-red-500 mt-2">{mensageError}</p>}
        <div className="mt-8 flex justify-between">
          <div className="flex gap-2 items-center">
            <input
              type="checkbox"
              className="border-primary/90 text-primary ring-primary border-primary"
              name="Remember login"
              id="rememberLogin"
            />
            <p className="text-sm font-normal">{t('remember_login')}</p>
          </div>
          <Link to="password/forget" className="text-light-blue-500 hover:brightness-[.95] text-sm">
            {t('password_recovery')}
          </Link>
        </div>
        {/* <Link to="/dashboard">
                    <input
                        className="bg-primary cursor-pointer hover:brightness-[.95] text-white font-medium w-full p-3 rounded-md transition-all mt-8"
                        placeholder="Log in"
                        type="submit"
                        value={"Log in"}
                    />
                </Link> */}
        <Button onClick={handleSignIn} loading={loading} className="mt-8 w-full">
          {t('login')}
        </Button>
      </form>
    </main>
  );
}
