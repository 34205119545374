import React, { useEffect, useState } from 'react';

import { useOrganization } from '../../hooks/organization/useOrganization';

import viewIcon from '../../assets/icons/view.svg';
// import editIcon from "../../assets/icons/edit.svg";
// import deleteIcon from "../../assets/icons/delete.svg";
import searchIcon from '../../assets/icons/search.svg';
import { ButtonDefault } from '../../components/Buttons/ButtonDefault';
import { ModalDelete } from '../../components/Modal/ModalDelete';
import Input from '@components/Inputs';

type ModalDeleteState = Record<string, boolean>;

export default function ListOrganizations() {
  const [modalDeleteState, setModalDeleteState] = useState<ModalDeleteState>({});

  const { organizations, getOrganizations, deleteOrganization } = useOrganization();

  useEffect(() => {
    getOrganizations('sortBy=created_at&sortDirection=desc');
  }, []);

  // const handleDeleteButtonClick = (id: any) => {
  //     setModalDeleteState((prevState) => ({
  //         ...prevState,
  //         [id]: true
  //     }));
  // };

  return (
    <main className="">
      <div className="flex flex-col mb-11">
        <div className="flex flex-col gap-2">
          <h1 className="text-4xl text-gray-800 font-bold">Organizações</h1>
          <h2 className="text-gray-600 text-base">Aqui você pode administrar suas organizações.</h2>
        </div>
        <div className="mt-9 flex lg:justify-end justify-between gap-3">
          <div className="border border-solid border-primary/90 rounded-full flex gap-1 px-3 py-1">
            <Input type="search" name="search" id="search" placeholder="Pesquisar..." />
            <img src={searchIcon} alt="Search icon" className="w-5" />
          </div>
          <ButtonDefault href="/organization/create" content="Nova Organização" />
        </div>
        <div className="overflow-x-auto shadow-lg mt-4 rounded-lg">
          <table className="table-auto w-full rounded-lg">
            <thead className="bg-gray-200 text-gray-800">
              <tr className="">
                <th className="py-2 px-4 text-start">Nome</th>
                <th className="hidden lg:table-cell py-2 px-4 text-start">Email</th>
                <th className="hidden lg:table-cell py-2 px-4 text-start">Phone</th>
                <th className="hidden lg:table-cell py-2 px-4 text-start">Linguagem</th>
                <th className="hidden lg:table-cell py-2 px-4 text-start">Imagem</th>
                <th className="py-2 px-4 text-start"></th>
              </tr>
            </thead>
            <tbody className="text-gray-800">
              {organizations.map((organization, index) => (
                <React.Fragment key={organization.id}>
                  <tr key={organization.id} className={index % 2 === 0 ? 'bg-gray-50' : ' bg-gray-100'}>
                    <td className="py-2 px-4">{organization.name}</td>
                    <td className="hidden lg:table-cell py-2 px-4">{organization.email}</td>
                    <td className="hidden lg:table-cell py-2 px-4">{organization.phone}</td>
                    <td className="hidden lg:table-cell py-2 px-4">{organization.language}</td>
                    <td className="hidden lg:table-cell py-2 px-4">
                      <img
                        className="h-8 w-8 rounded-full object-cover"
                        src={`${import.meta.env.VITE_API_URL}storage/images/organization/${organization.image}`}
                        alt="Image"
                      />
                    </td>
                    <td className="py-2 px-4 lg:flex table gap-4 justify-end">
                      <a href={`/organization/${organization.uuid}`}>
                        <img src={viewIcon} alt="View" className="mt-2" />
                      </a>
                      {/* <a href={`/organization/${organization.uuid}/edit`}>
                                                    <img src={editIcon} alt="Edit" />
                                                </a>
                                                <a
                                                    className="cursor-pointer"
                                                    onClick={() => handleDeleteButtonClick(organization.id)}
                                                >
                                                    <img src={deleteIcon} alt="Delete" />
                                                </a> */}
                    </td>
                  </tr>
                  {modalDeleteState[organization.id!] && (
                    <ModalDelete
                      onConfirm={async () => {
                        await deleteOrganization(organization.uuid!);
                        await getOrganizations('sortBy=created_at&sortDirection=desc');
                      }}
                      isOpen={modalDeleteState[organization.id!]}
                      onClose={() => {
                        setModalDeleteState((prevState) => ({
                          ...prevState,
                          [organization.id!]: false,
                        }));
                      }}
                      title="Apagar Organização"
                      content={`Tem certeza que deseja excluir a Organização ${organization.name}?`}
                    />
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </main>
  );
}
