import { useTranslation } from 'react-i18next';
import { XMarkIcon } from '@heroicons/react/24/solid';
import StackedButton from '@components/StackedList/StackedButton';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  content: React.ReactNode;
  onConfirm(): void;
}

export function ModalDelete({ isOpen, onClose, title, content, onConfirm }: ModalProps) {
  const { t } = useTranslation();
  const closeModal = () => {
    onClose();
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className="fixed inset-0 bg-[#414241] bg-opacity-20 transition-opacity backdrop-filter backdrop-blur-[3px] z-30 cursor-pointer">
      <div
        className="fixed top-[15%]  left-1/2 transform -translate-x-1/2 translate-y-[15%] z-10 w-96 cursor-default overflow-y-auto px-3"
        onClick={closeModal}
      >
        <div className="w-full max-w-[1060px] rounded-xl bg-gray-50 text-black p-4">
          <div className="flex justify-between gap">
            <p className="text-xl font-bold text-blue-900">{title}</p>
            <button onClick={closeModal}>
              <XMarkIcon className="w-6 text-blue-900" />
            </button>
          </div>
          <p className="my-2 text-blue-900">{content}</p>
          <div className="mt-5 flex gap-4">
            <StackedButton className="w-full" onClick={closeModal}>
              {t('cancel')}
            </StackedButton>
            <StackedButton theme="error" className="w-full" onClick={onConfirm}>
              {t('delete')}
            </StackedButton>
          </div>
        </div>
      </div>
    </div>
  );
}
