import { create } from "zustand";
import { CustomFile } from "../../@types/CustomFile";

type SpendingState = {
    files: CustomFile[];
    setFiles: (value: any) => void;
    removeFile(state: any): void;
    clearConfigSpending(): void;
};

export const useSpending = create<SpendingState>((set) => ({
    files: [],
    setFiles: (value: CustomFile[]) => set({ files: value }),
    removeFile: (identificator: string) => {
        set((state) => ({
            files: state.files.filter(
                (file: any) => file.preview !== identificator
            ),
        }));
    },
  

    clearConfigSpending: () =>
        set({
           files: [],
        }),

}));
