import { HTMLAttributes } from 'react';
import logo from './images/fastdoc-colored.svg';
import { classNames } from '@components/Badges/StatusBadge';

interface FastdocLogProps extends HTMLAttributes<HTMLImageElement> {}

function FastdocLog({ className, ...props }: FastdocLogProps) {
  return <img src={logo} alt="logo" className={classNames('w-full max-w-[30px]', className)} {...props} />;
}

export default FastdocLog;
