import { classNames } from '@components/Badges/StatusBadge';
import { HTMLAttributes } from 'react';

export const HolidayMark = () => <div className="bg-accent w-[13px] h-[13px] rounded-full" />;

export const VacationMark = ({ children, className, ...props }: HTMLAttributes<HTMLDivElement>) => (
  <div className={classNames('flex flex-col items-center justify-center relative', className)} {...props}>
    {children && <span className="absolute text-white text-[10px] z-10">{children}</span>}
    <div className="bg-green w-[13px] h-[13px] rounded-full" />{' '}
  </div>
);

export const AbsenceMark = ({ children, className, ...props }: HTMLAttributes<HTMLDivElement>) => (
  <div className={classNames('flex flex-col items-center justify-center relative', className)} {...props}>
    {children && <span className="absolute text-white text-[10px] z-10">{children}</span>}
    <div className="bg-lilac w-[13px] h-[13px] rounded-full" />{' '}
  </div>
);

export const ExpenseMark = ({ children, className, ...props }: HTMLAttributes<HTMLDivElement>) => (
  <div className={classNames('flex flex-col items-center justify-center relative', className)} {...props}>
    {children && <span className="absolute text-white text-[10px] z-10">{children}</span>}
    <div className="bg-red-600 w-[13px] h-[13px] rounded-full" />
  </div>
);

export const DisplacementMark = ({ children, className, ...props }: HTMLAttributes<HTMLDivElement>) => (
  <div className={classNames('flex flex-col items-center justify-center relative', className)} {...props}>
    {children && <span className="absolute text-white text-[10px] z-10">{children}</span>}
    <div className="bg-gray-600 w-[13px] h-[13px] rounded-full" />
  </div>
);

export const PresenceMark = ({ children, className, ...props }: HTMLAttributes<HTMLDivElement>) => (
  <div className={classNames('flex flex-col items-center justify-center relative', className)} {...props}>
    {children && <span className="absolute text-white text-[10px] z-10">{children}</span>}
    <div className="bg-orange-600 w-[13px] h-[13px] rounded-full" />
  </div>
);
