import { Month, NextMonth, PrevMonth } from '@components/Calendar';
import { HolidayMark } from '@components/Calendar/Summary';
import { PresenceSummary } from '@components/Calendar/Summary/PresenceSummary';
import Button from '@components/Common/Buttons/Button';
import NavbarHolidays from '@components/Holidays/NavbarHolidays';
import Input from '@components/Inputs';
import { ModalDelete } from '@components/Modal/ModalDelete';
import Loading from '@components/loading';
import { PencilIcon, TrashIcon } from '@heroicons/react/24/solid';
import { useAuth } from '@hooks/auth/useAuth';
import useAxios from '@hooks/axios/useAxios';
import { useOrganization } from '@hooks/organization/useOrganization';
import { format, parse } from 'date-fns';
import { values } from 'lodash';
import moment from 'moment';
import { useCallback, useRef, useState } from 'react';
import { DayPickerSingleDateController } from 'react-dates';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

export default function HolidaysOrganization() {
  const { user } = useAuth();
  const [showListHolidays, setShowListHolidays] = useState(true);
  const [isCreateHolidays, setIsCreateHolidays] = useState(false);
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  const { locationId } = useParams<{ locationId: string }>();
  const { getOrganizationHolidays } = useOrganization();
  const [focusedInput, setFocusedInput] = useState<boolean>(false);

  const { loading: createHolidayLoading, request: createHolidayRequest } = useAxios();
  const { loading: queryHolidayDetailsLoading, request: queryHolidayDetailsRequest } = useAxios();
  const { loading: editHolidayLoading, request: editHolidayRequest } = useAxios();
  const { request: deleteHolidayRequest } = useAxios();

  const { isLoading: queryHolidaysLoading, data: localHolidays } = useQuery<any[]>(['holidays', user], () =>
    getOrganizationHolidays(user?.organization?.uuid!),
  );

  const [holidayDateState, setHolidayDateState] = useState<moment.Moment>(moment());

  const [nameHolidays, setNameHolidays] = useState('');
  const [locationUuid, setLocationUuid] = useState('');
  const nameRef = useRef<HTMLInputElement>(null);

  const handleDeleteButtonClick = () => {
    setIsModalDeleteOpen(true);
  };

  const queryClient = useQueryClient();

  const createHolidayMutation = useMutation({
    mutationFn: async () => {
      const form = new FormData();
      form.append('name', nameRef.current?.value!);
      form.append('date', String(holidayDateState ? format(holidayDateState.toDate(), 'yyyy-MM-dd') : undefined)!);
      form.append('year', String(holidayDateState ? format(holidayDateState.toDate(), 'yyyy') : undefined)!);
      form.append('locations_id', locationId!);

      return createHolidayRequest(`/api/organizations/${user?.organization?.uuid!}/holidays`, 'POST', form)
        .then(async () => {
          toast.success('Feriado criado com sucesso!');
          setIsCreateHolidays(false);
          setShowListHolidays(true);
        })
        .catch(({ response }) => {
          values(response?.data?.errors)?.map((errors: any) => errors?.map((error: any) => toast.error(error)));
        });
    },
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: ['holidays'] });
    },
  });

  function handleEditHolidayClick(holidayUuid: string) {
    setShowListHolidays(false);
    setIsCreateHolidays(false);

    queryHolidayDetailsRequest(`/api/organizations/${user?.organization?.uuid!}/holidays/${holidayUuid}`).then(
      ({ data }) => {
        const holidayDetails = data.content.data;
        setHolidayDateState(moment(holidayDetails.date!));
        setNameHolidays(holidayDetails.name);
        setLocationUuid(holidayDetails.uuid);
      },
    );
  }

  const editHolidayMutation = useMutation({
    mutationFn: async () => {
      const form = new FormData();
      form.append('name', nameHolidays);
      form.append('date', String(holidayDateState ? format(holidayDateState.toDate(), 'yyyy-MM-dd') : undefined)!);
      form.append('year', String(holidayDateState ? format(holidayDateState.toDate(), 'yyyy') : undefined)!);
      form.append('locations_id', locationId!);
      form.append('_method', 'PUT');

      return editHolidayRequest(
        `/api/organizations/${user?.organization?.uuid!}/holidays/${locationUuid}`,
        'POST',
        form,
      )
        .then(async () => {
          toast.success('Feriado atualizado com sucesso!');
          setShowListHolidays(true);
          setIsCreateHolidays(false);
          //await getCurrentHolidays(dateSelected!);
        })
        .catch(({ response }) => {
          values(response?.data?.errors)?.map((errors: any) => errors?.map((error: any) => toast.error(error)));
        });
    },
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: ['holidays'] });
    },
  });

  const deleteHolidayMutation = useMutation({
    mutationFn: async ({ organizationUuid, holidayUuid }: { organizationUuid: string; holidayUuid: string }) =>
      deleteHolidayRequest(`/api/organizations/${organizationUuid}/holidays/${holidayUuid}`, 'DELETE')
        .then(() => {
          toast.success('Feriado excluído com sucesso!');
        })
        .catch(({ response }) => {
          values(response?.data?.errors)?.map((errors: any) => errors?.map((error: any) => toast.error(error)));
        }),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: ['holidays'] });
    },
  });

  const handleCompareIfDateSelected = useCallback(
    (item: moment.Moment) => {
      return (
        localHolidays?.some?.((holiday: any) => {
          const selectedHoliday = parse(holiday.date, 'yyyy-MM-dd HH:mm:ss', new Date());

          return item.isSame(selectedHoliday, 'day');
        }) ?? false
      );
    },
    [localHolidays],
  );

  return (
    <>
      <main className="max-w-[800px] w-full flex flex-col mt-4 mx-auto md:shadow-md px-8 pb-10 rounded bg-gray-50">
        <NavbarHolidays />
        {/* <Outlet /> */}

        <div className="w-full mt-4 mx-auto px-2 max-w-md">
          {queryHolidaysLoading && <Loading className="w-full mx-auto" />}
          <div className="flex items-center justify-center w-full">
            <DayPickerSingleDateController
              daySize={48}
              navNext={<NextMonth />}
              navPrev={<PrevMonth />}
              renderMonthElement={Month}
              initialVisibleMonth={() => moment()}
              noBorder
              verticalBorderSpacing={5}
              isDayHighlighted={handleCompareIfDateSelected}
              isDayBlocked={handleCompareIfDateSelected}
              date={holidayDateState}
              onFocusChange={({ focused }) => {
                setFocusedInput(focused);
              }}
              focused={focusedInput}
              renderCalendarInfo={() => <PresenceSummary />}
              onDateChange={(date: any) => {
                const isDateSelectedOnHolidayRange = localHolidays?.some?.((holiday: any) => {
                  const selectedHoliday = parse(holiday.date, 'yyyy-MM-dd HH:mm:ss', new Date());

                  return date.isSame(selectedHoliday);
                });

                if (!isDateSelectedOnHolidayRange) {
                  setHolidayDateState(date);
                  return;
                }
              }}
              renderDayContents={(day) => {
                const dateHolidaySelected = localHolidays?.some?.((holiday: any) => {
                  const selectedHoliday = parse(holiday.date, 'yyyy-MM-dd HH:mm:ss', new Date());

                  return day.isSame(moment(selectedHoliday, 'MM/D/YYYY'), 'date');
                });

                if (dateHolidaySelected) {
                  return (
                    <div className="flex flex-col items-center justify-center gap-2">
                      {day.format('D')}
                      <div className="flex flex-row gap-1 items-center">{dateHolidaySelected && <HolidayMark />}</div>
                    </div>
                  );
                }
                return day.format('D');
              }}
            />
          </div>

          {showListHolidays ? (
            <>
              <div className="w-full gap-3 flex flex-col">
                {localHolidays?.map((holiday) => {
                  const dateParts = holiday.date!.split(' ')[0].split('-');
                  const formattedDate = `${dateParts[0]}/${dateParts[1]}/${dateParts[2]}`;
                  return (
                    <div key={holiday.id} className="flex justify-between border-b-2 border-solid border-gray-200">
                      <div className="flex flex-col">
                        <span className="text-gray-700 text-sm font-medium">{formattedDate}</span>
                        <p
                          className={`text-gray-700 text-xl font-medium ${
                            moment().isSame(moment(holiday.date), 'day') ||
                            (moment().isAfter(moment(holiday.date), 'day') && 'line-through')
                          }`}
                        >
                          {holiday.name}
                        </p>
                      </div>
                      <div className="flex gap-2">
                        <button
                          onClick={() => handleEditHolidayClick(holiday.uuid!)}
                          className="text-primary hover:text-primary/80"
                        >
                          <PencilIcon className="w-5" />
                        </button>
                        <button
                          onClick={() => {
                            handleDeleteButtonClick();
                          }}
                          className="text-red-600 hover:text-red-700"
                        >
                          <TrashIcon className="w-5" />
                        </button>
                        {isModalDeleteOpen && (
                          <ModalDelete
                            isOpen={isModalDeleteOpen}
                            onClose={() => setIsModalDeleteOpen(false)}
                            title="Apagar Feriado"
                            content={`Tem certeza que deseja excluir o feriado?`}
                            onConfirm={() => {
                              deleteHolidayMutation.mutate({
                                holidayUuid: holiday.uuid!,
                                organizationUuid: user?.organization?.uuid!,
                              });
                              setIsModalDeleteOpen(false);
                            }}
                          />
                        )}
                      </div>
                    </div>
                  );
                })}

                <Button
                  className="font-normal sticky bottom-5 m-2"
                  onClick={() => {
                    setShowListHolidays(false);
                    setIsCreateHolidays(true);
                  }}
                  // loading={createAbsenceLoading}
                >
                  Adicionar Feriado
                </Button>
              </div>
            </>
          ) : (
            <>
              {isCreateHolidays ? (
                <>
                  <div className="w-full gap-3 flex flex-col">
                    <div>
                      <span className="text-gray-700 text-sm font-medium">Dia selecionado para o feriado</span>
                      <p className="text-gray-700 text-base font-medium">
                        {holidayDateState.toDate()?.getDate()}/{holidayDateState.toDate()!.getMonth() + 1}/
                        {holidayDateState.toDate()?.getFullYear()}
                      </p>
                    </div>
                    <div className="mb-3">
                      <span className="text-gray-700 text-sm font-medium">Nome</span>
                      <div className="grid grid-cols-1 w-full">
                        <Input
                          type="text"
                          name="name"
                          id="name"
                          ref={nameRef}
                          placeholder="Escreva o nome do feriado"
                          className={`resize-none bg-gray-50 border border-primary/90 w-full rounded-lg max-h-36 shadow-md p-2`}
                        />
                      </div>
                    </div>
                    <div className="flex">
                      <Button
                        buttonType={Button.Type.SECONDARY}
                        onClick={() => {
                          setShowListHolidays(true);
                          // setIsEditingAbsence(false)
                        }}
                      >
                        Cancelar
                      </Button>
                      <Button onClick={() => createHolidayMutation.mutate()} loading={createHolidayLoading}>
                        Criar Feriado
                      </Button>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {queryHolidayDetailsLoading ? (
                    <Loading className="w-full mx-auto" />
                  ) : (
                    <>
                      <div className="w-full gap-3 flex flex-col">
                        <div>
                          <span className="text-gray-700 text-sm font-medium">Dia selecionado para o feriado</span>
                          <p className="text-gray-700 text-base font-medium">
                            {holidayDateState.toDate()?.getDate()}/{holidayDateState.toDate()!.getMonth() + 1}/
                            {holidayDateState.toDate()?.getFullYear()}
                          </p>
                        </div>
                        <div className="mb-3">
                          <span className="text-gray-700 text-sm font-medium">Nome</span>
                          <div className="grid grid-cols-1 w-full">
                            <Input
                              type="text"
                              name="name"
                              id="name"
                              value={nameHolidays}
                              onChange={(e) => setNameHolidays(e.target.value)}
                              placeholder="Escreva o nome do feriado"
                            />
                          </div>
                        </div>
                        <div className="flex">
                          <Button
                            buttonType={Button.Type.SECONDARY}
                            onClick={() => {
                              setIsCreateHolidays(false);
                              setShowListHolidays(true);
                            }}
                          >
                            Cancelar
                          </Button>
                          <Button onClick={() => editHolidayMutation.mutate()} loading={editHolidayLoading}>
                            Salvar Feriado
                          </Button>
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </main>
    </>
  );
}
