import { createBrowserRouter, createRoutesFromElements, Outlet, Route } from 'react-router-dom';
import ProtectedRoutes from './ProtectedRoutes';

import {
  Login,
  Forget,
  Reset,
  Dashboard,
  Presences,
  Expenses,
  Requests,
  Profile,
  Location,
  LocationCreate,
  LocationRead,
  LocationEdit,
  Organization,
  OrganizationCreate,
  OrganizationEdit,
  OrganizationRead,
  HolidaysOrganization,
  Users,
  UserCreate,
  UserRead,
  UserEdit,
} from './routes';
import ExpensesSection from '@components/Expenses/Sections/ExpensesSection';
import DisplacementsSection from '@components/Expenses/Sections/DisplacementsSection';
import ReportPage from '@pages/Reports/ReportPage';
import VacancyReportSection from '@pages/Reports/VacancyReportSection';
import DisplacementsReportSection from '@pages/Reports/DisplacementsReportSection';
import ExpensesReportSection from '@pages/Reports/ExpensesReportSection';
import EditProfile from '@pages/Profile/EditProfile';
import PasswordProfile from '@pages/Profile/PasswordProfile';
import RequestsUser from '@pages/Profile/RequestsUser';
import FastdocLayout from 'layouts/FastdocLayout';
import AbsenceReportSection from '@pages/Reports/AbsenceReportSection';
import Language from '@pages/Profile/Language';
import PointSection from '@components/Presence/Sections/PointSection';
import AbsenceSection from '@components/Presence/Sections/AbsenceSection';
import VacationSection from '@components/Presence/Sections/VacationSection';
import AbsenceRequests from '@components/Requests/Sections/AbsenceSection';
import VacationRequests from '@components/Requests/Sections/VacationSection';
import PresenceSection from '../components/Requests/Sections/PresenceSection';
import AccountSettings from '@pages/Profile/AccountSettings';
import ReadExpense from '@pages/ReadExpense';
import ReadQrCode from '@pages/ReadPoint/ReadQrCode';
import ReadRemote from '@pages/ReadPoint/ReadRemote';

export const routes = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Outlet />}>
      <Route index element={<Login />} />
      <Route path="password/forget" element={<Forget />} />
      <Route path="password/reset" element={<Reset />} />

      {/* Protected routes */}
      <Route element={<ProtectedRoutes />}>
        <Route path="dashboard" element={<FastdocLayout />}>
          <Route path="" element={<Dashboard />} />
          <Route path="organizations" element={<Organization />} />
          <Route path="organization/create" element={<OrganizationCreate />} />
          <Route path="organization/:id/edit" element={<OrganizationEdit />} />
          <Route path="organization/:id" element={<OrganizationRead />} />
        </Route>
        <Route path="organizations/:organizationUuid">
          <Route path="dashboard" element={<FastdocLayout />}>
            <Route path="" element={<Dashboard />} />
            <Route path="read-point" element={<ReadQrCode />} />
            <Route path="read-point/remote" element={<ReadRemote />} />
            <Route path="read-expense" element={<ReadExpense />} />
            <Route path="presences/user/:id" element={<Presences />}>
              <Route path="" element={<PointSection />} />
              <Route path="absences" element={<AbsenceSection />} />
              <Route path="vacations" element={<VacationSection />} />
            </Route>
            <Route path="expenses" element={<Expenses />}>
              <Route path="all" element={<ExpensesSection />} />
              <Route path="displacements" element={<DisplacementsSection />} />
            </Route>
            <Route path="reports" element={<ReportPage />}>
              <Route path="" element={<DisplacementsReportSection />} />
              <Route path="displacements" element={<DisplacementsReportSection />} />
              <Route path="expenses" element={<ExpensesReportSection />} />
              <Route path="vacations" element={<VacancyReportSection />} />
              <Route path="absences" element={<AbsenceReportSection />} />
            </Route>

            <Route path="requests" element={<Requests />}>
              <Route path="" element={<AbsenceRequests />} />
              <Route path="absences" element={<AbsenceRequests />} />
              <Route path="vacations" element={<VacationRequests />} />
              <Route path="presences" element={<PresenceSection />} />
            </Route>

            <Route path="profile" element={<Profile />}>
              <Route path="" element={<AccountSettings />} />
              <Route path="preferences" element={<AccountSettings />} />

              <Route path="account" element={<EditProfile />} />
              <Route path="password" element={<PasswordProfile />} />
              <Route path="requests" element={<RequestsUser />} />
              <Route path="language" element={<Language />} />
            </Route>
            <Route path="locations" element={<Location />} />
            <Route path="location/create" element={<LocationCreate />} />
            <Route path="location/:locationUuid" element={<LocationRead />} />
            <Route path="location/:locationId/holidays" element={<HolidaysOrganization />} />
            <Route path="location/:locationUuid/edit" element={<LocationEdit />} />
            <Route path="organizations" element={<Organization />} />
            <Route path="organization/create" element={<OrganizationCreate />} />
            <Route path="organization/:id/edit" element={<OrganizationEdit />} />
            <Route path="organization/:id" element={<OrganizationRead />} />
            <Route path="users" element={<Users />} />
            <Route path="user/create" element={<UserCreate />} />
            <Route path="user/:id/edit" element={<UserEdit />} />
            <Route path="user/:id" element={<UserRead />} />
          </Route>
        </Route>
      </Route>
    </Route>,
  ),
);
