import { useState, useRef, useEffect } from 'react';
import { useUser } from '@hooks/user/useUser';
import { useAuth } from '@hooks/auth/useAuth';
import { useTranslation } from 'react-i18next';

import imgDefault from '@assets/images/user-img-default.jpg';
import Button from '@components/Common/Buttons/Button';
import { useNavigate } from 'react-router-dom';
import { SelectPicker } from 'rsuite';
import DateRangerPicker from '@components/DatePicker';
import { useLocation } from '@hooks/location/useLocation';
import { toast } from 'react-toastify';
import Input from '@components/Inputs';
import { keys, values } from 'lodash';
import { WorkType } from './EditUser';

export interface OptionsProps {
  value: string;
  label: string;
}

interface DateRange {
  startDate: string;
  endDate: string;
}

export default function CreateUsers() {
  const { t } = useTranslation();
  const { locations, getLocations } = useLocation();
  const { createUser } = useUser();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [roleSelected, setRoleSelected] = useState('');
  const [languageSelected, setLanguageSelected] = useState('');
  const [locationSelected, setLocationSelected] = useState('');

  const [isDisabledButton, setIsDisabledButton] = useState(true);
  const [createUserLoading, setCreateUserLoading] = useState(false);

  const [startDateSelected, setStartDateSelected] = useState<[Date, Date] | null>(null);
  const [endDateSelected, setEndDateSelected] = useState<[Date, Date] | null>(null);
  const [startDateRangeSelected, setStartDateRangeSelected] = useState<DateRange | null>(null);
  const [endDateRangeSelected, setEndDateRangeSelected] = useState<DateRange | null>(null);

  const [workTypeSelected, setWorkTypeSelected] = useState<string>();

  const nameRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const confirmPasswordRef = useRef<HTMLInputElement>(null);
  const imageRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    getLocations(user?.organization?.uuid!, '&take=10&page=1&sortBy=created_at&sortDirection=desc');
  }, []);

  const locationOptions = locations.map((location) => ({
    value: location.id,
    label: location.name,
  }));

  const rolesOptionsSuperAdmin: OptionsProps[] = [
    { value: '1', label: 'Super Admin' },
    { value: '2', label: 'Admin' },
    { value: '3', label: 'Employee' },
  ];

  const rolesOptionsAdmin: OptionsProps[] = [
    { value: '2', label: 'Admin' },
    { value: '3', label: 'Employee' },
  ];

  const rolesOptions: OptionsProps[] =
    user?.roles && user.roles.length > 0 && user.roles[0].name === 'superAdmin'
      ? rolesOptionsSuperAdmin
      : rolesOptionsAdmin;

  const languageOptions: OptionsProps[] = [
    { value: '1', label: 'PT' },
    { value: '2', label: 'EN' },
  ];

  const workTypeOptions: OptionsProps[] = values(WorkType).map((workType, index) => ({
    value: keys(WorkType)[index],
    label: t(workType),
  }));

  const handleSelectChangeRole = (value: string | null) => {
    if (value) {
      setRoleSelected(value);
    }
  };

  const handleSelectChangeLanguage = (value: string | null) => {
    if (value) {
      setLanguageSelected(value);
    }
  };

  const handleLocationChange = (value: string | null) => {
    if (value) {
      setLocationSelected(value);
    }
  };

  function handleImageChange(event: any) {
    const imgElement = document.getElementById('preview-img');
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      if (imgElement instanceof HTMLImageElement && reader.result) {
        imgElement.src = reader.result.toString();
      }
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  }

  function validateFields() {
    const name = nameRef.current?.value;
    const email = emailRef.current?.value;
    const password = passwordRef.current?.value;
    const confirmPassword = confirmPasswordRef.current?.value;

    const isNameValid = !!name && name.trim() !== '';
    const isEmailValid = !!email && email.trim() !== '';
    const isPasswordValid = !!password && password.trim() !== '';
    const isConfirmPasswordValid = !!confirmPassword && confirmPassword.trim() !== '';

    const isFormValid = isNameValid && isEmailValid && isPasswordValid && isConfirmPasswordValid;

    setIsDisabledButton(!isFormValid);

    return isFormValid;
  }

  function handleSave() {
    setCreateUserLoading(true);
    if (!validateFields()) {
      return;
    }

    const file = imageRef.current?.files?.[0] ?? null;

    createUser(
      {
        name: nameRef.current!.value,
        email: emailRef.current!.value,
        password: passwordRef.current!.value,
        password_confirmation: confirmPasswordRef.current!.value,
        roles: roleSelected,
        organization_id: user?.organization?.id,
        location_id: locationSelected,
        user_language_id: languageSelected,
        start_work_date: startDateSelected ? startDateSelected[0]?.toISOString().split('T')[0] : null,
        end_work_date: endDateSelected ? endDateSelected[0]?.toISOString().split('T')[0] : '',
        work_type: workTypeSelected,
      },
      file,
    )
      .then(() => {
        toast.success(t('user_created_successfully'));
        navigate(`/organizations/${user?.organization?.uuid}/dashboard/users`);
      })
      .finally(() => {
        setCreateUserLoading(false);
      });
  }

  return (
    <main className="px-4">
      <div className="flex flex-col lg:max-w-5xl md:max-w-3xl max-w-lg w-full mx-auto">
        <div className="flex flex-col gap-2">
          <h1 className="text-4xl text-gray-800 font-bold">{t('new_user')}</h1>
          <h2 className="text-gray-600 text-base">{t('here_you_can_add_a_new_user')}.</h2>
        </div>
      </div>
      <div className="mt-9 text-gray-600 bg-gray-100 rounded-lg shadow-lg p-4 mb-11 lg:max-w-5xl md:max-w-3xl max-w-lg w-full mx-auto">
        <div className="w-full grid lg:grid-cols-4 grid-cols-1 gap-2 mb-3">
          <div>
            <label htmlFor="" className="font-bold">
              {t('name')}*
            </label>
            <Input placeholder="John Doe" type="text" name="name" ref={nameRef} onChange={validateFields} />
          </div>
          <div>
            <label htmlFor="" className="font-bold">
              {t('email')}*
            </label>
            <Input
              placeholder="jhondoe@example.com"
              type="email"
              name="email"
              ref={emailRef}
              onChange={validateFields}
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="" className="font-bold">
              {t('role')}*
            </label>
            <SelectPicker
              data={rolesOptions}
              value={roleSelected}
              onChange={handleSelectChangeRole}
              style={{ width: '100%' }}
              searchable={false}
              size="lg"
              placeholder={t('select_role')}
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="" className="font-bold">
              {t('language')}*
            </label>
            <SelectPicker
              data={languageOptions}
              value={languageSelected}
              onChange={handleSelectChangeLanguage}
              style={{ width: '100%' }}
              searchable={false}
              size="lg"
              placeholder={t('select_language')}
            />
          </div>
        </div>

        <div className="w-full grid md:grid-cols-3 grid-cols-1 gap-2 mb-3">
          <div className="flex flex-col">
            <label htmlFor="" className="font-bold">
              {t('location')}*
            </label>
            <SelectPicker
              data={locationOptions}
              value={locationSelected}
              onChange={handleLocationChange}
              style={{ width: '100%' }}
              searchable={false}
              size="lg"
              placeholder={t('select_location')}
            />
          </div>
          <div>
            <label htmlFor="" className="font-bold">
              Password*
            </label>
            <Input placeholder="********" type="password" name="password" ref={passwordRef} onChange={validateFields} />
          </div>
          <div>
            <label htmlFor="" className="font-bold">
              {t('confirm')} Password*
            </label>
            <Input
              placeholder="********"
              type="password"
              name="confirmPassword"
              ref={confirmPasswordRef}
              onChange={validateFields}
            />
          </div>
        </div>
        <div className="w-full grid md:grid-cols-2 grid-cols-1 gap-2 mb-3">
          <div className="flex flex-col">
            <label htmlFor="" className="font-bold">
              {t('start_work_date')}
            </label>
            <DateRangerPicker
              typeDate="date"
              dateSelected={startDateSelected}
              setDateSelected={setStartDateSelected}
              setDateRangeSelected={setStartDateRangeSelected}
              dateRangeSelected={startDateRangeSelected}
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="" className="font-bold">
              {t('end_work_date')}
            </label>
            <DateRangerPicker
              typeDate="date"
              dateSelected={endDateSelected}
              setDateSelected={setEndDateSelected}
              setDateRangeSelected={setEndDateRangeSelected}
              dateRangeSelected={endDateRangeSelected}
            />
          </div>
        </div>

        <div className="w-full grid md:grid-cols-2 grid-cols-1 gap-2 mb-3">
          <div className="flex flex-col">
            <label htmlFor="" className="font-bold">
              {t('work_type')}
            </label>

            <SelectPicker
              data={workTypeOptions}
              value={workTypeSelected}
              onChange={(value) => setWorkTypeSelected(value as WorkType)}
              style={{ width: '100%' }}
              searchable={false}
              size="lg"
              placeholder={t('work_type')}
            />
          </div>
        </div>
        <div className="w-full grid grid-cols-1 gap-2 mt-3">
          <div className="flex gap-4 items-center">
            <img id="preview-img" className="h-14 w-14 rounded-full object-cover" src={imgDefault} alt="Profile" />
            <label
              htmlFor="avatar"
              className="relative cursor-pointer bg-primary text-sm text-white font-medium w-max py-3 px-5 rounded-md transition-all"
            >
              <span className="cursor-pointer">{t('select_image')}</span>
              <Input
                ref={imageRef}
                type="file"
                id="avatar"
                name="avatar"
                accept="image/*"
                className="absolute inset-0 opacity-0 cursor-pointer"
                onChange={handleImageChange}
              />
            </label>
          </div>
        </div>
        <div className="mt-6 mb-2 flex gap-2">
          <Button
            onClick={() => {
              navigate(`/organizations/${user?.organization?.uuid}/dashboard/users`);
            }}
            buttonType={Button.Type.SECONDARY}
          >
            {t('cancel')}
          </Button>
          <Button onClick={handleSave} disabled={isDisabledButton} loading={createUserLoading}>
            {t('create_user')}
          </Button>
        </div>
      </div>
    </main>
  );
}
