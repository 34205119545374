import { classNames } from '@components/Badges/StatusBadge';
import { HTMLAttributes } from 'react';

interface EntityResume extends HTMLAttributes<HTMLDivElement> {
  title: string;
  value: string;
}

export default function EntityResume({ className, title, value, ...props }: EntityResume) {
  return (
    <div className={classNames('bg-gray-50 rounded-lg shadow-md px-4 py-5', className)} {...props}>
      <p className="text-primary/90 text-sm font-medium">{title}</p>
      <span className="text-gray-900 text-xl font-semibold">{value}</span>
    </div>
  );
}
