/* eslint-disable import/no-unresolved */
import { useAuth } from '@hooks/auth/useAuth';
import useAxios from '@hooks/axios/useAxios';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import imgDefault from '@assets/images/user-img-default.jpg';
import Card from './Card';
import Row from '@components/Flex/Row';
import Col from '@components/Flex/Col';
import StackedButton from '@components/StackedList/StackedButton';
import Input from '@components/Inputs';
import logo from '../../assets/images/fastdoc-colored.svg';

export default function EditProfile() {
  const { t } = useTranslation();
  const { user } = useAuth();
  const navigate = useNavigate();

  const [name, setName] = useState('');
  const [nif, setNif] = useState('');

  const imageRef = useRef<HTMLInputElement>(null);

  const [editing, setEditing] = useState<boolean>(false);
  const { loading: editUserLoading, request: editUserRequest } = useAxios();

  function handleImageChange(event: any) {
    const imgElement = document.getElementById('preview-img');
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      if (imgElement instanceof HTMLImageElement && reader.result) {
        imgElement.src = reader.result.toString();
      }
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  }

  async function updateUser() {
    const file = imageRef.current?.files?.[0] ?? null;

    const form = new FormData();
    form.append('name', name);
    form.append('nif', nif);
    form.append('_method', 'PUT');
    if (file) {
      form.append('avatar', file);
    }

    try {
      const response = await editUserRequest(`/api/users/${user?.uuid}`, 'POST', form);

      if (response.data && response.data.content.data) {
        const newUser = response.data.content.data;

        const sessionDataString = sessionStorage.getItem('user-auth');
        if (sessionDataString !== null) {
          const sessionData = JSON.parse(sessionDataString);

          sessionData.state.user.name = newUser.name;
          sessionData.state.user.nif = newUser.nif;
          sessionData.state.user.avatar = newUser.avatar;

          sessionStorage.setItem('user-auth', JSON.stringify(sessionData));
        }

        toast.success(t('user_updated_successfully'));
        navigate(`/organizations/${user?.organization?.uuid}/dashboard/profile?updated=true`);
      } else {
        toast.error(t('error_updating_user'));
      }
    } catch (error: any) {
      toast.error(t('error_updating_user'));
      console.error(error);
    }
  }

  useEffect(() => {
    setName(user?.name!);
    setNif(user?.nif!);
  }, [user]);

  return (
    <Card gap={3}>
      <span className="text-blue-900 font-semibold text-base">{t('information')}</span>
      {(editing && (
        <form
          action=""
          className="text-gray-600 w-full"
          onSubmit={(e) => {
            e.preventDefault();
            updateUser();
          }}
        >
          <Row className="items-center" gap={4}>
            <img
              id="preview-img"
              className="h-10 w-10 rounded-full object-cover"
              src={
                user?.avatar === null
                  ? imgDefault
                  : `${import.meta.env.VITE_API_URL}storage/images/user/${user?.avatar}`
              }
              alt="Profile"
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = logo;
                currentTarget.className = 'h-10 w-10 rounded-full object-cover bg-primary/10';
              }}
            />
            <StackedButton
              className="w-fit"
              onClick={(event) => {
                event?.preventDefault();
                imageRef.current?.click();
              }}
            >
              {t('edit_photo')}
            </StackedButton>
            <Input
              ref={imageRef}
              type="file"
              id="avatar"
              name="avatar"
              accept="image/*"
              className="hidden inset-0 opacity-0 cursor-pointer"
              onChange={handleImageChange}
            />
          </Row>

          <Col gap={1} className="mt-2">
            <label htmlFor="">{t('name')}</label>
            <Input placeholder="John Doe" type="text" value={name} onChange={(e) => setName(e.target.value)} />
          </Col>

          <Col gap={1} className="mt-2">
            <label htmlFor="">{t('nif')}</label>
            <Input placeholder="123456789" type="number" min={0} value={nif} onChange={(e) => setNif(e.target.value)} />
          </Col>
          <Col gap={1} className="mt-2">
            <label htmlFor="">{t('email')}</label>
            <Input placeholder="johndoe@example.com" type="email" value={user?.email} disabled />
          </Col>

          <Row gap={4} className="mt-2">
            <StackedButton className="w-full" onClick={() => setEditing(false)}>
              {t('cancel')}
            </StackedButton>

            <StackedButton theme="primary" loading={editUserLoading} className="w-full" onClick={updateUser}>
              {t('update')}
            </StackedButton>
          </Row>
        </form>
      )) || (
        <Col gap={2}>
          <Row className="items-start" gap={4}>
            <img
              id="preview-img"
              className="h-10 w-10 rounded-full object-cover"
              src={
                user?.avatar === null
                  ? imgDefault
                  : `${import.meta.env.VITE_API_URL}storage/images/user/${user?.avatar}`
              }
              alt="Profile"
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = logo;
                currentTarget.className = 'h-10 w-10 rounded-full object-cover bg-primary/10';
              }}
            />
            <Col className="w-full items-start" gap={1}>
              <span className="text-gray-900 font-medium text-sm">{name}</span>
              <span className="text-gray-500 font-normal text-sm">{user?.email}</span>
              <span className="text-gray-500 font-normal text-sm">{nif}</span>
            </Col>
          </Row>
          <StackedButton className="w-fit" onClick={() => setEditing(true)}>
            {t('edit')}
          </StackedButton>
        </Col>
      )}
    </Card>
  );
}
