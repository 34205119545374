import { CheckIcon, ClockIcon, XMarkIcon } from '@heroicons/react/24/outline';

export const PendingDot = () => (
  <ClockIcon className="text-white bg-yellow-500 text-3xl relative right-[8px] w-7 p-1 rounded-full" />
);
export const RejectedDot = () => (
  <XMarkIcon className="text-white bg-red-500 text-3xl relative right-[8px] w-7 p-1 rounded-full" />
);
export const ApprovedDot = () => (
  <CheckIcon className="text-white bg-lime-700 text-3xl relative right-[8px] w-7 p-1 rounded-full" />
);
