import { classNames } from '@components/Badges/StatusBadge';
import { HTMLAttributes } from 'react';

export interface ModalBodyProps extends HTMLAttributes<HTMLDivElement> {}

export default function ModalBody({ className, children, ...props }: ModalBodyProps) {
  return (
    <div
      className={classNames('flex flex-col items-start transition-all ease-in-out h-full w-full  px-6', className)}
      {...props}
    >
      {children}
    </div>
  );
}
