import { ChevronDownIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { useExpenses } from '../../../hooks/expense/useExpense';
import { useState } from 'react';

export default function SendMapDisplacementExpenses() {
  const { isOpenSendMapDisplacementModal, setIsOpenSendMapDisplacementModal } = useExpenses();
  const [isOpenSelect, setIsOpenSelect] = useState(false);

  const [optionsDisplacements, setOptionsDisplacements] = useState('');

  const options = [
    {
      id: 1,
      name: 'Deslocação Viatura Propria',
    },
    {
      id: 2,
      name: 'Deslocação Com Ajudas de Custo',
    },
  ];

  return (
    <>
      <div
        className={`${
          !isOpenSendMapDisplacementModal ? 'hidden' : 'block'
        } bg-[#414241] bg-opacity-30 backdrop-blur z-40 fixed w-full left-0 top-0 h-screen`}
      >
        <div
          className={`mx-auto w-max bg-gray-50 py-10 mt-20 rounded-lg flex flex-col justify-center items-center  transition-all`}
        >
          <header className="fixed top-6 w-full flex p-6 md:py-0 items-center justify-end">
            <button
              onClick={() => {
                setIsOpenSendMapDisplacementModal(false);
              }}
            >
              <XMarkIcon className="w-6" />
            </button>
          </header>
          <section className="px-8 w-full md:max-w-[600px] mx-auto">
            <div>
              <h1 className="text-3xl font-bold text-center mb-5">Enviar mapa de deslocação</h1>
            </div>
            <div>
              <h2 className="text-xl font-bold mb-5">Selecione uma opção</h2>
              <div className="relative flex z-20 w-full gap-2 border border-primary ">
                <button
                  onClick={() => setIsOpenSelect(!isOpenSelect)}
                  className={`${
                    isOpenSelect && 'z-20'
                  } flex bg-gray-50 z-20 items-center px-4 text-primary rounded-md font-semibold justify-between w-full  py-3`}
                >
                  <span>{optionsDisplacements === '' ? 'Escolha uma opção' : optionsDisplacements}</span>
                  <ChevronDownIcon className={`${isOpenSelect ? 'rotate-[180deg]' : ''} transition-all`} />
                </button>

                <ul
                  className={`${
                    !isOpenSelect && 'hidden'
                  } absolute w-full top-[100%] border-t border-primary mb-16 h-[120px] overflow-y-scroll overflow-auto bg-gray-50 shadow-md`}
                >
                  {options.map((option) => (
                    <li
                      key={option.id}
                      onClick={() => {
                        setOptionsDisplacements(option.name);
                        setIsOpenSelect(false);
                      }}
                      className="w-full py-3 px-4 border-b cursor-pointer hover:bg-primary hover:text-white transition-all"
                    >
                      {option.name}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="mt-6 w-full">
              <button className=" w-full py-4 border-2 border-primary text-primary font-bold rounded-md text-center hover:text-white hover:bg-primary transition-all">
                Enviar
              </button>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}
