import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { useAuth } from '@hooks/auth/useAuth';
import { useTranslation } from 'react-i18next';

import { api } from 'services/api';
import useAxios from '@hooks/axios/useAxios';
import imageDefaultUser from '../../assets/images/user-img-default.jpg';
import { ArrowLeftOnRectangleIcon, EyeIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { EyeIcon as EyeIconOutline } from '@heroicons/react/24/outline';
import logo from '../../assets/images/fastdoc-colored.svg';

import { CurrencyEuroIcon, UserCircleIcon } from '@heroicons/react/24/outline';

const activeClassTabs = 'text-blue-900 pb-4 px-2 text-sm border-b border-blue-900';
const normalClassTabs = 'text-sm text-primary/90 pb-4 px-2';

export function Notifications() {
  const { t } = useTranslation();
  const { user } = useAuth();
  const [openMenu, setOpenMenu] = useState(false);
  const { logOut } = useAuth();

  const [currentSectionTabs, setCurrentSectionTabs] = useState('all');
  const [notifications, setNotifications] = useState<any>([]);

  const { request: markAsReadRequest } = useAxios();

  const filteredNotifications = notifications.filter((notification: any) => {
    if (currentSectionTabs === 'all') {
      return true;
    } else if (currentSectionTabs === 'unread') {
      return notification.read_at === null;
    } else {
      return notification.read_at;
    }
  });

  const markAsRead = (notificationId: number | string) => {
    const updatedNotifications = notifications.map((notification: any) => {
      if (notification.id === notificationId) {
        return { ...notification, read_at: true };
      }
      return notification;
    });

    setNotifications(updatedNotifications);
  };

  const getUserNotifications = useCallback(() => {
    if (user) {
      api.defaults.headers.common['Authorization'] = `Bearer ${user.token}`;
      api.get(`api/organizations/${user?.organization?.uuid}/user/${user?.uuid}/notifications`).then(({ data }) => {
        setNotifications(data.data);
      });
    }
  }, [user]);

  useEffect(() => {
    getUserNotifications();
  }, []);

  const handleMarkAsRead = (notificationId: number | string) => {
    markAsReadRequest(
      `/api/organizations/${user?.organization?.uuid}/user/${user?.uuid}/notifications/${notificationId}`,
      'PUT',
    ).then(() => {
      markAsRead(notificationId);
    });
  };

  const handleMarkAllAsRead = () => {
    markAsReadRequest(
      `/api/organizations/${user?.organization?.uuid}/user/${user?.uuid}/notifications/mark-all-read`,
      'POST',
    ).then(() => {
      setNotifications(notifications.map((notification: any) => ({ ...notification, read_at: true })));
    });
  };

  return (
    <div className="flex flex-row gap-2 items-center">
      <button className="cursor-pointer relative" onClick={() => setOpenMenu(!openMenu)}>
        <img
          className="h-8 w-8 rounded-full object-cover"
          src={`${
            user?.avatar === null
              ? imageDefaultUser
              : `${import.meta.env.VITE_API_URL}storage/images/user/${user?.avatar}`
          } `}
          alt="Profile"
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = logo;
            currentTarget.className = 'h-8 w-8 rounded-full object-cover bg-primary/10';
          }}
        />{' '}
        <span className="absolute -top-2 right-0 h-4 w-4 rounded-full bg-red-500 text-white flex items-center justify-center text-sm select-none">
          {filteredNotifications.filter((notification: any) => !notification.read_at).length}
        </span>
      </button>
      <div className="relative">
        <div
          className={`${openMenu ? 'block' : 'hidden'}  fixed z-40 top-0 right-0 w-full h-full bg-black opacity-50`}
          onClick={() => setOpenMenu(false)}
        ></div>
        <div
          className={`${
            openMenu ? 'translate-x-0' : 'translate-x-full'
          }  fixed top-0 right-0 w-4/5 h-full bg-gray-50 shadow-lg transition-transform duration-300 transform ease-in-out z-50`}
        >
          <div className="flex justify-end p-4">
            <div onClick={() => setOpenMenu(false)}>
              <XMarkIcon className="text-gray-600 hover:text-gray-800 cursor-pointer h-6" />
            </div>
          </div>
          <nav className="flex flex-col justify-start h-full gap-2 max-h-[100vh]">
            <div className="flex flex-col gap-3 sticky top-0 bg-gray-50 px-4">
              <p className="text-sm text-gray-800 font-bold">{t('notifications')}</p>
              <div className="flex">
                <div className="flex gap-2  border-b w-full justify-start">
                  <button
                    onClick={() => {
                      setCurrentSectionTabs('all');
                    }}
                    className={currentSectionTabs == 'all' ? activeClassTabs : normalClassTabs}
                  >
                    {t('all')}
                  </button>
                  <button
                    onClick={() => {
                      setCurrentSectionTabs('unread');
                    }}
                    className={currentSectionTabs == 'unread' ? activeClassTabs : normalClassTabs}
                  >
                    {t('unread')}
                  </button>
                  <button
                    onClick={() => {
                      setCurrentSectionTabs('read');
                    }}
                    className={currentSectionTabs == 'read' ? activeClassTabs : normalClassTabs}
                  >
                    {t('read')}
                  </button>
                </div>
                {currentSectionTabs !== 'read' && (
                  <>
                    <button
                      className="text-sm text-gray-800 hover:text-secondary hover:underline whitespace-nowrap"
                      onClick={() => {
                        handleMarkAllAsRead();
                      }}
                    >
                      {t('read_all')}
                    </button>
                  </>
                )}
              </div>
            </div>
            <div className="flex flex-col divide-y pb-24  h-full overflow-y-scroll justify-start">
              {filteredNotifications?.length === 0 ? (
                <div className="flex flex-col items-center justify-center mt-2">
                  <p>{t('no_notification_available')}.</p>
                </div>
              ) : (
                filteredNotifications?.map((notification: any) => (
                  <div
                    key={notification.id}
                    className={`flex flex-row items-center gap-2 justify-between ${
                      notification.read_at ? 'p-2' : 'bg-blue-50 p-2'
                    }`}
                  >
                    <div className="flex flex-col items-start ">
                      <div className="flex flex-row gap-2 items-center">
                        <CurrencyEuroIcon className="text-blue-900 w-6 h-6" />
                        <span className="text-blue-900 font-bold text-sm">{notification?.data?.title}</span>
                      </div>

                      <p className="text-gray-600 font-light text-sm">{notification?.data?.message}</p>
                    </div>

                    <div className="flex gap-2 items-center">
                      {notification?.read_at ? (
                        <EyeIconOutline
                          className="text-blue-900 h-7"
                          // onClick={() => handleMarkAsUnread(notification?.id)}
                        />
                      ) : (
                        <EyeIcon
                          className="hover:text-secondary text-gray-600 cursor-pointer h-7"
                          onClick={() => handleMarkAsRead(notification?.id)}
                        />
                      )}
                    </div>
                  </div>
                ))
              )}
            </div>

            <div className="flex flex-col gap-1 w-full sticky bottom-5 bg-gray-50 p-2 border-t">
              <Link
                onClick={() => setOpenMenu(false)}
                to={`/organizations/${user?.organization?.uuid}/dashboard/profile/preferences`}
                className="text-blue-900 font-medium flex gap-2 items-center pt-2"
              >
                <UserCircleIcon className="w-6 h-6" />
                {t('profile')}
              </Link>
              <Link
                to="/"
                onClick={() => {
                  logOut();
                  setOpenMenu(false);
                  window.history.replaceState({}, '', '/');
                }}
                className="text-blue-900 font-medium flex gap-2 items-center"
              >
                <ArrowLeftOnRectangleIcon className="w-6 h-6" />
                {t('logout')}
              </Link>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
}
