import FastdocBanner from '@components/Banners/FastdocBanner';
import QrCodeReader from '@components/QrCode/QrCodeReader';
import { QrCodeIcon } from '@heroicons/react/24/solid';
import { useAuth } from '@hooks/auth/useAuth';
import { useQrCode } from '@hooks/layout/useQrCode';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useEffect } from 'react';

import { useLayout } from '@hooks/layout/useLayout';
import TabLink from '@components/Navbar/TabLink';

export default function ReadQrCode() {
  const { t } = useTranslation();

  const { user } = useAuth();

  const { setPageTitle } = useLayout();

  useEffect(() => {
    setPageTitle(t('read_point'));

    return () => {
      setPageTitle(null);
    };
  }, []);

  const { setQrCodeScanContent } = useQrCode();
  const navigate = useNavigate();

  if (user?.work_type == 'remote') {
    navigate(`/organizations/${user?.organization?.uuid}/dashboard/read-point/remote`);
  }

  return (
    <main className="bg-gray-50 overflow-y-hidden h-full max-w-xl mx-auto w-full">
      {user?.work_type == 'hybrid' && (
        <div className="w-full flex rounded-lg shadow-lg bg-gray-50">
          <TabLink to={`/organizations/${user!.organization?.uuid}/dashboard/read-point/remote`}>{t('remote')}</TabLink>
          <TabLink to={`/organizations/${user!.organization?.uuid}/dashboard/read-point`}>{t('office')}</TabLink>
        </div>
      )}
      <section className="flex max-w-[500px] mx-auto flex-col w-full h-full justify-start gap-4 py-5 px-4">
        <QrCodeReader
          onRead={(data) => {
            if (data) {
              navigate(`/organizations/${user?.organization?.uuid}/dashboard/presences/user/${user?.uuid}`);
              setQrCodeScanContent(data);
            }
          }}
        />

        <FastdocBanner className="mx-auto text-blue-900">
          <QrCodeIcon className="w-8 h-8" />
          <span className="w-full "> {t('read_qr_code_message')}</span>
        </FastdocBanner>
      </section>{' '}
    </main>
  );
}
