/* eslint-disable import/no-unresolved */
import { useAbsences } from '@hooks/presence/useAbsences';
import RequestsAbsenceUserSection from './RequestsAbsenceUserSection';
import RequestsVacationUserSection from './RequestsVacationUserSection';
import { useTranslation } from 'react-i18next';

const activeClassTabs = 'text-blue-900 pb-4 px-2 text-sm border-b border-blue-900';
const normalClassTabs = 'text-sm text-primary/90 pb-4 px-2';

export default function RequestsUser() {
  const { t } = useTranslation();
  const { currentSection, setCurrentSection } = useAbsences();

  return (
    <div className=" w-full p-1 bg-gray-50 mt-4 md:mt-0">
      <div className="flex gap-2  border-b w-full justify-start">
        <button
          onClick={() => setCurrentSection('absence')}
          className={currentSection == 'absence' ? activeClassTabs : normalClassTabs}
        >
          {t('absences')}
        </button>
        <button
          onClick={() => setCurrentSection('vocation')}
          className={currentSection == 'vocation' ? activeClassTabs : normalClassTabs}
        >
          {t('vacations')}
        </button>
      </div>
      {currentSection == 'absence' && <RequestsAbsenceUserSection />}
      {currentSection == 'vocation' && <RequestsVacationUserSection />}
    </div>
  );
}
