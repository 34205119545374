import React, { DetailedHTMLProps, TextareaHTMLAttributes } from 'react';

interface TextareaProps extends DetailedHTMLProps<TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> {}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(({ className, children, ...props }, ref) => {
  return (
    <textarea
      className={`border px-3 py-2 border-gray-300 bg-white w-full rounded-md h-[42px] focus:ring-0 focus:border-gray-300 ${className}`}
      {...props}
      ref={ref}
    >
      {children}
    </textarea>
  );
});

export default Textarea;
