import NavbarRequests from '@components/Requests/Navbar/NavbarRequests';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { Outlet } from 'react-router-dom';

import 'react-toastify/dist/ReactToastify.css';

function Requests() {
  return (
    <>
      <main className="max-w-[700px] w-full flex flex-col mt-4 mx-auto md:shadow-md px-8 pb-10 rounded">
        <NavbarRequests />
        <Outlet />
      </main>
    </>
  );
}

export default Requests;
