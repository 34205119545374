import { useEffect } from 'react';
import NavbarExpense from '../components/Expenses/Navbar';
import { useExpenses } from '../hooks/expense/useExpense';
import 'react-toastify/dist/ReactToastify.css';
import { useSpending } from '../hooks/expense/useShopping';
import { Outlet } from 'react-router-dom';

function Expenses() {
  const { clearConfigExpenses } = useExpenses();
  const { clearConfigSpending } = useSpending();

  useEffect(() => {
    clearConfigExpenses();
    clearConfigSpending();
  }, [clearConfigExpenses, clearConfigSpending]);

  return (
    <main className="">
      <main className="max-w-[800px] flex flex-col mt-4 mx-auto md:shadow-md pb-10 rounded">
        <NavbarExpense />
        {<Outlet />}
      </main>
    </main>
  );
}

export default Expenses;
