import { useLocation } from '@hooks/location/useLocation';

function NavbarHolidays() {
  const { currentSection, setCurrentSection } = useLocation();

  const activeClass = 'border-solid border-b-2 border-secondary  text-gray-800 shadow-md font-semibold';
  const normalClass = 'border-solid border-l border-gray-200 text-primary/90';

  return (
    <div className="w-full flex rounded-lg shadow-lg mb-5 bg-gray-100">
      <button
        onClick={() => setCurrentSection('holidays')}
        className={`${
          currentSection == 'holidays' ? activeClass : normalClass
        } w-full px-3 py-1.5   text-center rounded-l-lg rounded-r-lg`}
      >
        Feriados
      </button>
    </div>
  );
}

export default NavbarHolidays;
