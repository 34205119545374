import { ButtonHTMLAttributes } from 'react';
import { ButtonType } from './Button';
import { classNames } from '@components/Badges/StatusBadge';
import Loading from '@components/loading';

interface ButtonFloatProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  buttonType?: ButtonType;
  loading?: boolean;
}

function ButtonFloat({
  buttonType = ButtonType.PRIMARY,
  children,
  className,
  disabled,
  loading,
  ...props
}: ButtonFloatProps) {
  let customStyles = '';

  switch (buttonType) {
    case ButtonType.SECONDARY:
      customStyles += 'bg-gray-50 border border-primary text-primary rounded-full border-primary';
      break;
    case ButtonType.TERTIARY:
      customStyles +=
        'bg-transparent text-black hover:text-white rounded-full hover:bg-secondary/60 active:bg-secondary/80';
      break;
    case ButtonType.ACCENT:
      customStyles +=
        'bg-secondary text-white rounded-full hover:text-white hover:bg-secondary bg-secondary border-secondary hover:bg-secondary/80 active:bg-secondary/60 ';
      break;

    case ButtonType.ERROR:
      customStyles +=
        'bg-red-500 text-white rounded-full hover:text-white hover:bg-red-500 bg-red-500 border-red-500 hover:bg-red-500/80 active:bg-red-500/60 ';
      break;
    case ButtonType.SUCCESS:
      customStyles +=
        'bg-emerald-600 text-white rounded-full hover:text-white hover:bg-emerald-600 bg-emerald-600 border-primary hover:bg-emerald-600/80 active:bg-emerald-600/60 ';
      break;
    default:
      customStyles += 'bg-primary text-white rounded-full hover:text-white hover:bg-primary/80 active:bg-primary/60';
      break;
  }

  return (
    <button
      {...props}
      disabled={disabled || loading}
      className={classNames(
        'shadow-2xl transition-all text-white p-3 rounded-full disabled:cursor-not-allowed disabled:opacity-60 disabled:active:scale-100 active:scale-[0.98]',
        className,
        customStyles,
      )}
    >
      {(loading && <Loading />) || children}
    </button>
  );
}

export default ButtonFloat;
