import TabLink from '@components/Navbar/TabLink';
import { useAuth } from '@hooks/auth/useAuth';
import { useLayout } from '@hooks/layout/useLayout';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

function NavbarExpense() {
  const { t } = useTranslation();
  const { user } = useAuth();

  const { setPageTitle } = useLayout();

  useEffect(() => {
    setPageTitle(t('expenses'));

    return () => {
      setPageTitle(null);
    };
  }, []);

  return (
    <div className="w-full flex rounded-lg shadow-lg bg-gray-50">
      <TabLink to={`/organizations/${user?.organization?.uuid}/dashboard/expenses/all`}>{t('expenses')}</TabLink>
      <TabLink to={`/organizations/${user?.organization?.uuid}/dashboard/expenses/displacements`}>
        {t('displacement')}
      </TabLink>
    </div>
  );
}

export default NavbarExpense;
