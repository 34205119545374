import { keys, values } from 'lodash';

export const classNames: any = (...classes: any) => classes?.filter?.(Boolean)?.join?.(' ');

export enum BadgeStatus {
  pending = 'pendente',
  approved = 'aprovado',
  rejected = 'rejeitado',
}

export interface StatusBadgeProps {
  status: BadgeStatus;
}

export default function StatusBadge({ status }: StatusBadgeProps) {
  let badgeStyles: string = '';

  const selectedStatusKey = keys(BadgeStatus).findIndex((badgeStatus) => {
    return badgeStatus == status;
  });
  const selectedStatusValue = values(BadgeStatus)[selectedStatusKey];

  switch (selectedStatusValue) {
    case 'pendente':
      badgeStyles = 'text-white bg-yellow-500';
      break;
    case 'aprovado':
      badgeStyles = 'text-white bg-green-800';
      break;
    case 'rejeitado':
      badgeStyles = 'text-white bg-red-800';
      break;
    default:
      badgeStyles = 'text-white bg-yellow-500';
      break;
  }

  return (
    <div
      className={classNames('flex flex-row items-center w-fit rounded-lg px-2 py-1 text-base bg-lime-800', badgeStyles)}
    >
      {selectedStatusValue}
    </div>
  );
}
