import { classNames } from '@components/Badges/StatusBadge';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { HTMLAttributes } from 'react';

export interface ModalHeaderProps extends HTMLAttributes<HTMLDivElement> {
  handleCloseButtonClick?: () => void;
  title: string;
}

export default function ModalHeader({ handleCloseButtonClick, className, title, ...props }: ModalHeaderProps) {
  return (
    <div className={classNames('flex flex-row items-center justify-between w-full px-6 pt-6', className)} {...props}>
      <span className="text-2xl font-semibold text-libre-grey-100">{title}</span>
      <button className="p-2 cursor-pointer" onClick={handleCloseButtonClick}>
        <XMarkIcon className="text-libre-grey-100 w-6" />
      </button>
    </div>
  );
}
