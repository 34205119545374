import { create } from 'zustand';
import { api } from '../../services/api';
import { BadgeStatus } from '@components/Badges/StatusBadge';
import { User } from '@hooks/auth/useAuth';
import { LIMIT_RECORDS } from 'App';

export interface Vacation {
  uuid?: string;
  id?: number;
  organizationUuid: string;
  user_id?: number;
  time_in: string;
  time_out: string;
  time_in_confirm?: string;
  time_out_confirm?: string;
  created_at?: string;
  status?: BadgeStatus;
  statuses: any[];
}

interface VacationProps {
  vacations: Vacation[];
  createVacation: (vacation?: Vacation) => Promise<void>;
  getAllVacations: (user: User) => Promise<any>;
  getOrganizationVacations: (organizationUuid: string) => Promise<any>;
}

export const useVacations = create<VacationProps>((set) => ({
  vacations: [],
  createVacation: async (vacation?: Vacation) => {
    const { data } = await api.post(`/api/organizations/${vacation?.organizationUuid}/vacations`, vacation);
    set((state) => ({ vacations: [...state.vacations, data] }));
  },
  getOrganizationVacations: (organizationUuid: string) => {
    return api
      .get(
        `/api/organizations/${organizationUuid}/vacations?take=${LIMIT_RECORDS}&sortBy=created_at&sortDirection=desc`,
      )
      .then(({ data }) => data?.content?.data?.data);
  },
  getAllVacations: (user: User) => {
    return api
      .get(
        `/api/organizations/${user?.organization?.uuid}/vacations?take=${LIMIT_RECORDS}&sortBy=created_at&sortDirection=desc&user_id=${user?.id}`,
      )
      .then(({ data }) => data?.content?.data?.data);
  },
}));
