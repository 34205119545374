import { create } from 'zustand';
import { toast } from 'react-toastify';
import { api } from '../../services/api';
import { LIMIT_RECORDS } from 'App';
import { Location } from '@hooks/location/useLocation';

export type PointProps = {
  id?: number;
  uuid?: string;
  user_id: number;
  organization_id: string;
  location_id: string;
  type: 'in' | 'out';
  time: string;
  lat: string;
  lng: string;
  manual?: boolean;
  ip?: string;
  model?: string;
  status?: string;
  location?: Location;
};

export type ResponseType = {
  [date: string]: PointProps[];
};

type PointState = {
  points: PointProps[];
  setPoints: (points: PointProps[]) => void;
  createPoint: (point: PointProps) => Promise<void>;
  createPointQrCode: (url: string, point: PointProps) => Promise<void>;
  getPointsByUser: (
    organizationUuid: string,
    user_id: string,
    day: number,
    month: number,
    year: number,
  ) => Promise<any>;
};

export const usePoint = create<PointState>((set) => ({
  points: [],
  setPoints: (points: PointProps[]) => set({ points }),
  getPointsByUser: async (organizationUuid: string, user_id: string, day: number, month: number, year: number) => {
    try {
      const { data } = await api.get(
        `/api/organizations/${organizationUuid}/presences/user/${user_id}/${year}/${month}/${day}?take=${LIMIT_RECORDS}&sortBy=created_at&sortDirection=desc`,
      );

      const points = data.content.data;
      set({ points }); // Define o estado localmente
      return { point: points }; // Retorna o objeto no formato esperado
    } catch (error) {
      console.error(error);
      throw error; // Lança o erro para que ele seja tratado externamente, se necessário
    }
  },
  createPoint: async (point: PointProps) => {
    try {
      await api.post(`/api/organizations/${point.organization_id}/presences`, point);
      toast.success('Ponto cadastrado com sucesso!');
    } catch (error: any) {
      console.error(error);
      toast.error('Erro ao cadastrar ponto');
    }
  },

  createPointQrCode: async (url: string, point: PointProps) => {
    try {
      await api.post(url, point);
    } catch (error) {
      throw error; // Lançar o erro novamente para que ele possa ser tratado pelo código que chama essa função
    }
  },
}));
