import { useEffect, useMemo, useRef, useState } from 'react';
import { Expense, useExpenses } from '../../../hooks/expense/useExpense';
import AttachmentFileExpenseModal from '../Modals/AttachmentFileExpenseModal';
import { extractInfoFromQRCode } from '../Modals/QRCodeModalExpense';

import { useAuth } from '@hooks/auth/useAuth';
import { parse } from 'date-fns';
import { InputNumber, SelectPicker, Toggle } from 'rsuite';
import { useDropzone } from 'react-dropzone';
import { CustomFile, useAbsences } from '@hooks/presence/useAbsences';
import { keys, values } from 'lodash';
import { toast } from 'react-toastify';
import { api } from 'services/api';
import Button from '@components/Common/Buttons/Button';
import useAxios from '@hooks/axios/useAxios';
import './style.css';
import ButtonFloat from '@components/Common/Buttons/ButtonFloat';
import { useTranslation } from 'react-i18next';
import { DayPickerSingleDateController } from 'react-dates';
import { getCurrentDimension } from '@components/Presence/Sections/AbsenceSection';
import moment from 'moment';
import { useVacations } from '@hooks/presence/useVacations';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useOrganization } from '@hooks/organization/useOrganization';
import { t } from 'i18next';
import { LIMIT_RECORDS } from 'App';
import { PresenceSummary } from '@components/Calendar/Summary/PresenceSummary';
import { AbsenceMark, ExpenseMark, HolidayMark, VacationMark } from '@components/Calendar/Summary';
import { Month, NextMonth, PrevMonth } from '@components/Calendar';
import { CurrencyEuroIcon, DocumentIcon, PhotoIcon } from '@heroicons/react/24/outline';
import { CheckIcon, QrCodeIcon, TrashIcon, XMarkIcon } from '@heroicons/react/24/solid';
import Loading from '@components/loading';
import { useQrCode } from '@hooks/layout/useQrCode';
import { useNavigate } from 'react-router-dom';
import Input from '@components/Inputs';
import Textarea from '@components/Inputs/Textarea';
import Col from '@components/Flex/Col';

export enum TypesExpenses {
  flexible_benefits = 'Benefícios flexiveis',
  diesel = 'Combustível (Gasóleo)',
  gasoline = 'Combustível (Gasolina)',
  stays = 'Estadia',
  miscellaneous_materials = 'Materiais Diversos',
  displacement_meals = 'Refeição (em deslocação)',
  representation_meals = 'Refeição (em representação)',
  specialized_services = 'Serviços Especializados',
}

export function enumTypes(enumObj: any) {
  return Object.keys(enumObj).map((key) => ({
    label: t(key),
    value: key,
  }));
}

export const getExpenseLabel = (expenseType: string) => {
  const selectedTypeKey = keys(TypesExpenses).findIndex((currentType) => {
    return expenseType == currentType;
  });
  const selectedTypeValues = values(TypesExpenses)[selectedTypeKey];
  return selectedTypeValues;
};

function ExpensesSection() {
  const { t } = useTranslation();
  const { files, setFiles, removeFile, deleteFile, deleteExpense } = useExpenses();
  const { user } = useAuth();

  const [expenses, setExpenses] = useState<Expense[]>([]);
  const { getAllVacations } = useVacations();

  const { getAllAbsences } = useAbsences();
  const { getOrganizationHolidays } = useOrganization();
  const { getAllExpenses } = useExpenses();

  const [showListExpenses, setShowListExpenses] = useState(true);

  const [isEditingExpense, setIsEditingExpense] = useState(false);
  const [editingExpenseDetails, setEditingExpenseDetails] = useState<Expense | null>(null);

  const [editedComment, setEditedComment] = useState('');
  const [editedTotal, setEditedTotal] = useState('');
  const [editedSupplier, setEditedSupplier] = useState('');
  const [selectedTypesExpenses, setSelectedTypesExpenses] = useState<string | null>(null);
  const [creatingSupplier, setCreatingSupplier] = useState('');

  const [commentError, setCommentError] = useState('');

  const [valueTotal, setValueTotal] = useState<number | undefined>(undefined);
  const [barcodeContent, setBarcodeContent] = useState<string | undefined>();

  const commentRef = useRef<HTMLTextAreaElement>(null);
  const valueTotalRef = useRef<HTMLInputElement>(null);
  const commentEditRef = useRef<HTMLTextAreaElement>(null);
  const valueEditTotalRef = useRef<HTMLInputElement>(null);
  const supplierRef = useRef<HTMLInputElement>(null);
  const supplierEditRef = useRef<HTMLInputElement>(null);
  const [companyMoney, setCompanyMoney] = useState<boolean>(false);
  const [expenseDateState, setExpenseDateState] = useState<moment.Moment>(moment());
  const [focusedInput, setFocusedInput] = useState<boolean>(false);

  const { qrCodeExpenseContent, setQrCodeExpenseContent } = useQrCode();

  const [screenSize, setScreenSize] = useState(getCurrentDimension());

  const navigate = useNavigate();

  const { loading: queryExpensesLoading, request: queryExpensesRequest } = useAxios();
  const { loading: queryExpenseLoading, request: queryExpenseRequest } = useAxios();
  const { loading: createExpenseLoading, request: createExpenseRequest } = useAxios();
  const { loading: editExpenseLoading, request: editExpenseRequest } = useAxios();

  const { isLoading: queryVacationsLoading, data: localVacations } = useQuery<any[]>(['vacations', user], () =>
    getAllVacations(user!),
  );

  const { isLoading: queryAbsencesLoading, data: localAbsences } = useQuery<any[]>(['absences', user], () =>
    getAllAbsences(user!),
  );

  const { isLoading: queryHolidaysLoading, data: localHolidays } = useQuery<any[]>(['holidays', user], () =>
    getOrganizationHolidays(user?.organization?.uuid!),
  );

  const { isLoading: queryAllExpensesLoading, data: localExpenses } = useQuery<Expense[]>(['expenses', user], () =>
    getAllExpenses(user!),
  );

  const queryClient = useQueryClient();

  const createExpenseMutation = useMutation({
    mutationFn: async (newData: any) =>
      createExpenseRequest(`/api/organizations/${user?.organization?.uuid}/expenses`, 'POST', newData)
        .then(() => getCurrentExpenses(expenseDateState.toDate()))
        .catch(({ response }) => {
          values(response?.data?.errors)?.map((errors: any) => errors?.map((error: any) => toast.error(error)));
        }),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: ['expenses'] });
    },
  });

  const editExpenseMutation = useMutation({
    mutationFn: async (newData: any) =>
      editExpenseRequest(
        `/api/organizations/${user?.organization?.uuid}/expenses/${editingExpenseDetails?.uuid!}`,
        'POST',
        newData,
      )
        .then(() => {
          toast.success(t('expenses_successfully_updated'));
          getCurrentExpenses(expenseDateState?.toDate());
        })
        .catch(({ response }) => {
          values(response?.data?.errors)?.map((errors: any) => errors?.map((error: any) => toast.error(error)));
        }),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: ['expenses'] });
    },
  });

  const deleteExpenseMutation = useMutation({
    mutationFn: async ({ organizationUuid, expenseUuid }: any) =>
      deleteExpense(organizationUuid, expenseUuid)
        .then(() => {
          setExpenses((prevExpenses) => prevExpenses.filter((expense) => expense.uuid !== expenseUuid));
        })
        .catch((error) => {
          console.error(error);
        }),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: ['expenses'] });
    },
  });

  const optionsTypesExpenses = enumTypes(TypesExpenses);

  function validateFields() {
    let isValid = true;

    if (!editedComment) {
      setCommentError('O campo Motivo é obrigatório.');
      isValid = false;
    } else {
      setCommentError('');
    }

    return isValid;
  }

  const getCurrentExpenses = async (date: Date) => {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    queryExpensesRequest(
      `/api/organizations/${user?.organization
        ?.uuid!}/expenses/date/${year}/${month}/${day}?take=${LIMIT_RECORDS}&user_id=${user?.id}`,
    )
      .then(({ data }) => {
        setExpenses(data?.content?.data?.data);
      })
      .catch(({ response }) => {
        values(response?.data?.errors)?.map((errors: any) => errors?.map((error: any) => toast.error(error)));
      });
  };

  useEffect(() => {
    getCurrentExpenses(expenseDateState?.toDate());

    const params: any = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop as string),
    });

    if (params?.expenseEditUuid!) {
      handleEditExpenseClick(params?.expenseEditUuid!);
    }
  }, [expenseDateState]);

  async function handleSave() {
    if (!validateFields()) {
      return;
    }

    const file = files[0];
    const totalString = valueTotal?.toString() || '';

    const form = new FormData();
    form.append('user_id', String(user?.id!));
    form.append('expense_type', selectedTypesExpenses!);
    form.append('comment', editedComment!);
    form.append('total', totalString);
    form.append('date', String(expenseDateState ? expenseDateState?.format('yyyy-MM-DD') : undefined)!);
    form.append('supplier', creatingSupplier);
    form.append('company_money', String(Number(companyMoney)));

    //form.append('2d_barcode', expense.qr_code!);
    if (file) {
      form.append('file', file);
    }

    createExpenseMutation.mutate(form);
    setShowListExpenses(true);
    toast.success('Despesa criada com sucesso!');

    setEditedComment('');
    setEditedTotal('');
    setEditedSupplier('');
    setSelectedTypesExpenses(null);
    setFiles([]);
  }

  const getImageQrCodeContent = (file: File) => {
    const form = new FormData();

    form.append('file', file);

    return api?.post(`/api/organizations/${user?.organization?.uuid}/file`, form);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': [],
      'application/pdf': [],
    },
    onDrop: (acceptedFiles: any) => {
      const newFiles = acceptedFiles.map((file: any) => {
        const newFile = Object.assign(file, {
          preview: URL.createObjectURL(file),
          realFile: file,
        });

        getImageQrCodeContent(newFile).then(({ data }) => {
          if (data?.content?.data?.content) {
            setBarcodeContent(data?.content?.data?.content);
          }
        });

        return newFile;
      });
      setFiles(newFiles);
    },
  });

  function handleEditExpenseClick(expenseUuid: string) {
    setIsEditingExpense(true);
    setShowListExpenses(false);

    queryExpenseRequest(`/api/organizations/${user?.organization?.uuid!}/expenses/${expenseUuid}`)
      .then(({ data }) => {
        const expenseDetails = data.content.data;
        setEditingExpenseDetails(expenseDetails);
        setEditedComment(expenseDetails.comment);
        setEditedSupplier(expenseDetails.supplier!);
        setEditedTotal(expenseDetails.total);

        const barcodeKey = keys(expenseDetails)?.indexOf('2d_barcode');
        const newValue = values(expenseDetails)[barcodeKey];
        setBarcodeContent(newValue as string);
        setSelectedTypesExpenses(expenseDetails.expense_type);
        setCompanyMoney(expenseDetails?.company_money);
      })
      .catch(({ response }) => {
        values(response?.data?.errors)?.map((errors: any) => errors?.map((error: any) => toast.error(error)));
      });
  }

  function handleSaveEditing() {
    const file = files[0] || '';
    const totalString = editedTotal?.toString() || '';

    const form = new FormData();
    form.append('user_id', String(user?.id!));
    form.append('expense_type', selectedTypesExpenses!);
    form.append('comment', editedComment!);
    form.append('total', totalString);
    form.append('date', String(expenseDateState ? expenseDateState.format('yyyy-MM-DD') : ''));
    form.append('supplier', editedSupplier!);
    form.append('company_money', String(Number(companyMoney)));
    form.append('_method', 'PUT');
    if (file) {
      form.append('file', file);
    }

    editExpenseMutation.mutate(form);

    setFiles([]);
    setShowListExpenses(true);
  }

  const barcodeFields = useMemo(() => {
    if (barcodeContent && barcodeContent !== 'undefined') {
      const barcodeValue = extractInfoFromQRCode(barcodeContent);
      const totalValue = barcodeValue['O'];
      const supplier = barcodeValue['A'];
      if (isEditingExpense) {
        setEditedTotal(totalValue);
        setEditedSupplier(supplier);
      } else {
        setValueTotal(Number(totalValue));
        if (supplierRef) {
          supplierRef.current!.value = supplier;
        }
      }

      return barcodeValue;
    }
  }, [barcodeContent]);

  useEffect(() => {
    if (qrCodeExpenseContent) {
      const barcodeValue = extractInfoFromQRCode(qrCodeExpenseContent);
      console.log(qrCodeExpenseContent, barcodeValue);

      const totalValue = barcodeValue['O'];
      const supplier = barcodeValue['A'];

      setShowListExpenses(false);
      setSelectedTypesExpenses(null);
      setCompanyMoney(false);

      setValueTotal(Number(totalValue));
      setCreatingSupplier(supplier);
      setQrCodeExpenseContent(null);
    }
  }, [qrCodeExpenseContent, supplierRef]);

  function handleDeleteExpense(organizationUuid: string, expenseUuid: string) {
    deleteExpenseMutation.mutate({ organizationUuid, expenseUuid });
  }

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener('resize', updateDimension);

    return () => {
      window.removeEventListener('resize', updateDimension);
    };
  }, [screenSize]);

  return (
    <>
      <AttachmentFileExpenseModal />

      <section className="flex flex-col w-full mx-auto px-2">
        <div className="mt-4 flex justify-center">
          {(queryVacationsLoading || queryAbsencesLoading || queryHolidaysLoading || queryAllExpensesLoading) && (
            <Loading className="w-full mx-auto" />
          )}

          {!queryVacationsLoading && !queryAbsencesLoading && !queryHolidaysLoading && !queryAllExpensesLoading && (
            <DayPickerSingleDateController
              daySize={48}
              navNext={<NextMonth />}
              navPrev={<PrevMonth />}
              renderMonthElement={Month}
              initialVisibleMonth={() => moment()}
              noBorder
              verticalBorderSpacing={5}
              date={expenseDateState}
              onFocusChange={({ focused }) => {
                setFocusedInput(focused);
              }}
              focused={focusedInput}
              renderCalendarInfo={() => (
                <PresenceSummary>
                  <span className="text-base text-gray-950 flex flex-row items-center gap-2">
                    <ExpenseMark /> {t('expense')}
                  </span>
                </PresenceSummary>
              )}
              onDateChange={(date: any) => {
                const isDateSelectedOnDisabledRange = localAbsences?.some((absence: any) => {
                  const absenceStart = parse(absence.time_in, 'yyyy-MM-dd HH:mm:ss', new Date());
                  const absenceEnd = parse(absence.time_out, 'yyyy-MM-dd HH:mm:ss', new Date());

                  return date.isSame(absenceStart) || date.isSame(absenceEnd);
                });

                const isDateSelectedOnVacationRange = localVacations?.some((vacation: any) => {
                  const vacationStart = parse(vacation.time_in, 'yyyy-MM-dd HH:mm:ss', new Date());
                  const vacationEnd = parse(vacation.time_out, 'yyyy-MM-dd HH:mm:ss', new Date());

                  return date.isSame(vacationStart) || date.isSame(vacationEnd);
                });

                const isDateSelectedOnHolidayRange = localHolidays?.some((holiday: any) => {
                  const selectedHoliday = parse(holiday.date, 'yyyy-MM-dd HH:mm:ss', new Date());

                  return date.isSame(selectedHoliday);
                });

                if (!isDateSelectedOnDisabledRange && !isDateSelectedOnHolidayRange && !isDateSelectedOnVacationRange) {
                  setExpenseDateState(date);
                  return;
                }
              }}
              renderDayContents={(day) => {
                const dateAbsenceSelected = localAbsences?.some((absence: any) => {
                  const absenceStart = parse(absence.time_in, 'yyyy-MM-dd HH:mm:ss', new Date());
                  const absenceEnd = parse(absence.time_out, 'yyyy-MM-dd HH:mm:ss', new Date());

                  return day.isBetween(absenceStart, absenceEnd);
                });

                const dateVacationSelected = localVacations?.some((vacation: any) => {
                  const absenceStart = parse(vacation.time_in, 'yyyy-MM-dd HH:mm:ss', new Date());
                  const absenceEnd = parse(vacation.time_out, 'yyyy-MM-dd HH:mm:ss', new Date());
                  return day.isBetween(absenceStart, absenceEnd);
                });

                const dateHolidaySelected = localHolidays?.some((holiday: any) => {
                  const selectedHoliday = parse(holiday.date, 'yyyy-MM-dd HH:mm:ss', new Date());

                  return day.isSame(moment(selectedHoliday, 'MM/D/YYYY'), 'date');
                });

                const dateExpenseSelected =
                  localExpenses?.filter((expense: any) => {
                    const selectedExpense = parse(expense.date, 'yyyy-MM-dd HH:mm:ss', new Date());

                    return day.isSame(moment(selectedExpense, 'MM/D/YYYY'), 'date');
                  }) ?? [];

                if (
                  dateAbsenceSelected ||
                  dateVacationSelected ||
                  dateHolidaySelected ||
                  dateExpenseSelected?.length > 0
                ) {
                  return (
                    <div className="flex flex-col items-center justify-center gap-2">
                      {day.format('D')}
                      <div className="flex flex-row gap-1 items-center">
                        {dateAbsenceSelected && <AbsenceMark />}
                        {dateVacationSelected && <VacationMark />}
                        {dateHolidaySelected && <HolidayMark />}
                        {dateExpenseSelected?.length > 0 && <ExpenseMark>{dateExpenseSelected?.length}</ExpenseMark>}
                      </div>
                    </div>
                  );
                }
                return day.format('D');
              }}
            />
          )}
        </div>

        <div className="w-full mt-4 mx-auto px-2">
          {(queryExpenseLoading ||
            queryExpensesLoading ||
            createExpenseMutation.isLoading ||
            editExpenseMutation.isLoading ||
            deleteExpenseMutation.isLoading) && <Loading className="w-full mx-auto" />}

          {showListExpenses ? (
            expenses?.length === 0 ? (
              !queryExpenseLoading &&
              !queryExpensesLoading && (
                <Col className="w-full justify-center items-center gap-2">
                  <div className="bg-gray-300 rounded-lg p-3">
                    <CurrencyEuroIcon className="w-6 text-white" />
                  </div>
                  <p className="text-gray-400 text-sm text-center">{t('no_records_for_the_selected_date')}.</p>
                </Col>
              )
            ) : (
              expenses.map((expense) => {
                return (
                  <div key={`${expense.id}`} className="border-b-2 border-solid border-gray-200 py-2 w-full">
                    <div className="flex justify-between items-center">
                      <span className="text-gray-700 text-sm font-medium">
                        {expense?.user?.name} - {t(expense.expense_type)}
                      </span>
                      <div className="flex gap-2 items-center">
                        <button
                          onClick={() => handleEditExpenseClick(expense.uuid!)}
                          className="text-primary font-bold text-base hover:text-green-700"
                        >
                          {t('edit')}
                        </button>
                        <button
                          onClick={() => handleDeleteExpense(user?.organization?.uuid!, expense.uuid!)}
                          className="text-red-600 hover:text-red-700"
                        >
                          <TrashIcon className="w-5" />
                        </button>
                      </div>
                    </div>
                    <span className="text-xs text-gray-950 font-semibold">
                      {t('supplier')}: {expense.supplier}
                    </span>
                    <div className="w-full mt-2 flex justify-between items-center">
                      <div className="flex gap-2 items-center">
                        <CheckIcon className="text-primary w-6" />
                        <p className="text-lg text-gray-900 font-normal truncate w-48 md:w-72">{expense.comment}</p>
                      </div>
                      <span className="text-gray-400 text-base font-normal">{Number(expense.total)?.toFixed(2)}€</span>
                    </div>
                  </div>
                );
              })
            )
          ) : (
            <>
              {queryExpenseLoading && <Loading className="w-full mx-auto" />}

              {isEditingExpense ? (
                <div>
                  <div className="my-2">
                    <span className="text-gray-700 text-sm font-medium">{t('date_of_expense')}</span>
                    <p>{expenseDateState ? expenseDateState?.format('DD/MM/yyyy') : ''}</p>
                  </div>
                  <span className="flex flex-row gap-3 items-center w-full">
                    <Toggle className=" my-2" checked={companyMoney} onChange={setCompanyMoney} />
                    {companyMoney ? t('company_money') : t('own_money')}
                  </span>

                  <div className="my-2">
                    <span className="text-gray-700 text-sm font-medium">{t('type_of_expense')}</span>

                    <SelectPicker
                      data={optionsTypesExpenses}
                      placeholder={t('select_the_type_of_expense')}
                      placement="autoVerticalStart"
                      style={{ width: '100%' }}
                      onChange={(e) => setSelectedTypesExpenses(e)}
                      value={selectedTypesExpenses}
                    />
                    <div className="my-2">
                      <span className="text-gray-700 text-sm font-medium">{t('reason')}</span>
                      <div className="grid grid-cols-1 w-full">
                        <Textarea
                          name="comment"
                          id="comment"
                          ref={commentEditRef}
                          value={editedComment}
                          onChange={(e) => setEditedComment(e.target.value)}
                          placeholder={t('write_a_description_of_the_expense')}
                          // className={`resize-none bg-gray-50 border ${validateFormData ? "border-red-500" : "border-primary/90"
                          //     } w-full rounded-lg max-h-36 shadow-md p-2`}
                        />
                        {/* {validateFormData && (
                                            <p className="text-red-500 text-sm mt-2">
                                                Por favor, escreva uma descrição.
                                            </p>
                                        )} */}
                      </div>
                    </div>
                    <div className="my-2">
                      <span className="text-gray-700 text-sm font-medium">{t('supplier')}</span>
                      <div className="grid grid-cols-1 w-full">
                        <Input
                          ref={supplierEditRef}
                          type="text"
                          name="suplier"
                          id="suplier"
                          value={editedSupplier}
                          onChange={(e) => setEditedSupplier(e.target.value)}
                          placeholder="Escreva o nome do fornecedor"
                          // className={`resize-none bg-gray-50 border ${validateFormData ? "border-red-500" : "border-primary/90"
                          //     } w-full rounded-lg max-h-36 shadow-md p-2`}
                        />
                      </div>
                    </div>
                    <div className="my-2">
                      <span className="text-gray-700 text-sm font-medium">{t('total_value')}</span>
                      <div className="grid grid-cols-1 w-full">
                        <InputNumber
                          name="valueTotal"
                          id="valueTotal"
                          ref={valueEditTotalRef}
                          value={editedTotal}
                          // onChange={validateForm}
                          onChange={(e) => setEditedTotal(e.toLocaleString())}
                          placeholder="0.00"
                          prefix="$"
                          // className={`resize-none bg-gray-50 border ${validateFormData ? "border-red-500" : "border-primary/90"
                          //     } w-full rounded-lg shadow-md`}
                          className={`resize-none bg-gray-50 border border-primary/90 w-full rounded-lg shadow-md`}
                        />
                        {/* {validateFormData && (
                                            <p className="text-red-500 text-sm mt-2">
                                                Por favor, preencha o valor.
                                            </p>
                                        )} */}
                      </div>
                    </div>
                    <div className="w-full mt-2">
                      <span className="text-gray-700 text-sm font-medium">{t('file')}</span>
                      <div {...getRootProps({ className: 'dropzone' })}>
                        <Input {...getInputProps()} />
                        <div className="border-2 border-dashed border-primary/90 flex flex-col justify-center items-center p-4 mt-2 cursor-pointer">
                          <PhotoIcon className="text-primary/90 w-12" />
                          <p className="font-medium text-sm text-gray-600 mt-1">
                            <span className="text-primary">{t('upload')} </span>
                            {t('or_drag_and_drop_a_document')}
                          </p>
                          <p className="text-xs font-normal text-primary/90">{t('file_upload_limit')}</p>
                        </div>
                      </div>
                      {files.length > 0 && (
                        <div className="flex flex-col mt-8 gap-2 w-full border p-4 overflow-x-auto items-center justify-center">
                          {files.map((file: CustomFile) => (
                            <div key={file.name}>
                              <div className="relative w-[100px] h-[100px]">
                                {file.type === 'application/pdf' ? (
                                  <DocumentIcon className="text-gray-600 w-24" />
                                ) : (
                                  <img
                                    className="w-[100px] rounded h-[100px] object-cover"
                                    src={file.preview}
                                    // Revogar o data URI após o carregamento da imagem
                                    onLoad={() => {
                                      URL.revokeObjectURL(file.preview);
                                    }}
                                  />
                                )}
                                <button
                                  onClick={() => {
                                    removeFile(file.preview);

                                    handleSaveEditing();
                                  }}
                                  className="absolute right-1 top-1 text-red-500 shadow-md"
                                >
                                  <XMarkIcon className="w-5" />
                                </button>
                              </div>
                            </div>
                          ))}
                          {barcodeFields && barcodeFields?.['A'] && barcodeFields?.['O'] && (
                            <div className="flex flx-row justify-start w-full gap-3">
                              <div className="my-1">
                                <label htmlFor="" className="text-gray-700 text-base font-medium">
                                  NIF {t('supplier')}
                                </label>
                                <p className="text-gray-700 text-lg">{barcodeFields['A']}</p>
                              </div>
                              <div className="my-1">
                                <label htmlFor="" className="text-gray-700 text-base font-medium">
                                  {t('total_value')}
                                </label>
                                <p className="text-gray-700 text-lg">{barcodeFields['O']} €</p>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                      {editingExpenseDetails?.expense_file && (
                        <>
                          <div className="flex flex-col mt-8 gap-2 w-full border p-4 overflow-x-auto items-center justify-center">
                            <div>
                              <div className="relative w-[100px] h-[100px]">
                                {editingExpenseDetails.expense_file?.file &&
                                editingExpenseDetails.expense_file?.file.endsWith('.pdf') ? (
                                  <DocumentIcon className="text-gray-600 w-20" />
                                ) : (
                                  <PhotoIcon className="text-gray-600 w-20" />
                                )}
                                <button
                                  onClick={() => {
                                    deleteFile(
                                      user?.organization?.uuid!,
                                      editingExpenseDetails.uuid!,
                                      editingExpenseDetails.expense_file?.uuid!,
                                    );
                                    setFiles([]);
                                    setEditingExpenseDetails((prevDetails) => ({
                                      ...prevDetails!,
                                      expense_file: null as any | null,
                                    }));
                                  }}
                                  className="absolute right-1 top-1 text-red-500 shadow-md"
                                >
                                  <XMarkIcon className="w-5" />
                                </button>
                              </div>
                            </div>
                            {barcodeFields && barcodeFields?.['A'] && barcodeFields?.['O'] && (
                              <div className="flex flx-row justify-start w-full gap-3">
                                <div className="my-1">
                                  <label htmlFor="" className="text-gray-700 text-base font-medium">
                                    NIF {t('supplier')}
                                  </label>
                                  <p className="text-gray-700 text-lg">{barcodeFields['A']}</p>
                                </div>
                                <div className="my-1">
                                  <label htmlFor="" className="text-gray-700 text-base font-medium">
                                    {t('total_value')}
                                  </label>
                                  <p className="text-gray-700 text-lg">{barcodeFields['O']} €</p>
                                </div>
                              </div>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="my-2">
                    <span className="text-gray-700 text-sm font-medium">{t('date_of_expense')}</span>
                    <p>{expenseDateState ? expenseDateState?.format('DD/MM/yyyy') : ''}</p>
                  </div>
                  <span className="flex flex-row gap-3 items-center w-full">
                    <Toggle className=" my-2" checked={companyMoney} onChange={setCompanyMoney} />
                    {companyMoney ? t('company_money') : t('own_money')}
                  </span>

                  <div className="my-2">
                    <div className="flex flex-col">
                      <span className="text-gray-700 text-sm font-medium">{t('type_of_expense')}</span>
                      <SelectPicker
                        data={optionsTypesExpenses}
                        placeholder={t('select_the_type_of_expense')}
                        placement="autoVerticalStart"
                        style={{ width: '100%' }}
                        onChange={(e) => setSelectedTypesExpenses(e)}
                        value={selectedTypesExpenses}
                      />
                    </div>
                    <div className="my-2">
                      <span className="text-gray-700 text-sm font-medium">{t('reason')}</span>
                      <div className="grid grid-cols-1 w-full">
                        <Textarea
                          name="comment"
                          id="comment"
                          ref={commentRef}
                          value={editedComment}
                          onChange={(e) => setEditedComment(e.target.value)}
                          // onChange={validateForm}
                          placeholder={t('write_a_description_of_the_expense')}
                          // className={`resize-none bg-gray-50 border ${validateFormData ? "border-red-500" : "border-primary/90"
                          //     } w-full rounded-lg max-h-36 shadow-md p-2`}
                        />
                        <span className="text-red-500">{commentError}</span>
                        {/* {validateFormData && (
                                                <p className="text-red-500 text-sm mt-2">
                                                    Por favor, escreva uma descrição.
                                                </p>
                                            )} */}
                      </div>
                    </div>
                    <div className="my-2">
                      <span className="text-gray-700 text-sm font-medium">{t('supplier')}</span>
                      <div className="grid grid-cols-1 w-full">
                        <Input
                          ref={supplierRef}
                          type="text"
                          name="suplier"
                          id="suplier"
                          value={creatingSupplier}
                          onChange={(e) => setCreatingSupplier(e.target.value)}
                          placeholder={t('write_the_name_of_the_supplier')}
                          // className={`resize-none bg-gray-50 border ${validateFormData ? "border-red-500" : "border-primary/90"
                          //     } w-full rounded-lg max-h-36 shadow-md p-2`}
                        />
                      </div>
                    </div>
                    <div className="my-2">
                      <span className="text-gray-700 text-sm font-medium">{t('total_value')}</span>
                      <div className="grid grid-cols-1 w-full">
                        <InputNumber
                          name="valueTotal"
                          id="valueTotal"
                          ref={valueTotalRef}
                          // onChange={validateForm}
                          onChange={(value) => setValueTotal(value as number)}
                          placeholder="0.00"
                          prefix="$"
                          value={valueTotal}
                          // className={`resize-none bg-gray-50 border ${validateFormData ? "border-red-500" : "border-primary/90"
                          //     } w-full rounded-lg shadow-md`}
                          className={`resize-none bg-gray-50 border border-primary/90 w-full rounded-lg shadow-md`}
                        />
                        {/* {validateFormData && (
                                                <p className="text-red-500 text-sm mt-2">
                                                    Por favor, preencha o valor.
                                                </p>
                                            )} */}
                      </div>
                    </div>
                    <div className="w-full mt-2">
                      <span className="text-gray-700 text-sm font-medium">{t('file')}</span>
                      <div {...getRootProps({ className: 'dropzone' })}>
                        <Input {...getInputProps()} />
                        <div className="border-2 border-dashed border-primary/90 flex flex-col justify-center items-center p-4 mt-2 cursor-pointer">
                          <PhotoIcon className="text-primary/90 w-12" />
                          <p className="font-medium text-sm text-gray-600 mt-1">
                            <span className="text-primary">{t('upload')} </span>
                            {t('or_drag_and_drop_a_document')}
                          </p>
                          <p className="text-xs font-normal text-primary/90">{t('file_upload_limit')}</p>
                        </div>
                      </div>
                      {files.length > 0 && (
                        <div className="flex flex-col mt-8 gap-2 w-full border p-4 overflow-x-auto items-center justify-center">
                          {files.map((file: CustomFile) => (
                            <div key={file.name}>
                              <div className="relative w-[100px] h-[100px]">
                                {file.type === 'application/pdf' ? (
                                  <DocumentIcon className="text-gray-600 w-20" />
                                ) : (
                                  <img
                                    className="w-[100px] rounded h-[100px] object-cover"
                                    src={file.preview}
                                    // Revogar o data URI após o carregamento da imagem
                                    onLoad={() => {
                                      URL.revokeObjectURL(file.preview);
                                    }}
                                  />
                                )}
                                <button
                                  onClick={() => removeFile(file.preview)}
                                  className="absolute right-1 top-1 text-red-500 shadow-md"
                                >
                                  <XMarkIcon className="w-5" />
                                </button>
                              </div>
                            </div>
                          ))}
                          {barcodeFields && barcodeFields?.['A'] && barcodeFields?.['O'] && (
                            <div className="flex flx-row justify-start w-full gap-5">
                              <div className="my-1">
                                <label htmlFor="" className="text-gray-700 text-base font-medium">
                                  NIF {t('supplier')}
                                </label>
                                <p className="text-gray-700 text-lg">{barcodeFields['A']}</p>
                              </div>
                              <div className="my-1">
                                <label htmlFor="" className="text-gray-700 text-base font-medium">
                                  {t('total_value')}
                                </label>
                                <p className="text-gray-700 text-lg">{barcodeFields['O']} €</p>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
        {showListExpenses ? (
          <div className="flex gap-4 items-center justify-center w-full sticky bottom-5 mt-3 mx-auto">
            <Button
              className="font-normal"
              onClick={() => {
                setShowListExpenses(false);
                setSelectedTypesExpenses(null);
                setCompanyMoney(false);
              }}
              buttonType={Button.Type.PRIMARY}
            >
              {t('add_expense')}
            </Button>
            <ButtonFloat
              buttonType={Button.Type.PRIMARY}
              onClick={() => {
                navigate(`/organizations/${user!.organization?.uuid}/dashboard/read-expense`);
              }}
              className="bg-primary text-base text-white rounded-full font-normal flex justify-center items-center"
            >
              <QrCodeIcon className="w-6" />
            </ButtonFloat>
          </div>
        ) : (
          <>
            {isEditingExpense ? (
              <div className="grid grid-cols-1 md:grid-cols-2 w-full gap-4 mt-4 mx-auto">
                <Button
                  disabled={editExpenseLoading}
                  buttonType={Button.Type.SECONDARY}
                  className="md:mt-4 w-full"
                  onClick={() => {
                    setIsEditingExpense(false);
                    setShowListExpenses(true);
                    setSelectedTypesExpenses(null);
                    setFiles([]);
                  }}
                >
                  {t('cancel')}
                </Button>
                <Button onClick={handleSaveEditing} className="md:mt-4 w-full" loading={editExpenseLoading}>
                  {t('save_expense')}
                </Button>
              </div>
            ) : (
              <div className="grid grid-cols-1 md:grid-cols-2 w-full gap-4 mt-4 mx-auto">
                <Button
                  disabled={createExpenseLoading}
                  buttonType={Button.Type.SECONDARY}
                  className="md:mt-4 w-full"
                  onClick={() => {
                    setIsEditingExpense(false);
                    setShowListExpenses(true);
                    setSelectedTypesExpenses(null);
                    setFiles([]);
                  }}
                >
                  {t('cancel')}
                </Button>
                <Button onClick={handleSave} className="md:mt-4 w-full" loading={createExpenseLoading}>
                  {t('save_expense')}
                </Button>
              </div>
            )}
          </>
        )}
      </section>
    </>
  );
}

export default ExpensesSection;
