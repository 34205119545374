import { useCallback, useEffect, useState } from 'react';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import AttachmentFilePresenceModal from '../../Presence/Modals/AttachmentFilePresenceModal';
import { ModalDenyAbsence } from '@components/Modal/ModalDenyAbsence';
import { useAuth } from '@hooks/auth/useAuth';
import StatusBadge, { BadgeStatus } from '@components/Badges/StatusBadge';
import { toast } from 'react-toastify';
import Button from '@components/Common/Buttons/Button';
import ButtonFloat from '@components/Common/Buttons/ButtonFloat';
import { values } from 'lodash';
import useAxios from '@hooks/axios/useAxios';
import { useTranslation } from 'react-i18next';
import { getCurrentDimension } from '@components/Presence/Sections/AbsenceSection';
import { DayPickerSingleDateController } from 'react-dates';
import moment from 'moment';
import { format, parse } from 'date-fns';
import { useAbsences } from '@hooks/presence/useAbsences';
import { useVacations } from '@hooks/presence/useVacations';
import parseISO from 'date-fns/esm/parseISO/index.js';
import { Timeline } from 'rsuite';
import { usePresences } from '@hooks/presence/usePresences';
import { useOrganization } from '@hooks/organization/useOrganization';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { PresenceSummary } from '@components/Calendar/Summary/PresenceSummary';
import { AbsenceMark, HolidayMark, PresenceMark, VacationMark } from '@components/Calendar/Summary';
import { Month, NextMonth, PrevMonth } from '@components/Calendar';
import Loading from '@components/loading';
import { ArrowPathIcon, CheckIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { ApprovedDot, PendingDot, RejectedDot } from '@components/Timeline/Dots';

type ModalDenyState = Record<string, boolean>;

function PresenceSection() {
  const { t } = useTranslation();
  const [modalDenyState, setModalDenyState] = useState<ModalDenyState>({});
  const [selectedEmployee, setSelectedEmployee] = useState<string | null>(null);

  const { user } = useAuth();

  const [presenceDateState, setPresenceDateState] = useState<moment.Moment>(moment());
  const [focusedInput, setFocusedInput] = useState<boolean>(false);
  const [screenSize, setScreenSize] = useState(getCurrentDimension());

  const { getOrganizationPresences } = usePresences();
  const { getOrganizationHolidays } = useOrganization();
  const { getOrganizationVacations } = useVacations();
  const { getOrganizationAbsences } = useAbsences();

  const { isLoading: queryVacationsLoading, data: localVacations } = useQuery<any[]>(['vacations', user], () =>
    getOrganizationVacations(user?.organization?.uuid!),
  );

  const { isLoading: queryAbsencesLoading, data: localAbsences } = useQuery<any[]>(['absences', user], () =>
    getOrganizationAbsences(user?.organization?.uuid!),
  );

  const { isLoading: queryHolidaysLoading, data: localHolidays } = useQuery<any[]>(['holidays', user], () =>
    getOrganizationHolidays(user?.organization?.uuid!),
  );

  const { isLoading: queryAllPresencesLoading, data: localPresences } = useQuery<any[]>(['presences', user], () =>
    getOrganizationPresences(user?.organization?.uuid!),
  );

  const queryClient = useQueryClient();

  const manageAbsenceMutation = useMutation({
    mutationFn: async ({ presenceUuid, status }: { presenceUuid: string; status: BadgeStatus }) =>
      managePresenceRequest(`/api/organizations/${user?.organization?.uuid}/presences/${presenceUuid}/manage`, 'POST', {
        user_id: user?.id,
        presence_status: status,
      })
        .then(() => {
          getPresences();
          toast.success('Presença atualizada com sucesso!');
        })
        .catch(({ response }) => {
          values(response?.data?.errors)?.map((errors: any) => errors?.map((error: any) => toast.error(error)));
        }),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: ['presences'] });
    },
  });
  const [presenceRequests, setPresenceRequests] = useState<any[]>([]);

  const { loading: queryPresencesLoading, request: queryPresencesRequest } = useAxios();
  const { loading: managePresenceLoading, request: managePresenceRequest } = useAxios();
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  const getPresences = useCallback(() => {
    const day = presenceDateState.toDate()?.getDate();
    const month = presenceDateState.toDate().getMonth() + 1;
    const year = presenceDateState.toDate().getFullYear();

    queryPresencesRequest(
      `/api/organizations/${user?.organization?.uuid}/presences/date/${year}/${month}/${day}?sortBy=created_at&sortDirection=desc`,
      'GET',
    )
      .then(({ data }) => {
        setPresenceRequests(data?.content?.data?.data ?? []);
      })
      .catch(({ response }) => {
        values(response?.data?.errors)?.map((errors: any) => errors?.map((error: any) => toast.error(error)));
      });
  }, [user, presenceDateState]);

  const managePresence = useCallback(
    (status: BadgeStatus, presenceUuid: string) => {
      manageAbsenceMutation.mutate({ status, presenceUuid });
    },
    [user, getPresences],
  );

  useEffect(() => {
    getPresences();
  }, [presenceDateState]);

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener('resize', updateDimension);

    return () => {
      window.removeEventListener('resize', updateDimension);
    };
  }, [screenSize]);

  return (
    <>
      <section className="flex flex-col items-center justify-center w-full pb-10">
        <AttachmentFilePresenceModal />
        <div className="mt-4 w-full flex flex-row justify-center">
          <DayPickerSingleDateController
            daySize={48}
            navNext={<NextMonth />}
            navPrev={<PrevMonth />}
            renderMonthElement={Month}
            initialVisibleMonth={() => moment()}
            noBorder
            verticalBorderSpacing={5}
            date={presenceDateState}
            onFocusChange={({ focused }) => {
              setFocusedInput(focused);
            }}
            focused={focusedInput}
            renderCalendarInfo={() => (
              <PresenceSummary>
                <span className="text-base text-gray-950 flex flex-row items-center gap-2">
                  <PresenceMark /> {t('presence')}
                </span>
              </PresenceSummary>
            )}
            onDateChange={(date: any) => {
              setPresenceDateState(date);
            }}
            renderDayContents={(day) => {
              const dateAbsenceSelected = localAbsences?.some((absence: any) => {
                const absenceDate = parse(
                  format(parseISO(absence?.created_at), 'yyyy-MM-dd HH:mm:ss'),
                  'yyyy-MM-dd HH:mm:ss',
                  new Date(),
                );

                return day.isSame(moment(absenceDate, 'MM/D/YYYY'), 'date');
              });

              const dateVacationSelected = localVacations?.some((vacation: any) => {
                const vacationDate = parse(
                  format(parseISO(vacation?.created_at), 'yyyy-MM-dd HH:mm:ss'),
                  'yyyy-MM-dd HH:mm:ss',
                  new Date(),
                );
                return day.isSame(moment(vacationDate, 'MM/D/YYYY'), 'date');
              });

              const dateHolidaySelected = localHolidays?.some((holiday: any) => {
                const selectedHoliday = parse(holiday.date, 'yyyy-MM-dd HH:mm:ss', new Date());

                return day.isSame(moment(selectedHoliday, 'MM/D/YYYY'), 'date');
              });

              const datePresenceSelected =
                localPresences?.filter((presence: any) => {
                  const selectedPresence = parse(presence.time, 'yyyy-MM-dd HH:mm:ss', new Date());

                  return day.isSame(moment(selectedPresence, 'MM/D/YYYY'), 'date');
                }) ?? [];

              if (
                dateAbsenceSelected ||
                dateVacationSelected ||
                dateHolidaySelected ||
                datePresenceSelected?.length > 0
              ) {
                return (
                  <div className="flex flex-col items-center justify-center gap-2">
                    {day.format('D')}
                    <div className="flex flex-row gap-1 items-center">
                      {dateAbsenceSelected && <AbsenceMark />}
                      {dateVacationSelected && <VacationMark />}
                      {dateHolidaySelected && <HolidayMark />}
                      {datePresenceSelected?.length > 0 && <PresenceMark>{datePresenceSelected?.length}</PresenceMark>}
                    </div>
                  </div>
                );
              }
              return day.format('D');
            }}
          />
        </div>

        {presenceDateState && (
          <>
            <h2 className="text-base font-semibold w-full">
              {t('absence_requests_for')} {presenceDateState?.toDate().toLocaleDateString('pt-BR')}
            </h2>
            <div className="mt-4 w-full overflow-x-auto rounded-lg">
              {!queryPresencesLoading &&
                !queryVacationsLoading &&
                !queryAbsencesLoading &&
                !queryHolidaysLoading &&
                !queryAllPresencesLoading &&
                presenceRequests.length == 0 && (
                  <p className="text-center text-gray-950 text-base w-full">{t('no_absences_to_show')}</p>
                )}

              {(queryPresencesLoading ||
                queryVacationsLoading ||
                queryAbsencesLoading ||
                queryHolidaysLoading ||
                queryAllPresencesLoading) && <Loading className="w-full mx-auto" />}

              {!queryPresencesLoading &&
                !queryVacationsLoading &&
                !queryAbsencesLoading &&
                !queryHolidaysLoading &&
                !queryAllPresencesLoading &&
                presenceRequests?.map((presence, index) => {
                  const startTimePresence = String(new Date(presence?.time).toISOString().split('T')[0])
                    .replace('-', '/')
                    .replace('-', '/');

                  return (
                    <section
                      key={presence?.id}
                      className="flex flex-col gap-4 w-full border-b-2 border-solid border-gray-200 py-2"
                    >
                      <div className="flex justify-between items-center">
                        <span className="text-gray-700 text-sm font-medium">
                          {String(new Date(presence?.time).toISOString().split('T')[0])
                            .replace('-', '/')
                            .replace('-', '/')}
                          {' - '}
                          {presence?.user?.name} {t('requested_an_presence')}
                        </span>
                        <div className="flex flex-row gap-3 items-center">
                          <button
                            className="p-2 flex items-center justify-center hover:bg-primary/20 rounded-lg"
                            onClick={() => {
                              if (selectedIndex == index && typeof selectedIndex == 'number') {
                                setSelectedIndex(null);
                              } else setSelectedIndex(index);
                            }}
                          >
                            <ArrowPathIcon className="text-primary text-lg w-5 h-5" />
                          </button>
                          <StatusBadge status={presence?.status} />
                        </div>
                      </div>
                      {(selectedIndex == index && presence.statuses.length >= 0 && (
                        <Timeline className="custom-timeline ml-4">
                          {presence.statuses.map((status: any) => {
                            if (status.name == 'pending') {
                              return (
                                <Timeline.Item dot={<PendingDot />} key={status?.id}>
                                  <p className="ml-2">{format(parseISO(status?.created_at), 'yyyy/MM/dd HH:mm:ss')}</p>
                                  <p className="ml-2">{`${status?.user?.name} ${t('requested_an_presence')}`}</p>
                                </Timeline.Item>
                              );
                            } else if (status.name == 'rejected') {
                              return (
                                <Timeline.Item dot={<RejectedDot />} key={status?.id}>
                                  <p className="ml-2">{format(parseISO(status?.created_at), 'yyyy/MM/dd HH:mm:ss')}</p>
                                  <p className="ml-2">{`${status?.user?.name} ${t('rejected_presence_request')}`}</p>
                                </Timeline.Item>
                              );
                            } else {
                              return (
                                <Timeline.Item dot={<ApprovedDot />} key={status?.id}>
                                  <p className="ml-2">{format(parseISO(status?.created_at), 'yyyy/MM/dd HH:mm:ss')}</p>
                                  <p className="ml-2">{`${status?.user?.name} ${t('approved_presence_request')}`}</p>
                                </Timeline.Item>
                              );
                            }
                          })}
                        </Timeline>
                      )) ||
                        (selectedIndex == index && presence.statuses.length == 0 && (
                          <span className="text-base text-black text-center w-full">{t('nothing_to_show')}</span>
                        ))}

                      <div className="flex flex-row justify-between items-center">
                        <span key={presence?.id} className="text-black text-base">
                          {startTimePresence}
                        </span>

                        <div className="flex flex-row gap-3 items-center">
                          <ButtonFloat
                            disabled={managePresenceLoading}
                            buttonType={Button.Type.ERROR}
                            onClick={() => managePresence('rejected' as BadgeStatus, presence?.uuid)}
                          >
                            <XMarkIcon className="w-6" />
                          </ButtonFloat>
                          <ButtonFloat
                            disabled={managePresenceLoading}
                            buttonType={Button.Type.SUCCESS}
                            onClick={() => managePresence('approved' as BadgeStatus, presence?.uuid)}
                          >
                            <CheckIcon className="w-6" />
                          </ButtonFloat>
                        </div>
                      </div>
                    </section>
                  );
                })}
            </div>
          </>
        )}
      </section>
      {selectedEmployee && modalDenyState[selectedEmployee] && (
        <ModalDenyAbsence
          onConfirm={async () => {
            setModalDenyState((prevState) => ({
              ...prevState,
              [selectedEmployee]: false,
            }));
            setSelectedEmployee(null);
          }}
          isOpen={modalDenyState[selectedEmployee]}
          onClose={() => {
            setModalDenyState((prevState) => ({
              ...prevState,
              [selectedEmployee]: false,
            }));
            setSelectedEmployee(null);
          }}
          title={t('deny_absence_request')}
          content={`${t('are_you_sure_you_want_to_deny_the_absence_request_for')} ${selectedEmployee}?`}
        />
      )}
    </>
  );
}

export default PresenceSection;
