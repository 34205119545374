import { BadgeStatus } from '@components/Badges/StatusBadge';
import { User } from '@hooks/auth/useAuth';
import { LIMIT_RECORDS } from 'App';
import { toast } from 'react-toastify';
import { api } from 'services/api';
import { create } from 'zustand';

export interface CustomFile extends Blob {
  path: string;
  preview: string;
  name: string;
  lastModified: number;
  lastModifiedDate: Date;
  webkitRelativePath: string;
  size: number;
  dataQrCode?: any;
  type: string;
  realFile?: any;
}

export interface Absence {
  uuid?: string;
  organizationUuid: string;
  user_id: number;
  absence_reason: string;
  absence_type: 'scheduled' | 'unscheduled';
  time_in?: string;
  time_out?: string;
  time_in_confirm?: string;
  time_out_confirm?: string;
  created_at?: string;
  status?: BadgeStatus;
  absence_file?: AbsenceFile;
  statuses: any[];
}

export interface AbsenceFile {
  absence_id: number;
  file: string;
  file_path: string;
  id: number;
  uuid: string;
}

type AbsencesState = {
  files: CustomFile[];
  setFiles: (value: any) => void;
  isOpenFileModal: boolean;
  setIsOpenFileModal: (value: boolean) => void;
  clearConfigAbsences(): void;
  removeFile(state: any): void;
  currentSection: 'absence' | 'vocation';
  setCurrentSection: (section: 'absence' | 'vocation') => void;
  getAllAbsences: (user: User) => Promise<any>;
  createAbsence: (absence: Absence, file: File | '') => Promise<void>;
  deleteFile: (OrganizationUuid: string, AbsenceUuid: string, AbsenceFileUuid: string) => Promise<void>;
  getOrganizationAbsences: (organizationUuid: string) => Promise<any>;
};

export const useAbsences = create<AbsencesState>((set) => ({
  files: [],

  setFiles: (value: CustomFile[]) => set({ files: value }),
  isOpenFileModal: false,

  setIsOpenFileModal: (value) => set({ isOpenFileModal: value }),

  removeFile: (identificator: string) => {
    set((state) => ({
      files: state.files.filter((file: any) => file.preview !== identificator),
    }));
  },
  clearConfigAbsences: () => set({ isOpenFileModal: false, files: [] }),

  getOrganizationAbsences: (organizationUuid: string) => {
    return api
      .get(
        `/api/organizations/${organizationUuid}/absences/date?take=${LIMIT_RECORDS}&sortBy=created_at&sortDirection=desc`,
      )
      .then(({ data }) => data?.content?.data?.data);
  },

  getAllAbsences: (user: User) => {
    return api
      .get(
        `/api/organizations/${user?.organization?.uuid}/absences?take=${LIMIT_RECORDS}&sortBy=created_at&sortDirection=desc&user_id=${user?.id}`,
      )
      .then(({ data }) => data?.content?.data?.data);
  },

  currentSection: 'absence',

  setCurrentSection: (section) => set({ currentSection: section }),

  createAbsence: async (absence: Absence, file: File | '') => {
    try {
      const form = new FormData();
      form.append('organizationUuid', absence.organizationUuid);
      form.append('user_id', String(absence.user_id));
      form.append('absence_reason', absence.absence_reason);
      form.append('absence_type', absence.absence_type);
      form.append('time_in', absence.time_in!);
      form.append('time_out', absence.time_out!);
      if (file) {
        form.append('file', file);
      }

      return api.post(`/api/organizations/${absence.organizationUuid}/absences`, form);
    } catch (error: any) {
      return error;
    }
  },
  deleteFile: async (OrganizationUuid: string, AbsenceUuid: string, AbsenceFileUuid: string) => {
    try {
      await api.delete(`/api/organizations/${OrganizationUuid}/absences/${AbsenceUuid}/file/${AbsenceFileUuid}`);
      toast.success('Arquivo deletado com sucesso!');
    } catch (error) {
      toast.error('Erro ao deletar arquivo');
      console.error(error);
    }
  },
}));
