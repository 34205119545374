import { useRef, useState } from 'react';

import { useAuth } from '@hooks/auth/useAuth';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Button from '@components/Common/Buttons/Button';
import { useLocation } from '@hooks/location/useLocation';
import { useTranslation } from 'react-i18next';
import Input from '@components/Inputs';

export default function CreateLocation() {
  const { t } = useTranslation();
  const { user } = useAuth();
  const navigate = useNavigate();
  const { createLocation } = useLocation();
  const [loading, setLoading] = useState(false);

  const nameRef = useRef<HTMLInputElement>(null);
  const addressRef = useRef<HTMLInputElement>(null);
  const numberRef = useRef<HTMLInputElement>(null);
  const complementRef = useRef<HTMLInputElement>(null);
  const neighborhoodRef = useRef<HTMLInputElement>(null);
  const zipCodeRef = useRef<HTMLInputElement>(null);
  const cityRef = useRef<HTMLInputElement>(null);
  const stateRef = useRef<HTMLInputElement>(null);
  const latRef = useRef<HTMLInputElement>(null);
  const lngRef = useRef<HTMLInputElement>(null);

  async function handleCreateLocation() {
    setLoading(true);
    const location = {
      name: nameRef.current?.value,
      street_address: addressRef.current?.value,
      number: numberRef.current?.value,
      complement: complementRef.current?.value,
      city: cityRef.current?.value,
      state: stateRef.current?.value,
      zip_code: zipCodeRef.current?.value,
      neighborhood: neighborhoodRef.current?.value,
      lat: latRef.current?.value,
      lng: lngRef.current?.value,
    };

    await createLocation(user?.organization?.uuid!, location)
      .then(() => {
        toast.success('Localização criada com sucesso!');
        navigate(`/organizations/${user?.organization?.uuid}/dashboard/locations`);
      })
      .catch((error) => {
        console.error('Erro ao criar localização', error);

        if (error.response.data.errors.city && error.response.data.errors.city[0]) {
          toast.error(error.response.data.errors.city[0]);
        }
        if (error.response.data.errors.lat && error.response.data.errors.lat[0]) {
          toast.error(error.response.data.errors.lat[0]);
        }
        if (error.response.data.errors.lng && error.response.data.errors.lng[0]) {
          toast.error(error.response.data.errors.lng[0]);
        }
        if (error.response.data.errors.name && error.response.data.errors.name[0]) {
          toast.error(error.response.data.errors.name[0]);
        }
        if (error.response.data.errors.number && error.response.data.errors.number[0]) {
          toast.error(error.response.data.errors.number[0]);
        }
        if (error.response.data.errors.state && error.response.data.errors.state[0]) {
          toast.error(error.response.data.errors.state[0]);
        }
        if (error.response.data.errors.street_address && error.response.data.errors.street_address[0]) {
          toast.error(error.response.data.errors.street_address[0]);
        }
        if (error.response.data.errors.zip_code && error.response.data.errors.zip_code[0]) {
          toast.error(error.response.data.errors.zip_code[0]);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <main className="px-4">
      <div className="flex flex-col pt-4 lg:max-w-5xl md:max-w-3xl max-w-lg w-full mx-auto">
        <div className="flex flex-col gap-2">
          <h1 className="text-4xl text-gray-800 font-bold">{t('new_location')}</h1>
          <h2 className="text-gray-600 text-base">{t('here_you_can_add_a_new_location')}</h2>
        </div>
      </div>
      <div className="mt-9 text-gray-600 bg-gray-100 rounded-lg shadow-lg p-4 mb-11 px-4 lg:max-w-5xl md:max-w-3xl max-w-lg w-full mx-auto">
        <div className="w-full grid grid-cols-1 gap-2 mb-3">
          <div className="flex flex-col">
            <label htmlFor="" className="font-bold">
              {t('name')}
            </label>
            <Input type="text" ref={nameRef} placeholder="Jaskolski-Abernathy" name="nome" />
          </div>
        </div>
        <div className="w-full grid lg:grid-cols-4 grid-cols-1 gap-2 mb-3">
          <div className="flex flex-col col-span-3">
            <label htmlFor="" className="font-bold">
              {t('address')}
            </label>
            <Input
              type="text"
              ref={addressRef}
              placeholder="Rua Tressie Walks, 34, Sala B, Linda Spurs"
              name="endereco"
            />
          </div>
          {/* <div className='flex flex-col'>
            <label htmlFor="" className="font-bold">
              Número
            </label>
            <Input type="text" ref={numberRef} placeholder="35" name="numero" className='border border-solid border-gray-200 rounded-lg px-3 py-2' />
          </div>
          <div className='flex flex-col'>
            <label htmlFor="" className="font-bold">
              Complemento
            </label>
            <Input type="text" ref={complementRef} placeholder="Sala B" name="complemento" className='border border-solid border-gray-200 rounded-lg px-3 py-2' />
          </div>
          <div className='flex flex-col'>
            <label htmlFor="" className="font-bold">
              Bairro
            </label>
            <Input type="text" ref={neighborhoodRef} placeholder="Linda Spurs" name="bairro" className='border border-solid border-gray-200 rounded-lg px-3 py-2' />
          </div> */}
          <div className="flex flex-col col-span-1">
            <label htmlFor="" className="font-bold">
              {t('zip_code')}
            </label>
            <Input type="text" ref={zipCodeRef} placeholder="65800-000" name="cep" />
          </div>
        </div>
        <div className="w-full grid md:grid-cols-4 grid-cols-2 gap-2">
          <div className="flex flex-col col-span-2">
            <label htmlFor="" className="font-bold">
              {t('city')}
            </label>
            <Input
              type="text"
              ref={cityRef}
              placeholder="Lisboa"
              name="cidade"
              className="border border-solid border-gray-200 rounded-lg px-3 py-2"
            />
          </div>
          {/* <div className='flex flex-col'>
            <label htmlFor="" className="font-bold">
              Estado
            </label>
            <Input type="text" ref={stateRef} placeholder="Lisboa" name="cidade" className='border border-solid border-gray-200 rounded-lg px-3 py-2' />
          </div> */}
          <div className="flex flex-col">
            <label htmlFor="" className="font-bold">
              Latitude
            </label>
            <Input type="text" ref={latRef} placeholder="25.3696505" name="latitude" />
          </div>
          <div className="flex flex-col">
            <label htmlFor="" className="font-bold">
              Longitude
            </label>
            <Input type="text" ref={lngRef} placeholder="-49.6636505" name="longitude" />
          </div>
        </div>
        <div className="mt-6 mb-2 flex flex-col gap-3">
          <Button onClick={handleCreateLocation} loading={loading}>
            {t('create_location')}
          </Button>
          <Button
            onClick={() => {
              navigate(`/organizations/${user?.organization?.uuid}/dashboard/locations`);
            }}
            buttonType={Button.Type.SECONDARY}
          >
            {t('cancel')}
          </Button>
        </div>
      </div>
    </main>
  );
}
