import { User } from '@hooks/auth/useAuth';
import { LIMIT_RECORDS } from 'App';
import { toast } from 'react-toastify';
import { api } from 'services/api';
import { create } from 'zustand';

interface OwnCar {
  origin?: string;
  destiny?: string;
  kilometers_traveled?: number;
  reason?: string;
}

interface TravelAssistance {
  value?: number | string;
  subsistence_allowance_start?: string;
  subsistence_allowance_end?: string;
  location?: string;
  territory?: string;
  description?: string;
}
export interface Displacement {
  id?: number;
  uuid?: string;
  date?: string;
  origin?: string;
  destiny?: string;
  reason?: string;
  value?: string;
  location?: string;
  displacement_type: string;
  kilometers_traveled?: string;
  subsistence_allowance_start?: string;
  subsistence_allowance_end?: string;
  territory?: string;
  user_id: number;
  user?: User;
  movable?: OwnCar & TravelAssistance;
  movable_type?: 'App\\Models\\OwnCarDisplacement' | 'App\\Models\\TravelAssistanceDisplacement';
  organization_id?: string;
}

type DisplacementState = {
  displacements: Displacement[];
  getDisplacements: (
    organizationUuid: string,
    year: number,
    month: number,
    day: number,
    user_id: number,
    admin?: false,
  ) => Promise<void>;

  getDisplacementsByDate: (
    organizationUuid: string,
    year: number,
    month: number,
    day: number,
    user_id: number,
    admin?: false,
  ) => Promise<any>;
  getAllUserDisplacements: (user: User) => Promise<any>;

  getDisplacementByUuid: (organizationUuid: string, displacementUuid: string) => Promise<void>;
  editDisplacement: (displacement: Displacement, organizationUuid: string, displacementUuid: string) => Promise<void>;
  createDisplacement: (displacement: Displacement) => Promise<void>;
};

export const useDisplacement = create<DisplacementState>((set) => ({
  displacements: [],

  getDisplacements: async (
    organizationUuid: string,
    year: number,
    month: number,
    day: number,
    user_id: number,
    admin?: boolean,
  ) => {
    try {
      let endpoint = `/api/organizations/${organizationUuid}/displacements/date/${year}/${month}/${day}?take=${LIMIT_RECORDS}&user_id=${user_id}&sortBy=created_at&sortDirection=desc`;

      if (admin) {
        endpoint = `/api/organizations/${organizationUuid}/displacements/date/${year}/${month}/${day}?take=${LIMIT_RECORDS}0&sortBy=created_at&sortDirection=desc`;
      }
      const { data } = await api.get(endpoint);
      const displacements = data?.content?.data?.data ?? [];
      set({ displacements });
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  getDisplacementsByDate: async (
    organizationUuid: string,
    year: number,
    month: number,
    day: number,
    user_id: number,
    admin?: boolean,
  ) => {
    try {
      let endpoint = `/api/organizations/${organizationUuid}/displacements/date/${year}/${month}/${day}?take=${LIMIT_RECORDS}&user_id=${user_id}&sortBy=created_at&sortDirection=desc`;

      if (admin) {
        endpoint = `/api/organizations/${organizationUuid}/displacements/date/${year}/${month}/${day}?take=${LIMIT_RECORDS}&sortBy=created_at&sortDirection=desc`;
      }
      const { data } = await api.get(endpoint);
      return data?.content?.data?.data ?? [];
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  getAllUserDisplacements: async (user: User) => {
    return api
      .get(
        `/api/organizations/${user?.organization?.uuid}/displacements?take=${LIMIT_RECORDS}&sortBy=created_at&sortDirection=desc&user_id=${user?.id}`,
      )
      .then(({ data }) => data?.content?.data?.data);
  },
  getDisplacementByUuid: async (organizationUuid: string, displacementUuid: string) => {
    try {
      const { data } = await api.get(`/api/organizations/${organizationUuid}/displacements/${displacementUuid}`);
      return data?.content?.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  createDisplacement: async (displacement: Displacement) => {
    try {
      return api.post(`/api/organizations/${displacement.organization_id}/displacements`, displacement);
    } catch (error: any) {
      if (error.response.data.errors.date && error.response.data.errors.date[0]) {
        toast.error(error.response.data.errors.date[0]);
      }
      if (error.response.data.errors.displacement_type && error.response.data.errors.displacement_type[0]) {
        toast.error(error.response.data.errors.displacement_type[0]);
      }
      if (error.response.data.errors.user_id && error.response.data.errors.user_id[0]) {
        toast.error(error.response.data.errors.user_id[0]);
      }
      if (error.response.data.errors.reason && error.response.data.errors.reason[0]) {
        toast.error(error.response.data.errors.reason[0]);
      }
      if (
        error.response.data.errors.subsistence_allowance_end &&
        error.response.data.errors.subsistence_allowance_end[0]
      ) {
        toast.error(error.response.data.errors.subsistence_allowance_end[0]);
      }
      if (
        error.response.data.errors.subsistence_allowance_start &&
        error.response.data.errors.subsistence_allowance_start[0]
      ) {
        toast.error(error.response.data.errors.subsistence_allowance_start[0]);
      }
      if (error.response.data.errors.location && error.response.data.errors.location[0]) {
        toast.error(error.response.data.errors.location[0]);
      }
      if (error.response.data.errors.territory && error.response.data.errors.territory[0]) {
        toast.error(error.response.data.errors.territory[0]);
      }
      console.error(error);
    }
  },
  editDisplacement: async (displacement: Displacement, organizationUuid: string, displacementUuid: string) => {
    const form = new FormData();
    form.append('user_id', String(displacement.user_id));
    form.append('Displacements_type', displacement?.displacement_type);
    form.append('reason', displacement?.reason!);
    form.append('date', displacement?.date!);
    form.append('origin', displacement?.origin!);
    form.append('destiny', String(displacement.destiny));
    form.append('kilometers_traveled', displacement.kilometers_traveled!);
    form.append('_method', 'PUT');

    return api.post(`/api/organizations/${organizationUuid}/displacements/${displacementUuid}`, form);
  },
  // createDisplacements: async (Displacements: Displacements) => {
  //     try {
  //         const form = new FormData();
  //         form.append("user_id", Displacements.user_id);
  //         form.append("date", Displacements.date!);
  //         form.append("origin", Displacements.origin!);
  //         form.append("destiny", Displacements.destiny!);
  //         form.append("reason", Displacements.reason!);
  //         form.append("Displacements_type", Displacements.Displacements_type);
  //         form.append("kilometers_traveled", Displacements.kilometers_traveled!);
  //         form.append("subsistence_allowance_start", Displacements.subsistence_allowance_start!);
  //         form.append("subsistence_allowance_end", Displacements.subsistence_allowance_end!);
  //         form.append("territory", Displacements.territory!);
  //         form.append("location", Displacements.location!);
  //         form.append("value", Displacements.value!);

  //         try{
  //             await api.post(`/api/organizations/${Displacements.organization_id}/Displacementss`);
  //             toast.success("Deslocação cadastrada com sucesso!");
  //         } catch (error: any) {
  //             if (error.response.data.errors.date && error.response.data.errors.date[0]) {
  //                 toast.error(error.response.data.errors.date[0]);
  //             }
  //             if (error.response.data.errors.Displacements_type && error.response.data.errors.Displacements_type[0]) {
  //                 toast.error(error.response.data.errors.Displacements_type[0]);
  //             }
  //             if (error.response.data.errors.user_id && error.response.data.errors.user_id[0]) {
  //                 toast.error(error.response.data.errors.user_id[0]);
  //             }
  //             if (error.response.data.errors.reason && error.response.data.errors.reason[0]) {
  //                 toast.error(error.response.data.errors.reason[0]);
  //             }
  //         }

  //     } catch (error) {
  //         console.error(error);
  //     }
  // }
}));
