import { Link } from 'react-router-dom';

interface ModalProps {
  content: string;
  href: string;
}

export function ButtonDefault({ content, href }: ModalProps) {
  return (
    <Link
      to={href}
      className="bg-primary text-sm cursor-pointer hover:brightness-[.95] text-white font-medium w-max px-3 py-2 rounded-md transition-all"
    >
      {content}
    </Link>
  );
}
