import { Notifications } from '../Profile/Notifications';
import { useAuth } from '@hooks/auth/useAuth';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import FastdocLog from '@assets/FastdocLogo';
import { useTranslation } from 'react-i18next';
import { ChevronLeftIcon } from '@heroicons/react/24/solid';
import { useLayout } from '@hooks/layout/useLayout';
import { usePresences } from '@hooks/presence/usePresences';
import { useEffect, useMemo, useState } from 'react';
import { PointProps } from '@hooks/presence/usePoint';
import moment from 'moment';
import { addTimes } from '@components/Presence/Modals/QRCodePresenceModal';

function HeaderHome() {
  const { t } = useTranslation();
  const { user } = useAuth();

  const navigate = useNavigate();
  const { pageTitle } = useLayout();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentPresenceCounterSum, setCurrentPresenceCounterSum] = useState<string | null>(null);

  function handleGoBack() {
    navigate(-1);
  }
  const { pathname } = useLocation();

  const isDashboardRoute =
    pathname === `/organizations/${user?.organization?.uuid}/dashboard` || pathname === `/dashboard`;

  function getGreeting() {
    const hours = new Date().getHours();
    if (hours < 12) {
      return t('good_morning');
    } else if (hours >= 12 && hours < 18) {
      return t('good_afternoon');
    } else {
      return t('good_evening');
    }
  }

  const hrefAdminOrSuperAdmin =
    user?.roles && user!?.roles[0].name === 'superAdmin'
      ? '/dashboard'
      : `/organizations/${user?.organization?.uuid}/dashboard`;

  const { globalPresences, refreshGlobalPresences } = usePresences();

  const presenceHoursSum = useMemo(() => {
    const times: string[] = globalPresences?.map?.((dayPresence: PointProps, index) => {
      const previousPresence = globalPresences[index - 1];

      if (dayPresence?.type == 'in' && previousPresence?.type == 'out') {
        var dif = moment.duration(moment(previousPresence?.time).diff(moment(dayPresence?.time)));

        return [dif.hours(), dif.minutes(), dif.seconds()].join(':');
      }
      return 'OO:OO:OO';
    });

    return addTimes(times);
  }, [globalPresences]);

  const lastPresence = useMemo(() => globalPresences?.[0], [globalPresences]);

  useEffect(() => {
    const date = new Date();

    const day = date?.getDate();
    const month = date?.getMonth()! + 1;
    const year = date?.getFullYear();

    if (user) setIsLoading(true);
    refreshGlobalPresences(user?.organization?.uuid!, user?.uuid!, day, month, year).finally(() => {
      setIsLoading(false);
    });
  }, [user]);

  useEffect(() => {
    if (lastPresence && presenceHoursSum) {
      var dif = moment.duration(moment(new Date()).diff(moment(lastPresence?.time)));

      setCurrentPresenceCounterSum(addTimes([presenceHoursSum, [dif.hours(), dif.minutes(), dif.seconds()].join(':')]));
    }
    const interval = setInterval(() => {
      if (lastPresence) {
        setCurrentPresenceCounterSum((prevState) => addTimes([prevState ?? '00:00:00', '00:00:01']));
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [lastPresence, presenceHoursSum]);

  return (
    <header className="flex items-center justify-between fixed w-full  py-3 top-0 z-10 bg-gray-50">
      <div className="w-screen absolute flex justify-center">
        <Link to={hrefAdminOrSuperAdmin} className="mx-auto ">
          {(pageTitle && <h3 className="text-3xl font-extrabold text-blue-900">{pageTitle}</h3>) || <FastdocLog />}
        </Link>
      </div>
      <div className="flex items-center gap-4 pb-3 px-3">
        {!isDashboardRoute && (
          <button onClick={handleGoBack} className="p-2 z-10">
            <ChevronLeftIcon className="w-6" />
          </button>
        )}

        <div className="hidden md:block">
          {user?.roles && user.roles.length > 0 && user.roles[0].name !== 'superAdmin' && (
            <span className="text-gray-400 text-sm">
              {t('organization')} {user.organization?.name}
            </span>
          )}
          <h2 className="text-lg font-semibold text-gray-600 flex flex-row gap-2 items-center">
            {getGreeting()}!{' '}
            {user?.roles && user.roles.length > 0 && user.roles[0].name !== 'superAdmin' && (
              <span className="font-bold text-primary ">
                {t('welcome')}, {user.name}
              </span>
            )}
            {user?.roles && user.roles.length > 0 && user.roles[0].name === 'superAdmin' && (
              <span className="font-bold text-primary">{user.name}</span>
            )}
            {(isLoading && <PresenceHourSkeleton />) ||
              (lastPresence?.type === 'in' && (
                <span className="text-normal font-normal text-black">{currentPresenceCounterSum}</span>
              )) ||
              presenceHoursSum}
          </h2>
        </div>
      </div>

      <div className="flex gap-5 my-2">
        <Notifications />
      </div>
    </header>
  );
}

export const PresenceHourSkeleton = () => {
  return <div className="w-28 h-5 bg-gray-400 animate-pulse rounded-lg" />;
};

export default HeaderHome;
