import FastdocBanner from '@components/Banners/FastdocBanner';
import { PresenceHourSkeleton } from '@components/Home/HeaderHome';
import { addTimes } from '@components/Presence/Modals/QRCodePresenceModal';
import { PauseCircleIcon, PlayCircleIcon, QrCodeIcon } from '@heroicons/react/24/solid';
import { useAuth } from '@hooks/auth/useAuth';
import { PointProps, usePoint } from '@hooks/presence/usePoint';
import { usePresences } from '@hooks/presence/usePresences';
import { format } from 'date-fns';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { useLayout } from '@hooks/layout/useLayout';
import TabLink from '@components/Navbar/TabLink';

export default function ReadPoint() {
  const { t } = useTranslation();

  const { user } = useAuth();

  const { setPageTitle } = useLayout();

  useEffect(() => {
    setPageTitle(t('read_point'));

    return () => {
      setPageTitle(null);
    };
  }, []);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { globalPresences, refreshGlobalPresences } = usePresences();
  const [currentPresenceCounterSum, setCurrentPresenceCounterSum] = useState<string | null>(null);
  const { createPointQrCode } = usePoint();

  const presenceHoursSum = useMemo(() => {
    const times: string[] = globalPresences?.map?.((dayPresence: PointProps, index) => {
      const previousPresence = globalPresences[index - 1];

      if (dayPresence?.type == 'in' && previousPresence?.type == 'out') {
        var dif = moment.duration(moment(previousPresence?.time).diff(moment(dayPresence?.time)));

        return [dif.hours(), dif.minutes(), dif.seconds()].join(':');
      }
      return 'OO:OO:OO';
    });

    return addTimes(times);
  }, [globalPresences]);

  const lastPresence = useMemo(() => globalPresences?.[0], [globalPresences]);

  const dayType = useMemo<'out' | 'in'>(() => {
    //@ts-ignore
    const dayNow = globalPresences[0] ?? undefined;
    //@ts-ignore
    return dayNow?.type === 'in' ? 'out' : 'in';
  }, [globalPresences]);

  const now = new Date();

  const formattedNow = format(now, 'yyyy-MM-dd HH:mm:ss');

  function savePresenceQrCode() {
    const point = {
      user_id: user!.id,
      organization_id: user!.organization!.uuid!,
      type: dayType,
      time: formattedNow,
    } as PointProps;
    createPointQrCode(
      `api/organizations/${user?.organization?.uuid}/location/${user?.location?.uuid}/presence-qrcodes`,
      point,
    )
      .then(() => {
        toast.success(dayType === 'in' ? t('entry_successfully_registered') : t('exit_successfully_registered'));
        const date = new Date();
        const day = date?.getDate();
        const month = date?.getMonth()! + 1;
        const year = date?.getFullYear();

        if (user) refreshGlobalPresences(user?.organization?.uuid!, user?.uuid!, day, month, year);
      })
      .catch((error) => {
        console.error('Erro ao cadastrar ponto', error);
        toast.error(error.response.data.message);
      });
  }

  useEffect(() => {
    const date = new Date();

    const day = date?.getDate();
    const month = date?.getMonth()! + 1;
    const year = date?.getFullYear();

    if (user) setIsLoading(true);
    refreshGlobalPresences(user?.organization?.uuid!, user?.uuid!, day, month, year).finally(() => {
      setIsLoading(false);
    });
  }, [user]);

  useEffect(() => {
    if (lastPresence && presenceHoursSum) {
      var dif = moment.duration(moment(new Date()).diff(moment(lastPresence?.time)));

      setCurrentPresenceCounterSum(addTimes([presenceHoursSum, [dif.hours(), dif.minutes(), dif.seconds()].join(':')]));
    }
    const interval = setInterval(() => {
      if (lastPresence) {
        setCurrentPresenceCounterSum((prevState) => addTimes([prevState ?? '00:00:00', '00:00:01']));
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [lastPresence, presenceHoursSum]);

  return (
    <main className="bg-gray-50 overflow-y-hidden h-full max-w-xl mx-auto w-full">
      {user?.work_type == 'hybrid' && (
        <div className="w-full flex rounded-lg shadow-lg bg-gray-50">
          <TabLink to={`/organizations/${user!.organization?.uuid}/dashboard/read-point/remote`}>{t('remote')}</TabLink>
          <TabLink to={`/organizations/${user!.organization?.uuid}/dashboard/read-point`}>{t('office')}</TabLink>
        </div>
      )}
      <section className="flex justify-center items-center mx-auto flex-col w-full h-full py-5 px-4">
        <div className="flex flex-col items-center justify-center gap-16 w-full">
          {isLoading && <PresenceHourSkeleton />}

          {(!isLoading && lastPresence?.type === 'in' && (
            <button onClick={savePresenceQrCode}>
              <PauseCircleIcon className="w-20 text-blue-900 bg-transparent" />
              <span className="text-normal font-normal text-black">{currentPresenceCounterSum}</span>
            </button>
          )) || (
            <button onClick={savePresenceQrCode}>
              <PlayCircleIcon className="w-20 text-blue-900 bg-transparent" />

              <span className="text-normal font-normal text-black">{presenceHoursSum}</span>
            </button>
          )}
          <FastdocBanner type="info" className="mx-auto text-blue-900">
            <QrCodeIcon className="w-8 h-8" />
            <span className="w-full "> {t('play_remote')}</span>
          </FastdocBanner>
        </div>
      </section>{' '}
    </main>
  );
}
