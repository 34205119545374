import { create } from 'zustand';

type LayoutState = {
  pageTitle?: string | null;
  setPageTitle: (pageTitle?: string | null) => void;
};

export const useLayout = create<LayoutState>((set) => ({
  pageTitle: undefined,
  setPageTitle: (pageTitle) => set({ pageTitle }),
}));
