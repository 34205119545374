import { HTMLAttributes } from 'react';
import EditProfile from './EditProfile';
import PasswordProfile from './PasswordProfile';
import Language from './Language';
import Col from '@components/Flex/Col';
import NotificationsSettings from './NotificationsSettings';

export interface AccountSettingsProps extends HTMLAttributes<HTMLDivElement> {}

export default function AccountSettings({ children, ...props }: AccountSettingsProps) {
  return (
    <Col className="items-start h-full p-1 md:p-2 w-full" gap={3} {...props}>
      <EditProfile />
      <PasswordProfile />
      <Language />
      <NotificationsSettings />
    </Col>
  );
}
