import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useAuth } from '@hooks/auth/useAuth';

import Input from '@components/Inputs';
import signInLogo from '../../assets/images/login-screen-logo.svg';
import { classNames } from '@components/Badges/StatusBadge';
import Button from '@components/Common/Buttons/Button';
import { useTranslation } from 'react-i18next';

export default function Reset() {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [messageError, setMessageError] = useState('');
  const { resetPassword } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const [isReseting, setIsReseting] = useState<boolean>(false);
  const { t } = useTranslation();

  const searchParams = new URLSearchParams(location.search);
  const email = searchParams.get('email') || '';
  const token = searchParams.get('token') || '';

  const handleResetPassword = async (e: React.FormEvent) => {
    setIsReseting(true);
    e.preventDefault();

    try {
      await resetPassword(email, token, password, confirmPassword);
      navigate('/');
    } catch (error: any) {
      console.log(error);
      if (error.response.data.errors) {
        const errors = error.response.data.errors;
        const message = Object.keys(errors)[0];
        setMessageError(errors[message][0]);
      } else {
        setMessageError(error.response.data.message);
      }

      console.error(error);
    } finally {
      setIsReseting(false);
    }
  };

  return (
    <main className="flex flex-col h-screen w-full items-center justify-center">
      <a href="/" className="max-md:max-w-[200px] lg:max-w-xs w-full">
        <img src={signInLogo} alt="logo" className={classNames('w-full')} />
      </a>
      <div className="flex flex-col justify-center items-center gap-2 mb-8 mt-3 text-center">
        <h1 className="text-black font-bold text-3xl">{t('reset_your_password')}</h1>
        <p className="text-sm font-medium text-primary hover:brightness-[.95]">
          {t('reset_your_password_instructions')}
        </p>
      </div>
      <form className="w-full flex flex-col px-6 max-w-[450px]" onSubmit={handleResetPassword}>
        <Input
          placeholder={t('password')}
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="border w-full p-3 rounded-t-lg focus:border-primary rounded-b-none"
        />

        <Input
          placeholder={t('confirm_password')}
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          className="border-primary/90 text-primary ring-primary border-primary"
        />
        {messageError && <p className="text-red-500 mt-2">{messageError}</p>}

        <Button
          buttonType={Button.Type.PRIMARY}
          className={isReseting ? 'opacity-50 cursor-not-allowed' : ''}
          disabled={isReseting}
        >
          {isReseting ? t('reseting') : t('reset')}
        </Button>
      </form>
    </main>
  );
}
