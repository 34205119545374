import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { AxiosContext } from './AxiosInstanceProvider';
import axios, { AxiosInstance } from 'axios';

const useAxios = (url?: string, method?: string, payload?: any) => {
  const [data, setData] = useState<any>(null);
  const [error, setError] = useState<any>('');
  const [loading, setLoading] = useState<boolean>(false);
  const contextInstance = useContext(AxiosContext);

  const instance = useMemo<AxiosInstance | undefined>(() => {
    return contextInstance || axios;
  }, [contextInstance]);

  const controllerRef = useRef(new AbortController());

  const cancel = () => {
    controllerRef.current.abort();
  };

  const userJson = sessionStorage.getItem('user-auth');
  let token = '';

  if (userJson) {
    try {
      const userAuth = JSON.parse(userJson);
      token = userAuth?.state?.user?.token;
    } catch (error) {
      console.log('Erro na conversão do token', error);
    }
  }

  const request = async (requestUrl?: string, requestMethod?: string, requestPayload?: any) => {
    try {
      setLoading(true);
      const response = await instance?.request({
        signal: controllerRef.current.signal,
        data: requestPayload ?? payload,
        method: requestMethod ?? method,
        url: requestUrl ?? url,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setData(response?.data);

      return { cancel, data: response?.data, error, loading, request };
    } catch (error: any) {
      setError(error?.message);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (url && method && payload) {
      request(url, method, payload);
    }
  }, []);

  return { cancel, data, error, loading, request };
};

export default useAxios;
