import { useState } from 'react';
import { useAuth } from '@hooks/auth/useAuth';
import Button from '@components/Common/Buttons/Button';
import Input from '@components/Inputs';
import signInLogo from '../../assets/images/login-screen-logo.svg';
import { classNames } from '@components/Badges/StatusBadge';
import { useTranslation } from 'react-i18next';
import Col from '@components/Flex/Col';

export default function Forget() {
  const [email, setEmail] = useState('');
  const [isSending, setIsSending] = useState(false);
  const { forgetPassword } = useAuth();
  const [messageError, setMessageError] = useState('');
  const { t } = useTranslation();

  const handleForgetPassword = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      setIsSending(true);
      await forgetPassword(email);
      setEmail('');
    } catch (error: any) {
      setMessageError(error.response.data.message);
    } finally {
      setIsSending(false);
    }
  };

  return (
    <main className="flex flex-col h-screen w-full items-center justify-center max-w-lg lg:max-w-5xl mx-auto px-4 gap-8">
      <a href="/" className="max-md:max-w-[200px] lg:max-w-xs w-full">
        <img src={signInLogo} alt="logo" className={classNames('w-full')} />
      </a>
      <div className="flex flex-col justify-center items-center gap-2 px-4 text-center">
        <h1 className="text-black font-bold text-3xl">{t('password_recovery')}</h1>
        <p className="text-sm font-normal text-primary text-center">{t('password_recovery_instructions')}</p>
      </div>
      <form className="w-full flex flex-col gap-8 max-w-[450px]">
        <Col className="w-full justify-start items-start gap-2">
          <Input
            placeholder={t('email_address')}
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {messageError && <p className="text-red-500">{messageError}</p>}
        </Col>

        <Button
          buttonType={Button.Type.PRIMARY}
          className={isSending ? 'opacity-50 cursor-not-allowed' : ''}
          onClick={handleForgetPassword}
          disabled={isSending}
        >
          {isSending ? t('sending') : t('send')}
        </Button>
      </form>
    </main>
  );
}
