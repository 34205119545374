import { create } from 'zustand';
import { api } from '../../services/api';
import { User } from './useUser';

export interface UserVacations {
  id?: number;
  uuid?: string;
  user_id?: number;
  vacation_days?: number;
  vacation_year?: string;
  user?: User;
  _method?: string;
}

interface UserVacationsProps {
  userVacations: UserVacations[];
  getUserVacations: (organizationUuid: string, userId: string | number) => Promise<void>;
  createUserVacations: (organizationUuid: string, userVacations: UserVacations) => Promise<void>;
  editUserVacations: (organizationUuid: string, userVacations: UserVacations) => Promise<void>;
  deleteUserVacations: (organizationUuid: string, userVacationsUuid: string) => Promise<void>;
}

export const useUserVacations = create<UserVacationsProps>((set) => ({
  userVacations: [],
  getUserVacations: async (organizationUuid: string, userId: string | number) => {
    try {
      const { data } = await api.get(`/api/organizations/${organizationUuid}/user-vacations?user_id=${userId}`);
      set({ userVacations: data.content.data.data });
    } catch (error) {
      throw error;
    }
  },
  createUserVacations: async (organizationUuid: string, userVacations: UserVacations) => {
    try {
      await api.post(`/api/organizations/${organizationUuid}/user-vacations`, userVacations);
    } catch (error) {
      throw error;
    }
  },
  editUserVacations: async (organizationUuid: string, userVacations: UserVacations) => {
    try {
      await api.post(`/api/organizations/${organizationUuid}/user-vacations/${userVacations.uuid}`, userVacations);
    } catch (error) {
      throw error;
    }
  },
  deleteUserVacations: async (organizationUuid: string, userVacationsUuid: string) => {
    try {
      await api.delete(`/api/organizations/${organizationUuid}/user-vacations/${userVacationsUuid}`);
    } catch (error) {
      throw error;
    }
  },
}));
