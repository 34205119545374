import { Link, LinkProps, matchPath, useLocation } from 'react-router-dom';

export default function TabLink({ to, ...props }: LinkProps) {
  const location = useLocation();

  return (
    <Link
      className={`${
        matchPath(location.pathname, to as string)
          ? 'border-solid border-b-2 border-blue-900 text-gray-900 shadow-md'
          : 'border-solid border-l border-gray-200 text-primary/90'
      } w-full px-4 pt-4 pb-3  text-center text-sm first:rounded-l-md last:rounded-r-md font-normal`}
      to={to}
      {...props}
    ></Link>
  );
}
