import { useEffect } from 'react';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import NavbarPresence from '../components/Presence/Navbar';
import { usePresences } from '../hooks/presence/usePresences';
import { useAbsences } from '../hooks/presence/useAbsences';
import 'react-toastify/dist/ReactToastify.css';
import { Outlet } from 'react-router-dom';

function Presence() {
  const { clearConfigPresences } = usePresences();
  const { clearConfigAbsences } = useAbsences();

  useEffect(() => {
    clearConfigPresences();
    clearConfigAbsences();
  }, [clearConfigAbsences, clearConfigPresences]);

  return (
    <main className="">
      <main className="max-w-[800px] flex flex-col mt-4 mx-auto md:shadow-md pb-10 rounded">
        <NavbarPresence />
        {<Outlet />}
      </main>
    </main>
  );
}

export default Presence;
