import { useAuth } from '@hooks/auth/useAuth'
import { Navigate, Outlet } from 'react-router-dom'

export default function ProtectedRoutes() {
  //needs to be replaced with a context variable
  // const isLoggedIn = true
  const { isAuthenticated, user } = useAuth();

  if(!isAuthenticated && !user) 
   return <Navigate to="/" />
  
  return <Outlet />
}
