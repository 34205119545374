import TabLink from '@components/Navbar/TabLink';
import { useAuth } from '@hooks/auth/useAuth';
import { useLayout } from '@hooks/layout/useLayout';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

function NavbarReports() {
  const { t } = useTranslation();
  const { user } = useAuth();

  const { setPageTitle } = useLayout();

  useEffect(() => {
    setPageTitle(t('reports'));

    return () => {
      setPageTitle(null);
    };
  }, []);
  return (
    <div className="w-full flex rounded-lg shadow-lg mb-5 bg-gray-100 flex-wrap md:flex-nowrap">
      <TabLink to={`/organizations/${user?.organization?.uuid}/dashboard/reports/displacements`}>
        {t('displacement')}
      </TabLink>
      <TabLink to={`/organizations/${user?.organization?.uuid}/dashboard/reports/expenses`}>{t('expenses')}</TabLink>
      <TabLink to={`/organizations/${user?.organization?.uuid}/dashboard/reports/vacations`}>{t('vacations')}</TabLink>
      <TabLink to={`/organizations/${user?.organization?.uuid}/dashboard/reports/absences`}>{t('absences')}</TabLink>
    </div>
  );
}

export default NavbarReports;
