import axios, {
  AxiosInstance,
  AxiosInterceptorManager,
  AxiosInterceptorOptions,
  AxiosRequestConfig,
  CreateAxiosDefaults,
} from 'axios';

import { createContext, useEffect, useRef } from 'react';

interface AxiosInstanceProviderProps extends CreateAxiosDefaults<any> {
  children: JSX.Element | JSX.Element[];
  config: AxiosRequestConfig;
  requestInterceptors?: AxiosInterceptorOptions[];
  responseInterceptors?: AxiosInterceptorManager<any>[];
}

export const AxiosContext = createContext<AxiosInstance>({} as AxiosInstance);

export const AxiosInstanceProvider = ({
  config = {},
  requestInterceptors = [],
  responseInterceptors = [],
  children,
}: AxiosInstanceProviderProps): any => {
  const instanceRef = useRef(axios.create(config));

  useEffect(() => {
    requestInterceptors.forEach((interceptor: any) => {
      instanceRef?.current?.interceptors?.request?.use?.(interceptor);
    });
    responseInterceptors.forEach((interceptor: any) => {
      instanceRef?.current?.interceptors?.response?.use?.(interceptor);
    });
  }, []);

  return <AxiosContext.Provider value={instanceRef?.current}>{children}</AxiosContext.Provider>;
};
