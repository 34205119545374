import Col from '@components/Flex/Col';
import { CalendarIcon } from '@heroicons/react/24/outline';

export default function NoRequestsMessage({ message }: { message: string }) {
  return (
    <Col className="w-full justify-center items-center mx-auto gap-4">
      <div className="rounded-lg p-3 bg-gray-300">
        <CalendarIcon className="h-6 w-6 text-white" />
      </div>
      <span className="text-gray-400 text-base">{message}</span>
    </Col>
  );
}
