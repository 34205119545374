import { useEffect, useState } from 'react';

import { Organization, useOrganization } from '../../hooks/organization/useOrganization';

import { ModalDelete } from '../../components/Modal/ModalDelete';
import CardHome from '@components/Home/CardHome';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ClipboardDocumentCheckIcon, CurrencyEuroIcon, MapPinIcon, UserCircleIcon } from '@heroicons/react/24/solid';
import Loading from '@components/loading';

export default function ReadOrganization() {
  const { t } = useTranslation();
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [organization, setOrganization] = useState<Organization | null>(null);
  const { getOrganizationById, deleteOrganization } = useOrganization();
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    if (!id) {
      return;
    }
    setLoading(true);
    async function getOrganization() {
      const { organization } = await getOrganizationById(id!);
      setOrganization(organization);
    }

    getOrganization().finally(() => {
      setLoading(false);
    });
  }, [getOrganizationById, id]);

  const handleDeleteButtonClick = () => {
    setIsModalDeleteOpen(true);
  };

  return (
    <main className="px-4">
      {loading ? (
        <Loading className="w-full mx-auto" />
      ) : (
        <>
          <div className="flex justify-between items-center gap-3">
            <h1 className="text-2xl text-gray-800 font-bold">
              <span className="text-lg font-semibold">{t('organization')}</span>
              <br></br> {organization?.name}
            </h1>
            <div className="flex gap-2">
              <Link
                to={`/dashboard/organization/${organization?.uuid}/edit`}
                className="bg-primary text-sm cursor-pointer hover:brightness-[.95] text-white font-medium w-max h-10 flex justify-center items-center px-2 rounded-md transition-all mr-3"
              >
                {t('edit_organization')}
              </Link>
              <a
                onClick={handleDeleteButtonClick}
                className="bg-gray-50 text-sm cursor-pointer flex justify-center items-center px-2 hover:brightness-[.95] text-gray-600 font-medium w-max h-10 rounded-md transition-all border border-solid border-primary/90"
              >
                {t('delete_organization')}
              </a>
            </div>
          </div>
          <div className="flex flex-col mt-4">
            <div className="grid lg:grid-cols-4 grid-cols-2 w-full justify-center gap-4">
              <CardHome
                link={`/organizations/${organization?.uuid}/dashboard/users`}
                description={t('users')}
                icon={<UserCircleIcon className="w-8 h-8" />}
                type="primary"
              />
              <CardHome
                link={`/organizations/${organization?.uuid}/dashboard/requests/absences`}
                description={t('requests')}
                icon={<ClipboardDocumentCheckIcon className="w-8 h-8" />}
                type="primary"
              />
              <CardHome
                link={`/organizations/${organization?.uuid}/dashboard/expenses/all`}
                description={t('expenses')}
                icon={<CurrencyEuroIcon className="w-8 h-8" />}
                type="primary"
              />
              <CardHome
                link={`/organizations/${organization?.uuid}/dashboard/locations`}
                description={t('locations')}
                icon={<MapPinIcon className="w-8 h-8" />}
                type="primary"
              />
            </div>
          </div>
        </>
      )}
      {isModalDeleteOpen && (
        <ModalDelete
          onConfirm={async () => {
            await deleteOrganization(organization?.uuid!);
            window.location.href = `/dashboard`;
          }}
          isOpen={isModalDeleteOpen}
          onClose={() => setIsModalDeleteOpen(false)}
          title={t('delete_organization')}
          content={`${t('youre_sure_you_want_to_delete_the_organization')} ${organization?.name}?`}
        />
      )}
    </main>
  );
}
