import { classNames } from '@components/Badges/StatusBadge';
import Loading from '@components/loading';
import { ButtonHTMLAttributes } from 'react';

export enum ButtonType {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
  ACCENT = 'accent',
  ERROR = 'error',
  SUCCESS = 'success',
}

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  buttonType?: ButtonType;
  loading?: boolean;
}

function Button({ buttonType = ButtonType.PRIMARY, children, loading, className, disabled, ...props }: ButtonProps) {
  let customStyles = '';

  switch (buttonType) {
    case ButtonType.SECONDARY:
      customStyles += 'bg-gray-50 border border-primary text-primary rounded-full border-primary';
      break;
    case ButtonType.TERTIARY:
      customStyles +=
        'bg-transparent text-black hover:text-white rounded-full hover:bg-secondary/60 active:bg-secondary/80';
      break;
    case ButtonType.ACCENT:
      customStyles +=
        'bg-secondary text-white rounded-full hover:text-white hover:bg-secondary bg-secondary border-secondary hover:bg-secondary/80 active:bg-secondary/60 ';
      break;
    default:
      customStyles += 'bg-primary text-white rounded-full hover:text-white hover:bg-primary/80 active:bg-primary/60';
      break;
  }
  return (
    <button
      {...props}
      disabled={disabled || loading}
      className={classNames(
        'w-full py-3 px-11 text-center transition-all disabled:cursor-not-allowed disabled:opacity-60 active:scale-[0.98] disabled:active:scale-100',
        className,
        customStyles,
      )}
    >
      {(loading && (
        <div className="flex flex-row gap-3 items-center w-full justify-center">
          {children}{' '}
          {loading && (
            <Loading
              className={
                buttonType === ButtonType.TERTIARY ||
                buttonType === ButtonType.PRIMARY ||
                buttonType === ButtonType.ACCENT
                  ? 'text-white'
                  : 'text-black'
              }
            />
          )}
        </div>
      )) ||
        children}
    </button>
  );
}

Button.Type = ButtonType;

export default Button;
