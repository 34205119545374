import { useAuth } from '@hooks/auth/useAuth';
import { DashboardSuperAdmin } from '@components/Dashboard/DashboardSuperAdmin';
import { DashboardAdmin } from '@components/Dashboard/DashboardAdmin';
import { DashboardEmployee } from '@components/Dashboard/DashboardEmployee';

export default function Home() {
  const { user } = useAuth();

  return (
    <main className="">
      <section className="w-full mb-8">
        {user?.roles && user.roles.length > 0 && (
          <>
            {user.roles[0].name === 'superAdmin' && <DashboardSuperAdmin />}
            {user.roles[0].name === 'admin' && <DashboardAdmin />}
            {user.roles[0].name === 'employee' && <DashboardEmployee />}
          </>
        )}
      </section>
    </main>
  );
}
